import * as tracksApi from '../api/tracks'
import {
  AUTO_DISMISS_SECONDS,
  MAX_DOWNLOAD_COUNT,
  NOTIFICATION_MESSAGES,
} from '../shared/utils'
import { AVAILABLE_FEATURES, isFeatureEnabled } from '../selectors/features'
import { defineMessages } from 'react-intl'
import { error, success } from 'react-notification-system-redux'
import { intl } from '../shared/IntlGlobalProvider'
import {
  isChromiumSelector,
  isMobileSelector,
  isSafariSelector,
} from '../selectors/device'
import { loadFromLocalStorage, saveToLocalStorage } from '../shared/utils'
import {
  openModal,
  SIGNUP_MODAL,
  WARNING_BANNER,
  WARNING_MODAL,
} from '../action_creators/modals'
import { push } from 'connected-react-router'
import {
  receiveDownloadAllTracks,
  receiveDownloadTrack,
  setDownloadTrackStatus,
} from './downloads'
import { removeTrack } from './crate'
import permissionsImg from 'spa/images/download_permissions.png'
import PQueue from 'p-queue'

// To avoid browsers from blocking concurrent download requests, we wait for 2 seconds between each download
export const DOWNLOAD_QUEUE = new PQueue({
  concurrency: 1,
  intervalCap: 1,
  interval: 2000,
  carryoverConcurrencyCount: true,
})

// To avoid failing requests on low bandwidth connections, we limit the number of concurrent requests to 10
export const FETCH_QUEUE = new PQueue({
  concurrency: 10,
  carryoverConcurrencyCount: true,
})

const CHROMIUM_DOWNLOAD_PERMISSION = 'chromiumDownloadPermission'

const notification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.songDownloadTitle),
    message: '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const localizedMessages = defineMessages({
  directoryNotExist: {
    defaultMessage: 'Directory does not exist',
    id: 'djcity.common.modals.directoryNotExist',
  },
  directoryWarningTitle: {
    defaultMessage: 'Having trouble downloading?',
    id: 'djcity.common.modals.directoryWarning.title',
  },
  directoryWarningBody: {
    defaultMessage:
      'Please make sure you pick a custom directory for the downloads other than the default system directories.',
    id: 'djcity.common.modals.directoryWarning.body',
  },
  browserDownloadsModalTitle: {
    defaultMessage: 'Having trouble downloading?',
    id: 'djcity.common.modals.browserDownloadsModal.title',
  },
  browserDownloadsModalBody: {
    defaultMessage:
      "Please make sure you have allowed downloads on your browser. If you have mistakenly selected to block downloads, refer to our guide <a href='https://support.djcity.com'>here</a> for step-by-step instructions on enabling downloads for DJ City.",
    id: 'djcity.common.modals.browserDownloadsModal.body',
  },
  browserDownloadBanner: {
    defaultMessage:
      "<b>Having trouble downloading?</b> Please make sure you have allowed downloads on your browser. If you have mistakenly selected to block downloads, refer to our guide <u><b><a href='https://support.djcity.com'>here</a></b></u> for step-by-step instructions on enabling downloads for DJ City.",
    id: 'djcity.common.modals.browserDownloadBanner',
  },
})

function canUserDownload(user) {
  return user && user.activeSubscription
}

export function throwDownloadError() {
  return function(dispatch, getState) {
    const user = getState().currentUser.user
    if (!canUserDownload(user)) {
      dispatch(
        error({
          position: 'tr',
          autoDismiss: AUTO_DISMISS_SECONDS,
          title: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberTitle),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.subscribeLabel),
            callback: () => {
              if (!user) {
                dispatch(openModal(SIGNUP_MODAL))
              } else {
                dispatch(push('/payment-details'))
              }
            },
          },
        })
      )
      return false
    }
  }
}

/**
 * Downloads all tracks for a given songId OR a specific array of tracks (for crate usage)
 * @param {string} songId - The ID of the song to download tracks for.
 * @param {Array} tracks - An array of tracks to be downloaded.
 */
export function batchDownload({ songId, tracks, trackList }) {
  return async function(dispatch, getState) {
    const state = getState()
    const { songsById } = state.songs
    const { user } = state.currentUser
    const isIosSafari = isSafariSelector(state) && isMobileSelector(state)
    const isChromium = isChromiumSelector(state)
    const isBulkDownloadEnabled = isFeatureEnabled(
      state.features,
      AVAILABLE_FEATURES.BULK_DOWNLOAD
    )

    if (!canUserDownload(user)) {
      dispatch(
        error({
          ...notification(intl),
          title: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberTitle),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.subscribeLabel),
            callback: () => {
              if (!user) {
                dispatch(openModal(SIGNUP_MODAL))
              } else {
                dispatch(push('/payment-details'))
              }
            },
          },
        })
      )
      return false
    }

    if (!isBulkDownloadEnabled) {
      dispatch(
        error({
          ...notification(intl),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.bulkDownloadDisabled
          ),
        })
      )
      return false
    }

    if (isIosSafari) {
      dispatch(
        error({
          ...notification(intl),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.bulkDownloadDisabledForSomeDevices
          ),
        })
      )
      return false
    }

    if (songId) {
      tracks = songsById[songId].types
    }

    if (tracks.some(v => v.downloadCount >= MAX_DOWNLOAD_COUNT)) {
      dispatch(
        error({
          ...notification(intl),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.batchError),
        })
      )
      return false
    }

    if (tracks.some(v => v.isDownloading)) {
      dispatch(
        error({
          ...notification(intl),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.downloadInProgressError
          ),
        })
      )
      return false
    }

    if (isChromium) {
      if (loadFromLocalStorage(CHROMIUM_DOWNLOAD_PERMISSION) === undefined) {
        // only first time display modal

        saveToLocalStorage(CHROMIUM_DOWNLOAD_PERMISSION, false)
        dispatch(
          openModal(WARNING_MODAL, {
            title: intl.formatMessage(
              localizedMessages.browserDownloadsModalTitle
            ),
            imageSrc: permissionsImg,
            message: intl.formatHTMLMessage(
              localizedMessages.browserDownloadsModalBody
            ),
          })
        )
      } else if (loadFromLocalStorage(CHROMIUM_DOWNLOAD_PERMISSION) === false) {
        // display banner
        dispatch(
          openModal(WARNING_BANNER, {
            message: intl.formatHTMLMessage(
              localizedMessages.browserDownloadBanner
            ),
            doNotShowAgainFunction: () =>
              saveToLocalStorage(CHROMIUM_DOWNLOAD_PERMISSION, true),
          })
        )
      }
    }

    tracks.forEach(({ rid, ttid }) => {
      dispatch(
        download({ songId: songId || rid, trackId: ttid, trackList }, true)
      )
    })
    if (songId) {
      dispatch(receiveDownloadAllTracks({ songId: songId, trackList }))
    }
  }
}

export function download({ songId, trackId, trackList }, fromBatch = false) {
  return async function(dispatch, getState) {
    const state = getState()
    const { user } = state.currentUser
    const isChromium = isChromiumSelector(state)

    if (!canUserDownload(user)) {
      dispatch(
        error({
          ...notification(intl),
          title: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberTitle),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.subscribeLabel),
            callback: () => {
              if (!user) {
                dispatch(openModal(SIGNUP_MODAL))
              } else {
                dispatch(push('/payment-details'))
              }
            },
          },
        })
      )
      return false
    }

    if (
      !fromBatch &&
      isChromium &&
      loadFromLocalStorage(CHROMIUM_DOWNLOAD_PERMISSION) === false
    ) {
      // display banner
      dispatch(
        openModal(WARNING_BANNER, {
          message: intl.formatHTMLMessage(
            localizedMessages.browserDownloadBanner
          ),
          doNotShowAgainFunction: () =>
            saveToLocalStorage(CHROMIUM_DOWNLOAD_PERMISSION, true),
        })
      )
    }

    dispatch(setDownloadTrackStatus({ songId, trackId, isDownloading: true }))

    return tracksApi
      .download({ rid: songId, tid: trackId })
      .then(async res => {
        switch (res.status) {
          case 200: {
            FETCH_QUEUE.add(async () => {
              try {
                const signedUrlResponse = await fetch(res.data.signedUrl)
                const blob = await signedUrlResponse.blob()
                const blobUrl = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = blobUrl
                a.download = res.data.filename

                DOWNLOAD_QUEUE.add(() => {
                  a.click()
                  tracksApi.markDownloaded({ rid: songId, tid: trackId })
                  setTimeout(() => window.URL.revokeObjectURL(blobUrl), 2000)
                  dispatch(
                    success({
                      ...notification(intl),
                      message: res.data.filename,
                    })
                  )
                  dispatch(receiveDownloadTrack({ songId, trackId, trackList }))

                  dispatch(
                    removeTrack({ songId, trackId, displayNotification: false })
                  )
                })
              } catch (err) {
                dispatch(
                  error({
                    ...notification(intl),
                    message: `${err.message} / ${res.data.filename}`,
                  })
                )
                dispatch(
                  setDownloadTrackStatus({
                    songId,
                    trackId,
                    isDownloading: false,
                  })
                )
              }
            })
            break
          }
          case 400:
          case 403:
            dispatch(
              error({
                ...notification(intl),
                message: res.data.error.userDescription,
              })
            )
            dispatch(
              setDownloadTrackStatus({
                songId,
                trackId,
                isDownloading: false,
              })
            )
            return false
        }
      })
      .catch(() =>
        dispatch(
          setDownloadTrackStatus({
            songId,
            trackId,
            isDownloading: false,
          })
        )
      )
  }
}
