import '../../../lib/initFontAwesome'

import { addLocaleData } from 'react-intl'
import { AppWrapper } from '../../components/app_wrapper'
import { createBrowserHistory } from 'history'
import { ErrorMessage } from '../../components/error'
import { hot } from 'react-hot-loader'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import Init from './init'
import ja from 'react-intl/locale-data/ja'
// import MobileOverlay from "../../components/mobile_overlay";

import '@stripe/stripe-js'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import { BATCH_JOB_LIST } from '../../components/worker/jobs'
import { DownloadsListener } from '../../components/browser_downloads'
import { ModalWarning } from '../../components/warnings'
import { Notifications } from '../../components/notifications'
import { ScrollbarMemory } from '../../components/scrollbar_memory/scrollbar_memory'
import BatchWorker from '../../components/worker/batchWorker'
import configureStore from './configureStore'
import ForgotPasswordModal from '../../components/forgot_password_modal'
import IntlGlobalProvider from '../../shared/IntlGlobalProvider'
import KeyboardShortcutsModal from 'spa/components/keyboard_shortcuts_modal'
import LocaleProvider from 'spa/components/locale_provider'
import LoginModal from 'spa/components/login_modal'
import NewSignUp from 'spa/components/newsignup'
import PlaybackErrorModal from '../../components/player/playbackErrorModal'
import React, { StrictMode } from 'react'
import ResetPasswordModal from '../../components/reset_password_modal'
import Routes from './routes'
import ScrollToTop from '../../components/scroll/scroll_to_top'
import UpdatePasswordModal from '../../components/update_password_modal'

import '../../styles/globalRules.global.sass'

// Open App
addLocaleData([...en, ...es, ...ja, ...de])
const history = createBrowserHistory()
const { store, persistor } = configureStore(history)

// expose store when run in Cypress
if (window?.Cypress) {
  window.store = store
  window.storage = window.localStorage
}

// Setup Bugsnag
const bugsnagEnabled =
  window && !window.Cypress && !process.env.IS_TEST && process.env.BUGSNAG_KEY

let ErrorBoundary
if (bugsnagEnabled) {
  const Bugsnag = require('@bugsnag/js')
  const BugsnagPluginReact = require('@bugsnag/plugin-react')
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_KEY,
    appVersion: '1.0.0',
    plugins: [new BugsnagPluginReact(React)],
    enabledReleaseStages: ['production'],
    releaseStage: process.env.NODE_ENV,
  })
  ErrorBoundary = Bugsnag.getPlugin('react')
}

const renderContent = component => (
  <Init>
    <BatchWorker interval={15000} jobs={BATCH_JOB_LIST(store.dispatch)}>
      <ScrollbarMemory />
      <DownloadsListener />
      <ScrollToTop>{component}</ScrollToTop>
    </BatchWorker>
  </Init>
)

const renderApp = component => {
  render(
    <Provider store={store}>
      <Router history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <LocaleProvider>
            <IntlGlobalProvider>
              {bugsnagEnabled ? (
                <ErrorBoundary FallbackComponent={ErrorMessage}>
                  {renderContent(component)}
                </ErrorBoundary>
              ) : (
                renderContent(component)
              )}
            </IntlGlobalProvider>
          </LocaleProvider>
        </PersistGate>
      </Router>
    </Provider>,
    document.getElementById('root')
  )
  hot(module)(component)
}

renderApp(
  <StrictMode>
    <AppWrapper>
      <Routes />
      <NewSignUp />
      <LoginModal />
      <ForgotPasswordModal />
      <KeyboardShortcutsModal />
      <UpdatePasswordModal />
      <ResetPasswordModal />
      <PlaybackErrorModal />
      <Notifications />
      <ModalWarning />
      {/* <MobileOverlay /> */}
    </AppWrapper>
  </StrictMode>
)

if (module.hot) {
  module.hot.accept('../../apps/public/routes', () => {
    const newRoutes = require('../../apps/public/routes').default
    renderApp(newRoutes)
  })
  module.hot.accept('../../reducers', () => {
    const nextCreateRootReducer = require('../../reducers/index').default
    store.replaceReducer(nextCreateRootReducer(history))
  })
}
