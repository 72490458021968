import {
  CLEAR_GLOBAL_SONGS,
  RECEIVE_GLOBAL_SONGS,
  REQUEST_GLOBAL_SONGS,
} from '../action_constants'
import {
  defaultGlobalFilters,
  defaultPagination,
  defaultSortBy,
} from '../api/songs'

import { createSongsListReducer } from './create_songs_list_reducer'

export default createSongsListReducer({
  defaultGlobalFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_GLOBAL_SONGS,
  receiveActionConstant: RECEIVE_GLOBAL_SONGS,
  clearActionConstant: CLEAR_GLOBAL_SONGS,
})

export function clearNewReleases() {
  return {
    type: CLEAR_GLOBAL_SONGS,
    filters: defaultGlobalFilters,
    sortBy: defaultSortBy,
    pagination: defaultPagination,
  }
}
