import { LOCALE_VALUES, LocaleFilterPanel } from '.'
import { Popover } from 'spa/components/popover'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { FilterButtonIcon } from './filter_button_icon'
import styles from './styles'

export class LocalePopover extends React.PureComponent {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }

  render() {
    const { onChange, value, defaultValue } = this.props
    return (
      <Popover
        renderTrigger={({ handleClick, open, ref }) => (
          <button
            className={classNames({
              popoverTrigger: !open,
              popoverTriggerOpen: open,
              [styles.filtered]: value !== defaultValue,
            })}
            onClick={handleClick}
            ref={ref}
          >
            {LOCALE_VALUES[value]} <FilterButtonIcon isOpen={open} />
          </button>
        )}
      >
        <LocaleFilterPanel onChange={onChange} value={value} />
      </Popover>
    )
  }
}
