import {
  CLEAR_CURRENT_USER,
  ERROR_RECEIVING_USER_FEATURES,
  RECEIVE_USER_FEATURES,
  REQUEST_USER_FEATURES,
} from '../action_constants'

const defaultState = {
  features: [],
  isFetching: false,
  error: null,
}

export default function features(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_USER_FEATURES:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case RECEIVE_USER_FEATURES:
      return {
        ...state,
        isFetching: false,
        features: action.features,
      }
    case ERROR_RECEIVING_USER_FEATURES:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case CLEAR_CURRENT_USER:
      return defaultState
    default:
      return state
  }
}
