import { CURRENCY_SYMBOL_MAP } from '../../shared/utils'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import { Heading } from '../typography'
import { SIGNUP_MODAL } from 'spa/action_creators/modals'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

function WelcomeBackCampaignHeader({ isLoginScreen }) {
  const { promoData } = useSelector(state => state.promos)
  const isFromPlayButton = useSelector(
    state => state.modals[SIGNUP_MODAL].data?.isFromPlayButton
  )

  if (isLoginScreen && promoData)
    return (
      <>
        <Heading className={styles.heading}>
          <FormattedHTMLMessage
            defaultMessage="Login to redeem your <span>{currency}{price} offer</span>"
            id="djcity.account.login.campaign.welcome_back.title"
            values={{
              currency: CURRENCY_SYMBOL_MAP[promoData.promoCurrency],
              price: promoData.promoPrice,
            }}
          />
        </Heading>
        <div className={styles.subtitle}>
          <FormattedMessage
            defaultMessage="{currency}{price} for first month* Cancel anytime."
            id="djcity.account.login.campaign.welcome_back.subtitle"
            values={{
              currency: CURRENCY_SYMBOL_MAP[promoData.promoCurrency],
              price: promoData.promoPrice,
            }}
          />
        </div>
      </>
    )

  return isFromPlayButton ? (
    <Heading className={styles.heading}>
      <FormattedHTMLMessage
        defaultMessage="REGISTER FOR <span>FREE</span> PREVIEW ACCESS"
        id="djcity.common.register_for_free"
      />
    </Heading>
  ) : (
    <>
      <Heading className={styles.heading}>
        <FormattedHTMLMessage
          defaultMessage="LET'S GET STARTED"
          id="djcity.common.welcome"
        />
      </Heading>
      <div className={styles.subtitle}>
        <FormattedMessage
          defaultMessage="Create an account"
          id="djcity.account.signup.createaccount"
        />
      </div>
    </>
  )
}

WelcomeBackCampaignHeader.propTypes = {
  isLoginScreen: PropTypes.bool,
}

export default injectIntl(WelcomeBackCampaignHeader)
