import { CategoryButton } from 'spa/components/button/category_button'
import { connect } from 'react-redux'
import { standardFiltersShape } from 'spa/api/songs'
import intersection from 'lodash/intersection'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const GENRE_TAGS = Object.freeze({
  HIP_HOP: [1],
  DANCE: [102],
  LATIN: [3],
  POP: [4],
  'R&B': [5],
  AFRICAN: [36],
  OTHER: [8],
  JAPAN: [107, 130, 141, 190],
  GERMANY: [126, 127, 128, 131, 178, 197],
  UK: [22, 23, 24, 25, 26, 27, 28, 37, 54, 57, 78, 188],
})

export const REGION_IDS = {
  JAPAN: 2,
  UK: 3,
  GERMANY: 4,
}

export const GENRE_TAG_TO_LABEL = {
  1: 'Hip Hop',
  102: 'Dance',
  3: 'Latin',
  4: 'Pop',
  5: 'R&B',
  36: 'African',
  8: 'Other',
  22: 'UK',
  23: 'UK',
  24: 'UK',
  25: 'UK',
  26: 'UK',
  27: 'UK',
  28: 'UK',
  37: 'UK',
  54: 'UK',
  57: 'UK',
  78: 'UK',
  188: 'UK',
  107: '日本',
  130: '日本',
  141: '日本',
  190: '日本',
  126: 'DE',
  127: 'DE',
  128: 'DE',
  131: 'DE',
  178: 'DE',
  197: 'DE',
}

const BASE_TAGS = ['HIP_HOP', 'DANCE', 'LATIN', 'POP', 'R&B', 'AFRICAN']

export const LOCALE_TAGS = {
  'en-GB': ['UK'],
  'de-DE': ['GERMANY'],
  'ja-JP': ['JAPAN'],
}

class CategoryFilterPanel extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    filters: PropTypes.shape(standardFiltersShape),
    locale: PropTypes.string,
    onChange: PropTypes.func,
    showLocaleButtons: PropTypes.bool,
  }

  static defaultProps = {
    showLocaleButtons: true,
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props

    if (prevProps.locale !== locale) {
      this.handleGenreChange([], 0)
    }
  }

  handleGenreChange = (genres, regionId = 0) => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, genres, regionId, page: 1 })
    }
  }

  handleChange = (genre, regionId, checked) => {
    const { onChange } = this.props
    const newGenre = checked ? genre : null
    const newRegionId = checked ? regionId : 0
    if (onChange) {
      this.handleGenreChange(newGenre, newRegionId)
    }
  }

  render() {
    const {
      children,
      filters: { genres } = null,
      locale,
      showLocaleButtons,
    } = this.props

    const GenreButton = genreTagKey => (
      <CategoryButton
        checked={intersection(genres, GENRE_TAGS[genreTagKey]).length > 0}
        key={genreTagKey}
        labelText={GENRE_TAG_TO_LABEL[GENRE_TAGS[genreTagKey][0]]}
        onChange={e =>
          this.handleChange(
            GENRE_TAGS[genreTagKey],
            REGION_IDS[genreTagKey] || 0,
            e.target.checked
          )
        }
      />
    )

    const buttons = BASE_TAGS.map(GenreButton)
    if (showLocaleButtons && LOCALE_TAGS[locale]) {
      buttons.push(...LOCALE_TAGS[locale].map(GenreButton))
    }

    return (
      <div className={styles.checkboxRow}>
        {buttons}
        {children}
        {GenreButton('OTHER')}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  locale: state.locale ? state.locale.locale : null,
  ...props,
})

const ConnectedCategoryFilterPanel = connect(mapStateToProps)(
  CategoryFilterPanel
)

export { ConnectedCategoryFilterPanel as CategoryFilterPanel }
