import { Layout } from '../layout'
import { ModuleLoader } from '../module_loader/module_loader'
import React from 'react'
const Marketing = React.lazy(() => import('../pages/marketing/marketing'))
const ForgotPasswordView = React.lazy(() => import('./forgot_password_view'))

const display = () => {
  return (
    <Layout fullLayout tabTitle="forgot_password">
      <ModuleLoader>
        <Marketing />
        <ForgotPasswordView />
      </ModuleLoader>
    </Layout>
  )
}

export { display as ForgotPasswordDisplay }
