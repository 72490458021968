import { FilterPanel } from './filter_panel'
import { FormattedMessage } from 'react-intl'
import { Slider } from '../slider'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles'

export const MIN_BPM = 0
export const MAX_BPM = 200

export const BpmFilterPanel = ({ bpmgt, bpmlt, className, onChange }) => {
  const handleChange = useCallback(([bpmgt, bpmlt]) => {
    if (onChange) {
      onChange({ bpmgt, bpmlt })
    }
  }, onChange)

  return (
    <FilterPanel
      className={className}
      title={
        <FormattedMessage
          defaultMessage="BPM Range"
          id="djcity.common.filter.BRMRange"
        />
      }
    >
      <div className={styles.bpmContent}>
        <Slider
          defaultValue={[bpmgt, bpmlt]}
          max={MAX_BPM}
          min={MIN_BPM}
          onChange={handleChange}
        />
      </div>
    </FilterPanel>
  )
}

BpmFilterPanel.propTypes = {
  bpmgt: PropTypes.number,
  bpmlt: PropTypes.number,
  className: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  onChange: PropTypes.func,
}
