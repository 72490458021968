import { loggedInSelector } from '../spa/selectors/users'
import { LOGIN_MODAL, openModal } from 'spa/action_creators/modals'
import { MaintenanceDisplay } from '../spa/components/pages/maintenance'
import { maintenanceSelector } from '../spa/selectors/maintenance'
import { MarketingDisplay } from '../spa/components/pages/marketing'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

export const ApplicationRoute = ({ requireAuth = false, ...rest }) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(loggedInSelector)
  const maintenanceMode = useSelector(maintenanceSelector)

  if (maintenanceMode) {
    return <MaintenanceDisplay />
  }
  if (!isLoggedIn && requireAuth) {
    dispatch(openModal(LOGIN_MODAL))
    return <MarketingDisplay />
  }
  return <Route {...rest} />
}

ApplicationRoute.propTypes = {
  requireAuth: PropTypes.bool,
}
