import { closeMenu, MenuItem } from 'react-aria-menubutton'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

export const NavMenuContentItem = ({
  children,
  className,
  href,
  menuId,
  navigateTo,
  highlight = true,
  onClick,
  ...rest
}) => {
  const handleClick = useCallback(
    e => {
      closeMenu(menuId)
      if (navigateTo) {
        e.preventDefault()
        navigateTo(href)
      }
      if (onClick) {
        onClick(e)
      }
    },
    [menuId, navigateTo, href, onClick]
  )

  return (
    <MenuItem
      className={classNames(styles.navMenuContentItem, className, {
        [styles.active]: location.pathname === href && highlight,
      })}
      href={href}
      onClick={handleClick}
      tag="a"
      {...rest}
    >
      {children}
    </MenuItem>
  )
}

NavMenuContentItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  highlight: PropTypes.bool,
  href: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  navigateTo: PropTypes.func,
  onClick: PropTypes.func,
}
