import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const Heading = ({ pull, className, color, ...rest }) => {
  const cls = classNames(
    styles.heading,
    {
      [styles.white]: color === 'white',
      [styles.black]: color === 'black',
    },
    className,
    pull ? styles.pull : null
  )
  return <h1 className={cls} {...rest} />
}

Heading.displayName = 'Heading'

Heading.propTypes = {
  className: PropTypes.string,
  /** Default behavior is to inherit color */
  color: PropTypes.oneOf(['white', 'black']),
  pull: PropTypes.bool,
}
