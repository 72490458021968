import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import spinnerImg from 'spa/images/svg/spinner.svg'

import styles from './spinner_button.sass'

export function SpinnerButton({
  children = null,
  className = null,
  loading = false,
  disabled = false,
  ...rest
}) {
  return (
    <button
      className={classNames(styles.spinnerButton, className, {
        [styles.loading]: loading,
        inactive: disabled,
      })}
      {...rest}
    >
      <span className={styles.spinnerButtonContent}>{children}</span>
      {loading ? <img className={styles.spinner} src={spinnerImg} /> : null}
    </button>
  )
}

SpinnerButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}
