import * as songsApi from '../api/songs'
import {
  CLEAR_AUTOCOMPLETE_SONGS,
  RECEIVE_AUTOCOMPLETE_SONGS,
  REQUEST_AUTOCOMPLETE_SONGS,
} from '../action_constants'
import { SAVE_AUTOCOMPLETE_KEYSTROKE } from '../action_constants'

function requestAutocompleteSongs({ filters, searchTerm, sortBy }) {
  return {
    type: REQUEST_AUTOCOMPLETE_SONGS,
    filters: { ...songsApi.defaultFilters, ...filters },
    searchTerm,
    sortBy,
  }
}

function receiveAutocompleteSongs({
  filters,
  searchTerm,
  suggestions,
  sortBy,
}) {
  return {
    type: RECEIVE_AUTOCOMPLETE_SONGS,
    filters,
    searchTerm,
    suggestions,
    sortBy,
  }
}

export function saveAutocompleteSearchKeys(input) {
  return {
    type: SAVE_AUTOCOMPLETE_KEYSTROKE,
    input,
  }
}

export function autocompleteSearch({ filters, searchTerm, sortBy }) {
  return async function(dispatch) {
    dispatch(requestAutocompleteSongs({ filters, searchTerm, sortBy }))
    // Save off autocomplete search term
    dispatch(saveAutocompleteSearchKeys(searchTerm))
    let suggestions = []
    const response = await songsApi.autocomplete(searchTerm)
    suggestions = response.suggestions

    return dispatch(
      receiveAutocompleteSongs({
        filters,
        searchTerm,
        suggestions,
        sortBy,
      })
    )
  }
}

export function clearAutocompleteSongs() {
  return {
    type: CLEAR_AUTOCOMPLETE_SONGS,
  }
}
