import get from 'lodash/get'

export const isMobileSelector = state =>
  get(state, 'device.platform.type') === 'mobile'

export const isDesktopSelector = state =>
  get(state, 'device.platform.type') === 'desktop'

export const isTabletSelector = state =>
  get(state, 'device.platform.type') === 'tablet'

export const isIosSelector = state => get(state, 'device.os.name') === 'iOS'

export const isAndroidSelector = state =>
  get(state, 'device.os.name') === 'Android'

export const isSafariSelector = state =>
  get(state, 'device.engine.name') === 'WebKit'

export const isChromiumSelector = state =>
  get(state, 'device.engine.name') === 'Blink'
