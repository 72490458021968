import { defineMessages, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const localizeMessages = defineMessages({
  addToQueue: {
    id: 'djcity.records.queue.add',
    defaultMessage: 'Add to Preview Queue',
  },
  removeFromQueue: {
    id: 'djcity.records.queue.remove',
    defaultMessage: 'Remove from Preview Queue',
  },
})

const PreviewQueueButton = ({ currentlyInQueue, onClick, intl, className }) => {
  return (
    <button
      className={classNames(styles.queueButton, className)}
      onClick={onClick}
    >
      <FontAwesomeIcon className={styles.icon} icon="list-alt" />
      {currentlyInQueue
        ? intl.formatMessage(localizeMessages.removeFromQueue)
        : intl.formatMessage(localizeMessages.addToQueue)}
    </button>
  )
}

PreviewQueueButton.displayName = 'PreviewQueueButton'

PreviewQueueButton.propTypes = {
  className: PropTypes.string,
  currentlyInQueue: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

const IntlPreviewQueueButton = injectIntl(PreviewQueueButton)
export { IntlPreviewQueueButton as PreviewQueueButton }
