import { refreshUser } from '../../action_creators/current_user'
import { updateSongsAsPlayed } from '../../action_creators/player'
function dispatchJob(dispatch, func) {
  return () => {
    dispatch(func())
  }
}
export const BATCH_JOB_LIST = dispatch => [
  dispatchJob(dispatch, updateSongsAsPlayed),
  dispatchJob(dispatch, refreshUser),
]
