import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'

import { SHORTCUTS } from './localizedMessages'
import { SHORTCUTS_MODAL } from '../../action_creators/modals'
import { TextStyle } from 'spa/components/typography/text_style'
import DarkModal from '../application_modal/dark_modal'

import styles from './styles'

const KeyboardShortcuts = ({ intl }) => {
  return (
    <DarkModal
      modalKey={SHORTCUTS_MODAL}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      title={intl.formatMessage(SHORTCUTS.modal.title)}
    >
      <div className={styles.section}>
        <TextStyle
          color="white"
          style={{ fontSize: '18px', marginBottom: '8px', marginTop: '16px' }}
          tagName="h3"
          uppercase={true}
          variant="bold"
        >
          {intl.formatMessage(SHORTCUTS.modal.sectionOneTitle)}
        </TextStyle>
        <ul className={styles.shortcutsList}>
          {Object.values(SHORTCUTS.titles).map((val, idx) => (
            <li className={styles.shortcutsItem} key={idx}>
              <p>
                <span>{intl.formatMessage(val)}</span>
              </p>
              <p>
                <FormattedHTMLMessage
                  {...Object.values(SHORTCUTS.descriptions)[idx]}
                />
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.section}>
        <TextStyle
          color="white"
          style={{ fontSize: '18px', marginBottom: '8px', marginTop: '16px' }}
          tagName="h3"
          uppercase={true}
          variant="bold"
        >
          {intl.formatMessage(SHORTCUTS.modal.sectionTwoTitle)}
        </TextStyle>
        <ul className={styles.shortcutsList}>
          <li className={styles.shortcutsItem}>
            <TextStyle
              color="white"
              style={{
                fontSize: '14px',
                marginBottom: '8px',
              }}
              tagName="span"
              uppercase={true}
              variant="bold"
            >
              {intl.formatMessage(SHORTCUTS.titlesTwo.autoplay)}
            </TextStyle>
            <TextStyle
              color="white"
              style={{
                fontSize: '14px',
                marginBottom: '8px',
              }}
              tagName="span"
              uppercase={true}
              variant="bold"
            >
              {intl.formatMessage(SHORTCUTS.descriptionsTwo.autoplay)}
            </TextStyle>
          </li>
        </ul>
      </div>
    </DarkModal>
  )
}

KeyboardShortcuts.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func,
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(KeyboardShortcuts)
