import { closeModal, WARNING_MODAL } from 'spa/action_creators/modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import Modal from 'react-modal'
import React from 'react'
import styles from './styles'

export function ModalWarning() {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.modals[WARNING_MODAL].enabled)
  const data = useSelector(state => state.modals[WARNING_MODAL].data)

  const onCloseClick = () => dispatch(closeModal(WARNING_MODAL))

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onRequestClose={onCloseClick}
    >
      <div className={styles.modalHeader}>
        <div className={styles.heading}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={['fa', 'exclamation-circle']}
          />
          <span className={styles.title}>{data?.title || 'Warning'}</span>
        </div>
        <FontAwesomeIcon
          className={styles.closeButton}
          icon={['fal', 'times']}
          onClick={onCloseClick}
        />
      </div>
      <div className={styles.modalBody}>
        {data?.imageSrc && (
          <div className={styles.imageContainer}>
            <img alt="" src={data.imageSrc} />
          </div>
        )}
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: data?.message || 'Warning',
          }}
        ></div>
      </div>
      <div className={styles.modalFooter}>
        <button
          className={classNames('large', 'secondary')}
          onClick={onCloseClick}
        >
          <FormattedMessage
            defaultMessage="Acknowledged"
            id="djcity.common.modals.browserDownloadsModal.button"
          />
        </button>
      </div>
    </Modal>
  )
}
