import {
  ERROR_RECEIVING_PAYMENT_METHOD,
  RECEIVE_PAYMENT_METHOD,
  REQUEST_PAYMENT_METHOD,
} from '../action_constants'

const defaultState = {
  cardsOnFile: [],
  isLoading: false,
}

const paymentMethods = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_PAYMENT_METHOD:
      return { ...defaultState, isLoading: true }
    case RECEIVE_PAYMENT_METHOD:
      return {
        ...state,
        isLoading: false,
        cardsOnFile: action.cardsOnFile,
      }
    case ERROR_RECEIVING_PAYMENT_METHOD:
      return {
        ...defaultState,
        error: action.error,
      }
    default:
      return state
  }
}

export default paymentMethods
