import * as songsApi from '../api/songs'
import {
  CLEAR_REMIXER,
  CLEAR_REMIXERS,
  RECEIVE_ALL_REMIXERS,
  RECEIVE_REMIXER,
  RECEIVE_REMIXER_ERROR,
  RECEIVE_REMIXERS,
  RECEIVE_REMIXERS_ERROR,
  REQUEST_ALL_REMIXERS,
  REQUEST_REMIXER,
  REQUEST_REMIXERS,
} from '../action_constants'

function receiveRemixer(remixer) {
  return {
    type: RECEIVE_REMIXER,
    remixer,
  }
}

function requestRemixer() {
  return {
    type: REQUEST_REMIXER,
  }
}

function errorReceivingRemixer(error) {
  return {
    type: RECEIVE_REMIXER_ERROR,
    error,
  }
}

export function clearRemixer() {
  return {
    type: CLEAR_REMIXER,
  }
}

function receiveRemixers(remixers) {
  return {
    type: RECEIVE_REMIXERS,
    remixers,
  }
}

function requestRemixers() {
  return {
    type: REQUEST_REMIXERS,
  }
}

function requestAllRemixers() {
  return {
    type: REQUEST_ALL_REMIXERS,
  }
}

function receiveAllRemixers(remixers) {
  return {
    type: RECEIVE_ALL_REMIXERS,
    remixers,
  }
}

function errorReceivingRemixers(error) {
  return {
    type: RECEIVE_REMIXERS_ERROR,
    error,
  }
}

export function clearRemixers() {
  return {
    type: CLEAR_REMIXERS,
  }
}

export function fetchRemixer(id) {
  return async function(dispatch) {
    dispatch(requestRemixer())
    try {
      const remixer = await songsApi.remixer(id)
      return dispatch(receiveRemixer(remixer))
    } catch (e) {
      return dispatch(errorReceivingRemixer(e))
    }
  }
}

export function fetchRemixers(namePrefix) {
  return async function(dispatch) {
    dispatch(requestRemixers())
    try {
      const remixers = await songsApi.remixers(namePrefix)
      return dispatch(receiveRemixers(remixers))
    } catch (e) {
      return dispatch(errorReceivingRemixers(e))
    }
  }
}

export function fetchAllRemixers(params) {
  return async function(dispatch) {
    dispatch(requestAllRemixers())
    try {
      const remixers = await songsApi.getAllremixers(params)
      return dispatch(receiveAllRemixers(remixers))
    } catch (e) {
      return dispatch(errorReceivingRemixers(e))
    }
  }
}
