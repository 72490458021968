import {
  autocompleteSearch,
  clearAutocompleteSongs,
} from 'spa/action_creators/autocomplete'
import { clearRemixers, fetchRemixers } from 'spa/action_creators/remixers'
import { defaultFilters, defaultSortBy } from 'spa/api/songs'
import { defineMessages, injectIntl } from 'react-intl'
import { fetchTags } from 'spa/action_creators/tags'
import { getQueryParams } from 'spa/shared/utils'
import {
  isEmptyOrSpaces,
  MINIMUM_AUTOCOMPLETE_QUERY_LENGTH,
} from 'spa/shared/utils'
import { SearchBox } from 'spa/components/search_box'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const localizeMessages = defineMessages({
  search: {
    id: 'djcity.common.site.banner.search',
    defaultMessage: 'Search artists, titles, producers...',
  },
})

const buildSearchURL = q => `/search?${getQueryParams({ q })}`

const buildRemixerURL = r => `/remixer/${r.slug}`

const buildTagURL = t => `/genre/${t.slug}`

function SongSearchBox({ className, intl, reset }) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const songs = useSelector(state => state.autocompleteSongs.suggestions)
  const remixers = useSelector(state => state.autocompleteRemixers.suggestions)
  const tags = useSelector(state => state.tags.tags)
  const isLoadingTags = useSelector(state => state.tags.isLoading)

  // Fetches tags if they haven't been fetched yet
  useEffect(() => {
    if (!tags && !isLoadingTags) {
      dispatch(fetchTags())
    }
  }, [dispatch, tags, isLoadingTags])

  // The text field states: current value and whether
  // it has focus or not
  const [value, setValue] = useState('')
  const [focus, setFocus] = useState(false)
  const inputRef = useRef()

  const onChange = useCallback(e => setValue(e.target.value), [setValue])
  const onFocus = useCallback(() => setFocus(true), [setFocus])
  const onBlur = useCallback(() => setFocus(false), [setFocus])
  const onReset = useCallback(() => {
    setValue('')
    if (typeof reset === 'function') {
      reset()
    }
  }, [setValue])

  // The function that searches songs
  const searchSuggestions = useCallback(
    debounce(searchTerm => {
      if (!isEmptyOrSpaces(searchTerm)) {
        dispatch(
          autocompleteSearch({
            filters: defaultFilters,
            searchTerm,
            sortBy: defaultSortBy,
          })
        )

        if (searchTerm.trim().length >= MINIMUM_AUTOCOMPLETE_QUERY_LENGTH) {
          dispatch(fetchRemixers(searchTerm))
        }
      }
    }, 100),
    [dispatch]
  )

  // Trigger a search when the user types something or clear the search results
  // if the query term is small
  useEffect(() => {
    if (value.trim().length < MINIMUM_AUTOCOMPLETE_QUERY_LENGTH) {
      dispatch(clearAutocompleteSongs())
      dispatch(clearRemixers())
    }
    searchSuggestions(value)
  }, [value, searchSuggestions])

  // The suggestions list
  const suggestions = useMemo(() => {
    const results = []
    if (value && focus) {
      // First, the tags
      if (tags) {
        const cleanSearchTerm = (value || '')
          .toLowerCase()
          .trim()
          .replace(/\s+/g, ' ')
        const filteredTags = tags.filter(
          tag =>
            tag.name && tag.name.toLowerCase().indexOf(cleanSearchTerm) >= 0
        )
        if (filteredTags.length) {
          results.push({
            title: 'Genres',
            suggestions: filteredTags.map(tag => ({
              value: tag.name,
              url: buildTagURL(tag),
            })),
          })
        }
      }

      // Then, the songs
      if (songs && songs.length) {
        results.push({
          title: 'Tracks',
          suggestions: songs.map(song => ({
            value: song.value,
            url: buildSearchURL(song.value),
          })),
        })
      }

      // The remixers
      if (remixers && remixers.length) {
        results.push({
          title: 'Remixers',
          suggestions: remixers.map(remixer => ({
            value: remixer.name,
            url: buildRemixerURL(remixer),
          })),
        })
      }
    }

    return results
  }, [value, focus, tags, songs, remixers])

  // What to do when the user hits "Enter" to submit a search
  const onSubmit = useCallback(
    query => {
      if (value && value.trim()) {
        if (inputRef.current) {
          inputRef.current.blur()
        }
        if (reset) reset()
        history.push(buildSearchURL(query))
      }
    },
    [value, inputRef.current, history]
  )

  // What to do when a selection is made
  const onSuggestionSelected = useCallback(
    suggestion => {
      if (inputRef.current) {
        inputRef.current.blur()
      }
      if (reset) reset()
      history.push(suggestion.url)
    },
    [inputRef.current, history]
  )

  // When navigation occurs, clear the search box
  useEffect(() => {
    setValue('')
  }, [location, setValue])

  return (
    <SearchBox
      className={className}
      id="songSearchBox"
      inputRef={inputRef}
      isFocused={focus}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onSubmit={onSubmit}
      onSuggestionSelected={onSuggestionSelected}
      placeholder={intl.formatMessage(localizeMessages.search)}
      reset={onReset}
      showActions={true}
      suggestions={suggestions}
      value={value}
    />
  )
}

SongSearchBox.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  reset: PropTypes.func,
}

const LocalizedSongSearchBox = injectIntl(SongSearchBox)

export { LocalizedSongSearchBox as SongSearchBox }
