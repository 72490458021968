import { SET_TRACKING, SET_VOLUME, TOGGLE_AUTOPLAY } from 'spa/action_constants'

const defaultState = {
  autoplay: true,
  volume: 1,
  trackingAllowed: false,
  trackingChecked: false,
}

export default function userPreferences(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_AUTOPLAY:
      return {
        ...state,
        autoplay: !state.autoplay,
      }
    case SET_VOLUME:
      return {
        ...state,
        volume: action.payload,
      }
    case SET_TRACKING:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
