import { ON_FILTER_CHANGE, ON_SORT_CHANGE } from '../action_constants'
import isEqual from 'lodash/isEqual'

export function createSongsListActionCreator({
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant,
  receiveActionConstant,
  apiMethod,
}) {
  function requestSongs({ filters, pagination, sortBy }) {
    return {
      type: requestActionConstant,
      filters: { ...defaultFilters, ...filters },
      pagination: { ...defaultPagination, ...pagination },
      sortBy: sortBy || defaultSortBy,
    }
  }

  function receiveSongs({ filters, pagination, sortBy, songs }) {
    return {
      type: receiveActionConstant,
      filters,
      pagination,
      sortBy,
      songs,
    }
  }

  /**
   * Compares default filters to check if request match
   * @param dispatch
   * @param filters
   */
  function receiveFilterChange(dispatch, filters) {
    if (!isEqual(filters, defaultFilters)) {
      dispatch({
        type: ON_FILTER_CHANGE,
        filters,
      })
    }
  }

  /**
   * Compares default sort to check if request match
   * @param dispatch
   * @param sortBy
   */
  function receiveSortChange(dispatch, sortBy) {
    if (!isEqual(sortBy, defaultSortBy)) {
      dispatch({
        type: ON_SORT_CHANGE,
        sortBy,
      })
    }
  }

  return function({ filters, pagination, sortBy } = {}) {
    return function(dispatch) {
      const requestAction = requestSongs({ filters, pagination, sortBy })
      dispatch(requestAction)
      return apiMethod({
        ...requestAction.filters,
        ...requestAction.pagination,
        sortBy: requestAction.sortBy,
      }).then(response => {
        dispatch(
          receiveSongs({
            filters: requestAction.filters,
            pagination: {
              ...requestAction.pagination,
              ...response.metadata,
            },
            sortBy: requestAction.sortBy,
            songs: response.data,
          })
        )
        receiveFilterChange(dispatch, requestAction.filters)
        receiveSortChange(dispatch, requestAction.sortBy)
      })
    }
  }
}
