import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import React from 'react'
import styles from './styles'

export const UserImpersonationBanner = () => {
  const isUserImpersonationMode = useSelector(
    state => state.currentUser?.user?.userImpersonationMode
  )
  const userImpersonationValidity = useSelector(
    state => state.currentUser?.user?.userImpersonationValidity
  )

  return userImpersonationValidity && isUserImpersonationMode ? (
    <div className={styles.userImpersonationContainer}>
      <div className={styles.textContainer}>
        <FormattedMessage
          defaultMessage="Warning: You are currently impersonating another user."
          id="djcity.common.user_impersonation_mode"
        />
      </div>
    </div>
  ) : null
}
