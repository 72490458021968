import {
  ERROR_RECEIVING_USER_LOCATION_PROPERTIES,
  RECEIVE_USER_LOCATION_PROPERTIES,
  REQUEST_USER_LOCATION_PROPERTIES,
} from '../action_constants'

const defaultState = {
  country: '',
  countryCode: '',
  currencyId: '',
  paymentConfiguration: null,
  paymentProcessors: [],
  isFetching: false,
  error: null,
}

// We should warp up all user info here regardless of the login status
// TODO: Include device, resolution, etc...
export default function userProperties(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_USER_LOCATION_PROPERTIES:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_USER_LOCATION_PROPERTIES:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case ERROR_RECEIVING_USER_LOCATION_PROPERTIES:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    default:
      return state
  }
}
