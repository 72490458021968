export const ELLIPSIS = '&#8230;'

const DELTA = 2
const MAX = 7

export const getRange = (page, pageCount) => {
  if (pageCount <= MAX) {
    return Array.from(Array(pageCount).keys()).map(i => i + 1)
  }
  let range = []
  for (
    let i = Math.max(2, page - DELTA);
    i <= Math.min(pageCount - 1, page + DELTA);
    i++
  ) {
    range.push(i)
  }
  if (page - DELTA > 2) {
    range.unshift(ELLIPSIS)
  }
  if (page + DELTA < pageCount - 1) {
    range.push(ELLIPSIS)
  }
  range.unshift(1)
  range.push(pageCount)
  return range
}
