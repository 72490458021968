import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Popover } from 'spa/components/popover'
import { TextStyle } from 'spa/components/typography'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Badge from '@material-ui/core/Badge'
import classNames from 'classnames'
import CrateList from 'spa/components/crate_list'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles'

const CratePopover = ({ className, iconOnly }) => {
  const location = useLocation()
  const totalItems = useSelector(state => state.crate.metadata.totalItems || 0)
  const [openPopover, setOpenPopover] = useState(false)

  useEffect(() => {
    setOpenPopover(false)
  }, [location, setOpenPopover])

  const handleOpen = useCallback(() => setOpenPopover(true), [setOpenPopover])
  // const handleClose = useCallback(() => setOpenPopover(false), [
  //   setOpenPopover,
  // ]);

  return (
    <Popover
      className={className}
      closeOnClick={!totalItems}
      closeOnScroll={false}
      contentClassName={styles.cratePopoverContent}
      id="cratePopover"
      openPopover={openPopover}
      renderTrigger={({ handleClick, ref }) =>
        iconOnly ? (
          <Badge
            badgeContent={totalItems}
            classes={{ badge: styles.badge }}
            max={999}
            onClick={handleClick}
            ref={ref}
          >
            <FontAwesomeIcon icon={['far', 'archive']} />
          </Badge>
        ) : (
          <button
            className={classNames(
              'cleanButton',
              'simple',
              styles.subMenuPopoverBtn
            )}
            onClick={handleClick}
            ref={ref}
          >
            <FontAwesomeIcon icon="archive" />
            <TextStyle color="white" variant="label">
              <FormattedMessage
                defaultMessage="Your Crate"
                id="djcity.records.your_crate.notification.title"
              />
            </TextStyle>
            <span className={styles.crateCount}>{totalItems}</span>
          </button>
        )
      }
    >
      <CrateList
        // handleClosePopover={handleClose}
        handleOpenPopover={handleOpen}
      />
    </Popover>
  )
}

CratePopover.displayName = 'CratePopover'

CratePopover.propTypes = {
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
}

export { CratePopover }
