import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AutocompleteInput from './autocomplete'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const SearchInput = React.forwardRef(
  ({ className, style, autocomplete, ...rest }, ref) => {
    const cls = classNames(styles.searchInputWrapper, className)
    return (
      <div className={cls} style={style}>
        {!autocomplete && (
          <input
            className={styles.searchInput}
            placeholder="Search artists, titles, producers..."
            ref={ref}
            type="text"
            {...rest}
          />
        )}
        {autocomplete && (
          <AutocompleteInput
            className={styles.searchInput}
            placeholder="Search artists, titles, producers..."
            ref={ref}
            type="text"
            {...rest}
          />
        )}
        <FontAwesomeIcon className={styles.searchIcon} icon="search" />
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'

SearchInput.propTypes = {
  autocomplete: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.any,
}
