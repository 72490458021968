/////////////////////////
// Imported from the library source
// Uses this to override the notification styles because
// they are applied as inline styles, and are difficult to
// override with just css

import styles from './styles'

// Used for calculations
var defaultWidth = 400
var defaultColors = {
  success: {
    rgb: '17, 191, 90',
    hex: '#11BF5A',
  },
  error: {
    rgb: '255, 51, 51',
    hex: '#ff3333',
  },
  warning: {
    rgb: '242, 117, 23',
    hex: '#F2752E',
  },
  info: {
    rgb: '54, 156, 199',
    hex: '#369cc7',
  },
}

var notificationStyles = {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      fontFamily: "'Montserrat', sans-serif !default",
      position: 'fixed',
      width: defaultWidth,
      padding: '0 10px 10px 10px',
      borderTop: 'none',
      zIndex: parseInt(styles.notificationsZIndex, 10),
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },

    tl: {
      top: '0px',
      bottom: 'auto',
      left: '0px',
      right: 'auto',
    },

    tr: {
      top: '0px',
      bottom: 'auto',
      left: 'auto',
      right: '0px',
    },

    tc: {
      top: '0px',
      bottom: 'auto',
      margin: '0 auto',
      left: '50%',
      marginLeft: -(defaultWidth / 2),
    },

    bl: {
      top: 'auto',
      bottom: '0px',
      left: '0px',
      right: 'auto',
    },

    br: {
      top: 'auto',
      bottom: '0px',
      left: 'auto',
      right: '0px',
    },

    bc: {
      top: 'auto',
      bottom: '0px',
      margin: '0 auto',
      left: '50%',
      marginLeft: -(defaultWidth / 2),
    },
  },

  NotificationItem: {
    DefaultStyle: {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: '#0d0d0d',
      position: 'relative',
      width: '100%',
      cursor: 'pointer',
      fontSize: '13px',
      margin: '10px 0 0',
      padding: '18px 32px 18px 86px',
      display: 'block',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
      opacity: 0,
      transition: '0.3s ease-in-out',
      WebkitTransform: 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      willChange: 'transform, opacity',
      color: '#fff',
      zIndex: 15000,

      isHidden: {
        opacity: 0,
      },

      isVisible: {
        opacity: 1,
      },
    },
  },

  Title: {
    DefaultStyle: {
      fontSize: '18px',
      margin: '0 0 8px 0',
      padding: 0,
      fontWeight: 'bold',
    },
    success: {
      color: defaultColors.success.hex,
    },
    warning: {
      color: defaultColors.warning.hex,
    },
    error: {
      color: defaultColors.error.hex,
    },
    info: {
      color: defaultColors.info.hex,
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: 'serif',
      fontSize: '25px',
      position: 'absolute',
      top: '18px',
      right: '15px',
      lineHeight: '15px',
      backgroundColor: 'transparent',
      color: '#565656',
      borderRadius: 'none',
      textAlign: 'center',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#ffffff',
      borderRadius: '5px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: '2px solid',
    },

    success: {
      backgroundColor: defaultColors.success.hex,
      color: '#ffffff',
    },

    error: {
      backgroundColor: 'black',
      color: '#ffffff',
      borderRadius: 6,
      fontSize: 15,
    },

    warning: {
      backgroundColor: defaultColors.warning.hex,
      color: '#ffffff',
    },

    info: {
      backgroundColor: defaultColors.info.hex,
      color: '#ffffff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
}

export default notificationStyles
