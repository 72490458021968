import { findAllIndexes } from 'spa/shared/utils'
import { TextStyle } from './text_style'
import PropTypes from 'prop-types'
import React from 'react'

export function TextHighlight({ pattern = '', children = '', ...rest }) {
  const matches = findAllIndexes(children.toLowerCase(), pattern.toLowerCase())
  let result

  if (pattern) {
    result = []

    let index = 0
    let cursor = 0
    matches.forEach(matchIndex => {
      result.push(
        <TextStyle {...rest} key={index}>
          {children.substring(cursor, matchIndex)}
        </TextStyle>
      )
      result.push(
        <TextStyle {...rest} key={index + 1} variant="bold">
          {children.substr(matchIndex, pattern.length)}
        </TextStyle>
      )
      index += 2
      cursor = matchIndex + pattern.length
    })
    if (cursor < children.length) {
      result.push(
        <TextStyle {...rest} key={index}>
          {children.substring(cursor)}
        </TextStyle>
      )
    }
  } else {
    result = <TextStyle {...rest}>{children}</TextStyle>
  }

  return result
}

TextHighlight.propTypes = {
  ...TextStyle.propTypes,
  children: PropTypes.string,
  pattern: PropTypes.string,
}
