import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const Label = ({ children, className, text, ...rest }) => {
  const cls = classNames(styles.label, className)
  return (
    <label aria-label={text} className={cls} {...rest}>
      <div className={styles.titleContainer}>{text}</div>
      {children}
    </label>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
}
