import {
  artistSongs,
  clearArtistSongs,
  filterArtistSongs,
} from '../../../action_creators/artist_songs'
import { DEFAULT_DATE_OPTION } from '../../../shared/utils'
import { defaultFilters } from 'spa/api/songs'
import { DropdownPopover } from 'spa/components/filters/dropdown_popover'
import { FilterPopover, SORT_OPTIONS } from 'spa/components/filters'
import { FormattedMessage, injectIntl } from 'react-intl'
import { groupByDate } from 'spa/selectors/songs'
import { Heading, TextStyle } from '../../typography'
import { Helmet } from 'react-helmet'
import { isMobileSelector } from '../../../selectors/device'
import { Knotch } from '../../knotch'
import { Loading } from '../../loading'
import { LOCALIZED_MESSAGES } from '../../table/messages'
import { NoRecordsLabel } from '../../no_records_label'
import { SongTable } from '../../table'
import { TogglePanel } from 'spa/components/toggle_panel'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import styles from './styles'

export function Artists({ intl }) {
  const dispatch = useDispatch()
  let { isFetching, songs, filteredSongs } = useSelector(
    state => state.artistSongs
  )
  let groupBy = null
  const isMobile = useSelector(isMobileSelector)
  const location = useLocation()
  const artist = queryString.parse(location.search).q

  const [filters, setFilters] = useState(defaultFilters)
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.POPULARITY)

  if (sortBy !== SORT_OPTIONS.POPULARITY) {
    filteredSongs = groupByDate(filteredSongs)
    groupBy = 'releasedate'
  }

  useEffect(() => {
    dispatch(filterArtistSongs({ filters, sortBy }))
  }, [filters, sortBy, dispatch])

  useEffect(() => {
    if (!artist) return

    dispatch(
      artistSongs({
        filters: defaultFilters,
        sortBy: SORT_OPTIONS.POPULARITY,
        artistName: artist,
      })
    ).then(() => {
      setFilters(defaultFilters)
      setSortBy(SORT_OPTIONS.POPULARITY)
    })

    return () => dispatch(clearArtistSongs())
  }, [dispatch, artist])

  if (isFetching) {
    return <Loading />
  }

  return (
    <div className={styles.base}>
      <Helmet defer={false}>
        <title key="pagetitle">
          {artist ||
            intl.formatMessage({
              defaultMessage: 'Artist',
              id: 'djcity.common.artists',
            })}{' '}
          | DJcity
        </title>
      </Helmet>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="Artist"
            id="djcity.common.artists"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading} id="artist-name-title">
            <FormattedMessage
              defaultMessage="{artist_name}"
              id="djcity.common.artists.name"
              values={{ artist_name: artist || 'Artist' }}
            />
          </Heading>
          {!isMobile && (
            <div>
              <TogglePanel
                items={[
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.popularity),
                    value: SORT_OPTIONS.POPULARITY,
                  },
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.date),
                    value: SORT_OPTIONS.DATE_DESC,
                  },
                ]}
                onChange={setSortBy}
                showSeparator
                value={sortBy}
              />
              <FilterPopover filters={filters} onChange={setFilters} />
            </div>
          )}
        </div>
        <Knotch className={styles.knotch} size="big" />
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <DropdownPopover
            isMobile={isMobile}
            items={{
              [SORT_OPTIONS.POPULARITY]: intl.formatMessage(
                LOCALIZED_MESSAGES.popularity
              ),
              [SORT_OPTIONS.DATE_DESC]: intl.formatMessage(
                LOCALIZED_MESSAGES.date
              ),
            }}
            onChange={setSortBy}
            value={sortBy}
          />
          <FilterPopover
            filters={filters}
            isMobile={isMobile}
            onChange={setFilters}
          />
        </div>
      )}
      {artist && songs && songs.length ? (
        <>
          {groupBy ? (
            filteredSongs.map((group, i) => (
              <SongTable
                className={styles.table}
                firstColumnHeaderLabel={intl.formatDate(
                  new Date(group[groupBy]),
                  DEFAULT_DATE_OPTION
                )}
                isLoading={false}
                key={group[groupBy]}
                nextSongs={
                  i + 1 === filteredSongs.length
                    ? []
                    : filteredSongs[i + 1].songs
                }
                songs={group.songs}
                trackList={TRACKLIST.ARTISTS}
              />
            ))
          ) : (
            <SongTable
              className={styles.table}
              firstColumnHeaderLabel="Records"
              isLoading={false}
              songs={filteredSongs}
              trackList={TRACKLIST.ARTISTS}
            />
          )}
          {filteredSongs.length === 0 && !isFetching ? (
            <NoRecordsLabel />
          ) : null}
        </>
      ) : (
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            <FormattedMessage
              defaultMessage="Artist not found"
              id="djcity.records.artists.notfound"
            />
          </Heading>
          <TextStyle className={styles.bodyText}>
            <FormattedMessage
              defaultMessage="Whoops not able to find artist. Please check artist spelling."
              id="djcity.records.artists.notfoundmessage"
            />
          </TextStyle>
        </div>
      )}
    </div>
  )
}

Artists.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func,
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(Artists)
