import { SET_VOLUME, TOGGLE_AUTOPLAY } from 'spa/action_constants'

export function toggleAutoplay() {
  return {
    type: TOGGLE_AUTOPLAY,
  }
}

export function setVolume(payload) {
  return {
    type: SET_VOLUME,
    payload,
  }
}
