import {
  ERROR_RECEIVING_SUBSCRIPTIONS,
  RECEIVE_SUBSCRIPTIONS,
  REQUEST_SUBSCRIPTIONS,
  RESET_SUBSCRIPTION,
  SELECT_SUBSCRIPTION,
} from '../action_constants'

const defaultState = {
  list: [],
  selected: undefined,
  isFetching: true,
  errorMessage: null,
}

export default function locale(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_SUBSCRIPTIONS:
      return { ...state, isFetching: true }
    case RECEIVE_SUBSCRIPTIONS:
      return {
        ...state,
        list: action.payload,
        isFetching: false,
        errorMessage: null,
      }
    case ERROR_RECEIVING_SUBSCRIPTIONS:
      return {
        ...state,
        list: action.subscriptions,
        isFetching: false,
        errorMessage: action.payload,
      }
    case SELECT_SUBSCRIPTION:
      return { ...state, selected: action.payload }
    case RESET_SUBSCRIPTION:
      return { ...state, selected: defaultState.selected }
    default:
      return state
  }
}
