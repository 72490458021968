import { FormattedMessage } from 'react-intl'
import { Heading } from '../typography'
import { Layout } from 'spa/components/layout'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styles from './styles'

function ErrorMessage({ history, error, info, clearError }) {
  useEffect(() => {
    let unlisten
    if (typeof history?.listen === 'function') {
      unlisten = history.listen(clearError)
    }

    return unlisten
  }, [history, clearError])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && (error || info)) {
      // eslint-disable-next-line no-console
      console.error('CAUGHT BY ERROR BOUNDARY', error, info)
    }
  }, [error, info])

  return (
    <Layout tabTitle="error">
      <div className={styles.container}>
        <Heading className={styles.heading}>
          <FormattedMessage
            defaultMessage="Whoops, something went wrong."
            id="djcity.common.error"
          />
        </Heading>
      </div>
    </Layout>
  )
}

ErrorMessage.propTypes = {
  clearError: PropTypes.func.isRequired,
  error: PropTypes.any,
  history: PropTypes.shape({ listen: PropTypes.func }).isRequired,
  info: PropTypes.any,
}

const ConnectedErrorMessage = withRouter(ErrorMessage)

export { ConnectedErrorMessage as ErrorMessage }
