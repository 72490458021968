import { defaultSortBy } from 'spa/api/songs'
import { FilterButtonIcon } from './filter_button_icon'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { Popover } from 'spa/components/popover'
import { SORT_OPTIONS, SORT_TO_LABEL, SortPanel } from '.'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const SortPopover = ({
  onChange,
  value,
  intl,
  isMobile,
  isFromSearch,
  isFromGenres,
  defaultValue,
  className,
}) => {
  const currentValue = value || defaultValue
  const isSorted = !defaultValue
    ? defaultSortBy !== value
    : defaultValue !== value

  return (
    <Popover
      closeOnScroll={false}
      className={className}
      contentClassName={styles.filterPopoverContent}
      renderTrigger={({ handleClick, open, ref }) => (
        <button
          className={classNames({
            popoverTrigger: !open,
            popoverTriggerOpen: open,
            mobile: isMobile,
            [styles.sorted]: isSorted,
          })}
          onClick={handleClick}
          ref={ref}
        >
          {isMobile ? (
            <>
              <FilterButtonIcon isMobile={isMobile} isOpen={open} />{' '}
              {intl.formatMessage(
                SORT_TO_LABEL[
                  Object.keys(SORT_OPTIONS).find(
                    key => SORT_OPTIONS[key] === currentValue
                  )
                ]
              )}
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Sort By"
                id="djcity.common.sort_by"
              />{' '}
              <FilterButtonIcon isOpen={open} />
            </>
          )}
        </button>
      )}
    >
      <SortPanel
        isFromGenres={isFromGenres}
        isFromSearch={isFromSearch}
        isMobile={isMobile}
        onChange={onChange}
        value={currentValue}
      />
    </Popover>
  )
}

SortPopover.displayName = 'SortPopover'

SortPopover.propTypes = {
  defaultValue: PropTypes.number,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isFromGenres: PropTypes.bool,
  isFromSearch: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSorted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
}

const IntlSortPopover = injectIntl(SortPopover)

export { IntlSortPopover as SortPopover }
