import * as subscriptionsApi from '../api/subscriptions'
import { defineMessages } from 'react-intl'
import { error, success } from 'react-notification-system-redux'
import { intl } from '../shared/IntlGlobalProvider'
import { push } from 'connected-react-router'

import { AUTO_DISMISS_SECONDS } from '../shared/utils'
import {
  ERROR_CHANGING_SUBSCRIPTION,
  ERROR_RECEIVING_SUBSCRIPTION,
  ERROR_RECEIVING_SUBSCRIPTIONS,
  RECEIVE_SUBSCRIPTION,
  RECEIVE_SUBSCRIPTION_CHANGE,
  RECEIVE_SUBSCRIPTIONS,
  REQUEST_SUBSCRIPTION,
  REQUEST_SUBSCRIPTION_CHANGE,
  REQUEST_SUBSCRIPTIONS,
  RESET_SUBSCRIPTION,
  SELECT_SUBSCRIPTION,
} from '../action_constants'

const localizedMessages = defineMessages({
  subscriptionChangeSuccessTitle: {
    id: 'djcity.billing.subscription.change.success.title',
    defaultMessage: 'Your subscription plan was changed!',
  },
  subscriptionChangeSuccessMessage: {
    id: 'djcity.billing.subscription.change.success.message',
    defaultMessage:
      'Your new {planName} plan starts on {startDate} and renews on {renewDate}',
  },
  subscriptionPlanMonthly: {
    id: 'djcity.billing.subscription.plans.monthly',
    defaultMessage: 'MONTHLY',
  },
  subscriptionPlanQuarterly: {
    id: 'djcity.billing.subscription.plans.quarterly',
    defaultMessage: 'QUARTERLY',
  },
  subscriptionPlanBiannually: {
    id: 'djcity.billing.subscription.plans.biannually',
    defaultMessage: 'BIANNUALLY',
  },
  subscriptionPlanAnnually: {
    id: 'djcity.billing.subscription.plans.annually',
    defaultMessage: 'ANNUALLY',
  },
})

const PLANS_MAP = {
  MONTHLY: localizedMessages.subscriptionPlanMonthly,
  QUARTERLY: localizedMessages.subscriptionPlanQuarterly,
  BIANNUALLY: localizedMessages.subscriptionPlanBiannually,
  ANNUALLY: localizedMessages.subscriptionPlanAnnually,
}

function request(action) {
  return action
}
function receive(action) {
  return action
}
function errorReceiving(action) {
  return action
}

function generateNotification(notification) {
  return {
    title: intl.formatMessage(
      notification.title.string,
      notification.title.values
    ),
    message: intl.formatMessage(
      notification.message.string,
      notification.message.values
    ),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

export function selectSubscription(subs, subKey, dispatch) {
  const sub = subs.find(s => s.subscriptionKey.startsWith(subKey))
  if (sub) {
    dispatch({
      type: SELECT_SUBSCRIPTION,
      payload: sub,
    })
  }
}

export function fetchUserSubscription() {
  return async function(dispatch) {
    dispatch(request({ type: REQUEST_SUBSCRIPTION }))
    const res = await subscriptionsApi.fetchUserSubscription()

    if (res.status >= 400) {
      dispatch(error(generateNotification(res.data.notification.error)))
      dispatch(
        errorReceiving({
          type: ERROR_RECEIVING_SUBSCRIPTION,
          payload: res.data.error,
        })
      )
    }
    // dispatch(success({ message: res.data.message }));
    dispatch(receive({ type: RECEIVE_SUBSCRIPTION, payload: res.data }))
  }
}

export function changeUserSubscription(subscription) {
  return async function(dispatch, getState) {
    const userLocale = getState().currentUser.user.locale
    dispatch(request({ type: REQUEST_SUBSCRIPTION_CHANGE }))
    const res = await subscriptionsApi.changeUserSubscription(subscription)

    if (res.status >= 400) {
      dispatch(error({ message: res.data.notification.error }))
      dispatch(
        errorReceiving({
          type: ERROR_CHANGING_SUBSCRIPTION,
          payload: res.data.error,
        })
      )
    } else {
      const { subscriptionStart, subscriptionEnd, subscriptionKey } = res.data
      const title = {
        string: localizedMessages.subscriptionChangeSuccessTitle,
      }
      const message = {
        string: localizedMessages.subscriptionChangeSuccessMessage,
        values: {
          planName: intl.formatMessage(
            PLANS_MAP[subscriptionKey.split('_')[0]]
          ),
          startDate: Intl.DateTimeFormat(userLocale).format(
            new Date(subscriptionStart)
          ),
          renewDate: Intl.DateTimeFormat(userLocale).format(
            new Date(subscriptionEnd)
          ),
        },
      }
      dispatch(success(generateNotification({ title, message })))
      dispatch(
        receive({ type: RECEIVE_SUBSCRIPTION_CHANGE, payload: res.data })
      )
      dispatch(push('/'))
    }
  }
}

export function getSubscriptions() {
  return async function(dispatch) {
    dispatch(request({ type: REQUEST_SUBSCRIPTIONS }))
    try {
      const res = await subscriptionsApi.getSubscriptions()
      if (res.ok) {
        return dispatch(
          receive({ type: RECEIVE_SUBSCRIPTIONS, payload: res.data })
        )
      } else {
        return dispatch(
          errorReceiving({
            type: ERROR_RECEIVING_SUBSCRIPTIONS,
            payload: res.data.error,
          })
        )
      }
    } catch (e) {
      return dispatch(
        errorReceiving({ type: ERROR_RECEIVING_SUBSCRIPTIONS, payload: e })
      )
    }
  }
}

export function removeSubscription() {
  return { type: RESET_SUBSCRIPTION }
}
