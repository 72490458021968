import { CardInput } from '../../payment_details/card_input'
import { Layout } from '../../layout'
import React from 'react'

const display = () => {
  return (
    <Layout fullLayout tabTitle="payment">
      <CardInput />
    </Layout>
  )
}

export { display as PaymentDisplay }
