import { fetchWithHeaders, parseResponse, responseData } from '../shared/utils'
export function sendTrackToDownloader(params) {
  return fetchWithHeaders(`/queue`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parseResponse)
}

export function sendAllTracksToDownloader(params) {
  return fetchWithHeaders(`/queue/batch`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parseResponse)
}

export function getDownloadQueue() {
  return fetchWithHeaders(`/queue`, {
    method: 'GET',
  }).then(responseData)
}
