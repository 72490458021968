import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useRef } from 'react'
import ReactSlider from 'react-slider'

import styles from './styles'

const DEFAULT_MIN = 0
const DEFAULT_MAX = 100

export function Slider({
  className,
  min = DEFAULT_MIN,
  max = DEFAULT_MAX,
  defaultValue = [DEFAULT_MIN, DEFAULT_MAX],
  onChange,
}) {
  const previousValues = useRef(defaultValue)

  const onBeforeChange = useCallback(values => {
    previousValues.current = [...values]
  }, [])

  const onAfterChange = useCallback(
    values => {
      if (
        values[0] !== previousValues.current[0] ||
        values[1] !== previousValues.current[1]
      ) {
        onChange([...values])
      }
    },
    [onChange, previousValues.current]
  )

  return (
    <ReactSlider
      className={classNames(styles.slider, className)}
      defaultValue={defaultValue}
      max={max}
      min={min}
      minDistance={1}
      onAfterChange={onAfterChange}
      onBeforeChange={onBeforeChange}
      pearling
      renderThumb={(props, state) => (
        <div {...props}>
          <span
            className={classNames(styles.thumbValue, {
              [styles.left]:
                state.index === 0 && state.value[1] - state.value[0] < 30,
              [styles.right]:
                state.index === 1 && state.value[1] - state.value[0] < 30,
            })}
          >
            {state.valueNow}
          </span>
        </div>
      )}
      thumbClassName={styles.thumb}
      trackClassName={styles.track}
      value={defaultValue}
      withTracks
    />
  )
}

Slider.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
}
