import {
  defaultFilters,
  defaultPagination,
  defaultRemixersPagination,
  defaultSortBy,
  getAll,
} from '../api/songs'
import {
  RECEIVE_SONGS_BY_REMIXER,
  REQUEST_SONGS_BY_REMIXER,
} from '../action_constants'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListActionCreator } from './create_songs_list_action_creator'

export const fetchRemixSongs = createSongsListActionCreator({
  defaultFilters: defaultFilters,
  defaultPagination: defaultRemixersPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_SONGS_BY_REMIXER,
  receiveActionConstant: RECEIVE_SONGS_BY_REMIXER,
  apiMethod: getAll,
  reducerName: 'remixSongs',
})

export function clearSongList() {
  return {
    type: CLEAR_SONGS_LIST,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}
