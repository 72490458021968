import { defineMessages, injectIntl } from 'react-intl'
import { LOCALES } from '../../../locale_data/config'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import logoDE from 'spa/images/logos/logo_djcity_de_400.png'
import logoDEdark from 'spa/images/logos/logo_djcity_de_dark_400.png'
import logoES from 'spa/images/logos/logo_djcity_es_400.png'
import logoESdark from 'spa/images/logos/logo_djcity_es_dark_400.png'
import logoJP from 'spa/images/logos/logo_djcity_jp_400.png'
import logoJPdark from 'spa/images/logos/logo_djcity_jp_dark_400.png'
import logoUK from 'spa/images/logos/logo_djcity_uk_400.png'
import logoUKdark from 'spa/images/logos/logo_djcity_uk_dark_400.png'
import logoUS from 'spa/images/logos/logo_djcity_us_400.png'
import logoUSdark from 'spa/images/logos/logo_djcity_us_dark_400.png'

import styles from './styles'

const LOCALE_TO_LOGO = {
  [LOCALES.en_US]: logoUS,
  [LOCALES.en_GB]: logoUK,
  [LOCALES.es_ES]: logoES,
  [LOCALES.ja_JP]: logoJP,
  [LOCALES.de_DE]: logoDE,
}

const LOCALE_TO_LOGO_DARK = {
  [LOCALES.en_US]: logoUSdark,
  [LOCALES.en_GB]: logoUKdark,
  [LOCALES.es_ES]: logoESdark,
  [LOCALES.ja_JP]: logoJPdark,
  [LOCALES.de_DE]: logoDEdark,
}

const localizedMessages = defineMessages({
  altLogoText: {
    id: 'djcity.common.images.logo.alt',
    defaultMessage: 'DJ city',
  },
})

const Logo = ({
  alt = false,
  className = null,
  darkMode = false,
  svg,
  intl,
}) => {
  const locale = useSelector(state => state.locale.locale)
  const isDarkMode = useSelector(state => state.darkMode)
  let src
  if (alt) {
    src = logoUSdark
  } else {
    src = (isDarkMode && darkMode ? LOCALE_TO_LOGO_DARK : LOCALE_TO_LOGO)[
      locale || LOCALES.en_US
    ]
  }

  if (svg)
    return (
      <svg
        className={classNames(styles.logo, className, {
          [styles.inverse]: darkMode,
        })}
        fill="none"
        height="18"
        viewBox="0 0 102 18"
        width="102"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M28.2845 17.8852H21.6221V0.0214844H28.2845C33.896 0.0214844 37.7794 3.86589 37.7794 8.93415C37.777 13.9826 33.8936 17.8852 28.2845 17.8852ZM28.2845 2.89534H24.7702V15.0312H28.2845C32.0323 15.0312 34.4788 12.5067 34.4788 8.99237C34.4788 5.47808 32.0299 2.89534 28.2845 2.89534Z" />
        <path d="M41.894 18.0008C40.7938 18.0328 39.7029 17.7904 38.7198 17.2955C37.7367 16.8006 36.8923 16.0687 36.2627 15.166L38.3034 13.0688C39.3328 14.3695 40.3616 15.1269 41.8567 15.1269C43.5073 15.1269 44.6526 14.0201 44.6526 11.6901V0.15625H47.8175V11.7675C47.8157 15.942 45.3692 18.0008 41.894 18.0008Z" />
        <path d="M66.0679 5.47658C65.5354 4.22656 64.7021 3.12765 63.642 2.27774C62.5819 1.42784 61.3281 0.853317 59.9922 0.605367C58.6563 0.357417 57.2799 0.443735 55.9854 0.856631C54.691 1.26953 53.5187 1.99618 52.5732 2.97186C51.0055 4.57554 50.1278 6.72907 50.1278 8.97166C50.1278 11.2143 51.0055 13.3678 52.5732 14.9715C53.3354 15.7694 54.2514 16.4045 55.266 16.8385C56.2805 17.2724 57.3726 17.4961 58.476 17.4961C59.5795 17.4961 60.6715 17.2724 61.6861 16.8385C62.7006 16.4045 63.6167 15.7694 64.3789 14.9715C65.0909 14.2546 65.6636 13.4118 66.0679 12.486H66.4953C66.0704 13.5245 65.4434 14.4683 64.6508 15.2626C63.8526 16.096 62.8942 16.7596 61.8332 17.2134C60.7721 17.6673 59.6303 17.902 58.4763 17.9035C57.3213 17.9083 56.1776 17.6765 55.1157 17.2222C54.0538 16.768 53.0961 16.1011 52.3019 15.2626C50.6576 13.5768 49.7373 11.3151 49.7373 8.96026C49.7373 6.60538 50.6576 4.34374 52.3019 2.65795C53.1004 1.82514 54.0589 1.16224 55.1199 0.709037C56.1809 0.255832 57.3226 0.0216614 58.4763 0.0205803C59.6313 0.0157979 60.775 0.247662 61.837 0.701875C62.8989 1.15609 63.8565 1.82302 64.6508 2.66155C65.4266 3.46941 66.0513 4.40982 66.4953 5.43817H66.0679V5.47658Z" />
        <path d="M74.5149 0.389367H70.7089V17.5155H74.5149V17.9038H66.5151V17.5155H70.3211V0.389367H66.5151V0.0400391H74.5149V0.389367Z" />
        <path d="M81.2712 17.962V0.408526H75.0967V0.000976562H87.8538V0.408526H81.6793V17.962H81.2712Z" />
        <path d="M88.7668 0.0400391L95.0577 10.0979L101.349 0.0400391H101.835L95.2714 10.5061V17.8846H94.8639V10.5061L88.2812 0.0400391H88.7668Z" />
        <path d="M15.7073 2.99066L11.4745 7.20361C11.9246 7.82894 12.1223 8.601 12.0282 9.3657C11.9342 10.1304 11.5552 10.8315 10.9669 11.3291C10.3786 11.8266 9.62439 12.0841 8.85467 12.05C8.08496 12.0159 7.3564 11.6928 6.8144 11.1453C6.2482 10.5735 5.93217 9.80041 5.93577 8.99579C5.93937 8.19117 6.26231 7.4209 6.83361 6.85428C7.34607 6.34307 8.0228 6.02954 8.74412 5.96913C9.46544 5.90871 10.1849 6.10531 10.7753 6.52416L14.9888 2.31061C13.3599 0.786108 11.2004 -0.0425552 8.96979 0.000966389C7.79077 -0.0242199 6.61936 0.195697 5.52975 0.646791C4.44014 1.09788 3.45607 1.77032 2.63987 2.62153C1.78754 3.44208 1.11322 4.42927 0.658871 5.52167C0.204522 6.61407 -0.0201281 7.78829 -0.00109789 8.97126C-0.0220231 10.1573 0.201683 11.335 0.656066 12.4308C1.11045 13.5266 1.78576 14.517 2.63987 15.3402C3.4631 16.1943 4.4535 16.8696 5.54928 17.324C6.64507 17.7784 7.82273 18.0021 9.00881 17.9812C10.1965 18.0058 11.3765 17.7858 12.4754 17.3349C13.5744 16.8839 14.5689 16.2117 15.3969 15.36C16.2487 14.5319 16.9209 13.5375 17.3718 12.4385C17.8227 11.3395 18.0427 10.1595 18.0181 8.97186C18.0432 6.75558 17.216 4.61435 15.7073 2.99066Z" />
        <path d="M8.60139 8.58303C8.51874 8.66561 8.46244 8.77084 8.43961 8.88543C8.41678 9.00001 8.42845 9.11879 8.47313 9.22674C8.51782 9.33469 8.59352 9.42696 8.69066 9.49188C8.7878 9.5568 8.90201 9.59145 9.01884 9.59145C9.13567 9.59145 9.24988 9.5568 9.34702 9.49188C9.44416 9.42696 9.51986 9.33469 9.56455 9.22674C9.60923 9.11879 9.6209 9.00001 9.59807 8.88543C9.57524 8.77084 9.51894 8.66561 9.43629 8.58303C9.38178 8.52766 9.3168 8.48369 9.24513 8.45367C9.17346 8.42366 9.09654 8.4082 9.01884 8.4082C8.94114 8.4082 8.86422 8.42366 8.79255 8.45367C8.72088 8.48369 8.6559 8.52766 8.60139 8.58303Z" />
      </svg>
    )
  return (
    <img
      alt={intl.formatMessage(localizedMessages.altLogoText)}
      className={classNames(styles.logo, className, {
        [styles.inverse]: darkMode,
      })}
      src={src}
    />
  )
}

Logo.displayName = 'Logo'

Logo.propTypes = {
  alt: PropTypes.bool,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  svg: PropTypes.bool,
}

const LocalizedLogo = injectIntl(Logo)

export { LocalizedLogo as Logo }
