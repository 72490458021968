import {
  ERROR_RECEIVING_TOP_DOWNLOAD_DATES,
  RECEIVE_TOP_DOWNLOAD_DATES,
  REQUEST_TOP_DOWNLOAD_DATES,
} from '../action_constants'

const defaultState = {
  dates: [],
  isFetching: false,
  error: null,
}

export default function topDownloadsDates(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_TOP_DOWNLOAD_DATES:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case RECEIVE_TOP_DOWNLOAD_DATES:
      return {
        ...state,
        isFetching: false,
        dates: action.dates,
      }
    case ERROR_RECEIVING_TOP_DOWNLOAD_DATES:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
