import { BannerAssetPropType } from 'spa/api/banner_assets'
import { Link } from 'react-router-dom'
import { ProgressiveImage } from './progressive_image'
import { triggeredBannerClick } from '../../action_creators/banner_assets'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const AssetSlide = function({ asset }) {
  const dispatch = useDispatch()

  if (!asset || !asset.webAction) {
    return null
  }

  const url = asset.webAction.action
  const isAbsoluteUrl = url && !url.startsWith('/')

  const { targetBlank } = asset.webAction
  let SlideNode, slideParams
  if (!url) {
    SlideNode = 'div'
  } else if (isAbsoluteUrl || targetBlank) {
    SlideNode = 'a'
    slideParams = {
      href: url,
      target: targetBlank ? '_blank' : '_self',
      rel: 'noopener noreferrer',
    }
  } else {
    SlideNode = Link
    slideParams = { to: url }
  }

  const clickHandler = () => {
    if (dispatch) dispatch(triggeredBannerClick(asset))
  }

  return (
    <SlideNode onClick={clickHandler} {...slideParams}>
      <ProgressiveImage
        alt={asset.webAction.altTag}
        className={styles.image}
        src={asset.webAction.assetUrl}
        thumbnail={asset.webAction.thumbnailUrl}
      />
    </SlideNode>
  )
}

AssetSlide.propTypes = {
  asset: BannerAssetPropType.isRequired,
  loading: PropTypes.bool,
}

export { AssetSlide }
