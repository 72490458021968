import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const Switch = ({
  isActive,
  buttonClassName,
  className,
  Icon,
  labelText,
  onClick,
}) => {
  return (
    <div className={cn(styles.autoplay, className)}>
      {labelText ? (
        typeof labelText === 'string' ? (
          <span>{labelText}</span>
        ) : (
          labelText
        )
      ) : null}
      <button
        className={cn(
          styles.autoplaySwitch,
          { [styles.active]: isActive },
          buttonClassName
        )}
        onClick={onClick}
      >
        <div className={styles.switch}>{Icon ? <Icon /> : null}</div>
      </button>
    </div>
  )
}

Switch.displayName = 'Switch'

Switch.propTypes = {
  Icon: PropTypes.element,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  labelText: PropTypes.any,
  onClick: PropTypes.func.isRequired,
}
