import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import styles from './styles.sass'

export function ToolTip({
  children = null,
  className = null,
  toolTipClassName = null,
  toolTipContent = null,
}) {
  const [display, setDisplay] = useState(false)
  const node = useRef()

  return (
    <div
      className={classNames(styles.tooltipContainer, className)}
      onMouseOut={() => setDisplay(false)}
      onMouseOver={() => setDisplay(true)}
      ref={node}
    >
      {children}
      {toolTipContent && (
        <div
          className={classNames(styles.tooltip, toolTipClassName, {
            [styles.hidden]: !display,
          })}
        >
          {toolTipContent}
        </div>
      )}
    </div>
  )
}

ToolTip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  timeout: PropTypes.number,
  toolTipClassName: PropTypes.string,
  toolTipContent: PropTypes.node,
}
