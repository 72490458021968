import { SET_TRACKING } from '../action_constants'

export function detectAdBlocker() {
  return async function(dispatch) {
    let trackingAllowed = false
    try {
      await fetch(
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
        { method: 'HEAD', mode: 'no-cors', cache: 'no-store' }
      )
      trackingAllowed = true
    } catch (error) {
      trackingAllowed = false
    }

    return dispatch({
      type: SET_TRACKING,
      payload: { trackingAllowed: !!trackingAllowed, trackingChecked: true },
    })
  }
}
