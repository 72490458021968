/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { injectIntl } from 'react-intl'
import { isMobileSelector } from '../../selectors/device'
import { loggedInSelector, onboardingSelector } from '../../selectors/users'
import { saveCurrentUser } from '../../action_creators/current_user'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import { steps, tourOptions } from './config'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useContext, useEffect, useState } from 'react'

export const OnboardingWrapper = injectIntl(({ intl, children }) => {
  const [tourSteps, setTourSteps] = useState([])
  useEffect(() => {
    if (intl && tourSteps.length !== steps.length) {
      const tourSteps = steps.map(step => {
        const newStep = { ...step }
        newStep.buttons = step.buttons.map(button => {
          const newButton = { ...button }
          newButton.text = intl.formatMessage(button.text)
          return newButton
        })
        newStep.title = intl.formatMessage(step.title)
        newStep.text = intl.formatMessage(step.text)
        return newStep
      })
      setTourSteps(tourSteps)
    }
  }, [])

  return (
    <ShepherdTour steps={tourSteps} tourOptions={tourOptions}>
      {children}
    </ShepherdTour>
  )
})

export const OnboardingWalktrough = () => {
  const tour = useContext(ShepherdTourContext)
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(loggedInSelector)
  const hasOnboarding = useSelector(onboardingSelector)
  const isMobile = useSelector(isMobileSelector)
  const { stringResources } = useSelector(state => state)
  const { isFetching } = stringResources

  const isEnabled = useCallback(() => {
    return (
      !process.env.IS_TEST &&
      isLoggedIn &&
      location.pathname === '/' &&
      hasOnboarding &&
      !isFetching &&
      !isMobile
    )
  }, [])

  useEffect(() => {
    tour.on(
      'cancel',
      () =>
        hasOnboarding &&
        dispatch(saveCurrentUser({ onboardingSkipped: true }, false))
    )
  }, [])

  useEffect(() => {
    if (isEnabled() && tour) {
      setTimeout(() => {
        if (isEnabled()) {
          // still enabled after waiting
          tour.start()
        }
      }, 1500)
    }
  }, [isEnabled])

  return null
}
