import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../shared/utils'

export function requestPromoPricing(promo) {
  return fetchWithHeaders('/promos/promo/pricing?' + getQueryParams(promo), {
    method: 'GET',
  }).then(parseResponse)
}

export function requestPromo(promo) {
  return fetchWithHeaders(`/promos/promo?${getQueryParams(promo)}`, {
    method: 'GET',
  }).then(parseResponse)
}

export function redeemPromo(promo) {
  return fetchWithHeaders(`/promos/promo?${getQueryParams(promo)}`, {
    method: 'POST',
  }).then(parseResponse)
}

// export function requestPromoCode(promoCode) {
//   return fetchWithHeaders(`/promos/code?${getQueryParams(promoCode)}`, {
//     method: "GET",
//   }).then(parseResponse);
// }

// export function redeemPromoCode(promoCode) {
//   return fetchWithHeaders(`/promos/code?${getQueryParams(promoCode)}`, {
//     method: "POST",
//   }).then(parseResponse);
// }

// export function validatePromoCode(promoCode) {
//   return fetchWithHeaders(`/promos/code?${getQueryParams(promoCode)}`, {
//     method: "GET",
//   }).then(parseResponse);
// }

export function requestPromoToken(promoName) {
  return fetchWithHeaders(`/promos/token?promoName=${promoName}`, {
    method: 'GET',
  }).then(parseResponse)
}

export function redeemPromoToken(promoToken) {
  return fetchWithHeaders(`/promos/token`, {
    method: 'POST',
    body: JSON.stringify({ promoToken }),
  }).then(parseResponse)
}

export function redeemVIPCode(vipCode) {
  return fetchWithHeaders(`/promos/vip-access`, {
    method: 'POST',
    body: JSON.stringify({ vipCode }),
  }).then(parseResponse)
}
