export const actionButtons = [
  {
    endpoint: '/most-popular',
    icon: ['fas', 'fire'],
    message: {
      defaultMessage: 'Most popular',
      id: 'djcity.common.home.action.1',
    },
  },
  {
    endpoint: '/playlists',
    icon: ['fas', 'list-ul'],
    message: {
      defaultMessage: 'Top playlists',
      id: 'djcity.common.home.action.2',
    },
  },
  {
    endpoint: '/monthly-charts',
    icon: ['fas', 'calendar'],
    message: {
      defaultMessage: 'Monthly charts',
      id: 'djcity.common.home.action.3',
    },
  },
  {
    endpoint: '/genres',
    icon: ['fas', 'tags'],
    message: {
      defaultMessage: 'Browse genres',
      id: 'djcity.common.home.action.4',
    },
  },
]
