import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const TextStyle = ({
  color = 'black',
  className,
  tagName = 'span',
  truncate,
  uppercase,
  capitalize,
  padding,
  margin,
  variant = 'default',
  displayInline,
  ...rest
}) => {
  const cls = classNames(
    styles.textStyle,
    {
      [styles.extraBold]: variant === 'extra-bold',
      [styles.bold]: variant === 'bold',
      [styles.label]: variant === 'label',
      [styles.underline]: variant === 'underline',
      [styles.uppercase]: !!uppercase,
      [styles.capitalize]: !!capitalize,
      [styles.black]: color === 'black',
      [styles.red]: color === 'red',
      [styles.grey]: color === 'grey',
      [styles.white]: color === 'white',
      [styles.truncate]: truncate,
      [styles.paddingTop]: padding === 'paddingTop',
      [styles.marginLeft]: margin === 'marginLeft',
      [styles.displayInline]: displayInline === 'displayInline',
    },
    className
  )
  const Tag = tagName
  return <Tag className={cls} {...rest} />
}

TextStyle.displayName = 'TextStyle'

TextStyle.propTypes = {
  capitalize: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['black', 'grey', 'red', 'white']),
  displayInline: PropTypes.oneOf(['displayInline']),
  margin: PropTypes.oneOf(['marginLeft']),
  padding: PropTypes.oneOf(['paddingTop']),
  tagName: PropTypes.string,
  truncate: PropTypes.bool,
  uppercase: PropTypes.bool,
  variant: PropTypes.oneOf([
    'default',
    'extra-bold',
    'bold',
    'label',
    'underline',
  ]),
}
