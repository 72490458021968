const LOCALES = {
  en_US: "en-US",
  en_GB: "en-GB",
  es_ES: "es-ES",
  ja_JP: "ja-JP",
  de_DE: "de-DE",
};

const REGION_LOCALES = {
  "en-US": 1,
  "en-GB": 3,
  "es-ES": 6,
  "ja-JP": 2,
  "de-DE": 4,
};

const LANGUAGE_LOCALES = {
  en_US: "en",
  en_GB: "en",
  es_ES: "es",
  ja_JP: "ja",
  de_DE: "de",
};

const LOCALIZED_ROUTES = {
  de: LOCALES.de_DE,
  latino: LOCALES.es_ES,
  uk: LOCALES.en_GB,
  gb: LOCALES.en_GB,
  eu: LOCALES.en_GB,
  jp: LOCALES.ja_JP,
};

const DEFAULT_LOCALE = LOCALES.en_US;

module.exports = {
  DEFAULT_LOCALE,
  LOCALES,
  REGION_LOCALES,
  LANGUAGE_LOCALES,
  LOCALIZED_ROUTES,
};
