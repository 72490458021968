import { Link } from 'spa/components/link'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const NavItem = ({
  children,
  fullPageLoad,
  newWindow,
  to = '#',
  className,
  itemType = 'link',
  navIcon,
}) => {
  const cls = classNames(styles.navItemLink, className)
  return (
    <li className={styles.navItem}>
      {navIcon ? { navIcon } : null}
      {itemType === 'link' ? (
        <Link
          className={cls}
          fullPageLoad={fullPageLoad}
          newWindow={newWindow}
          to={to}
        >
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </li>
  )
}

NavItem.displayName = 'NavItem'

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullPageLoad: PropTypes.bool,
  itemType: PropTypes.string,
  navIcon: PropTypes.node,
  newWindow: PropTypes.bool,
  to: PropTypes.string,
}
