import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextStyle } from 'spa/components/typography'
import React from 'react'

import { FormattedMessage } from 'react-intl'
import styles from './styles'

export const NoRecordsLabel = () => {
  return (
    <div className={styles.noRecordsLabelBlock}>
      <div className={styles.noRecordsIcon}>
        <FontAwesomeIcon icon="search" />
      </div>
      <div>
        <TextStyle className={styles.noRecordsTitle} color="grey" uppercase>
          <FormattedMessage
            defaultMessage="Nothing to see here"
            id="djcity.common.records.empty"
          />
        </TextStyle>
      </div>
    </div>
  )
}
