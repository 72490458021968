import { fetchWithHeaders, getQueryParams, responseData } from '../shared/utils'
import PropTypes from 'prop-types'

export const BannerAssetPropType = PropTypes.shape({
  baid: PropTypes.number.isRequired,
  webAction: PropTypes.shape({
    altTag: PropTypes.string.isRequired,
    action: PropTypes.string,
    assetUrl: PropTypes.string.isRequired,
  }).isRequired,
})

export function fetchAll(params = {}) {
  return fetchWithHeaders(`/v1/banner_assets?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}
