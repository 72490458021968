export const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
}

const stepButtons = [
  {
    classes: ['button'],
    text: {
      defaultMessage: 'Back',
      id: 'djcity.common.intro.button.previous',
    },
    type: 'back',
  },
  {
    classes: ['button'],
    text: {
      defaultMessage: 'Next',
      id: 'djcity.common.intro.button.next',
    },
    type: 'next',
  },
]

const commonStepOptions = {
  arrow: false,
  buttons: stepButtons,
  cancelIcon: { enabled: true },
  classes: '',
  highlightClass: '',
  scrollTo: false,
}

export const steps = [
  {
    ...commonStepOptions,
    buttons: [stepButtons[1]],
    id: 'searchBox',
    attachTo: { element: 'div#songSearchBox', on: 'bottom' },
    title: {
      defaultMessage: 'Search Box',
      id: 'djcity.common.intro.one.title',
    },
    text: {
      defaultMessage: 'Search for your favorite artists, song or remixer name',
      id: 'djcity.common.intro.one.text',
    },
  },
  {
    ...commonStepOptions,
    id: 'catalog',
    attachTo: { element: 'nav>ul>div:first-of-type', on: 'bottom' },
    title: {
      defaultMessage: 'Full music catalog',
      id: 'djcity.common.intro.two.title',
    },
    text: {
      defaultMessage:
        'Browse the entire DJCity music catalog using the menu items here',
      id: 'djcity.common.intro.two.text',
    },
  },
  {
    ...commonStepOptions,
    id: 'actionMenu',
    attachTo: {
      element: '#homeSidebar div#songVersionsPopover',
      on: 'bottom-start',
    },
    title: {
      defaultMessage: 'Action Menu',
      id: 'djcity.common.intro.four.title',
    },
    text: {
      defaultMessage: 'Use these icons to download or add songs to your crate',
      id: 'djcity.common.intro.four.text',
    },
  },
  {
    ...commonStepOptions,
    id: 'crate',
    attachTo: { element: 'div#cratePopover', on: 'bottom' },
    title: {
      defaultMessage: 'Your Crate',
      id: 'djcity.common.intro.three.title',
    },
    text: {
      defaultMessage:
        'Find your saved songs here and bulk download them all using the Desktop App',
      id: 'djcity.common.intro.three.text',
    },
  },
  {
    ...commonStepOptions,
    id: 'tracklist',
    attachTo: { element: 'div#mostPopularTable', on: 'left-start' },
    title: {
      defaultMessage: 'Track Listing',
      id: 'djcity.common.intro.five.title',
    },
    text: {
      defaultMessage:
        'Click on any artist name, song title or genre to discover more music',
      id: 'djcity.common.intro.five.text',
    },
  },
  {
    ...commonStepOptions,
    buttons: [
      stepButtons[0],
      {
        classes: ['button'],
        text: {
          defaultMessage: 'Finish',
          id: 'djcity.common.intro.button.finish',
        },
        type: 'cancel',
      },
    ],
    id: 'filters',
    attachTo: { element: 'div#newReleasesFilter', on: 'bottom' },
    title: {
      defaultMessage: 'Filters',
      id: 'djcity.common.intro.six.title',
    },
    text: {
      defaultMessage:
        'Filter by key, BPM, version and more to find exactly what you are looking for',
      id: 'djcity.common.intro.six.text',
    },
  },
]

export default steps
