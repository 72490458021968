import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../shared/utils'

export function getSubscriptions() {
  return fetchWithHeaders(`/subscriptions`, {
    method: 'GET',
  }).then(parseResponse)
}

export function createUserSubscription() {
  return fetchWithHeaders(`/subscriptions/user`, {
    method: 'POST',
  }).then(parseResponse)
}

export function fetchUserSubscription() {
  return fetchWithHeaders(`/subscriptions/user`, {
    method: 'GET',
  }).then(parseResponse)
}

export function changeUserSubscription(params = {}) {
  return fetchWithHeaders(`/subscriptions/user?${getQueryParams(params)}`, {
    method: 'PUT',
  }).then(parseResponse)
}

export function deleteUserSubscription() {
  return fetchWithHeaders(`/subscriptions/user`, {
    method: 'DELETE',
  }).then(parseResponse)
}
