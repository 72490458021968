import { clearRemixer, fetchRemixer } from '../../../action_creators/remixers'
import {
  clearSongList,
  fetchRemixSongs,
} from '../../../action_creators/remix_songs'
import { DEFAULT_DATE_OPTION, generateQueryParams } from '../../../shared/utils'
import {
  defaultFilters,
  defaultRemixersPagination,
  filterParsers,
  filterStringifiers,
} from 'spa/api/songs'
import { DropdownPopover } from 'spa/components/filters/dropdown_popover'
import { FilterPopover, SORT_OPTIONS } from 'spa/components/filters'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Heading, TextStyle } from '../../typography'
import { Helmet } from 'react-helmet'
import { isMobileSelector } from '../../../selectors/device'
import { Knotch } from '../../knotch'
import { Link } from '../../link'
import { Loading } from '../../loading'
import { localeSelector, loggedInSelector } from 'spa/selectors/users'
import { LOCALIZED_MESSAGES } from '../../table/messages'
import { NoRecordsLabel } from '../../no_records_label'
import { Pagination } from '../../pagination'
import {
  remixSongSelector,
  remixSongSelectorGrouped,
} from '../../../selectors/songs'
import { SongTable } from '../../table'
import { TogglePanel } from 'spa/components/toggle_panel'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { usePaginationUrl } from 'spa/hooks/use_pagination_url'
import { useParams } from 'react-router-dom'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import PropTypes from 'prop-types'
import React, { useLayoutEffect } from 'react'
import styles from './styles'

export function Remixers({ intl }) {
  const { slug } = useParams()
  const remixer = useSelector(state => state.remixers.remixer)
  const isLoggedIn = useSelector(loggedInSelector)
  const isFetching = useSelector(
    state => state.remixers.isFetchingSingle || state.remixSongs.isFetching
  )
  const locale = useSelector(localeSelector)
  const isMobile = useSelector(isMobileSelector)

  const [queryParams, setQueryParams] = useQueryParameters(
    {
      ...defaultFilters,
      ...defaultRemixersPagination,
      sortBy: SORT_OPTIONS.POPULARITY,
    },
    {
      parsers: { ...filterParsers, sortBy: s => parseInt(s, 10) },
      stringifiers: filterStringifiers,
    }
  )

  const groupBy =
    queryParams.sortBy === SORT_OPTIONS.POPULARITY ? null : 'releasedate'

  const remixerSongs = useSelector(
    queryParams.sortBy === SORT_OPTIONS.POPULARITY
      ? remixSongSelector
      : remixSongSelectorGrouped
  )
  const pageCount = useSelector(state => state.remixSongs.pagination.pageCount)

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(fetchRemixer(slug))
    return () => dispatch(clearRemixer())
  }, [slug, dispatch])

  useLayoutEffect(() => {
    if (!remixer) {
      return
    }

    const params = generateQueryParams({ locale, queryParams })

    dispatch(
      fetchRemixSongs({
        ...params,
        filters: {
          ...params.filters,
          remixers: [remixer.id],
        },
      })
    )

    return () => dispatch(clearSongList())
  }, [locale, dispatch, remixer, queryParams, isLoggedIn])

  const handleChangeSort = sortBy => setQueryParams({ ...queryParams, sortBy })

  const buildPaginationUrl = usePaginationUrl(queryParams)

  if (isFetching) {
    return <Loading />
  }

  return (
    <div className={styles.base}>
      <Helmet defer={false}>
        <title key="pagetitle">
          {remixer?.name ||
            intl.formatMessage({
              defaultMessage: 'Remixer',
              id: 'djcity.common.remixers',
            })}{' '}
          | DJcity
        </title>
      </Helmet>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="Remixer"
            id="djcity.common.remixers"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            {remixer && remixer.image_url && (
              <img
                alt={remixer.name}
                className={styles.remixerImage}
                src={remixer.image_url}
              />
            )}
            <FormattedMessage
              defaultMessage="{remixer_name}"
              id="djcity.common.remixers.name"
              values={{ remixer_name: remixer ? remixer.name : 'Remixer' }}
            />
          </Heading>
          {!isMobile && (
            <div>
              <TogglePanel
                items={[
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.popularity),
                    value: SORT_OPTIONS.POPULARITY,
                  },
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.date),
                    value: SORT_OPTIONS.DATE_DESC,
                  },
                ]}
                onChange={handleChangeSort}
                showSeparator
                value={queryParams.sortBy}
              />
              <FilterPopover filters={queryParams} onChange={setQueryParams} />
              <Link to="/remixers">
                <button className="secondary">
                  <FormattedMessage
                    defaultMessage="Back to all remixers"
                    id="djcity.common.remixers.back.button"
                  />
                </button>
              </Link>
            </div>
          )}
        </div>
        {remixer && remixer.description && (
          <p className={styles.subTitle}>{remixer.description}</p>
        )}
        <Knotch className={styles.knotch} size="big" />
        {isMobile && (
          <Link className={styles.remixersButton} to="/remixers">
            <button className="secondary">
              <FormattedMessage
                defaultMessage="Back to all remixers"
                id="djcity.common.remixers.back.button"
              />
            </button>
          </Link>
        )}
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <DropdownPopover
            isMobile={isMobile}
            items={{
              [SORT_OPTIONS.POPULARITY]: intl.formatMessage(
                LOCALIZED_MESSAGES.popularity
              ),
              [SORT_OPTIONS.DATE_DESC]: intl.formatMessage(
                LOCALIZED_MESSAGES.date
              ),
            }}
            onChange={handleChangeSort}
            value={queryParams.sortBy}
          />
          <FilterPopover
            filters={queryParams}
            isMobile={isMobile}
            onChange={setQueryParams}
          />
        </div>
      )}
      {remixer ? (
        <Pagination
          buildUrl={buildPaginationUrl}
          page={queryParams.page}
          pageCount={pageCount}
        >
          {groupBy ? (
            remixerSongs.map((group, i) => (
              <SongTable
                className={styles.table}
                firstColumnHeaderLabel={intl.formatDate(
                  new Date(group[groupBy]),
                  DEFAULT_DATE_OPTION
                )}
                isLoading={false}
                key={group[groupBy]}
                nextSongs={
                  i + 1 === remixerSongs.length ? [] : remixerSongs[i + 1].songs
                }
                songs={group.songs}
                trackList={TRACKLIST.REMIXERS}
              />
            ))
          ) : (
            <SongTable
              className={styles.table}
              firstColumnHeaderLabel="Records"
              isLoading={false}
              songs={remixerSongs}
              trackList={TRACKLIST.REMIXERS}
            />
          )}
          {remixerSongs.length === 0 && !isFetching ? <NoRecordsLabel /> : null}
        </Pagination>
      ) : (
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            <FormattedMessage
              defaultMessage="Remixer not found"
              id="djcity.records.remixers.notfound"
            />
          </Heading>
          <TextStyle className={styles.bodyText}>
            <FormattedMessage
              defaultMessage="Whoops not able to find remixer. Please check remixer spelling."
              id="djcity.records.remixers.notfoundmessage"
            />
          </TextStyle>
        </div>
      )}
    </div>
  )
}

Remixers.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func,
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(Remixers)
