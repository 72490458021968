import { defineMessages, injectIntl } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.sass'

const localizedMessages = defineMessages({
  downloadAllTracks: {
    id: 'djcity.common.icons.download_all_tracks.alt',
    defaultMessage: 'Add All Tracks To Crate',
  },
  downloadAllTracksIcon: {
    id: 'djcity.common.icons.download_all_tracks.src',
    defaultMessage: '/assets/cdn/download-all-tracks-icon.svg',
  },
})

const DownloadAllTracksButton = ({
  className,
  iconClassName,
  intl,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        'cleanButton',
        styles.downloadAllTracksButton,
        className
      )}
      onClick={onClick}
    >
      <div
        aria-labelledby={intl.formatMessage(
          localizedMessages.downloadAllTracks
        )}
        className={iconClassName}
        role="img"
      >
        <div>
          <svg
            fill="black"
            height="19"
            viewBox="0 0 25 19"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fillPath"
              d="M15.5536 6.55355L16.4071 5.7H15.2H12.3V1V0.5H11.8H6.5H6V1V5.7H3H1.79289L2.64645 6.55355L8.74645 12.6536L9.1 13.0071L9.45355 12.6536L15.5536 6.55355Z"
              fill="black"
              stroke="white"
            />
            <path
              className="fillPath"
              d="M3 15.5H2.5V16V17.7V18.2H3H15.2H15.7V17.7V16V15.5H15.2H3Z"
              fill="black"
              stroke="white"
            />
            <path
              className="fillPath"
              d="M18.7567 6.55355L19.6102 5.7H18.4031H15.5031V1V0.5H15.0031H9.70312H9.20312V1V5.7H6.20312H4.99602L5.84957 6.55355L11.9496 12.6536L12.3031 13.0071L12.6567 12.6536L18.7567 6.55355Z"
              fill="black"
              stroke="white"
            />
            <path
              className="fillPath"
              d="M6.20312 15.5H5.70312V16V17.7V18.2H6.20312H18.4031H18.9031V17.7V16V15.5H18.4031H6.20312Z"
              fill="black"
              stroke="white"
            />
            <path
              className="fillPath"
              d="M21.9598 6.55355L22.8134 5.7H21.6062H18.7063V1V0.5H18.2063H12.9062H12.4062V1V5.7H9.40625H8.19914L9.0527 6.55355L15.1527 12.6536L15.5062 13.0071L15.8598 12.6536L21.9598 6.55355Z"
              fill="black"
              stroke="white"
            />
            <path
              className="fillPath"
              d="M9.40625 15.5H8.90625V16V17.7V18.2H9.40625H21.6062H22.1062V17.7V16V15.5H21.6062H9.40625Z"
              fill="black"
              stroke="white"
            />
          </svg>
        </div>
      </div>
    </button>
  )
}

DownloadAllTracksButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
}

const ConnectedDownloadAllTracksButton = injectIntl(DownloadAllTracksButton)

export { ConnectedDownloadAllTracksButton as DownloadAllTracksButton }
