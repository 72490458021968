import { CLOSE_MODAL, OPEN_MODAL } from '../action_constants'

export const LOGIN_MODAL = 'login'
export const SIGNUP_MODAL = 'signup'
export const FORGOT_PASSWORD_MODAL = 'forgotPassword'
export const UPDATE_PASSWORD_MODAL = 'updatePassword'
export const RESET_PASSWORD_MODAL = 'resetPassword'
export const PLAYBACK_ERROR_MODAL = 'playbackErrorModal'
export const SHORTCUTS_MODAL = 'keyboardShortcuts'
export const WARNING_MODAL = 'warningModal'
export const WARNING_BANNER = 'warningBanner'

export function openModal(modal, data) {
  return {
    type: OPEN_MODAL,
    modal,
    data,
  }
}

export function closeModal(modal = null) {
  return {
    type: CLOSE_MODAL,
    modal,
  }
}
