import classNames from 'classnames'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styles from './styles'

export function ProgressiveImage(props) {
  const [highResImageLoaded, setHighResImageLoaded] = useState(false)
  let filteredProps = omit(props, 'thumbnail')
  return (
    <div className={styles.container}>
      <img
        {...filteredProps}
        onLoad={() => {
          setHighResImageLoaded(true)
        }}
        src={props.src}
      />
      <img
        {...filteredProps}
        className={classNames(styles.overlay, {
          [styles.hidden]: highResImageLoaded,
        })}
        src={props.thumbnail}
      />
    </div>
  )
}

ProgressiveImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
}
