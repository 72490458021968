import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

export const NO_SORTING = 0
export const SORT_ASCENDING = 1
export const SORT_DESCENDING = 2

export const TableHeader = ({
  children,
  className,
  sort,
  onClick,
  sticky = false,
}) => {
  let sortIcon
  switch (sort) {
    case SORT_ASCENDING:
      sortIcon = 'sort-up'
      break

    case SORT_DESCENDING:
      sortIcon = 'sort-down'
      break

    case NO_SORTING:
      sortIcon = 'sort'
      break

    default:
      sortIcon = null
      break
  }

  const clickCallback = useCallback(() => {
    onClick((sort + 1) % 3)
  }, [onClick, sort])

  return (
    <span
      className={classNames(styles.tableHeader, className, {
        [styles.clickable]: onClick,
        [styles.sticky]: sticky,
        [styles.sortable]: sortIcon,
      })}
      onClick={onClick ? clickCallback : null}
    >
      {sortIcon && (
        <FontAwesomeIcon className={styles.sortIcon} icon={sortIcon} />
      )}
      {children}
    </span>
  )
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  sort: PropTypes.oneOf([SORT_ASCENDING, NO_SORTING, SORT_DESCENDING]),
  sticky: PropTypes.bool,
}
