import React from 'react'

export const Cookies = () => {
  return (
    <div id="ot-sdk-cookie-policy">
      <div
        className="ot-sdk-cookie-policy ot-sdk-container"
        id="ot-sdk-cookie-policy-v2"
      >
        <h3 className="ot-sdk-cookie-policy-title" id="cookie-policy-title">
          Cookie List
        </h3>
        <div id="cookie-policy-description">
          A cookie is a small piece of data (text file) that a website – when
          visited by a user – asks your browser to store on your device in order
          to remember information about you, such as your language preference or
          login information. Those cookies are set by us and called first-party
          cookies. We also use third-party cookies – which are cookies from a
          domain different than the domain of the website you are visiting – for
          our advertising and marketing efforts. More specifically, we use
          cookies and other tracking technologies for the following purposes:
        </div>

        <section>
          <h4 className="ot-sdk-cookie-policy-group">
            Strictly Necessary Cookies
          </h4>
          <p className="ot-sdk-cookie-policy-group-desc">
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
            These cookies do not store any personally identifiable information.
          </p>

          <table>
            <caption className="ot-scrn-rdr">
              Strictly Necessary Cookies
            </caption>
            <thead>
              <tr>
                <th className="ot-table-header ot-host" scope="col">
                  Cookie Subgroup
                </th>

                <th className="ot-table-header ot-cookies" scope="col">
                  Cookies
                </th>
                <th className="ot-table-header ot-cookies-type" scope="col">
                  Cookies used
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>.djcity.com
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    <a
                      aria-label="OptanonConsent Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/OptanonConsent"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      OptanonConsent
                    </a>
                    ,
                    <a
                      aria-label="OptanonAlertBoxClosed Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      OptanonAlertBoxClosed
                    </a>
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    First Party
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h4 className="ot-sdk-cookie-policy-group">Functional Cookies</h4>
          <p className="ot-sdk-cookie-policy-group-desc">
            These cookies enable the website to provide enhanced functionality
            and personalisation. They may be set by us or by third party
            providers whose services we have added to our pages. If you do not
            allow these cookies then some or all of these services may not
            function properly.
          </p>

          <table>
            <caption className="ot-scrn-rdr">Functional Cookies</caption>
            <thead>
              <tr>
                <th className="ot-table-header ot-host" scope="col">
                  Cookie Subgroup
                </th>

                <th className="ot-table-header ot-cookies" scope="col">
                  Cookies
                </th>
                <th className="ot-table-header ot-cookies-type" scope="col">
                  Cookies used
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>www.djcity.com
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    <a
                      aria-label="__stripe_sid Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/__stripe_sid"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      __stripe_sid
                    </a>
                    ,
                    <a
                      aria-label="__stripe_mid Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/__stripe_mid"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      __stripe_mid
                    </a>
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    First Party
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h4 className="ot-sdk-cookie-policy-group">Performance Cookies</h4>
          <p className="ot-sdk-cookie-policy-group-desc">
            These cookies allow us to count visits and traffic sources so we can
            measure and improve the performance of our site. They help us to
            know which pages are the most and least popular and see how visitors
            move around the site. All information these cookies collect is
            aggregated and therefore anonymous. If you do not allow these
            cookies we will not know when you have visited our site, and will
            not be able to monitor its performance.
          </p>

          <table>
            <caption className="ot-scrn-rdr">Performance Cookies</caption>
            <thead>
              <tr>
                <th className="ot-table-header ot-host" scope="col">
                  Cookie Subgroup
                </th>

                <th className="ot-table-header ot-cookies" scope="col">
                  Cookies
                </th>
                <th className="ot-table-header ot-cookies-type" scope="col">
                  Cookies used
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>djcity.com
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    <a
                      aria-label="_gid Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_gid"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _gid
                    </a>
                    ,
                    <a
                      aria-label="_uetvid Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_uetvid"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _uetvid
                    </a>
                    ,
                    <a
                      aria-label="_ga_K7D5DGWGZH Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_ga_K7D5DGWGZH"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _ga_K7D5DGWGZH
                    </a>
                    ,
                    <a
                      aria-label="_gclxxxx Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_gclxxxx"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _gclxxxx
                    </a>
                    ,
                    <a
                      aria-label="_ga Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_ga"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _ga
                    </a>
                    ,
                    <a
                      aria-label="_gat Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_gat"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _gat
                    </a>
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    First Party
                  </span>
                </td>
              </tr>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>
                  <a
                    aria-label="nr-data.net Opens in a new Tab"
                    href="https://cookiepedia.co.uk/host/nr-data.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    nr-data.net
                  </a>
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">JSESSIONID</span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    Third Party
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h4 className="ot-sdk-cookie-policy-group">Targeting Cookies</h4>
          <p className="ot-sdk-cookie-policy-group-desc">
            These cookies may be set through our site by our advertising
            partners. They may be used by those companies to build a profile of
            your interests and show you relevant adverts on other sites. They do
            not store directly personal information, but are based on uniquely
            identifying your browser and internet device. If you do not allow
            these cookies, you will experience less targeted advertising.
          </p>

          <table>
            <caption className="ot-scrn-rdr">Targeting Cookies</caption>
            <thead>
              <tr>
                <th className="ot-table-header ot-host" scope="col">
                  Cookie Subgroup
                </th>

                <th className="ot-table-header ot-cookies" scope="col">
                  Cookies
                </th>
                <th className="ot-table-header ot-cookies-type" scope="col">
                  Cookies used
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>djcity.com
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    <a
                      aria-label="_fbp Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_fbp"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _fbp
                    </a>
                    ,
                    <a
                      aria-label="_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx
                    </a>
                    ,
                    <a
                      aria-label="_gat_UA-107320-1 Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_gat_UA-107320-1"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _gat_UA-107320-1
                    </a>
                    ,
                    <a
                      aria-label="_uetsid Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/_uetsid"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      _uetsid
                    </a>
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    First Party
                  </span>
                </td>
              </tr>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>news.djcity.com
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    <a
                      aria-label="__sharethis_cookie_test__ Opens in a new Tab"
                      href="https://cookiepedia.co.uk/cookies/__sharethis_cookie_test__"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      __sharethis_cookie_test__
                    </a>
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    First Party
                  </span>
                </td>
              </tr>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>
                  <a
                    aria-label="doubleclick.net Opens in a new Tab"
                    href="https://cookiepedia.co.uk/host/doubleclick.net"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    doubleclick.net
                  </a>
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    IDE, test_cookie
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    Third Party
                  </span>
                </td>
              </tr>
              <tr>
                <td className="ot-host-td" data-label="Cookie Subgroup">
                  <span className="ot-mobile-border"></span>
                  <a
                    aria-label="youtube.com Opens in a new Tab"
                    href="https://cookiepedia.co.uk/host/youtube.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    youtube.com
                  </a>
                </td>

                <td className="ot-cookies-td" data-label="Cookies">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-td-content">
                    VISITOR_INFO1_LIVE, CONSENT, YSC
                  </span>
                </td>
                <td className="ot-cookies-type" data-label="Cookies used">
                  <span className="ot-mobile-border"></span>
                  <span className="ot-cookies-type-td-content">
                    Third Party
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  )
}
