import { BannerWarning } from 'spa/components/warnings'
import { BeatsourceBanner } from 'spa/components/beatsource_banner'
import { defaultStringResources } from '../../shared/utils'
import { fetchAvailableMaintenance } from '../../reducers/maintenance'
import { fetchStringResources } from '../../action_creators/string_resources'
import { Footer } from 'spa/components/footer'
import { Header, HeaderMobile } from 'spa/components/header'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Loading } from '../loading'
import { LOCALES } from '../../../locale_data/config'
import { loggedInSelector } from 'spa/selectors/users'
import { SubHeader } from 'spa/components/sub_header'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import defaultIcon from 'spa/images/favicon.png'
import Favicon from 'react-favicon'
import iconBlue from 'spa/images/favicon-blue.png'
import iconGreen from 'spa/images/favicon-green.png'
import localizedMessages from './localizedMessages'
import MobileQrPopover from '../mobile_qr/popover'
import Player from 'spa/components/player/player'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { isMobileSelector } from '../../selectors/device'
import { setLocale } from '../../action_creators/locale'
import { useQueryParameters } from '../../hooks/use_query_parameters'
import styles from './styles'

export const LOCALE_TO_ICON = {
  [LOCALES.en_US]: defaultIcon,
  [LOCALES.en_GB]: iconBlue,
  [LOCALES.es_ES]: iconGreen,
  [LOCALES.ja_JP]: defaultIcon,
  [LOCALES.de_DE]: iconBlue,
}

const Layout = ({
  children,
  fullLayout,
  intl,
  requiredResources = defaultStringResources,
  tabTitle,
}) => {
  const dispatch = useDispatch()
  // const activePromo = useSelector(state => state.promos.activePromo);
  const locale = useSelector(state => state.locale.locale)
  const isFetching = useSelector(state => state.stringResources.isFetching)
  const isMobile = useSelector(isMobileSelector)
  const isLoggedIn = useSelector(loggedInSelector)
  const { pathname } = useSelector(state => state.router.location)
  const [{ region }] = useQueryParameters({ region: '' })

  const ref = useRef()

  useEffect(() => {
    if (!ref.current) {
      dispatch(fetchAvailableMaintenance())
    }
  }, [dispatch, pathname])

  useEffect(() => {
    if (!isLoggedIn && region) dispatch(setLocale(region))
    dispatch(fetchStringResources(requiredResources))
  }, [dispatch, locale, requiredResources])

  return (
    <div
      className={classNames(styles.base, {
        [styles.baseMobile]: isMobile,
      })}
    >
      <Favicon url={LOCALE_TO_ICON[locale]} />
      <Helmet defer={false}>
        <title key="pagetitle">
          {intl.formatMessage(
            localizedMessages.hasOwnProperty(tabTitle)
              ? localizedMessages[tabTitle]
              : localizedMessages.djcityTitle
          )}
        </title>
        {isMobile ? (
          <meta
            content="width=device-width, initial-scale=1"
            key="initial-scale"
            name="viewport"
          />
        ) : (
          <meta content="maximum-scale=0" key="initial-scale" name="viewport" />
        )}
      </Helmet>
      <div className={styles.header} id="header">
        <BeatsourceBanner />

        {!isFetching &&
          (isMobile ? (
            <HeaderMobile />
          ) : (
            <>
              <Header />
              <SubHeader />
            </>
          ))}

        <BannerWarning />
      </div>
      {isFetching ? (
        <Loading className={styles.loading} />
      ) : (
        <div
          className={classNames({
            [styles.layoutContainerFull]: fullLayout,
            [styles.layoutContainer]: !fullLayout,
          })}
          id="body"
        >
          <MobileQrPopover />
          {children}
        </div>
      )}
      {isLoggedIn && <Player />}
      {!isFetching && <Footer />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  fullLayout: PropTypes.bool,
  intl: PropTypes.object,
  requiredResources: PropTypes.arrayOf(PropTypes.string),
  tabTitle: PropTypes.string,
}

const ConnectedLayout = injectIntl(Layout)

export { ConnectedLayout as Layout }
