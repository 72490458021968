import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const SliderControl = function({
  children,
  nav,
  className,
  changeSlide,
}) {
  return !!nav && React.Children.count(children) > 1 ? (
    <React.Fragment>
      <a
        className={classNames(className, styles.sliderButtonPrev)}
        onClick={() => changeSlide('prev')}
      >
        <span className="prev">&#8249;</span>
      </a>
      <a
        className={classNames(className, styles.sliderButtonNext)}
        onClick={() => changeSlide('next')}
      >
        <span className="next">&#8250;</span>
      </a>
    </React.Fragment>
  ) : null
}

SliderControl.propTypes = {
  changeSlide: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  nav: PropTypes.bool,
}
