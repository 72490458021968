import { AssetSlide } from 'spa/components/asset_slide'
import { ContentSlider, Slide } from 'spa/components/content_slider'
import { fetchAll as fetchBannerAssets } from 'spa/action_creators/banner_assets'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import NewReleasesTable from 'spa/components/new_releases_table'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { ActionButtons } from '../../action_buttons'
import { isMobileSelector } from '../../../selectors/device'
import { useHistory } from 'react-router-dom'
import styles from './styles'

const defaultQueryParameters = { page: 1 }
const parsers = { page: p => parseInt(p, 10) }

const Home = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useSelector(isMobileSelector)
  const bannerAssets = useSelector(state => state.bannerAssets.models)
  const bannerAssetsFetching = useSelector(
    state => state.bannerAssets.isFetching
  )
  const locale = useSelector(state => state.locale.locale)

  const [queryParams] = useQueryParameters(defaultQueryParameters, {
    parsers,
  })

  const showBannerSlides = (queryParams.page || 0) <= 1

  const browseCatalog = () => {
    const url = new URL('/new-releases', window.location.origin)
    Object.entries(queryParams).forEach(([k, v]) => {
      url.searchParams.set(k, v)
    })
    history.push(url.pathname + '?' + url.searchParams.toString())
  }

  // Loads banner assets on first render and when locale changes
  useEffect(() => {
    dispatch(fetchBannerAssets({}))
  }, [locale, dispatch])

  return (
    <div className={styles.base}>
      {showBannerSlides && (
        <ContentSlider className={styles.slider} loading={bannerAssetsFetching}>
          {bannerAssets.map(asset => (
            <Slide key={asset.baid}>
              <AssetSlide asset={asset} loading={bannerAssetsFetching} />
            </Slide>
          ))}
        </ContentSlider>
      )}
      <ActionButtons />
      <NewReleasesTable />
      {!isMobile && (
        <div className={styles.browseButton}>
          <button onClick={browseCatalog}>
            {intl.formatMessage({
              defaultMessage: 'Browse catalog',
              id: 'djcity.common.sidebar.browse',
            })}
          </button>
        </div>
      )}
    </div>
  )
}

Home.displayName = 'Home'

Home.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(Home)
