const defaultState = {
  isOpen: false,
  lastSeen: Date.now(),
}

export default function mobilePopover(state = defaultState, action) {
  switch (action.type) {
    case 'MOBILE_POPOVER_DISMISS':
      return { isOpen: false, lastSeen: Date.now() }
    case 'MOBILE_POPOVER_SHOW':
      return { isOpen: true, lastSeen: Date.now() }
    default:
      return state
  }
}
