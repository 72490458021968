import { defaultFilters, defaultPagination, defaultSortBy } from '../api/songs'
import { RECEIVE_SONGS_BY_TAG, REQUEST_SONGS_BY_TAG } from '../action_constants'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListReducer } from './create_songs_list_reducer'

export default createSongsListReducer({
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_SONGS_BY_TAG,
  receiveActionConstant: RECEIVE_SONGS_BY_TAG,
  clearActionConstant: CLEAR_SONGS_LIST,
})
