import bowserData from '../shared/bowser_data'

const { browser, engine, os, ...device } = bowserData

const defaultState = {
  ...device,
  browser,
  engine,
  os,
}

export default function deviceReducer(state = defaultState) {
  return state
}
