// This custom hook returns a function that generates URLs
// where the page parameter is in the query string,
// and is fit to be used with the <Pagination> component
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export function usePaginationUrl(queryParams = null) {
  const { pathname } = useLocation()

  return useCallback(
    page => {
      const params = queryString.stringify(
        { ...queryParams, page },
        { arrayFormat: 'comma' }
      )
      return `${pathname}?${params}`
    },
    [pathname, queryParams]
  )
}
