import { defineMessages, injectIntl } from 'react-intl'
import { PopoverHeading } from '../typography/popover_heading'
import { SongPropType } from 'spa/api/songs'
import { SongsListItem } from './songs_list_item'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  played: {
    id: 'djcity.records.queue.player.already_played',
    defaultMessage: 'previous',
  },
  playingNow: {
    id: 'djcity.records.queue.player.playing_now',
    defaultMessage: 'playing now',
  },
  playingNext: {
    id: 'djcity.records.queue.player.next_play',
    defaultMessage: 'next',
  },
})

const SongsList = props => {
  const {
    className,
    intl,
    showArtist = true,
    showBorders,
    showNumbers,
    songIdx,
    songs,
    variant,
    addSongToQueue,
    removeSongFromQueue,
    trackList,
    fixedPopover = false,
    scrollToBottom = false,
    showPopularity,
  } = props

  const ref = useRef()
  const currentRef = useRef()

  const { currentSongId, queue } = useSelector(state => state.player)

  const currentSongIdx = queue.findIndex(
    s => parseInt(s.id) === parseInt(currentSongId)
  )
  const currentSong = queue[currentSongIdx]
  const playedSongs = queue.slice(0, currentSongIdx)
  const songsToPlay = queue.slice(currentSongIdx + 1)

  useEffect(() => {
    if (scrollToBottom && ref.current && currentRef.current) {
      const { offsetTop, offsetHeight } = currentRef.current
      ref.current.scrollTo({
        top: offsetTop - (offsetHeight * 2 + 5),
        behavior: 'smooth',
      })
    }
  }, [])

  return (
    <div className={className} ref={ref}>
      {playedSongs.length > 0 && (
        <>
          <PopoverHeading className={styles.queuePopoverHeading}>
            {intl.formatMessage(localizedMessages.played)}
          </PopoverHeading>
          <ul className={styles.simpleSongsList}>
            {playedSongs.map((song, index) => (
              <SongsListItem
                addSongToQueue={addSongToQueue}
                blur
                currentlyInQueue={true}
                fixedPopover={fixedPopover}
                index={showNumbers ? index + 1 : undefined}
                key={song.id}
                listIndex={index}
                removeSongFromQueue={removeSongFromQueue}
                showArtist={showArtist}
                showBorder={showBorders}
                showPopularity={showPopularity}
                song={song.data}
                songs={songs}
                trackList={trackList}
                variant={variant}
              />
            ))}
          </ul>
        </>
      )}
      {currentSong && (
        <>
          <div ref={currentRef}>
            <PopoverHeading className={styles.queuePopoverHeading}>
              {intl.formatMessage(localizedMessages.playingNow)}
            </PopoverHeading>
          </div>
          <ul className={styles.simpleSongsList}>
            <SongsListItem
              addSongToQueue={addSongToQueue}
              currentlyInQueue={true}
              fixedPopover={fixedPopover}
              index={showNumbers ? songIdx : undefined}
              key={currentSong.id}
              removeSongFromQueue={removeSongFromQueue}
              showArtist={showArtist}
              showBorder={showBorders}
              showPopularity={showPopularity}
              song={currentSong.data}
              trackList={trackList}
              variant={variant}
            />
          </ul>
        </>
      )}
      {songsToPlay.length > 0 && (
        <>
          <PopoverHeading className={styles.queuePopoverHeading}>
            {intl.formatMessage(localizedMessages.playingNext)}
          </PopoverHeading>
          <ul className={styles.simpleSongsList}>
            {songsToPlay.map((song, index) => (
              <SongsListItem
                addSongToQueue={addSongToQueue}
                currentlyInQueue={true}
                fixedPopover={fixedPopover}
                index={showNumbers ? index + 1 : undefined}
                key={song.id}
                listIndex={index}
                removeSongFromQueue={removeSongFromQueue}
                showArtist={showArtist}
                showBorder={showBorders}
                showPopularity={showPopularity}
                song={song.data}
                songs={songs}
                trackList={trackList}
                variant={variant}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

SongsList.propTypes = {
  addSongToQueue: PropTypes.func,
  className: PropTypes.string,
  compact: PropTypes.bool,
  fixedPopover: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  queuedSongIds: PropTypes.arrayOf(PropTypes.number),
  removeSongFromQueue: PropTypes.func,
  scrollToBottom: PropTypes.bool,
  showArtist: PropTypes.bool,
  showBorders: PropTypes.bool,
  showNumbers: PropTypes.bool,
  showPopularity: PropTypes.bool,
  songIdx: PropTypes.number,
  songs: PropTypes.arrayOf(SongPropType),
  trackList: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
}

const IntlSongsList = injectIntl(SongsList)

export { IntlSongsList as SongsList }
