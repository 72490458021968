import { Layout } from '../layout'
import { ModuleLoader } from '../module_loader/module_loader'
import React from 'react'
const HomeSidebar = React.lazy(() => import('../pages/home/home_sidebar'))
const Home = React.lazy(() => import('../pages/home/home'))
const FreePackCampaign = React.lazy(() => import('./free_pack_campaign'))

const display = () => {
  return (
    <Layout>
      <ModuleLoader>
        <Home />
        <HomeSidebar />
        <FreePackCampaign />
      </ModuleLoader>
    </Layout>
  )
}

export { display as FreePackCampaignDisplay }
