import * as crateActions from '../../action_creators/crate'
import * as playerActions from '../../action_creators/player'
import * as tracksActions from '../../action_creators/tracks'
import {
  activeSubscriptionSelector,
  desktopClientDownloadedSelector,
} from 'spa/selectors/users'
import { AVAILABLE_FEATURES, isFeatureEnabled } from 'spa/selectors/features'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CrateListItem } from './crate_list_item'
import { defineMessages, FormattedHTMLMessage, injectIntl } from 'react-intl'
import { DownloadAllTracksButton } from 'spa/components/track_buttons/download_all_tracks_button'
import { filterInt, MAX_DOWNLOAD_COUNT } from '../../shared/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfiniteScroll } from 'spa/components/infinite_scroll'
import { isMobileSelector, isSafariSelector } from '../../selectors/device'
import { Link } from 'spa/components/link'
import { PopoverHeading } from '../typography'
import { SendAllTracksToDownloaderButton } from '../track_buttons/send_all_tracks_to_downloader_button/send_all_tracks_to_downloader_button'
import { ToolTip } from 'spa/components/tooltip'
import { TRACKLIST } from '../../shared/constants'
import ActionConfirm from '../../clear_confirm/clear_confirm'
import classNames from 'classnames'
import EmptyCrate from './empty_crate'
import findIndex from 'lodash/findIndex'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  cancelButton: {
    id: 'djcity.records.crate.clear.button.cancel',
    defaultMessage: 'cancel',
  },
  clearButton: {
    id: 'djcity.records.crate.clear.button.confirmation',
    defaultMessage: 'yes, clear crate',
  },
  title: {
    id: 'djcity.records.crate.clear.confirmation',
    defaultMessage: 'Are You Sure You Want to Clear All Tracks in Your Crate?',
  },
  sendButton: {
    id: 'djcity.records.crate.send.confirmation.title',
    defaultMessage: 'Send',
  },
  confirmTitle: {
    id: 'djcity.records.crate.send.confirmation',
    defaultMessage: 'Send ALL tracks to Desktop App',
  },
  sendDownloader: {
    id: 'djcity.common.icons.sent.downloader.alt',
    defaultMessage: 'Send To Downloader',
  },
  noVersion: {
    id: 'djcity.records.crate.no_version',
    defaultMessage: 'No Versions in Crate',
  },
  oneVersion: {
    id: 'djcity.records.crate.one_version',
    defaultMessage: '1 Version in Crate',
  },
  moreVersions: {
    id: 'djcity.records.crate.more_version',
    defaultMessage: 'Versions in Crate',
  },
})

class CrateList extends React.Component {
  static displayName = 'CrateList'

  static propTypes = {
    activeSubscription: PropTypes.bool,
    addSongsToQueue: PropTypes.func.isRequired,
    autoplay: PropTypes.bool.isRequired,
    batchDownload: PropTypes.func.isRequired,
    clearQueue: PropTypes.func.isRequired,
    currentSongId: PropTypes.string,
    desktopClientDownloaded: PropTypes.bool,
    getCrate: PropTypes.func.isRequired,
    handleOpenPopover: PropTypes.func,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    isBulkDownloadEnabled: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    metadata: PropTypes.object,
    playing: PropTypes.bool.isRequired,
    removeTrack: PropTypes.func,
    removeTracks: PropTypes.func,
    sendToDownloader: PropTypes.func.isRequired,
    songsById: PropTypes.object,
    userProperties: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      clear: false,
      confirm: false,
    }
    this.handleClickBubble = this.handleClickBubble.bind(this)
  }

  onClearList = () => {
    this.setState(prevState => ({
      clear: !prevState.clear,
      confirm: false,
    }))
  }

  onSendConfirmation = () => {
    this.setState(prevState => ({
      confirm: !prevState.confirm,
    }))
  }

  handleSentItemsToCrate = () => {
    const { sendToDownloader } = this.props
    sendToDownloader()
    this.setState({
      confirm: false,
    })
  }

  downloadCrateToBrowser = () => {
    const { items, batchDownload, songsById } = this.props

    batchDownload({
      tracks: items.map(item => {
        const track = songsById[item.track.record.rid]
          ? songsById[item.track.record.rid].types.find(
              type => type.ttid == item.track.ttid
            )
          : item.track
        return {
          ...track,
          rid: item.track.record.rid,
        }
      }),
      trackList: TRACKLIST.CRATE,
    })
  }

  isBulkDownloadLoadingState = () => {
    const { items } = this.props
    return items.some(item => item.track.isDownloading)
  }

  hasDownloadableItems = items => {
    return (
      findIndex(items, x => {
        return x.track.downloadCount < MAX_DOWNLOAD_COUNT
      }) >= 0
    )
  }

  versionCase = intl => {
    const { metadata } = this.props

    if (!metadata || !metadata.totalItems) {
      return intl.formatMessage(localizeMessages.noVersion)
    }
    if (metadata.totalItems === 1) {
      return intl.formatMessage(localizeMessages.oneVersion)
    }
    return `${metadata.totalItems} ${intl.formatMessage(
      localizeMessages.moreVersions
    )}`
  }

  loadCrateItems = () => {
    const { items, getCrate } = this.props

    getCrate({ skipItems: items ? items.length : 0 })
  }

  handleClickBubble(e) {
    const { listIndex } = e.target.dataset
    const songIndex = filterInt(listIndex)

    const { autoplay, items, currentSongId } = this.props
    const { addSongsToQueue } = this.props
    if (
      autoplay &&
      typeof songIndex === 'number' &&
      !!currentSongId &&
      items[songIndex] &&
      parseInt(currentSongId) !== parseInt(items[songIndex].id)
    ) {
      const songsToEnqueue = this.props.items.slice(songIndex)
      songsToEnqueue.length && addSongsToQueue(songsToEnqueue)
    }
  }

  render() {
    const {
      activeSubscription,
      removeTrack,
      removeTracks,
      items,
      metadata,
      intl,
      // handleClosePopover,
      handleOpenPopover,
      desktopClientDownloaded,
      isBulkDownloadEnabled,
      userProperties,
    } = this.props

    const desktopBtnEnabled =
      desktopClientDownloaded &&
      this.hasDownloadableItems(items) &&
      activeSubscription

    const isIosSafari =
      isSafariSelector({ userProperties }) &&
      isMobileSelector({ userProperties })

    return (
      <>
        {items.length > 0 ? (
          <PopoverHeading className={styles.heading}>
            {this.versionCase(intl)}
            <FontAwesomeIcon
              className={styles.trash}
              icon={['far', 'trash']}
              onClick={items.length > 0 ? this.onClearList : null}
            />
          </PopoverHeading>
        ) : null}
        {items.length > 0 && !this.state.clear && !this.state.confirm ? (
          <>
            <div className={styles.downloadall}>
              <FormattedHTMLMessage
                defaultMessage="DOWNLOAD ALL"
                id="djcity.records.crate.download_all"
              />
            </div>
            <div className={styles.center}>
              {isBulkDownloadEnabled && !isIosSafari && (
                <div className={styles.buttonContainer}>
                  <button
                    className={classNames('secondary', styles.browserButton)}
                    onClick={this.downloadCrateToBrowser}
                  >
                    {this.isBulkDownloadLoadingState() ? (
                      <FontAwesomeIcon
                        className={styles.loading}
                        icon="circle-notch"
                        spin
                      />
                    ) : (
                      <DownloadAllTracksButton
                        className={styles.desktop}
                        iconClassName={styles.browserDownload}
                      />
                    )}
                    <FormattedHTMLMessage
                      defaultMessage="BROWSER"
                      id="djcity.records.crate.browser_download"
                    />
                  </button>
                </div>
              )}
              <ToolTip
                className={styles.buttonContainer}
                toolTipClassName={styles.appTooltip}
                toolTipContent={
                  !desktopClientDownloaded && (
                    <React.Fragment>
                      <FormattedHTMLMessage
                        defaultMessage="Versions"
                        id="djcity.common.kebab.tooltip.install"
                      />
                      &nbsp;
                      <Link
                        color="red"
                        key="link"
                        onClick={handleOpenPopover}
                        to="/apps"
                      >
                        <FormattedHTMLMessage
                          defaultMessage="Install Now"
                          id="djcity.records.crate.install_desktop_app"
                        />
                      </Link>
                    </React.Fragment>
                  )
                }
              >
                <button
                  className={classNames('secondary', styles.desktopButton, {
                    inactive: !desktopBtnEnabled,
                  })}
                  onClick={desktopBtnEnabled ? this.onSendConfirmation : null}
                >
                  <SendAllTracksToDownloaderButton className={styles.desktop} />
                  <FormattedHTMLMessage
                    defaultMessage="DESKTOP APP"
                    id="djcity.records.crate.send_desktop_app"
                  />
                </button>
              </ToolTip>
            </div>
          </>
        ) : null}
        {!this.state.clear &&
        !this.state.confirm &&
        items &&
        items.length > 0 ? (
          <InfiniteScroll
            className={styles.crateList}
            hasMoreItems={
              metadata &&
              metadata.totalItems &&
              items.length < metadata.totalItems
            }
            loadMoreItems={this.loadCrateItems}
            onClick={this.handleClickBubble}
            tag="ul"
          >
            {items.map(item => (
              <CrateListItem
                deleteItem={item.id}
                handleOpenPopover={handleOpenPopover}
                key={`${item.id}-${item.track.ttid}`}
                onRemoveFromCrate={removeTrack}
                track={item.track}
                // trackList={items}
              />
            ))}
          </InfiniteScroll>
        ) : null}
        {this.state.clear && items.length > 0 ? (
          <ActionConfirm
            primaryAction={this.onClearList}
            primaryActionText={intl.formatMessage(
              localizeMessages.cancelButton
            )}
            secondaryAction={removeTracks}
            secondaryActionText={intl.formatMessage(
              localizeMessages.clearButton
            )}
            title={intl.formatMessage(localizeMessages.title)}
          />
        ) : null}
        {this.state.confirm && items.length > 0 ? (
          <ActionConfirm
            primaryAction={this.handleSentItemsToCrate}
            primaryActionText={intl.formatMessage(localizeMessages.sendButton)}
            secondaryAction={this.onSendConfirmation}
            secondaryActionText={intl.formatMessage(
              localizeMessages.cancelButton
            )}
            title={intl.formatMessage(localizeMessages.confirmTitle)}
          />
        ) : null}
        {items.length === 0 && <EmptyCrate />}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    autoplay: state.userPreferences.autoplay,
    currentSongId: state.player.currentSongId,
    playing: state.player.playing,
    desktopClientDownloaded: desktopClientDownloadedSelector(state),
    isBulkDownloadEnabled: isFeatureEnabled(
      state.features,
      AVAILABLE_FEATURES.BULK_DOWNLOAD
    ),
    isFetching: state.crate.isFetching,
    items: state.crate.items,
    metadata: state.crate.metadata,
    activeSubscription: activeSubscriptionSelector(state),
    songsById: state.songs.songsById,
    userProperties: state.userProperties,
  }
}

function mapDispatchToProps(dispatch) {
  const {
    getCrate,
    removeTrack,
    removeTracks,
    sendCrateToDownloader,
  } = bindActionCreators(crateActions, dispatch)
  const { addSongsToQueue, clearQueue } = bindActionCreators(
    playerActions,
    dispatch
  )
  const { batchDownload } = bindActionCreators(tracksActions, dispatch)
  return {
    addSongsToQueue,
    clearQueue,
    getCrate,
    removeTrack,
    removeTracks,
    sendToDownloader: sendCrateToDownloader,
    batchDownload,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CrateList))
