import * as geoApi from '../api/geo'
import { createSlice } from '@reduxjs/toolkit'

const territories = createSlice({
  name: 'territories',
  initialState: {
    isFetching: false,
    territories: null,
    error: null,
  },
  reducers: {
    requestTerritories: state => {
      return {
        ...state,
        isFetching: true,
      }
    },
    receiveTerritories: (state, action) => {
      return {
        ...state,
        isFetching: false,
        territories: action.payload.territories.data,
      }
    },
    errorReceivingTerritories: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      }
    },
  },
})

const territoriesReducer = territories.reducer

export { territoriesReducer as territories }

export const {
  requestTerritories,
  receiveTerritories,
  errorReceivingTerritories,
} = territories.actions

/**
 * Request selectable territories
 * @returns {function(...[*]=)}
 */
export const getTerritories = (countryId = null) => async dispatch => {
  try {
    dispatch(requestTerritories())
    const territories = await geoApi.getTerritories(countryId)
    return dispatch(receiveTerritories({ territories }))
  } catch (error) {
    return dispatch(errorReceivingTerritories({ error }))
  }
}
