import { AccountPopover } from 'spa/components/account_popover'
import {
  activeSubscriptionSelector,
  loggedInSelector,
} from 'spa/selectors/users'
import { CratePopover } from 'spa/components/crate_popover'
import { defineMessages, injectIntl } from 'react-intl'
import { HIDE_LEARNMORE_BUTTON_ON_PAGES } from '../../shared/utils'
import { Link } from 'react-router-dom'
import { SongSearchBox } from 'spa/components/song_search_box'
import { TextStyle } from 'spa/components/typography'
import { useLocation, useParams } from 'react-router-dom'
import { UserImpersonationBanner } from '../user_impersonation_banner'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'
import SubscribeButton from 'spa/components/subscribe_button'

export const subscribeLocalizeMessages = defineMessages({
  DJcity: {
    id: 'djcity.common.site.banner.DJcity',
    defaultMessage: 'DJcity',
  },
  message: {
    id: 'djcity.common.site.banner.message',
    defaultMessage:
      'the a music discovery platform that helps you find the hottest songs to help you build your playlist.',
  },
  learn: {
    id: 'djcity.common.site.banner.learn',
    defaultMessage: 'Learn More',
  },
  subscribeNow: {
    id: 'djcity.common.marketing.subscribenow',
    defaultMessage: 'Subscribe Now',
  },
})

const SubHeader = ({ intl }) => {
  const isLoggedIn = useSelector(loggedInSelector)
  const isActive = useSelector(activeSubscriptionSelector)
  const isMarketingPage = ['/', '/about'].includes(useLocation()?.pathname)
  const { offerName } = useParams()

  return (
    <div className={styles.subHeader}>
      <div className={styles.subHeaderRow}>
        <div
          className={classNames(styles.headerCell, {
            [styles.hiResOnly]: !isLoggedIn,
          })}
        >
          <SongSearchBox
            className={classNames(styles.searchBox, {
              [styles.searchBoxLoggedIn]: isLoggedIn,
            })}
          />
          {isLoggedIn ? (
            <>
              <span className={styles.headerSeparator} />
              <CratePopover />
            </>
          ) : null}
        </div>
        <AccountPopover
          className={classNames(
            styles.headerCell,
            {
              [styles.hiResOnly]: !isLoggedIn,
            },
            styles.accountPopover
          )}
        />
      </div>
      {!isActive &&
      ((isLoggedIn && (isMarketingPage || offerName)) ||
        (!isMarketingPage && !offerName)) ? (
        <div className={styles.subHeaderRow}>
          <div className={classNames(styles.ctaCell)}>
            <div className={styles.headline}>
              <TextStyle color="red" variant="bold">
                {intl.formatMessage(subscribeLocalizeMessages.DJcity)}{' '}
              </TextStyle>
              <TextStyle color="white" variant="label">
                {intl.formatMessage(subscribeLocalizeMessages.message)}
              </TextStyle>
            </div>
            {!location.pathname.match(HIDE_LEARNMORE_BUTTON_ON_PAGES) && (
              <Link
                className={classNames('button', 'secondary', styles.infoButton)}
                to="/about"
              >
                {intl.formatMessage(subscribeLocalizeMessages.learn)}
              </Link>
            )}
          </div>
          <div className={styles.subscribeContainer}>
            <SubscribeButton
              className={classNames('large', styles.subscribeButton)}
            >
              {intl.formatMessage(subscribeLocalizeMessages.subscribeNow)}
            </SubscribeButton>
          </div>
        </div>
      ) : null}
      <UserImpersonationBanner />
    </div>
  )
}

SubHeader.displayName = 'SubHeader'

SubHeader.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

const ConnectedSubHeader = injectIntl(SubHeader)

export { ConnectedSubHeader as SubHeader }
