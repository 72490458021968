import { closeModal, PLAYBACK_ERROR_MODAL } from 'spa/action_creators/modals'
import { FormattedMessage, injectIntl } from 'react-intl'
import { playCurrentSong } from '../../action_creators/player'
import { TextStyle } from '../typography'
import { useDispatch } from 'react-redux'
import ApplicationModal from 'spa/components/application_modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

function PlaybackErrorModal() {
  const dispatch = useDispatch()
  const closePlaybackModal = useCallback(() => {
    dispatch(closeModal(PLAYBACK_ERROR_MODAL))
    dispatch(playCurrentSong())
  }, [dispatch])
  const onClose = useCallback(() => {
    dispatch(playCurrentSong())
  }, [dispatch])
  return (
    <ApplicationModal
      modalKey={PLAYBACK_ERROR_MODAL}
      onClose={onClose}
      title={
        <FormattedMessage
          defaultMessage="Playback Error"
          id="djcity.records.player.playback.error.title"
        />
      }
    >
      <TextStyle className={classNames(styles.headerText, styles.mv)}>
        <FormattedMessage
          defaultMessage="Error starting track. Please try again."
          id="djcity.records.player.playback.error.description"
        />
      </TextStyle>
      <button
        className={classNames('secondary', styles.heading)}
        onClick={closePlaybackModal}
      >
        <FormattedMessage
          defaultMessage="Try Again"
          id="djcity.records.player.playback.error.continue_button"
        />
      </button>
    </ApplicationModal>
  )
}

PlaybackErrorModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(PlaybackErrorModal)
