import { intlShape } from 'react-intl'

let INTL
const IntlGlobalProvider = (props, context) => {
  INTL = context.intl
  return props.children
}

IntlGlobalProvider.contextTypes = {
  intl: intlShape.isRequired,
}

let instance
class IntlTranslator {
  constructor() {
    if (!instance) {
      instance = this
    }
    return instance
  }

  formatHTMLMessage(message, values) {
    return INTL.formatHTMLMessage(message, values)
  }

  formatMessage(message, values) {
    return INTL.formatMessage(message, values)
  }

  // https://formatjs.io/docs/react-intl/api/#formatdate
  formatDate(date, options) {
    return INTL.formatDate(date, options)
  }
}

export const intl = new IntlTranslator()
export default IntlGlobalProvider
