import { getQueryParams } from './shared/utils'
import { Link } from 'react-router-dom'
import React from 'react'

export function makeSongUrl(song) {
  return `/records/${song.rid}`
}

export function linkifyArtistNames(song) {
  const buildArtistURL = q => `/artist?${getQueryParams({ q })}`

  let artists = [song.artist, song.featuring]
    .filter(Boolean)
    .join(' ')
    .replace(
      /( \(prod\. )|( \(ft\. )|(; prod\. )|( & )|( vs )|( \(.+?\))|(\) ?)|(, )/gi,
      '</a>$&<a>'
    )
    .replace('<a></a>', '')
    .replace(/\)<a>$/gi, ')')
    .replace(/([^)])$/gi, '$&</a>')
  artists = '<a>' + artists
  artists = artists
    .split(/(<a>.+?<\/a>)/)
    .filter(Boolean)
    .map(str => {
      const artistName = str.match(/^<a>(.+)<\/a>$/)
      return artistName ? (
        <Link
          key={song.rid + artistName[1]}
          title={artistName[1]}
          to={buildArtistURL(artistName[1])}
        >
          {artistName[1]}
        </Link>
      ) : (
        str
      )
    })

  return artists
}
