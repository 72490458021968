import { BlankLayout } from '../blank_layout'
import { PaymentConfirmation } from './payment_confirmation'
import React from 'react'

const display = () => {
  return (
    <BlankLayout simple>
      <PaymentConfirmation />
    </BlankLayout>
  )
}

export { display as PaymentConfirmationDisplay }
