import { DOWNLOAD_QUEUE, FETCH_QUEUE } from '../../action_creators/tracks'
import { useEffect } from 'react'

export const DownloadsListener = () => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      const someTrackVersionsAreBeingDownloaded =
        FETCH_QUEUE.size || DOWNLOAD_QUEUE.size
      if (!someTrackVersionsAreBeingDownloaded) return
      const e = event || window.event
      e.preventDefault()
      const message = `Are you sure you want to leave? Downloads are in progress.`
      e.returnValue = message // Standard for most browsers
      return message // For some older browsers
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [FETCH_QUEUE.size, DOWNLOAD_QUEUE.size])

  return null
}
