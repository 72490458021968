import * as featureApi from '../api/features'
import {
  ERROR_RECEIVING_USER_FEATURES,
  RECEIVE_USER_FEATURES,
  REQUEST_USER_FEATURES,
} from '../action_constants'

function requestUserFeatures(userId) {
  return {
    type: REQUEST_USER_FEATURES,
    userId,
  }
}

function receiveUserFeatures(userId, features) {
  return {
    type: RECEIVE_USER_FEATURES,
    userId,
    features,
  }
}

function errorReceivingUserFeatures(userId, error) {
  return {
    type: ERROR_RECEIVING_USER_FEATURES,
    userId,
    error,
  }
}

export function getUserFeatures(userId) {
  return function(dispatch) {
    dispatch(requestUserFeatures(userId))
    return featureApi
      .fetchUserFeatures(userId)
      .then(features => {
        dispatch(receiveUserFeatures(userId, features))
      })
      .catch(err => {
        dispatch(errorReceivingUserFeatures(userId, err))
      })
  }
}
