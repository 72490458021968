import {
  ERROR_RECEIVING_SMASH_VISION_ACCOUNT,
  ERROR_UPDATING_SMASH_VISION_ACCOUNT,
  RECEIVE_SMASH_VISION_ACCOUNT,
  REQUEST_SMASH_VISION_ACCOUNT,
  UPDATED_SMASH_VISION_ACCOUNT,
  UPDATING_SMASH_VISION_ACCOUNT,
} from '../action_constants'

const defaultState = {
  account: {},
  isFetching: false,
  error: null,
}

export default function smashVision(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_SMASH_VISION_ACCOUNT:
    case UPDATING_SMASH_VISION_ACCOUNT:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case RECEIVE_SMASH_VISION_ACCOUNT:
    case UPDATED_SMASH_VISION_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        account: action.account,
      }
    case ERROR_RECEIVING_SMASH_VISION_ACCOUNT:
    case ERROR_UPDATING_SMASH_VISION_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
