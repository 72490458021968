import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
  responseData,
} from '../shared/utils'

export function addToCrate(params) {
  return fetchWithHeaders('/crate', {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parseResponse)
}

export function addAllTracksToCrate(params) {
  return fetchWithHeaders('/crate/batch', {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parseResponse)
}

export function removeTrack(params) {
  let endpoint
  if (params.id) {
    endpoint = `/crate/${params.id}`
  } else {
    endpoint = `/crate/${params.songId}/${params.trackId}`
  }
  return fetchWithHeaders(endpoint, {
    method: 'DELETE',
  }).then(responseData)
}

export function removeAllTracks(params) {
  return fetchWithHeaders(`/crate/batch`, {
    method: 'DELETE',
    body: JSON.stringify(params),
  }).then(responseData)
}

export function removeTracks() {
  return fetchWithHeaders(`/crate`, {
    method: 'DELETE',
  }).then(responseData)
}

export function sendToDownloader() {
  return fetchWithHeaders(`/crate/enqueue`, {
    method: 'POST',
  }).then(parseResponse)
}

export function getCrate(params = {}) {
  return fetchWithHeaders(`/crate?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}
