import { AccountPopover } from 'spa/components/account_popover'
import {
  activeSubscriptionSelector,
  loggedInSelector,
} from 'spa/selectors/users'
import { CratePopover } from 'spa/components/crate_popover'
import { DarkSwitch } from 'spa/components/dark_switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import { HeaderMenu } from './header_menu'
import { Link, useLocation, useParams } from 'react-router-dom'
import { LOGIN_MODAL, openModal } from 'spa/action_creators/modals'
import { Logo } from 'spa/components/logo'
import { SongSearchBox } from 'spa/components/song_search_box'
import { useDispatch, useSelector } from 'react-redux'
import { UserImpersonationBanner } from '../user_impersonation_banner'
import classNames from 'classnames'
import localizedMessages from './localizedMessages'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import styles from './header_mobile.sass'
import SubscribeButton from 'spa/components/subscribe_button'

const HeaderMobile = ({ intl }) => {
  const dispatch = useDispatch()
  const isMarketingPage = ['/', '/about'].includes(useLocation()?.pathname)
  const { offerName } = useParams()

  // Selectors
  const isLoggedIn = useSelector(loggedInSelector)
  const isActive = useSelector(activeSubscriptionSelector)

  // Local state
  const [isMenuVisible, setMenuVisible] = useState(false)
  const [isSearchBoxVisible, setSearchBoxVisible] = useState(false)

  const onClickMenuIcon = () => setMenuVisible(!isMenuVisible)

  const onLoginClick = useCallback(() => dispatch(openModal(LOGIN_MODAL)), [
    dispatch,
  ])

  return (
    <div
      className={classNames(styles.headerMobile, {
        [styles.headerMobileOpen]: isMenuVisible,
      })}
    >
      <UserImpersonationBanner />
      {!isMenuVisible &&
        (isSearchBoxVisible ? (
          <div className={styles.topWithSearchBoxVisible}>
            <SongSearchBox
              className={styles.mobileSearchBox}
              reset={() => setSearchBoxVisible(false)}
            />
          </div>
        ) : (
          <div className={styles.top}>
            <div
              className={styles.searchCollapsed}
              onClick={() => setSearchBoxVisible(true)}
            >
              <FontAwesomeIcon className={styles.searchIcon} icon="search" />
            </div>
            {!isActive &&
              ((isLoggedIn && (isMarketingPage || offerName)) ||
                (!isMarketingPage && !offerName)) && (
                <SubscribeButton className={styles.subscribeButton}>
                  <FormattedMessage
                    defaultMessage="Subscribe Now"
                    id="djcity.common.marketing.subscribenow"
                  />
                </SubscribeButton>
              )}
            {!isLoggedIn ? (
              <button
                className={classNames(styles.loginButton)}
                onClick={onLoginClick}
              >
                {intl.formatMessage(localizedMessages.login)}
              </button>
            ) : (
              <AccountPopover
                className={classNames(
                  styles.headerCell,
                  styles.accountPopover,
                  {
                    [styles.hiResOnly]: !isLoggedIn,
                  }
                )}
              />
            )}
          </div>
        ))}
      <div className={styles.bottom} onClick={e => e.stopPropagation()}>
        <div className={styles.bars} onClick={onClickMenuIcon}>
          <FontAwesomeIcon icon={isMenuVisible ? 'times' : 'bars'} />
        </div>

        {!isMenuVisible && (
          <Link to="/">
            <Logo className={styles.logo} svg />
          </Link>
        )}

        <div className={styles.rightActions}>
          {isMenuVisible && <DarkSwitch className={styles.darkSwitch} />}
          {isLoggedIn && (
            <CratePopover className={styles.cratePopover} iconOnly />
          )}
        </div>
      </div>

      {isMenuVisible && <HeaderMenu onClick={onClickMenuIcon} />}
    </div>
  )
}

HeaderMobile.displayName = 'HeaderMobile'

HeaderMobile.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

const ConnectedHeader = injectIntl(HeaderMobile)

export { ConnectedHeader as HeaderMobile }
