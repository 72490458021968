import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const Checkbox = ({ className, children, selected, onChange }) => {
  return (
    <label className={classNames(styles.checkbox, className)}>
      <input onChange={onChange} selected={selected} type="checkbox" />
      <div className={styles.checkbox}>
        <FontAwesomeIcon
          className={styles.icon}
          icon={selected ? ['fas', 'check-square'] : ['far', 'square']}
        />
      </div>
      {children}
    </label>
  )
}

Checkbox.displayName = 'Checkbox'

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}
