import { Button as RAButton } from 'react-aria-menubutton'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const NavMenuTrigger = ({ className, ...rest }) => (
  <RAButton
    className={classNames(styles.navMenuTrigger, className)}
    {...rest}
  />
)

NavMenuTrigger.displayName = 'NavMenuTrigger'

NavMenuTrigger.propTypes = {
  className: PropTypes.string,
}
