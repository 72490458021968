import { connect } from 'react-redux'
import { getLocalizedNewsLink } from 'spa/components/navigation/utils'
import PropTypes from 'prop-types'
import React, { useLayoutEffect } from 'react'
import styles from './styles'

function News({ locale }) {
  useLayoutEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
      body::-webkit-scrollbar {
        display: none;
      }
      body {
        -ms-overflow-style: none;
      }
      html {
        scrollbar-width: none;
      }
      `
    document.head.appendChild(style)

    return () => document.head.removeChild(style)
  }, [])

  return (
    <iframe
      className={styles.newsFrame}
      src={`${getLocalizedNewsLink(locale)}?hide_header=1`}
    />
  )
}

News.propTypes = {
  locale: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    locale: state.locale.locale,
  }
}

const ConnectedNews = connect(mapStateToProps)(News)

export { ConnectedNews as News }
