import { Layout } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'
const Guide = React.lazy(() => import('./guide'))

const display = () => {
  return (
    <Layout tabTitle="guide">
      <ModuleLoader>
        <Guide />
      </ModuleLoader>
    </Layout>
  )
}

export { display as GuideDisplay }

export { Guide }

export default Guide
