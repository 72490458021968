import {
  CLEAR_GLOBAL_SONGS,
  RECEIVE_GLOBAL_SONGS,
  REQUEST_GLOBAL_SONGS,
} from '../action_constants'
import {
  defaultFilters,
  defaultGlobalPagination,
  defaultPagination,
  defaultSortBy,
  getAll,
} from '../api/songs'

import { createSongsListActionCreator } from './create_songs_list_action_creator'

export const fetchGlobalSongs = createSongsListActionCreator({
  defaultFilters,
  defaultPagination: defaultGlobalPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_GLOBAL_SONGS,
  receiveActionConstant: RECEIVE_GLOBAL_SONGS,
  apiMethod: getAll,
  reducerName: 'globalSongs',
})

export function clearGlobalList() {
  return {
    type: CLEAR_GLOBAL_SONGS,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}
