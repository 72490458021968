import { FormattedHTMLMessage } from 'react-intl'
import { Link } from 'spa/components/link'
import { linkifyArtistNames, makeSongUrl } from 'spa/urls'
import { PlayTrackButton } from 'spa/components/track_buttons/play_track_button'
import { SongPropType } from 'spa/api/songs'
import { SongVersionsPopover } from 'spa/components/table/song_versions_popover'
import { TextStyle } from 'spa/components/typography'
import { ToolTip } from '../tooltip'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export class SongsListItem extends React.PureComponent {
  static propTypes = {
    addSongToQueue: PropTypes.func,
    blur: PropTypes.bool,
    currentlyInQueue: PropTypes.bool,
    fixedPopover: PropTypes.bool,
    index: PropTypes.number,
    listIndex: PropTypes.number,
    removeSongFromQueue: PropTypes.func,
    showArtist: PropTypes.bool,
    showBorder: PropTypes.bool,
    showPopularity: PropTypes.bool,
    song: SongPropType,
    trackList: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark']),
  }

  render() {
    const {
      blur,
      index,
      listIndex,
      showBorder,
      song,
      currentlyInQueue,
      removeSongFromQueue,
      variant = 'dark',
      addSongToQueue,
      trackList,
      showArtist = true,
      fixedPopover = false,
      showPopularity = false,
    } = this.props
    return (
      <li
        className={classNames(styles.listItem, {
          [styles.listItemWithBorder]: showBorder,
          [styles.bluredItem]: blur,
        })}
        key={song.rid}
      >
        <PlayTrackButton
          listIndex={listIndex}
          numberIndex={index}
          songId={song.rid}
          trackList={trackList}
        />
        <div className={classNames(styles.info)}>
          <Link to={makeSongUrl(song)}>
            <TextStyle
              className={styles.cellTitle}
              color={variant === 'dark' ? 'black' : 'white'}
              truncate
              variant="bold"
            >
              {song.title}
            </TextStyle>
          </Link>
          {showArtist && (
            <TextStyle
              className={classNames('artist-names', styles.artist)}
              color={variant === 'dark' ? 'grey' : 'white'}
              tagName="p"
              truncate
            >
              {linkifyArtistNames(song)}
            </TextStyle>
          )}
          {showPopularity && (
            <div className={styles.popularityContainer}>
              <ToolTip
                toolTipContent={
                  <FormattedHTMLMessage
                    defaultMessage="Versions"
                    id="djcity.common.popularity.label"
                  />
                }
              >
                <div className={styles.popularityProgress}>
                  <div
                    className={styles.progress}
                    style={{ width: `${song.popularity}%` }}
                  />
                </div>
              </ToolTip>
            </div>
          )}
        </div>
        <div className={styles.moreBlock}>
          <SongVersionsPopover
            currentlyInQueue={currentlyInQueue}
            fixed={fixedPopover}
            onAddSongToQueue={addSongToQueue}
            onRemoveSongFromQueue={removeSongFromQueue}
            songId={song.rid}
            trackList={trackList}
          />
        </div>
      </li>
    )
  }
}
