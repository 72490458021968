import {
  ERROR_RECEIVING_PLAYLISTS_DATA,
  RECEIVE_PLAYLISTS_DATA,
  REQUEST_PLAYLISTS_DATA,
} from '../action_constants'

const defaultState = {
  dates: [],
  genericPlaylists: [],
  isFetching: false,
  error: null,
}

export default function playlistsData(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_PLAYLISTS_DATA:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case RECEIVE_PLAYLISTS_DATA:
      return {
        ...state,
        isFetching: false,
        dates: action.dates,
        genericPlaylists: action.genericPlaylists,
      }
    case ERROR_RECEIVING_PLAYLISTS_DATA:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
