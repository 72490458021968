import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'spa/components/link'
import { loggedInSelector } from 'spa/selectors/users'
import { TextStyle } from 'spa/components/typography'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import LocaleList from '../layout/locale/locale_list'
import PropTypes from 'prop-types'
import React from 'react'

import localizedMessages from './localizedMessages'

import styles from './header_menu.sass'

const HeaderMenu = ({ intl, onClick }) => {
  const isLoggedIn = useSelector(loggedInSelector)

  return (
    <div className={styles.container}>
      <div className={styles.recordPoolItem} onClick={e => e.stopPropagation()}>
        <TextStyle
          className={styles.title}
          tagName="h4"
          uppercase
          variant="extra-bold"
        >
          {intl.formatMessage(localizedMessages.browseTitle)}
        </TextStyle>

        <LocaleList className={styles.localeList} />
      </div>
      <ul
        aria-label={intl.formatMessage(localizedMessages.browseTitle)}
        className={styles.links}
        onClick={onClick}
        role="navigation"
      >
        <li>
          <Link className={styles.link} to="/">
            {intl.formatMessage(localizedMessages.home)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/new-releases',
          }}
        >
          <Link className={styles.link} to="/new-releases">
            {intl.formatMessage(localizedMessages.newReleases)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/most-popular',
          }}
        >
          <Link className={styles.link} to="/most-popular">
            {intl.formatMessage(localizedMessages.mostPopular)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/exclusives',
          }}
        >
          <Link className={styles.link} to="/exclusives">
            {intl.formatMessage(localizedMessages.exclusives)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/playlists/monthly',
          }}
        >
          <Link className={styles.link} to="/playlists/monthly">
            {intl.formatMessage(localizedMessages.playlists)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/monthly-charts',
          }}
        >
          <Link className={styles.link} to="/monthly-charts">
            {intl.formatMessage(localizedMessages.monthlyCharts)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname.match(/^\/genres?/),
          }}
        >
          <Link className={styles.link} to="/genres">
            {intl.formatMessage(localizedMessages.genres)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname.match(/^\/remixer?/),
          }}
        >
          <Link className={styles.link} to="/remixers">
            {intl.formatMessage(localizedMessages.remixers)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/track-updates',
          }}
        >
          <Link className={styles.link} to="/track-updates">
            {intl.formatMessage(localizedMessages.trackUpdates)}
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/global',
          }}
        >
          <Link className={styles.link} to="/global">
            {intl.formatMessage(localizedMessages.globalSongs)}
          </Link>
        </li>
      </ul>

      <div className={styles.moreItem} onClick={e => e.stopPropagation()}>
        <TextStyle
          className={styles.title}
          tagName="h4"
          uppercase
          variant="extra-bold"
        >
          {intl.formatMessage(localizedMessages.moreTitle)}
        </TextStyle>
      </div>
      <ul
        aria-label={intl.formatMessage(localizedMessages.moreTitle)}
        className={styles.links}
        onClick={onClick}
        role="navigation"
      >
        <li
          className={{
            [styles.active]: location.pathname === '/apps',
          }}
        >
          <Link className={styles.link} to="/apps">
            <FormattedMessage
              defaultMessage="Apps"
              id="djcity.common.navigation.apps"
            />
          </Link>
        </li>
        <li>
          <a
            className={styles.link}
            href="https://www.youtube.com/tvdjcity"
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="DJcityTV"
              id="djcity.common.navigation.djcity.tv"
            />
          </a>
        </li>
        <li
          className={classNames(styles.link, {
            [styles.active]: location.pathname === '/faq',
          })}
        >
          <Link to="/faq">
            <FormattedMessage
              defaultMessage="FAQ"
              id="djcity.common.navigation.faq"
            />
          </Link>
        </li>
        {isLoggedIn && (
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/account',
            })}
          >
            <Link to="/account">
              <FormattedMessage
                defaultMessage="Your Account"
                id="djcity.common.navigation.yourAccount"
              />
            </Link>
          </li>
        )}
        <li
          className={{
            [styles.active]: location.pathname === '/contact-us',
          }}
        >
          <Link className={styles.link} to="/contact-us">
            <FormattedMessage
              defaultMessage="Contact Us"
              id="djcity.common.navigation.contact_us"
            />
          </Link>
        </li>
        <li
          className={{
            [styles.active]: location.pathname === '/about',
          }}
        >
          <Link className={styles.link} to="/about">
            <FormattedMessage
              defaultMessage="About the Platform "
              id="djcity.common.navigation.about"
            />
          </Link>
        </li>
        <li className={styles.newsItem}>
          <Link to="/news">
            <TextStyle className={styles.title} uppercase variant="extra-bold">
              {intl.formatMessage(localizedMessages.newsTitle)}
            </TextStyle>
          </Link>
        </li>
      </ul>
    </div>
  )
}

HeaderMenu.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

const ConnectedHeader = injectIntl(HeaderMenu)

export { ConnectedHeader as HeaderMenu }
