import * as stringResourcesActions from '../../action_creators/string_resources'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { defaultStringResources } from '../../shared/utils'
import { defineMessages, injectIntl } from 'react-intl'
import { fetchAvailableMaintenance } from '../../reducers/maintenance'
import { Loading } from '../loading'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Footer from './_footer'
import PropTypes from 'prop-types'
import React from 'react'
import Styles from './styles'

const localizedMessages = defineMessages({
  logoWhite: {
    id: 'djcity.common.images.logoWhite',
    defaultMessage: '/assets/cdn/logo_djcity_w.png',
  },
  logoWhiteAlt: {
    id: 'djcity.common.images.logoWhite.alt',
    defaultMessage: 'DJcity',
  },
})

class BlankLayout extends React.Component {
  constructor(props) {
    super(props)
    this.fetchResources = this.fetchResources.bind(this)
  }

  componentDidMount() {
    const { fetchAvailableMaintenance } = this.props
    fetchAvailableMaintenance()
    this.fetchResources()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.locale !== prevProps.locale ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.fetchResources()
    }
  }

  fetchResources() {
    const { requiredResources, fetchStringResources } = this.props
    fetchStringResources(requiredResources)
  }

  render() {
    const { children, intl, isFetching, simple } = this.props
    if (isFetching) {
      return <Loading />
    }
    if (simple) return <>{children}</>

    return (
      <div className={classNames(Styles.row)}>
        <div className={classNames(Styles.column, Styles.logo_container)}>
          <img
            alt={intl.formatMessage(localizedMessages.logoWhiteAlt)}
            className={Styles.logo}
            src={intl.formatMessage(localizedMessages.logoWhite)}
          />
        </div>
        <div className={classNames(Styles.column, Styles.right)}>
          {children}
          <Footer />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale.locale,
    isFetching: state.stringResources.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  const { fetchStringResources } = bindActionCreators(
    stringResourcesActions,
    dispatch
  )
  return {
    fetchStringResources,
    fetchAvailableMaintenance,
  }
}

BlankLayout.propTypes = {
  children: PropTypes.node,
  fetchAvailableMaintenance: PropTypes.func.isRequired,
  fetchStringResources: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isFetching: PropTypes.bool,
  locale: PropTypes.string,
  location: PropTypes.object.isRequired,
  requiredResources: PropTypes.arrayOf(PropTypes.string),
  simple: PropTypes.bool,
}

BlankLayout.defaultProps = {
  requiredResources: defaultStringResources,
}

const ConnectedBlankLayout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(BlankLayout))
)

export { ConnectedBlankLayout as BlankLayout }
