import { defineMessages } from 'react-intl'
import { isMinimumCharacters } from '../shared/utils'
import { useCallback } from 'react'

const localizedMessages = defineMessages({
  invalidPassword: {
    id: 'djcity.common.error.password',
    defaultMessage:
      'Invalid password. Must be at least 8 characters. Please try again.',
  },
})

export function usePasswordValidation(intl) {
  return useCallback(
    v =>
      !isMinimumCharacters(v, 8)
        ? intl.formatMessage(localizedMessages.invalidPassword)
        : null,
    [intl]
  )
}
