/* eslint-disable react/prop-types */
import * as autocompleteSearchActions from 'spa/action_creators/autocomplete'
import * as React from 'react'
import * as tagActions from 'spa/action_creators/tags'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { defaultFilters, defaultSortBy } from 'spa/api/songs'
import { TextHighlight } from '../typography'
import Autosuggest from 'react-autosuggest'
import PropTypes from 'prop-types'
import styles from './styles'

const getSuggestions = value => {
  return value.value
}

const renderSuggestionsContainer = ({ containerProps, children }) => {
  if (!children) {
    return null
  }
  return (
    <div {...containerProps} className={styles.autocompleteContainer}>
      {children}
    </div>
  )
}

const shouldRenderSuggestions = value => {
  return value && typeof value === 'string' && value.trim().length >= 1
}

const isTag = tag => !!tag.popularity

class AutocompleteInput extends React.Component {
  static propTypes = {
    clearSongs: PropTypes.func.isRequired,
    fetchTags: PropTypes.func.isRequired,
    isLoadingTags: PropTypes.bool.isRequired,
    onCompletion: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    searchSongs: PropTypes.func.isRequired,
    suggestions: PropTypes.array,
    tags: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.state = {
      value: '',
    }
    this.timeout = 0
  }

  componentDidMount = () => {
    const { fetchTags, tags, isLoadingTags } = this.props

    if (!tags && !isLoadingTags) {
      fetchTags()
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.clearSongs()
  }

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = 0
    }
    this.timeout = setTimeout(() => {
      this.props.searchSongs({
        filters: defaultFilters,
        searchTerm: value,
        sortBy: defaultSortBy,
      })
      this.timeout = 0
    }, 500)
  }

  renderSuggestion = (suggestion, { isHighlighted }) => {
    const pattern = (this.state.value || '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, ' ')

    let text
    if (isTag(suggestion)) {
      text = suggestion.name
    } else {
      text = suggestion.value
    }
    return (
      <TextHighlight
        className={styles.songText}
        pattern={pattern}
        variant={isHighlighted ? 'bold' : 'default'}
      >
        {text}
      </TextHighlight>
    )
  }

  renderSectionTitle = section => (
    <div className={styles.searchSectionTitle}>{section.title}</div>
  )

  getSectionSuggestions = section => section.suggestions

  onChange = (event, { newValue }) => {
    if (newValue || newValue === '') {
      this.setState({
        value: newValue,
      })
    }
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    this.setState({ value: '' })
    event.preventDefault()
    let target
    if (isTag(suggestion)) {
      target = `/genre/${suggestion.id}`
    } else {
      target = `/search?q=${suggestionValue}`
    }
    this.props.onCompletion(target)
  }

  render() {
    const { value } = this.state
    const {
      placeholder,
      type,
      className,
      suggestions,
      tags,
      ...rest
    } = this.props

    const inputProps = {
      onChange: this.onChange,
      placeholder,
      value: value || '',
      type,
      className,
    }

    const cleanSearchTerm = (value || '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, ' ')
    const suggestionSections = []
    if (tags && cleanSearchTerm) {
      const filteredTags = tags.filter(
        tag => (tag.name || '').toLowerCase().indexOf(cleanSearchTerm) >= 0
      )
      if (filteredTags.length) {
        suggestionSections.push({
          title: 'Genres',
          suggestions: filteredTags,
        })
      }
    }

    if (suggestions && suggestions.length) {
      suggestionSections.push({
        title: 'Songs',
        suggestions,
      })
    }

    return (
      <Autosuggest
        getSectionSuggestions={this.getSectionSuggestions}
        getSuggestionValue={getSuggestions}
        inputProps={inputProps}
        multiSection
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderSectionTitle={this.renderSectionTitle}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        suggestions={suggestionSections}
        {...rest}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    filters: state.autocompleteSongs.filters,
    sortBy: state.autocompleteSongs.sortBy,
    pagination: state.autocompleteSongs.pagination,
    isFetching: state.autocompleteSongs.isFetching,
    searchTerm: state.autocompleteSongs.searchTerm,
    suggestions: state.autocompleteSongs.suggestions,
    tags: state.tags.tags,
    isLoadingTags: state.tags.isLoading,
  }
}

function mapDispatchToProps(dispatch) {
  const { autocompleteSearch, clearSongs } = bindActionCreators(
    autocompleteSearchActions,
    dispatch
  )
  const { fetchTags } = bindActionCreators(tagActions, dispatch)
  return {
    searchSongs: autocompleteSearch,
    clearSongs,
    fetchTags,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AutocompleteInput)
