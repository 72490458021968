import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

export const VersionButtonIcon = ({ isVertical, icon, style, className }) => {
  const props = { className, icon, style }
  if (isVertical) {
    props.rotation = 90
  }

  return <FontAwesomeIcon {...props} />
}

VersionButtonIcon.displayName = 'VersionButtonIcon'

VersionButtonIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  isVertical: PropTypes.bool.isRequired,
  style: PropTypes.object,
}
