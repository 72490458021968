import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'

import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  CURRENCY_SYMBOL_MAP,
  SUBSCRIPTIONS_AVAILABLE,
} from '../../shared/utils'
import styles from './styles.sass'

export function SubscriptionSelection({
  subscriptionSelected,
  handleSubscriptionChange,
}) {
  const { currencyId } = useSelector(state => state.userProperties)
  const subscriptions = useSelector(state => state.subscriptions.list)

  useEffect(() => {
    if (subscriptions.length) {
      const monthlyProdKey = subscriptions.find(p =>
        p.subscriptionKey.startsWith(SUBSCRIPTIONS_AVAILABLE.MONTHLY)
      ).subscriptionKey
      !Object.values(SUBSCRIPTIONS_AVAILABLE).includes(
        subscriptionSelected.split('_')[0]
      ) && handleSubscriptionChange(monthlyProdKey)
    }
  }, [subscriptions, subscriptionSelected])

  const getSubscriptionsSection = useCallback(() => {
    if (!subscriptions.length) return

    return (
      subscriptions
        // Only enable the user to subscribe in to monthly and biannually plans
        // keep plan in Cypress e2e tests
        // .filter(
        //   p => window.Cypress && !p.subscriptionKey.startsWith('QUARTERLY')
        // )
        .map(subscription => {
          if (
            subscription.subscriptionKey.startsWith('QUARTERLY') &&
            !window.Cypress
          )
            return null
          return (
            <label
              className={styles.productOption}
              id={subscription.subscriptionKey}
              key={subscription.subscriptionKey}
            >
              <input
                checked={subscriptionSelected === subscription.subscriptionKey}
                className={styles.optionControl}
                name="product"
                onChange={e => handleSubscriptionChange(e.target.value)}
                type="radio"
                value={subscription.subscriptionKey}
              />
              <FormattedHTMLMessage
                defaultMessage="{currency}{price}"
                id="djcity.common.subscriptions.pricing"
                values={{
                  currency: CURRENCY_SYMBOL_MAP[currencyId],
                  price: subscription.price,
                  interval: subscription.interval > 1 && subscription.interval,
                }}
              />
              {subscription.interval > 1 ? (
                <FormattedHTMLMessage
                  defaultMessage="/ {interval} months"
                  id="djcity.common.subscriptions.duration.multiple"
                  values={{ interval: subscription.interval }}
                />
              ) : (
                <FormattedHTMLMessage
                  defaultMessage="/ month"
                  id="djcity.common.subscriptions.duration.single"
                />
              )}
              {subscription.saving > 0 && subscription.interval !== 12 ? (
                <span className={styles.pin}>
                  <FormattedHTMLMessage
                    defaultMessage="Save <span>{currency}{price}</span>"
                    id="djcity.common.subscriptions.save"
                    values={{
                      currency: CURRENCY_SYMBOL_MAP[currencyId],
                      price: subscription.saving,
                    }}
                  />
                </span>
              ) : null}
              {subscription.saving > 0 && subscription.interval === 12 ? (
                <span className={styles.pin}>
                  <FormattedHTMLMessage
                    defaultMessage="Save <span>{currency}{price}</span>"
                    id="djcity.common.subscriptions.saveyearly"
                    values={{
                      currency: CURRENCY_SYMBOL_MAP[currencyId],
                      price: subscription.saving,
                    }}
                  />
                </span>
              ) : null}
            </label>
          )
        })
    )
  }, [subscriptions, subscriptionSelected])

  return (
    <div className={styles.subscriptionsContainer}>
      {getSubscriptionsSection()}
    </div>
  )
}

SubscriptionSelection.propTypes = {
  handleSubscriptionChange: PropTypes.func.isRequired,
  subscriptionSelected: PropTypes.string.isRequired,
}

export default injectIntl(SubscriptionSelection)
