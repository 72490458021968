import {
  activeSubscriptionSelector,
  loggedInSelector,
} from 'spa/selectors/users'
import { Link } from 'react-router-dom'
import { openModal, SIGNUP_MODAL } from 'spa/action_creators/modals'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

const SubscribeButton = ({ children, className, onClick }) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(loggedInSelector)
  const isActive = useSelector(activeSubscriptionSelector)
  const openSignupModal = useCallback(() => dispatch(openModal(SIGNUP_MODAL)), [
    dispatch,
  ])

  if (isActive) {
    return null
  }

  if (isLoggedIn) {
    return (
      <Link
        className={classNames('button', styles.subscribeButton, className)}
        to="/payment-details"
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      className={classNames(styles.subscribeButton, className)}
      onClick={onClick || openSignupModal}
    >
      {children}
    </button>
  )
}

SubscribeButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default SubscribeButton
