import { useEffect } from 'react'
import PropTypes from 'prop-types'

const BatchWorker = ({ children, interval, jobs }) => {
  useEffect(() => {
    const timedJobs = setInterval(() => {
      jobs.forEach(job => {
        job()
      })
    }, interval)
    return () => clearInterval(timedJobs)
  }, jobs)

  return children
}

BatchWorker.displayName = 'BatchWorker'
BatchWorker.propTypes = {
  children: PropTypes.node,
  interval: PropTypes.number.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.func),
}

export default BatchWorker
