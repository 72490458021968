import { closeModal, RESET_PASSWORD_MODAL } from 'spa/action_creators/modals'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { Label } from 'spa/components/label'
import { SpinnerButton } from 'spa/components/button'
import { TextInput } from 'spa/components/text_input'
import { updatePassword } from 'spa/action_creators/current_user'
import { useDispatch, useSelector } from 'react-redux'
import { usePasswordValidation } from 'spa/hooks/use_password_validation'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import { userEmailSelector } from 'spa/selectors/users'
import ApplicationModal from 'spa/components/application_modal'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import styles from './styles'

const localizedMessages = defineMessages({
  emailAddress: {
    id: 'djcity.account.update_password.email',
    defaultMessage: 'Email Address',
  },
  newPassword: {
    id: 'djcity.account.update_password.new_password',
    defaultMessage: 'New Password',
  },
  oldPassword: {
    id: 'djcity.account.update_password.old_password',
    defaultMessage: 'Current Password',
  },
})

const defaultQueryParameters = {
  code: null,
  email: null,
}

function ResetPasswordModal({ intl }) {
  const dispatch = useDispatch()
  const [{ code, email: queryEmail }] = useQueryParameters(
    defaultQueryParameters
  )
  const isOpen = useSelector(
    state => state.modals[RESET_PASSWORD_MODAL].enabled
  )
  const isResettingPassword = useSelector(state => state.isResettingPassword)
  const loggedEmail = useSelector(userEmailSelector)
  const [email, setEmail] = useState(loggedEmail || queryEmail || '')
  const [newPassword, setNewPassword] = useState('')

  const isDisabled = !(email && newPassword && code)

  useEffect(() => {
    setNewPassword('')
  }, [isOpen, setNewPassword])

  const onSubmit = async e => {
    e.preventDefault()
    if (!isDisabled) {
      const success = await dispatch(
        updatePassword(
          {
            email,
            newPassword,
            code,
          },
          false
        )
      )
      if (success) {
        dispatch(closeModal())
      }
    }
  }

  const passwordValidator = usePasswordValidation(intl)

  return (
    <ApplicationModal
      modalKey={RESET_PASSWORD_MODAL}
      title={
        <FormattedMessage
          defaultMessage="Reset Password"
          id="djcity.account.reset_password.label"
        />
      }
    >
      <form className={styles.resetPasswordForm} onSubmit={onSubmit}>
        {!loggedEmail && (
          <Label
            className={styles.label}
            text={intl.formatMessage(localizedMessages.emailAddress)}
          >
            <TextInput
              className={styles.input}
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </Label>
        )}
        <Label
          className={styles.label}
          text={intl.formatMessage(localizedMessages.newPassword)}
        >
          <TextInput
            className={styles.input}
            onChange={e => setNewPassword(e.target.value)}
            type="password"
            validator={passwordValidator}
            value={newPassword}
          />
        </Label>
        <SpinnerButton
          disabled={isDisabled || isResettingPassword}
          loading={isResettingPassword}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Reset Password"
            id="djcity.account.reset_password.button"
          />
        </SpinnerButton>
      </form>
    </ApplicationModal>
  )
}

ResetPasswordModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(ResetPasswordModal)
