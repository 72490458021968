import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Styles from './styles'

const Form = ({ children, onSubmit, className }) => {
  return (
    <form className={classnames(Styles.form, className)} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default Form
