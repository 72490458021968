import { isMobileSelector } from '../../selectors/device'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const Sidebar = ({ children, large, className, id }) => {
  const isMobile = useSelector(isMobileSelector)

  return isMobile ? null : (
    <div
      className={classNames(
        styles.sidebar,
        className,
        large ? styles.sidebar_lg : ''
      )}
      id={id}
    >
      {children}
    </div>
  )
}

Sidebar.displayName = 'Sidebar'

Sidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  large: PropTypes.bool,
}
