import { Loading } from '../loading'
import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
export const ModuleLoader = ({ children }) => {
  return <Suspense fallback={<Loading loading />}>{children}</Suspense>
}

ModuleLoader.propTypes = {
  children: PropTypes.node,
}
