import {
  CLEAR_SONGS_LIST,
  RECEIVE_PLAYLISTS_SONGS,
  REQUEST_PLAYLISTS_SONGS,
} from '../action_constants'
import {
  defaultPagination,
  defaultPlaylistsFilters,
  defaultSortBy,
} from '../api/songs'

const defaultState = {
  isFetching: false,
  filters: defaultPlaylistsFilters,
  pagination: defaultPagination,
  sortBy: defaultSortBy,
  playlists: [],
}

function transformPlaylist(data) {
  let newPlaylist = []
  data.forEach(playlist => {
    playlist.records.data.forEach(song => {
      newPlaylist.push({ rid: song.rid, genre: playlist.genre })
    })
  })

  return newPlaylist
}

export default function playlistsSongs(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_PLAYLISTS_SONGS:
      return {
        ...state,
        isFetching: true,
        filters: action.filters,
        pagination: action.pagination,
        sortBy: action.sortBy,
      }
    case RECEIVE_PLAYLISTS_SONGS:
      return {
        ...state,
        isFetching: false,
        filters: action.filters,
        pagination: action.pagination,
        playlists: action.playlists.map(playlist => ({
          ...playlist,
          songIds: playlist.records.data.map(song => song.rid),
        })),
        playlistsLookup: transformPlaylist(action.playlists),
        sortBy: action.sortBy,
      }
    case CLEAR_SONGS_LIST:
      return defaultState
    default:
      return state
  }
}
