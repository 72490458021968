import union from 'lodash/union'

/**
 * All /songs listing (GET) endpoints return data in the same format, with similar,
 * but varying parameters for filtering. Use this function to create a new reducer
 * for a songs list resource.
 */
export function createSongsListReducer({
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant,
  receiveActionConstant,
  clearActionConstant,
  removeActionConstant,
}) {
  const defaultState = {
    isFetching: false,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songIds: [],
  }

  return function(state = defaultState, action) {
    switch (action.type) {
      case requestActionConstant:
        return {
          ...state,
          isFetching: true,
          filters: action.filters,
          pagination: action.pagination,
          sortBy: action.sortBy,
        }
      case receiveActionConstant:
        if (action.loadPlayerSongsInBackround) return state
        return {
          ...state,
          isFetching: false,
          filters: action.filters,
          pagination: action.pagination,
          songIds: union(
            state.songIds,
            action.songs.map(song => song.rid)
          ),
          sortBy: action.sortBy,
        }
      case clearActionConstant:
        return {
          ...state,
          isFetching: false,
          filters: action.filters,
          pagination: action.pagination,
          songIds: [],
          sortBy: action.sortBy,
        }
      case removeActionConstant:
        return {
          ...state,
          filters: action.filters,
          isFetching: true,
          pagination: action.pagination,
          sortBy: action.sortBy,
          songIds: state.songIds.filter(
            songs => parseInt(songs) !== parseInt(action.rid)
          ),
        }
      default:
        return state
    }
  }
}
