import {
  CANCELED_SUBSCRIPTION,
  CLEAR_CURRENT_USER,
  REACTIVATED_SUBSCRIPTION,
  RECEIVE_ADD_BATCH_TO_CRATE,
  RECEIVE_ADD_TO_CRATE,
  RECEIVE_CURRENT_USER,
  RECEIVE_CURRENT_USER_INVOICE,
  RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS,
  RECEIVE_DOWNLOAD_TRACK,
  RECEIVE_REMOVE_ALL_FROM_CRATE,
  RECEIVE_REMOVE_BATCH_FROM_CRATE,
  RECEIVE_REMOVE_FROM_CRATE,
  RECEIVE_SEND_CRATE_TO_DOWNLOADER,
  RECEIVE_USER_PROFILE_SUCCESS,
  REFRESHING_USER_START,
  REQUEST_CURRENT_USER_PREVIOUS_DOWNLOADS,
  REQUEST_USER_PROFILE,
  SET_DOWNLOAD_TRACK_STATUS,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_FIELDS,
} from '../action_constants'
import { defaultPagination } from '../api/songs'
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../shared/utils'
import {
  RECEIVE_CURRENT_USER_BILLING_HISTORY,
  REFRESHING_USER_STOP,
} from '../action_constants'
import cloneDeep from 'lodash/cloneDeep'

// Also defined in app.js for express logout route
const AUTH_COOKIE = 'ac'

const authData = getAuthToken()

const defaultState = {
  previousDownloadPagination: defaultPagination,
  previouslyDownloaded: [],
  customerId: authData.customerId,
  token: authData.token,
  user: authData.user,
  isFetching: false,
  isSaving: false,
  isRefreshing: false,
}

export function getAuthToken() {
  return loadFromLocalStorage(AUTH_COOKIE) || {}
}

function saveCurrentUser({ customerId, token, user }) {
  saveToLocalStorage(AUTH_COOKIE, { customerId, token, user })
}

function removeCurrentUser() {
  removeFromLocalStorage(AUTH_COOKIE)
  removeFromLocalStorage('chromiumDownloadPermission')
}

export function findDownloadedTrack(songs, rid, ttid) {
  return songs.findIndex(
    track =>
      parseInt(track.ttid) === parseInt(ttid, 10) &&
      parseInt(track.record.rid) === parseInt(rid)
  )
}

// TODO: use immer in this reducer (https://immerjs.github.io/immer/docs/example-reducer) and drop cloneDeep
// Might be tricky because of the actions that call saveCurrentUser
//
// ALSO TODO: consider separate previouslyDownloaded into a new reducer
export default function currentUser(state = defaultState, action) {
  let newState
  let trackIndex
  let user
  let stateChanged = false
  switch (action.type) {
    case REQUEST_CURRENT_USER_PREVIOUS_DOWNLOADS:
    case REQUEST_USER_PROFILE:
      return { ...state, isFetching: true }
    case RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS:
      return {
        ...state,
        isFetching: false,
        previouslyDownloaded: action.songs,
        previousDownloadPagination: action.pagination,
      }
    case UPDATE_CURRENT_USER:
      saveCurrentUser({ ...state, ...action.fields })
      return { ...state, ...action.fields }
    case UPDATE_CURRENT_USER_FIELDS:
      return { ...state, user: { ...state.user, ...action.fields } }
    case RECEIVE_CURRENT_USER:
      user = { ...state.user, ...action.user }
      saveCurrentUser({ ...state, user })
      return { ...state, user }
    case RECEIVE_CURRENT_USER_BILLING_HISTORY:
      return { ...state, invoices: action.invoices }
    case RECEIVE_CURRENT_USER_INVOICE:
      return { ...state, invoice: action.invoice }
    case RECEIVE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.profile },
        isFetching: false,
      }
    case REFRESHING_USER_START:
      return {
        ...state,
        isRefreshing: true,
      }
    case REFRESHING_USER_STOP:
      return {
        ...state,
        isRefreshing: false,
      }
    case CANCELED_SUBSCRIPTION:
      return {
        ...state,
        user: {
          ...state.user,
          cancellationIsScheduled: true,
          cancellationScheduledDate: state.user.nextCycle,
        },
      }
    case REACTIVATED_SUBSCRIPTION:
      return {
        ...state,
        user: {
          ...state.user,
          cancellationIsScheduled: false,
          cancellationScheduledDate: null,
        },
      }
    case CLEAR_CURRENT_USER:
      removeCurrentUser()
      return {
        previousDownloadPagination: defaultPagination,
        previouslyDownloaded: [],
        isFetching: false,
        isSaving: false,
      }
    case RECEIVE_ADD_TO_CRATE:
      trackIndex = findDownloadedTrack(
        state.previouslyDownloaded,
        action.item.track.record.rid,
        action.item.track.ttid
      )
      if (trackIndex >= 0) {
        newState = cloneDeep(state)
        newState.previouslyDownloaded[trackIndex].inCrate = true
        return newState
      }
      return state
    case RECEIVE_ADD_BATCH_TO_CRATE:
      for (let item of action.items) {
        trackIndex = findDownloadedTrack(
          state.previouslyDownloaded,
          item.track.record.rid,
          item.track.ttid
        )

        if (trackIndex >= 0) {
          if (!stateChanged) {
            newState = cloneDeep(state)
            stateChanged = true
          }
          newState.previouslyDownloaded[trackIndex].inCrate = true
        }
      }

      if (stateChanged) {
        return newState
      }

      return state

    case RECEIVE_REMOVE_FROM_CRATE:
      trackIndex = findDownloadedTrack(
        state.previouslyDownloaded,
        action.songId,
        action.trackId
      )
      if (trackIndex >= 0) {
        newState = cloneDeep(state)
        newState.previouslyDownloaded[trackIndex].inCrate = false
        return newState
      }
      return state
    case RECEIVE_REMOVE_BATCH_FROM_CRATE:
      for (let trackId of action.trackIds) {
        trackIndex = findDownloadedTrack(
          state.previouslyDownloaded,
          action.songId,
          trackId
        )

        if (trackIndex >= 0) {
          if (!stateChanged) {
            newState = cloneDeep(state)
            stateChanged = true
          }
          newState.previouslyDownloaded[trackIndex].inCrate = true
        }
      }

      if (stateChanged) {
        return newState
      }

      return state
    case RECEIVE_REMOVE_ALL_FROM_CRATE:
      newState = cloneDeep(state)
      newState.previouslyDownloaded.forEach(track => {
        track.inCrate = false
      })
      return newState
    case RECEIVE_SEND_CRATE_TO_DOWNLOADER:
      newState = cloneDeep(state)
      action.items.forEach(item => {
        trackIndex = findDownloadedTrack(
          newState.previouslyDownloaded,
          item.rid,
          item.ttid
        )
        if (trackIndex >= 0) {
          newState.previouslyDownloaded[trackIndex].inCrate = false
        }
      })
      return newState
    case SET_DOWNLOAD_TRACK_STATUS:
      trackIndex = findDownloadedTrack(
        state.previouslyDownloaded,
        action.songId,
        action.trackId
      )
      if (trackIndex >= 0) {
        newState = cloneDeep(state)
        newState.previouslyDownloaded[trackIndex].isDownloading =
          action.isDownloading
        return newState
      }
      return state
    case RECEIVE_DOWNLOAD_TRACK:
      trackIndex = findDownloadedTrack(
        state.previouslyDownloaded,
        action.songId,
        action.trackId
      )
      if (trackIndex >= 0) {
        newState = cloneDeep(state)
        newState.previouslyDownloaded[trackIndex].downloadCount += 1
        newState.previouslyDownloaded[trackIndex].isDownloading = false
        return newState
      }
      return state
    default:
      return state
  }
}
