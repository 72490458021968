import { CrateButton } from 'spa/components/track_buttons/crate_button'
import { DownloadTrackButton } from 'spa/components/track_buttons/download_track_button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Link } from '../link'
import { SendToDownloaderButton } from 'spa/components/track_buttons/send_to_downloader_button'
import classNames from 'classnames'
import React from 'react'

import styles from './styles'

export const TrackDropDownTableInfo = () => (
  <div className={styles.versionInfoContainer}>
    <div className={styles.row}>
      <div className={styles.icon}>
        <DownloadTrackButton className={styles.iconImage} />
        <div>
          <FormattedHTMLMessage
            defaultMessage="DOWNLOAD"
            id="djcity.records.version.description.download.title"
          />
        </div>
      </div>
      <div className={styles.description}>
        <FormattedHTMLMessage
          defaultMessage="Download the selection track version with your browser."
          id="djcity.records.version.description.download"
        />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.icon}>
        <CrateButton className={styles.iconImage} />
        <div>
          <FormattedHTMLMessage
            defaultMessage="ADD TO CRATE"
            id="djcity.records.queue.track.crate.title"
          />
        </div>
      </div>
      <div className={styles.description}>
        <FormattedHTMLMessage
          defaultMessage="Add the selected track version to the crate. This icon will turn red when a track is currently in the crate. Click the icon again to remove from the crate."
          id="djcity.records.version.description.crate"
        />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.icon}>
        <SendToDownloaderButton className={styles.iconImage} />
        <div>
          <FormattedHTMLMessage
            defaultMessage="DL TO DESKTOP"
            id="djcity.records.queue.track.desktop.title"
          />
        </div>
      </div>
      <div className={styles.description}>
        <FormattedMessage
          defaultMessage="Send track version directly to the DJcity Desktop App to be downloaded. Must have Desktop App installed to enable this feature. Find out more details {settings}"
          id="djcity.records.version.description.desktop"
          values={{
            settings: (
              <Link className={styles.red} to="/apps">
                <FormattedMessage
                  defaultMessage="here."
                  id="djcity.records.version.link.desktop"
                />
              </Link>
            ),
          }}
        />
      </div>
    </div>
    <div className={styles.row}>
      <div className={classNames(styles.icon, styles.red)}>
        <FontAwesomeIcon icon="check" />
        <div>
          <FormattedHTMLMessage
            defaultMessage="PREVIOUSLY DOWNLOADED"
            id="djcity.records.version.description.downloaded.title"
          />
        </div>
      </div>
      <div className={styles.description}>
        <FormattedHTMLMessage
          defaultMessage="Indicates that the track version has previously been downloaded."
          id="djcity.records.version.description.downloaded"
        />
      </div>
    </div>
  </div>
)

TrackDropDownTableInfo.displayName = 'TrackDropDownTableInfo'
