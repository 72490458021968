import {
  CategoryFilterPanel,
  FilterPopover,
  ReleaseDatePopover,
} from 'spa/components/filters'
import {
  clearSongList,
  fetchHotBoxSongs,
} from 'spa/action_creators/hot_box_songs'
import {
  defaultHotBoxFilters,
  defaultHotBoxPagination,
  filterParsers,
  filterStringifiers,
} from 'spa/api/songs'
import { FormattedMessage, injectIntl } from 'react-intl'
import { generateQueryParams } from 'spa/shared/utils'
import { Heading, TextStyle } from 'spa/components/typography'
import { hotBoxSongsSelector } from 'spa/selectors/songs'
import { Knotch } from 'spa/components/knotch'
import { Loading } from 'spa/components/loading'
import { localeSelector } from 'spa/selectors/users'
import { LOCALIZED_MESSAGES } from 'spa/components/table/messages'
import { loggedInSelector } from 'spa/selectors/users'
import { NoRecordsLabel } from 'spa/components/no_records_label'
import { SongTable } from 'spa/components/table'
import { SORT_OPTIONS } from 'spa/components/filters'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'

import { isMobileSelector } from '../../../selectors/device'
import styles from './styles'

const HotBox = ({ intl }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.hotBoxSongs.isFetching)
  const isLoggedIn = useSelector(loggedInSelector)
  const songs = useSelector(hotBoxSongsSelector)
  const locale = useSelector(localeSelector)
  const isMobile = useSelector(isMobileSelector)
  const [queryParams, setQueryParams] = useQueryParameters(
    {
      ...defaultHotBoxFilters,
      ...defaultHotBoxPagination,
      sortBy: SORT_OPTIONS.POPULARITY,
      type: '1W',
    },
    {
      parsers: { ...filterParsers, sortBy: s => parseInt(s, 10) },
      stringifiers: filterStringifiers,
    }
  )

  const handleReleaseDatePopoverChange = useCallback(
    type => setQueryParams({ ...queryParams, type }),
    [queryParams, setQueryParams]
  )

  useEffect(() => {
    const params = generateQueryParams({ locale, queryParams })
    dispatch(
      fetchHotBoxSongs({
        filters: params.filters,
        pagination: {
          page: queryParams.page,
          pageSize: queryParams.genres.length ? 15 : 30,
        },
        sortBy: queryParams.sortBy,
      })
    )

    return () => dispatch(clearSongList())
  }, [locale, queryParams, isLoggedIn, dispatch])

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="Record Pool"
            id="djcity.common.record_pool"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            <FormattedMessage
              defaultMessage="Most Popular"
              id="djcity.common.most_popular"
            />
          </Heading>
          {!isMobile && (
            <div>
              <ReleaseDatePopover
                defaultValue="1W"
                isMobile={isMobile}
                onChange={handleReleaseDatePopoverChange}
                value={queryParams.type}
              />
              <FilterPopover
                filters={queryParams}
                isMobile={isMobile}
                onChange={setQueryParams}
              />
            </div>
          )}
        </div>
        <FormattedMessage
          defaultMessage="The most downloaded tracks by DJcity members"
          id="djcity.records.most_popular.sub_header"
        />
        <Knotch className={styles.knotch} size="big" />
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <ReleaseDatePopover
            defaultValue="1W"
            isMobile={isMobile}
            onChange={handleReleaseDatePopoverChange}
            value={queryParams.type}
          />
          <FilterPopover
            filters={queryParams}
            isMobile={isMobile}
            onChange={setQueryParams}
          />
        </div>
      )}
      <CategoryFilterPanel filters={queryParams} onChange={setQueryParams} />
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <SongTable
            className={styles.table}
            firstColumnHeaderLabel={intl.formatMessage(
              LOCALIZED_MESSAGES.records
            )}
            showNumbers
            songs={songs}
            trackList={TRACKLIST.HOTBOX}
          />
          {songs.length === 0 ? <NoRecordsLabel /> : null}
        </>
      )}
    </div>
  )
}

HotBox.displayName = 'HotBox'
HotBox.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(HotBox)
