import {
  THIRD_PARTY_CODE_ERROR,
  THIRD_PARTY_CODE_LOADED,
} from '../action_constants'

export default function thirdParty(state = {}, action) {
  switch (action.type) {
    case THIRD_PARTY_CODE_LOADED:
      return { ...state, [action.service]: true }
    case THIRD_PARTY_CODE_ERROR:
      return { ...state, [action.service]: false }
    default:
      return state
  }
}
