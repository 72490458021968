import { useFieldValidation } from 'spa/hooks/use_field_validation'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const TextInput = ({
  className,
  inputRef,
  validator = null,
  value,
  type = 'text',
  ...rest
}) => {
  const [validationProps, errorMessage, showError] = useFieldValidation(
    value,
    validator
  )

  const cls = classNames(styles.textInput, className, {
    [styles.invalidInput]: showError,
  })

  return (
    <>
      <input
        className={cls}
        ref={inputRef}
        type={type}
        value={value}
        {...validationProps}
        {...rest}
      />
      {validator && (
        <div
          className={classNames(styles.errorMessage, {
            [styles.hidden]: !showError,
          })}
        >
          {errorMessage || 'error'}
        </div>
      )}
    </>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.any,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  validator: PropTypes.func,
  value: PropTypes.string,
}
