import { RECEIVE_SIGNUP, REQUEST_SIGNUP } from '../action_constants'

export default function isSigningUp(state = false, action) {
  switch (action.type) {
    case REQUEST_SIGNUP:
      return true

    case RECEIVE_SIGNUP:
      return false

    default:
      return state
  }
}
