import { connect } from 'react-redux'
import { loggedInSelector } from '../../selectors/users'
import { PreviewQueueButton } from 'spa/components/preview_queue_button'
import { TablePopover } from './table_popover'
import {
  TrackDropdownTable,
  TrackDropdownTableAllTracksRow,
  TrackDropdownTableRow,
} from 'spa/components/track_dropdown_table'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const SongVersionsPopover = ({
  songId,
  trackList,
  currentlyInQueue,
  onAddSongToQueue,
  onRemoveSongFromQueue,
  fixed = false,
  loggedIn,
}) => {
  const song = useSelector(state => state.songs.songsById[parseInt(songId, 10)])
  const onQueueButtonClick = e => {
    e.stopPropagation()
    currentlyInQueue
      ? onRemoveSongFromQueue(song)
      : onAddSongToQueue(song, trackList)
  }

  return (
    <TablePopover
      buttonIcon="ellipsis-h"
      contentClassName={styles.tablePopoverContent}
      fixed={fixed}
      id="songVersionsPopover"
      style={{
        padding: '2px',
        height: '32px',
        width: '32px',
        textAlign: 'center',
      }}
    >
      <TrackDropdownTable>
        {song.types.map(track => (
          <TrackDropdownTableRow
            change={song.changes && find(song.changes, { tid: track.tid })}
            key={track.tid}
            songId={songId}
            track={track}
            trackList={trackList}
          />
        ))}
        <TrackDropdownTableAllTracksRow
          songId={songId}
          trackList={trackList}
          tracks={song.types}
        />
      </TrackDropdownTable>
      {loggedIn && (
        <PreviewQueueButton
          currentlyInQueue={currentlyInQueue}
          onClick={onQueueButtonClick}
        />
      )}
    </TablePopover>
  )
}

SongVersionsPopover.propTypes = {
  currentlyInQueue: PropTypes.bool,
  fixed: PropTypes.bool,
  loggedIn: PropTypes.bool,
  onAddSongToQueue: PropTypes.func,
  onRemoveSongFromQueue: PropTypes.func,
  songId: PropTypes.number.isRequired,
  trackList: PropTypes.string,
}

const mapStateToProps = state => {
  const loggedIn = loggedInSelector(state)
  return {
    loggedIn,
  }
}

const ConnectedSongVersionPopover = connect(mapStateToProps)(
  SongVersionsPopover
)

export { ConnectedSongVersionPopover as SongVersionsPopover }
