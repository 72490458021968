import * as songsApi from '../api/songs'
import {
  CLEAR_ARTIST_SONGS,
  FILTER_ARTIST_SONGS,
  RECEIVE_ARTIST_SONGS,
  REQUEST_ARTIST_SONGS,
} from '../action_constants'

function requestArtistSongs({ filters, sortBy, artistName }) {
  return {
    type: REQUEST_ARTIST_SONGS,
    filters: { ...songsApi.defaultFilters, ...filters },
    sortBy,
    artistName,
  }
}

function receiveArtistSongs({ filters, sortBy, artistName, songs }) {
  return {
    type: RECEIVE_ARTIST_SONGS,
    filters,
    sortBy,
    artistName,
    songs,
  }
}

export function clearArtistSongs() {
  return {
    type: CLEAR_ARTIST_SONGS,
  }
}

export function artistSongs({ filters, sortBy, artistName }) {
  return async function(dispatch) {
    dispatch(requestArtistSongs({ filters, sortBy, artistName }))
    const response = await songsApi.search({
      searchTerm: artistName,
      isArtist: true,
      exact: true,
    })

    return dispatch(
      receiveArtistSongs({
        filters,
        sortBy,
        artistName,
        songs: response.data,
      })
    )
  }
}

export function filterArtistSongs({ filters, sortBy }) {
  return {
    type: FILTER_ARTIST_SONGS,
    filters: { ...songsApi.defaultFilters, ...filters },
    sortBy,
  }
}
