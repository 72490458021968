import { Layout } from '../layout'
import { ModuleLoader } from '../module_loader/module_loader'
import AccountInvoiceDisplay from './invoice'
import React from 'react'
const Account = React.lazy(() => import('./account'))

const display = () => {
  return (
    <Layout tabTitle="account">
      <ModuleLoader>
        <Account />
      </ModuleLoader>
    </Layout>
  )
}

export { display as AccountDisplay }
export { AccountInvoiceDisplay }
export { Account }

export default Account
