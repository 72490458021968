import { defineMessages } from 'react-intl'

const modal = defineMessages({
  title: {
    id: 'djcity.common.modals.shortcuts.modalTitle',
    defaultMessage: 'Player guide',
  },
  sectionOneTitle: {
    id: 'djcity.common.modals.shortcuts.sectionOneTitle',
    defaultMessage: 'Keyboard Shortcuts',
  },
  sectionTwoTitle: {
    id: 'djcity.common.modals.shortcuts.sectionTwoTitle',
    defaultMessage: 'Features / Perks',
  },
})

const titles = defineMessages({
  one: {
    id: 'djcity.common.modals.shortcuts.titleOne',
    defaultMessage: 'PLAY / PAUSE',
  },
  two: {
    id: 'djcity.common.modals.shortcuts.titleTwo',
    defaultMessage: 'SEEK FORWARD',
  },
  three: {
    id: 'djcity.common.modals.shortcuts.titleThree',
    defaultMessage: 'SEEK BACKWARD',
  },
  four: {
    id: 'djcity.common.modals.shortcuts.titleFour',
    defaultMessage: 'NEXT TRACK',
  },
  five: {
    id: 'djcity.common.modals.shortcuts.titleFive',
    defaultMessage: 'PREVIOUS TRACK',
  },
  six: {
    id: 'djcity.common.modals.shortcuts.titleSix',
    defaultMessage: 'VOLUME UP',
  },
  seven: {
    id: 'djcity.common.modals.shortcuts.titleSeven',
    defaultMessage: 'VOLUME DOWN',
  },
})

const titlesTwo = defineMessages({
  autoplay: {
    id: 'djcity.common.modals.perks.autoplayTitle',
    defaultMessage: 'Autoplay',
  },
})

const descriptions = defineMessages({
  one: {
    id: 'djcity.common.modals.shortcuts.descriptionOne',
    defaultMessage: '<span>SPACEBAR</span>',
  },
  two: {
    id: 'djcity.common.modals.shortcuts.descriptionTwo',
    defaultMessage: '<span>→</span>',
  },
  three: {
    id: 'djcity.common.modals.shortcuts.descriptionThree',
    defaultMessage: '<span>←</span>',
  },
  four: {
    id: 'djcity.common.modals.shortcuts.descriptionFour',
    defaultMessage: '<span>SHIFT</span> + <span>→</span>',
  },
  five: {
    id: 'djcity.common.modals.shortcuts.descriptionFive',
    defaultMessage: '<span>SHIFT</span> + <span>←</span>',
  },
  six: {
    id: 'djcity.common.modals.shortcuts.descriptionSix',
    defaultMessage: '<span>SHIFT</span> + <span>↑</span>',
  },
  seven: {
    id: 'djcity.common.modals.shortcuts.descriptionSeven',
    defaultMessage: '<span>SHIFT</span> + <span>↓</span>',
  },
})

const descriptionsTwo = defineMessages({
  autoplay: {
    id: 'djcity.common.modals.perks.autoplayDescription',
    defaultMessage:
      'Automatically queue up songs in the preview player from the current track list',
  },
})

export const SHORTCUTS = {
  modal,
  titles,
  titlesTwo,
  descriptions,
  descriptionsTwo,
}
