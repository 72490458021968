import * as geoApi from '../api/geo'
import {
  ERROR_RECEIVING_USER_LOCATION_PROPERTIES,
  RECEIVE_USER_LOCATION_PROPERTIES,
  REQUEST_USER_LOCATION_PROPERTIES,
} from '../action_constants'

function requestCurrentUserLocation() {
  return {
    type: REQUEST_USER_LOCATION_PROPERTIES,
  }
}

function receiveCurrentUserLocation(data) {
  return {
    type: RECEIVE_USER_LOCATION_PROPERTIES,
    payload: data,
  }
}

function errorReceivingCurrentUserLocation(error) {
  return {
    type: ERROR_RECEIVING_USER_LOCATION_PROPERTIES,
    error,
  }
}

export function getUserLocationProperties(opts) {
  return async function(dispatch) {
    dispatch(requestCurrentUserLocation())
    return geoApi
      .ipLookup(opts)
      .then(res => {
        dispatch(receiveCurrentUserLocation(res.data))
      })
      .catch(err => dispatch(errorReceivingCurrentUserLocation(err)))
  }
}
