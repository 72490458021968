import { defineMessages, injectIntl } from 'react-intl'
import NewReleasesTable from 'spa/components/new_releases_table'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const localizeMessages = defineMessages({
  newReleasesSubHeader: {
    id: 'djcity.records.new_releases.sub_header',
    defaultMessage: 'The newest tracks updated in real time',
  },
})

const NewReleases = ({ intl }) => (
  <div className={styles.base}>
    <NewReleasesTable
      subHeading={intl.formatMessage(localizeMessages.newReleasesSubHeader)}
    />
  </div>
)

NewReleases.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(NewReleases)
