import { Layout } from 'spa/components/layout'
import { ModuleLoader } from '../module_loader/module_loader'
import React from 'react'
const SongDetails = React.lazy(() => import('./song_details'))

const display = () => {
  return (
    <Layout>
      <ModuleLoader>
        <SongDetails />
      </ModuleLoader>
    </Layout>
  )
}

export { display as SongDetailsDisplay }

export { SongDetails }

export default SongDetails
