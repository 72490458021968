import { defineMessages, injectIntl } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.sass'

const localizedMessages = defineMessages({
  sendAllTracksToDownloader: {
    id: 'djcity.common.icons.sent_all_tracks.downloader.alt',
    defaultMessage: 'Send All Tracks To Downloader',
  },
  sendAllTracksIcon: {
    id: 'djcity.common.icons.add.send_all_tracks.src',
    defaultMessage: '/assets/cdn/send-all-tracks-icon.svg',
  },
})

const SendAllTracksToDownloaderButton = ({
  className,
  iconClassName,
  intl,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        'cleanButton',
        styles.sendAllTracksToDownloaderButton,
        className
      )}
      onClick={onClick}
    >
      <div
        aria-labelledby={intl.formatMessage(
          localizedMessages.sendAllTracksToDownloader
        )}
        className={iconClassName}
        role="img"
      >
        <div>
          <svg
            height="18"
            viewBox="0 0 22 18"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect height="11.1962" width="14.6411" x="0.859375" y="0.861328" />
            <path
              className="fillPath"
              d="M1.63636 0C0.77512 0 0 0.688995 0 1.63636V11.1962C0 12.0574 0.77512 12.8325 1.63636 12.8325H14.7273C15.5885 12.8325 16.3636 12.1435 16.3636 11.1962V1.63636C16.3636 0.77512 15.5885 0 14.7273 0H1.63636ZM1.63636 1.63636H7.4067V3.27273H4.90909L7.4067 5.68421L8.18182 6.45933L8.95694 5.68421L11.4545 3.27273H8.95694V1.63636H14.7273V11.1962H12.2297V9.55981H4.13397V11.1962H1.63636V1.63636Z"
            />
            <rect height="11.1962" width="14.6411" x="3.44922" y="3.4458" />
            <path
              className="fillPath"
              d="M4.22621 2.58447C3.36496 2.58447 2.58984 3.27347 2.58984 4.22084V13.7806C2.58984 14.6419 3.36496 15.417 4.22621 15.417H17.3171C18.1784 15.417 18.9535 14.728 18.9535 13.7806V4.22084C18.9535 3.35959 18.1784 2.58447 17.3171 2.58447H4.22621ZM4.22621 4.22084H9.99654V5.8572H7.49893L9.99654 8.26868L10.7717 9.0438L11.5468 8.26868L14.0444 5.8572H11.5468V4.22084H17.3171V13.7806H14.8195V12.1443H6.72382V13.7806H4.22621V4.22084Z"
            />
            <rect height="11.1962" width="14.6411" x="6.02734" y="6.02881" />
            <path
              className="fillPath"
              d="M6.80433 5.16748C5.94309 5.16748 5.16797 5.85648 5.16797 6.80384V16.3637C5.16797 17.2249 5.94309 18 6.80433 18H19.8952C20.7565 18 21.5316 17.311 21.5316 16.3637V6.80384C21.5316 5.9426 20.7565 5.16748 19.8952 5.16748H6.80433ZM6.80433 6.80384H12.5747V8.44021H10.0771L12.5747 10.8517L13.3498 11.6268L14.1249 10.8517L16.6225 8.44021H14.1249V6.80384H19.8952V16.3637H17.3976V14.7273H9.30194V16.3637H6.80433V6.80384Z"
            />
          </svg>
        </div>
      </div>
    </button>
  )
}

SendAllTracksToDownloaderButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

const ConnectedSendAllTracksToDownloaderButton = injectIntl(
  SendAllTracksToDownloaderButton
)

export { ConnectedSendAllTracksToDownloaderButton as SendAllTracksToDownloaderButton }
