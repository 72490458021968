import { createSelector } from 'reselect'
import get from 'lodash/get'

export const userIdSelector = createSelector(
  state => get(state, 'currentUser.user.userId'),
  state => get(state, 'currentUser.user.customerId'),
  (userId, customerId) => userId || customerId
)

export const onboardingSelector = state =>
  !get(state, 'currentUser.user.onboardingSkipped')

export const paymentConfigurationSelector = createSelector(
  state => get(state, 'userProperties.paymentConfiguration'),
  paymentConfiguration => paymentConfiguration
)

export const userBillingSelector = createSelector(
  state => get(state, 'currentUser.user.billings'),
  billings => billings || []
)

export const loggedInSelector = state => !!userIdSelector(state)

export const isRefreshingSelector = state => {
  return get(state, 'currentUser.isRefreshing', false)
}
export const userEmailSelector = createSelector(
  state => state.currentUser.username,
  state => get(state, 'currentUser.user.email', null),
  (username, email) => username || email
)

export const activeSubscriptionSelector = createSelector(
  state => get(state, 'currentUser.user.activeSubscription'),
  loggedInSelector,
  (activeSubscription, loggedIn) => !!activeSubscription && loggedIn
)

export const desktopClientDownloadedSelector = createSelector(
  state => get(state, 'currentUser.user.desktopClientDownloaded'),
  loggedInSelector,
  (desktopClientDownloaded, loggedIn) => {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.MOCK_DESKTOP_CLIENT_DOWNLOADED
    ) {
      return loggedIn
    }
    return !!desktopClientDownloaded && loggedIn
  }
)

export const billingAddressSelector = createSelector(
  state => get(state, 'currentUser.user.billingAddress') || {},
  ({ addressLine1, addressLine2, city, country, state, suite, zip }) => ({
    addressLine1,
    addressLine2,
    city,
    country,
    state,
    suite,
    zip,
  })
)

export const localeSelector = state => get(state, 'locale.locale', null)
