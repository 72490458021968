import { DownloadPropType } from '../../api/songs'
import { DownloadTrackButton } from 'spa/components/track_buttons/download_track_button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TRACKLIST } from '../../shared/constants'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

const CrateOptions = ({ deleteItem, onRemoveFromCrate, track }) => {
  const handleClickRemoveFromCrate = useCallback(() => {
    onRemoveFromCrate({
      itemId: deleteItem,
      songId: track.record.rid,
      trackId: track.ttid,
      downloadCount: track.downloadCount,
    })
  }, [onRemoveFromCrate, deleteItem, track])

  return (
    <div className={styles.crateButtonContainer}>
      <DownloadTrackButton
        className={classNames(styles.action, styles.downloadButton)}
        songId={track.record.rid}
        trackId={track.ttid}
        trackList={TRACKLIST.CRATE}
      />
      <button
        className={classNames('cleanButton', styles.action)}
        onClick={handleClickRemoveFromCrate}
      >
        <FontAwesomeIcon icon="times" />
      </button>
    </div>
  )
}

CrateOptions.propTypes = {
  deleteItem: PropTypes.string,
  onRemoveFromCrate: PropTypes.func,
  track: DownloadPropType,
}

export default CrateOptions
