import * as maintenanceApi from '../api/maintenance'
import { createSlice } from '@reduxjs/toolkit'

const maintenance = createSlice({
  name: 'maintenance',
  initialState: {
    isFetching: false,
    maintenance: {},
    error: null,
  },
  reducers: {
    requestMaintenance: state => {
      return {
        ...state,
        isFetching: true,
      }
    },
    receiveMaintenance: (state, action) => {
      return {
        ...state,
        isFetching: false,
        maintenance: action.payload.maintenance,
      }
    },
    errorReceivingMaintenance: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      }
    },
  },
})

const maintenanceReducer = maintenance.reducer

export { maintenanceReducer as maintenance }

export const {
  requestMaintenance,
  receiveMaintenance,
  errorReceivingMaintenance,
} = maintenance.actions

/**
 * Request selectable maintenance
 * @returns {function(...[*]=)}
 */
export const fetchAvailableMaintenance = () => async dispatch => {
  try {
    dispatch(requestMaintenance())
    const maintenance = await maintenanceApi.getMaintenanceMode()
    return dispatch(receiveMaintenance({ maintenance }))
  } catch (error) {
    return dispatch(errorReceivingMaintenance({ error }))
  }
}
