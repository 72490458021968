import {
  RECEIVE_DOWNLOAD_QUEUE,
  RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER,
  RECEIVE_SEND_TRACK_TO_DOWNLOADER,
} from '../action_constants'

export default function queue(state = { queue: {} }, action) {
  switch (action.type) {
    case RECEIVE_DOWNLOAD_QUEUE:
      return {
        ...state,
        queue: action.queue,
      }
    case RECEIVE_SEND_TRACK_TO_DOWNLOADER:
      return {
        queue: {
          ...state.queue,
          [`${action.rid}:${action.ttid}`]: action.result,
        },
      }
    case RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER:
      return {
        queue: {
          ...state.queue,
          ...action.items.reduce(
            (merged, item) => ({
              ...merged,
              [`${action.rid}:${item.ttid}`]: item,
            }),
            {}
          ),
        },
      }
    default:
      return state
  }
}
