import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import {
  fetchCurrentUser,
  saveCurrentUser,
} from 'spa/action_creators/current_user'
import { Label } from 'spa/components/label'
import { openModal, UPDATE_PASSWORD_MODAL } from 'spa/action_creators/modals'
import { TextInput } from 'spa/components/text_input'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles'

export const infoLocalizedMessages = defineMessages({
  email: {
    id: 'djcity.account.my_info.email',
    defaultMessage: 'Email Address',
  },
  firstName: {
    id: 'djcity.account.my_info.first',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'djcity.account.my_info.last',
    defaultMessage: 'Last Name',
  },
  password: {
    id: 'djcity.account.my_info.password',
    defaultMessage: 'Password',
  },
  additionalInfo: {
    id: 'djcity.account.my_info.additionalInfo',
    defaultMessage: 'Additional Info',
  },
})

const MyInfo = ({ intl }) => {
  const dispatch = useDispatch()
  const _email = useSelector(state => state.currentUser.user.email)
  const _firstName = useSelector(state => state.currentUser.user.firstName)
  const _lastName = useSelector(state => state.currentUser.user.lastName)
  const _additionalInfo = useSelector(
    state => state.currentUser.user.additionalInfo
  )

  const [email, setEmail] = useState(_email)
  const [firstName, setFirstName] = useState(_firstName)
  const [lastName, setLastName] = useState(_lastName)
  const [additionalInfo, setAdditionalInfo] = useState(_additionalInfo)

  useEffect(() => {
    dispatch(fetchCurrentUser())
  }, [dispatch])

  const onEmailChange = useCallback(e => setEmail(e.currentTarget.value), [
    setEmail,
  ])

  const onFirstNameChange = useCallback(
    e => setFirstName(e.currentTarget.value),
    [setFirstName]
  )

  const onLastNameChange = useCallback(
    e => setLastName(e.currentTarget.value),
    [setLastName]
  )

  const onAdditionalInfoChange = useCallback(
    e => setAdditionalInfo(e.currentTarget.value),
    [setAdditionalInfo]
  )

  const saveChanges = useCallback(() => {
    dispatch(
      saveCurrentUser({
        firstName,
        lastName,
        email,
        additionalInfo,
      })
    )
  }, [dispatch, firstName, lastName, email, additionalInfo])

  const openUpdatePasswordModal = useCallback(() => {
    dispatch(openModal(UPDATE_PASSWORD_MODAL))
  }, [dispatch])

  return (
    <div className={styles.myInfo}>
      <div className={styles.infoRow}>
        <div className={styles.infoBlockLarge}>
          <Label
            className={styles.field}
            text={intl.formatMessage(infoLocalizedMessages.email)}
          >
            <TextInput
              className={styles.input}
              onChange={onEmailChange}
              value={email}
            />
          </Label>
        </div>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.infoBlock}>
          <Label text={intl.formatMessage(infoLocalizedMessages.firstName)}>
            <TextInput
              className={styles.input}
              onChange={onFirstNameChange}
              value={firstName}
            />
          </Label>
        </div>
        <div className={styles.infoBlock}>
          <Label text={intl.formatMessage(infoLocalizedMessages.lastName)}>
            <TextInput
              className={styles.input}
              onChange={onLastNameChange}
              value={lastName}
            />
          </Label>
        </div>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.infoBlockLarge}>
          <Label
            className={styles.field}
            text={intl.formatMessage(infoLocalizedMessages.additionalInfo)}
          >
            <TextInput
              className={styles.input}
              maxLength={255}
              onChange={onAdditionalInfoChange}
              value={additionalInfo}
            />
          </Label>
        </div>
      </div>
      <div className={classNames(styles.infoRow, styles.start)}>
        <div className={classNames(styles.infoBlock, styles.condensed)}>
          <button onClick={saveChanges}>
            <FormattedMessage
              defaultMessage="Save"
              id="djcity.account.my_info.save"
            />
          </button>
        </div>
        <div className={classNames(styles.infoBlock, styles.condensed)}>
          <button className="secondary" onClick={openUpdatePasswordModal}>
            <FormattedMessage
              defaultMessage="Change Password"
              id="djcity.account.password.change_button"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

MyInfo.displayName = 'MyInfo'

MyInfo.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(MyInfo)
