import * as currentUserApi from 'spa/api/current_user'
import { loggedInSelector } from '../selectors/users'
import { SET_DARK_MODE } from 'spa/action_constants'

export function setDarkMode(value) {
  return {
    type: SET_DARK_MODE,
    value: value,
  }
}

export function registerDarkMode(darkMode) {
  return async function(dispatch, getState) {
    const state = getState()
    const isLoggedIn = loggedInSelector(state)

    dispatch(setDarkMode(darkMode))

    if (isLoggedIn) {
      await currentUserApi.updateUser({ darkMode })
    }
  }
}
