import * as queueApi from 'spa/api/queue'
import { error, removeAll, success } from 'react-notification-system-redux'

import {
  activeSubscriptionSelector,
  desktopClientDownloadedSelector,
  loggedInSelector,
} from '../selectors/users'
import {
  AUTO_DISMISS_SECONDS,
  LONG_AUTO_DISMISS_SECONDS,
  MAX_DOWNLOAD_COUNT,
  NOTIFICATION_MESSAGES,
} from '../shared/utils'
import {
  ERROR_RECEIVING_DOWNLOAD_QUEUE,
  MARK_TRACKS_AS_DOWNLOADED,
  RECEIVE_DOWNLOAD_QUEUE,
  RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER,
  RECEIVE_SEND_TRACK_TO_DOWNLOADER,
  REQUEST_DOWNLOAD_QUEUE,
  REQUEST_SEND_ALL_TRACKS_TO_DOWNLOADER,
  REQUEST_SEND_TRACK_TO_DOWNLOADER,
} from '../action_constants'
import { intl } from '../shared/IntlGlobalProvider'
import { openModal, SIGNUP_MODAL } from '../action_creators/modals'
import { push } from 'connected-react-router'
import difference from 'lodash/difference'
import keyBy from 'lodash/keyBy'

const notification = {
  title: '',
  message: '',
  position: 'tr',
  autoDismiss: AUTO_DISMISS_SECONDS,
}

function requestSendTrackToDownloader() {
  return {
    type: REQUEST_SEND_TRACK_TO_DOWNLOADER,
  }
}

function requestSendAllTracksToDownloader() {
  return {
    type: REQUEST_SEND_ALL_TRACKS_TO_DOWNLOADER,
  }
}

function receiveSendAllTracksToDownloader(rid, ttids, trackList, items) {
  return {
    type: RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER,
    rid,
    ttids,
    trackList,
    items,
  }
}

function receiveSendTrackToDownloader(rid, ttid, trackList, result) {
  return {
    type: RECEIVE_SEND_TRACK_TO_DOWNLOADER,
    rid,
    ttid,
    trackList,
    result,
  }
}

function requestDownloadQueue() {
  return {
    type: REQUEST_DOWNLOAD_QUEUE,
  }
}

function receiveDownloadQueue(queue) {
  return {
    type: RECEIVE_DOWNLOAD_QUEUE,
    queue,
  }
}

function markTracksAsDownloaded(records) {
  return {
    type: MARK_TRACKS_AS_DOWNLOADED,
    records,
  }
}

function errorReceivingDownloadQueue(error) {
  return {
    type: ERROR_RECEIVING_DOWNLOAD_QUEUE,
    error,
  }
}

export function sendTrackToDownloader(params) {
  return (dispatch, getState) => {
    dispatch(requestSendTrackToDownloader())
    const loggedIn = loggedInSelector(getState())
    if (!loggedIn) {
      dispatch(
        error({
          ...notification,
          title: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberTitle),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.subscribeLabel),
            callback: () => {
              dispatch(openModal(SIGNUP_MODAL))
            },
          },
        })
      )
      return
    }

    const canSendToDownloader = desktopClientDownloadedSelector(getState())
    if (!canSendToDownloader) {
      dispatch(
        error({
          ...notification,
          title: intl.formatMessage(NOTIFICATION_MESSAGES.appNotInstalledTitle),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.appNotInstalledMessage
          ),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.installLabel),
            callback: () => {
              dispatch(removeAll())
              dispatch(push('/apps'))
            },
          },
        })
      )
      return
    }

    return queueApi
      .sendTrackToDownloader({ rid: params.songId, ttid: params.trackId })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.data.error)
        }
        return res.data
      })
      .then(result =>
        dispatch(
          receiveSendTrackToDownloader(
            params.songId,
            params.trackId,
            params.trackList,
            result.item
          )
        )
      )
      .then(() =>
        dispatch(
          success({
            ...notification,
            title: intl.formatMessage(NOTIFICATION_MESSAGES.yourCrate),
            message: intl.formatMessage(
              NOTIFICATION_MESSAGES.trackSentDownloader
            ),
          })
        )
      )
      .catch(err => {
        return dispatch(
          error({
            title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
            message: err.userDescription,
            position: 'tr',
            autoDismiss: AUTO_DISMISS_SECONDS,
          })
        )
      })
  }
}

export function sendAllTracksToDownloader(params) {
  return async (dispatch, getState) => {
    const { songId, trackList } = params
    const { songsById } = getState().songs
    const user = getState().currentUser.user
    const queue = getState().queue.queue
    const isActiveSubscription = activeSubscriptionSelector(getState())
    dispatch(requestSendAllTracksToDownloader())
    if (!isActiveSubscription) {
      dispatch(
        error({
          ...notification,
          title: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberTitle),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.subscribeLabel),
            callback: () => {
              if (!user) {
                dispatch(openModal(SIGNUP_MODAL))
              } else {
                dispatch(push('/payment-details'))
              }
            },
          },
        })
      )
      return null
    }

    const canSendToDownloader = desktopClientDownloadedSelector(getState())
    if (!canSendToDownloader) {
      dispatch(
        error({
          ...notification,
          title: intl.formatMessage(NOTIFICATION_MESSAGES.appNotInstalledTitle),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.appNotInstalledMessage
          ),
          action: {
            label: intl.formatMessage(NOTIFICATION_MESSAGES.installLabel),
            callback: () => {
              dispatch(removeAll())
              dispatch(push('/apps'))
            },
          },
        })
      )
      return null
    }

    const tracks = songsById[songId].types
    const tracksToQueue = tracks.filter(
      ({ ttid }) => !Object.keys(queue).includes(`${songId}:${ttid}`)
    )
    if (!tracksToQueue.length) {
      dispatch(
        error({
          title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.allTracksAlreadyInDownloader
          ),
          autoDismiss: LONG_AUTO_DISMISS_SECONDS,
          position: 'tr',
        })
      )
      return null
    }

    if (tracksToQueue.some(v => v.downloadCount >= MAX_DOWNLOAD_COUNT)) {
      dispatch(
        error({
          title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.batchError),
          autoDismiss: LONG_AUTO_DISMISS_SECONDS,
          position: 'tr',
        })
      )
      return null
    }

    const trackIds = tracksToQueue.map(({ ttid, tid }) => [
      songId,
      parseInt(ttid || tid),
    ])
    return queueApi
      .sendAllTracksToDownloader({ tracks: trackIds })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.data.error)
        }
        return res.data
      })
      .then(result => {
        if (result.items.length > 0) {
          dispatch(
            receiveSendAllTracksToDownloader(
              songId,
              trackIds,
              trackList,
              result.items
            )
          )
          return true
        }
      })
      .then(tracksAdded =>
        tracksAdded
          ? dispatch(
              success({
                ...notification,
                title: intl.formatMessage(NOTIFICATION_MESSAGES.yourCrate),
                message: intl.formatMessage(
                  NOTIFICATION_MESSAGES.allTracksSentDownloader
                ),
              })
            )
          : dispatch(
              error({
                ...notification,
                title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
                message: intl.formatMessage(
                  NOTIFICATION_MESSAGES.allTracksAlreadyInDownloader
                ),
              })
            )
      )
      .catch(err => {
        return dispatch(
          error({
            title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
            message: err.userDescription,
            position: 'tr',
            autoDismiss: AUTO_DISMISS_SECONDS,
          })
        )
      })
  }
}

/**
 * Receives user's download queue
 * @returns {function(...[*]=)}
 */
export function getDownloadQueue() {
  return async function(dispatch, getState) {
    const loggedIn = loggedInSelector(getState())
    const currentQueue = getState().queue.queue
    // If no active user, don't worry about getting download queue
    if (!loggedIn) {
      return
    }
    dispatch(requestDownloadQueue())
    try {
      const queue = await queueApi.getDownloadQueue()
      const items = keyBy(queue.items, item => {
        return `${item.track.record.rid}:${item.track.ttid}`
      })
      const downloadedRecords = difference(
        Object.keys(currentQueue),
        Object.keys(items)
      )
      if (downloadedRecords.length > 0) {
        const downloads = downloadedRecords.map(item => {
          const ids = item.split(':')
          return {
            songId: ids[0],
            ttid: ids[1],
          }
        })

        dispatch(markTracksAsDownloaded(downloads))
      }
      dispatch(receiveDownloadQueue(items))
    } catch (e) {
      dispatch(errorReceivingDownloadQueue(error))
    }
  }
}
