import {
  activeSubscriptionSelector,
  desktopClientDownloadedSelector,
} from 'spa/selectors/users'
import { defineMessages, injectIntl } from 'react-intl'
import { MAX_DOWNLOAD_COUNT } from 'spa/shared/utils'
import { sendTrackToDownloader } from 'spa/action_creators/download_queue'
import { trackInDownloadQueue, trackSelector } from 'spa/selectors/songs'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles.sass'

const localizedMessages = defineMessages({
  sendDownloader: {
    id: 'djcity.common.icons.sent.downloader.alt',
    defaultMessage: 'Send To Downloader',
  },
})

const SendToDownloaderButton = ({
  className,
  songId: songIdString,
  trackId: trackIdString,
  trackList,
  intl,
}) => {
  const songId = parseInt(songIdString, 10)
  const trackId = parseInt(trackIdString, 10)

  const dispatch = useDispatch()
  const track = useSelector(state => trackSelector(state, { songId, trackId }))
  const downloadCount = get(track, 'downloadCount', 0)
  const activeSubscription = useSelector(activeSubscriptionSelector)
  const desktopClientDownloaded = useSelector(desktopClientDownloadedSelector)
  const enabled =
    (!songId && !trackId) ||
    (activeSubscription &&
      desktopClientDownloaded &&
      downloadCount < MAX_DOWNLOAD_COUNT)

  const trackInQueue = useSelector(state =>
    trackInDownloadQueue(state, { songId, trackId })
  )

  const onClick = useCallback(() => {
    if (songId && trackId) {
      dispatch(sendTrackToDownloader({ songId, trackId, trackList }))
    }
  }, [songId, trackId, trackList])

  let iconClass = null
  if (!enabled) {
    iconClass = styles.disabled
  } else if (trackInQueue) {
    iconClass = styles.active
  }

  return (
    <button
      className={classNames(
        'cleanButton',
        styles.sendToDownloaderButton,
        className
      )}
      onClick={onClick}
    >
      <svg
        alt={intl.formatMessage(localizedMessages.sendDownloader)}
        className={iconClass}
        viewBox="0 0 19 16"
      >
        <path d="M1.9 0.900024C0.86159 0.900024 0 1.74438 0 2.76202V13.934C0 14.9517 0.86159 15.796 1.9 15.796H17.1C18.1384 15.796 19 14.9517 19 13.934V2.76202C19 1.74438 18.1384 0.900024 17.1 0.900024H1.9ZM1.9 2.76202H8.55V4.62402H5.7L8.55 7.41702L9.5 8.34802L10.45 7.41702L13.3 4.62402H10.45V2.76202H17.1V13.934H14.25V12.072H4.75V13.934H1.9V2.76202Z" />
      </svg>
    </button>
  )
}

SendToDownloaderButton.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  songId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackList: PropTypes.string,
}

const ConnectedSendToDownloaderButton = injectIntl(SendToDownloaderButton)

export { ConnectedSendToDownloaderButton as SendToDownloaderButton }
