import { TextStyle } from './text_style'
import PropTypes from 'prop-types'
import React from 'react'

const SPLIT_REGEX = /[\n\r]+/g

// A component that splits a multiline string and build a TextStyle
// element for each line.
//
// The split is made on newline and carriage return characters.
//
// Except for `children`, any props passed to TextStyleMultiline
// is replicated to all generated TextStyles.
export const TextStyleMultiline = ({ children, ...rest }) =>
  children.split(SPLIT_REGEX).map((line, i) => (
    <TextStyle key={i} {...rest}>
      {line}
    </TextStyle>
  ))

TextStyleMultiline.propTypes = {
  children: PropTypes.string,
}

TextStyleMultiline.defaultProps = {
  children: '',
}
