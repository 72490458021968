import { Layout } from '../layout'
import { ModuleLoader } from '../module_loader/module_loader'
import React from 'react'
const Marketing = React.lazy(() => import('../pages/marketing/marketing'))
const ResetPasswordView = React.lazy(() => import('./reset_password_view'))

const display = () => {
  return (
    <Layout fullLayout tabTitle="reset_password">
      <ModuleLoader>
        <Marketing />
        <ResetPasswordView />
      </ModuleLoader>
    </Layout>
  )
}

export { display as ResetPasswordDisplay }
