import { defaultDownloadHistoryPagination } from '../api/songs'

import { DEFAULT_LOCALE } from '../../locale_data/config'
import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
  responseData,
} from '../shared/utils'
import PropTypes from 'prop-types'

export const UserPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  activeSubscription: PropTypes.bool,
})

export function login(opts = {}) {
  return fetchWithHeaders(`/v1/auth`, {
    method: 'POST',
    body: JSON.stringify(opts),
  })
    .then(parseResponse)
    .catch(err => {
      return err
    })
}

export function signup(opts = { locale: DEFAULT_LOCALE }) {
  return fetchWithHeaders(`/users?locale=${opts.locale}`, {
    body: JSON.stringify(opts),
    method: 'POST',
  })
    .then(parseResponse)
    .catch(err => {
      return err
    })
}

export function putCurrentUser(params = {}) {
  return fetchWithHeaders(`/users`, {
    body: JSON.stringify(params),
    method: 'PUT',
  })
    .then(parseResponse)
    .catch(err => {
      return err
    })
}

export function fetchCurrentUser() {
  return fetchWithHeaders(`/v1/user`, {
    method: 'GET',
  }).then(responseData)
}

export function forgotPassword(opts = {}) {
  return fetchWithHeaders(`/auth/forgotpassword`, {
    method: 'POST',
    body: JSON.stringify(opts),
  })
    .then(parseResponse)
    .catch(err => {
      return err
    })
}

/**
 * @param opts
 * @param reset
 * @returns {Promise<Response>}
 */
export function updatePassword(opts = {}, reset = true) {
  return fetchWithHeaders(`/auth/updatepassword?reset=${reset}`, {
    method: 'POST',
    body: JSON.stringify(opts),
  })
    .then(parseResponse)
    .catch(err => {
      return err
    })
}

export function updateUser(opts = {}) {
  return fetchWithHeaders(`/users`, {
    method: 'PUT',
    body: JSON.stringify(opts),
  })
    .then(responseData)
    .catch(err => {
      return err
    })
}

export function fetchPreviouslyDownloaded(opts = {}) {
  const params = { ...defaultDownloadHistoryPagination, ...opts }
  return fetchWithHeaders(
    `/v1/songs/download/history?${getQueryParams(params)}`,
    {
      method: 'GET',
    }
  )
    .then(parseResponse)
    .then(res => ({ status: res.status, ...res.data }))
}

export function fetchCurrentUserBillingHistory() {
  return fetchWithHeaders(`/payments/invoices?includeAll=true`, {
    method: 'GET',
  }).then(responseData)
}

export function fetchCurrentUserInvoice(id) {
  return fetchWithHeaders(`/v1/payments/invoices/${id}`, {
    method: 'GET',
  }).then(responseData)
}

export function fetchUserProfile() {
  return fetchWithHeaders(`/users/profile`, {
    method: 'GET',
  }).then(responseData)
}
