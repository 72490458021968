import {
  CLEAR_REMIXERS,
  RECEIVE_REMIXERS,
  REQUEST_REMIXERS,
} from '../action_constants'

const defaultState = {
  isFetching: false,
  suggestions: [],
}

export default function autocompleteRemixers(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_REMIXERS:
      return {
        isFetching: true,
        suggestions: [],
      }
    case RECEIVE_REMIXERS:
      return {
        isFetching: false,
        suggestions: action.remixers,
      }
    case CLEAR_REMIXERS:
      return defaultState
    default:
      return state
  }
}
