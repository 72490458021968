import { useEffect } from 'react'
import { useHistory } from 'react-router'

const scrollbarPositionHistory = [
  //   {
  //     pagePath: 'url of the page',
  //     pageHeight: 'height of the page.',
  //     position: 'scrollbar position',
  //   },
]
let timer

export const ScrollbarMemory = () => {
  const history = useHistory()

  useEffect(() => {
    // first page url
    scrollbarPositionHistory.push({
      pagePath:
        history.location.pathname +
        history.location.search +
        history.location.hash,
    })

    addEventListener('scroll', () => {
      // continously track scrollbar position and page height for the last(current) page in the stack(scrollbarPositionHistory)
      Object.assign(scrollbarPositionHistory.at(-1), {
        position: document.documentElement.scrollTop,
        pageHeight: document.documentElement.scrollHeight,
      })
    })

    history.listen(location => {
      const pagePath = location.pathname + location.search + location.hash
      if (history.action === 'PUSH') {
        // Navigate to new page

        scrollbarPositionHistory.push({
          pagePath: pagePath,
        })
      } else if (history.action === 'POP') {
        if (
          !scrollbarPositionHistory.at(-2) ||
          scrollbarPositionHistory.at(-2).pagePath !== pagePath
        ) {
          // Browser forward button

          scrollbarPositionHistory.push({
            pagePath: pagePath,
          })
        } else {
          // Browser back button

          scrollbarPositionHistory.pop()
          const { position, pageHeight } = scrollbarPositionHistory.at(-1)

          clearInterval(timer) // clear previous timer (for cases when back button is pressed multiple times and fast)
          timer = setInterval(() => {
            if (document.documentElement.scrollHeight >= pageHeight) {
              // check if the current loaded page has the same(or larger) height as before
              // to determine if all the components are loaded (example: wait for Loading component to fetch songs list)
              requestAnimationFrame(() => {
                window.scrollTo({
                  top: position,
                })
              })
              clearInterval(timer)
            }
          }, 200)
        }
      }
    })
  }, [])

  return null
}
