import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const LocaleListItem = ({ locale, label, handleClick }) => {
  return (
    <li className={styles.link} onClick={() => handleClick(locale)}>
      {label}
    </li>
  )
}

LocaleListItem.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  locale: PropTypes.string,
  selected: PropTypes.bool,
}

export default LocaleListItem
