import { Link } from 'react-router-dom'
import { linkifyArtistNames, makeSongUrl } from 'spa/urls'
import { PlayTrackButton } from 'spa/components/track_buttons/play_track_button'
import { SongPropType } from 'spa/api/songs'
import { TextStyle } from '../typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles'

export const SongTableRowTitle = ({
  listIndex,
  numberIndex = null,
  onSelect = null,
  song,
  trackList,
}) => {
  const handleOnClick = useCallback(() => {
    if (onSelect) {
      onSelect(song.rid)
    }
  }, [onSelect, song.rid])

  return (
    <div className={styles.cellRow}>
      <PlayTrackButton
        className={styles.playButton}
        listIndex={listIndex}
        numberIndex={numberIndex}
        songId={song.rid}
        trackList={trackList}
      />
      <div className={styles.tableCellTitle}>
        <Link
          onClick={handleOnClick}
          to={{
            pathname: makeSongUrl(song),
            previousLocation: location.pathname,
          }}
        >
          <TextStyle className={styles.cellTitle} truncate variant="bold">
            {song.title}
          </TextStyle>
        </Link>
        <TextStyle
          className={classNames('artist-names', styles.cellSubtitle)}
          color="grey"
          tagName="p"
          truncate
        >
          {linkifyArtistNames(song)}
        </TextStyle>
      </div>
    </div>
  )
}

SongTableRowTitle.propTypes = {
  listIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberIndex: PropTypes.number,
  onAddSongToQueue: PropTypes.func,
  onHide: PropTypes.func,
  onRemoveSongFromQueue: PropTypes.func,
  onSelect: PropTypes.func,
  song: SongPropType,
  trackList: PropTypes.string,
}
