import * as songsApi from '../api/songs'
import { AUTO_DISMISS_SECONDS, NOTIFICATION_MESSAGES } from '../shared/utils'
import { error, success } from 'react-notification-system-redux'
import { intl } from '../shared/IntlGlobalProvider'
import { RECEIVE_RATE_SONG, REQUEST_RATE_SONG } from '../action_constants'

const successNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.songRatingTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.songRatingActionSuccess),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const errorNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.songRatingTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.songRatingActionError),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

function requestRateSong(song) {
  return {
    type: REQUEST_RATE_SONG,
    song,
  }
}

function receiveRateSong(song) {
  return {
    type: RECEIVE_RATE_SONG,
    song,
  }
}

function mergeRating(song, rating) {
  return {
    ...song,
    meta: {
      ...song.meta,
      myRating: rating,
    },
  }
}

export function rateSong({ id, rating }) {
  return function(dispatch, getState) {
    const song = getState().songs.songsById[id]
    const requestAction = requestRateSong(mergeRating(song, rating))
    dispatch(requestAction)
    return songsApi
      .rating(id, { rating })
      .then(response => {
        dispatch(receiveRateSong(mergeRating(song, response.rating)))
      })
      .then(() => dispatch(success(successNotification(intl))))
      .catch(err =>
        dispatch(
          error({
            ...errorNotification(intl),
            message: err.message,
          })
        )
      )
  }
}
