import { MenuButtonIcon } from 'spa/components/menu_button_icon'
import { Popover } from 'spa/components/popover'
import { VersionButtonIcon } from 'spa/components/version_button_icon'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export function TablePopover({
  buttonClassName,
  buttonIcon,
  buttonText,
  caretColor,
  children,
  className = '',
  contentClassName = '',
  fixed = false,
  handleGetCoords,
  ...rest
}) {
  return (
    <Popover
      className={className}
      closeOnScroll={false}
      contentClassName={contentClassName}
      fixed={fixed}
      renderTrigger={({ handleClick, open, ref }) => (
        <>
          <button
            className={classNames('cleanButton', buttonClassName, {
              [styles.buttonMargin]: buttonIcon === 'sort-down',
            })}
            onClick={e => {
              handleClick()
              handleGetCoords ? handleGetCoords(e) : null
            }}
            ref={ref}
          >
            {buttonText || null}
            {buttonIcon ? (
              <VersionButtonIcon
                className={styles.popoverTriggerIcon}
                icon={buttonIcon}
                isVertical={open}
                style={caretColor ? { color: caretColor } : null}
              />
            ) : (
              <MenuButtonIcon isOpen={open} />
            )}
          </button>
        </>
      )}
      {...rest}
    >
      {children}
    </Popover>
  )
}

TablePopover.propTypes = {
  buttonClassName: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.node,
  caretColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  fixed: PropTypes.bool,
  handleGetCoords: PropTypes.func,
}
