import * as stringResourcesApi from '../api/string_resources'

import {
  RECEIVE_STRING_RESOURCES,
  REQUEST_STRING_RESOURCES,
} from '../action_constants'

function requestStringResources() {
  return { type: REQUEST_STRING_RESOURCES }
}

function receiveStringResources({ resourceDomains, resources }) {
  return { type: RECEIVE_STRING_RESOURCES, resourceDomains, resources }
}

export function fetchStringResources(resourceDomains) {
  return async function(dispatch, getState) {
    const locale = getState().locale
    const stringResources = getState().stringResources
    dispatch(requestStringResources())
    let waitFor = []
    // Request all resources not yet loaded
    resourceDomains.forEach(id => {
      if (!stringResources.loaded.includes(id)) {
        waitFor.push(stringResourcesApi.fetchPage(id, locale.locale))
      }
    })
    const values = await Promise.all(waitFor)
    // Merge string resources
    const resources = [].concat(...values)
    dispatch(
      receiveStringResources({
        resourceDomains,
        resources,
      })
    )
  }
}
