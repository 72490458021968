import { clearSongList, fetchTagSongs } from 'spa/action_creators/tag_songs'
import { DEFAULT_DATE_OPTION, generateQueryParams } from 'spa/shared/utils'
import {
  defaultFilters,
  defaultGenresPagination,
  filterParsers,
  filterStringifiers,
} from 'spa/api/songs'
import { DropdownPopover } from 'spa/components/filters/dropdown_popover'
import { fetchTags } from 'spa/action_creators/tags'
import { FilterPopover } from 'spa/components/filters'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Heading, TextStyle } from 'spa/components/typography'
import { Helmet } from 'react-helmet'
import { isMobileSelector } from '../../../selectors/device'
import { Knotch } from 'spa/components/knotch'
import { Link } from '../../link'
import { Loading } from 'spa/components/loading'
import { localeSelector } from 'spa/selectors/users'
import { LOCALIZED_MESSAGES } from '../../table/messages'
import { loggedInSelector } from 'spa/selectors/users'
import { NoRecordsLabel } from '../../no_records_label'
import { Pagination } from 'spa/components/pagination'
import { Redirect, useParams } from 'react-router-dom'
import { SongTable } from 'spa/components/table'
import { SORT_OPTIONS } from 'spa/components/filters'
import { tagSongSelector, tagSongSelectorGrouped } from 'spa/selectors/songs'
import { TogglePanel } from 'spa/components/toggle_panel'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { usePaginationUrl } from 'spa/hooks/use_pagination_url'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles'

const Tags = ({ intl }) => {
  const [queryParams, setQueryParams] = useQueryParameters(
    {
      ...defaultFilters,
      ...defaultGenresPagination,
      sortBy: SORT_OPTIONS.POPULARITY,
    },
    {
      parsers: { ...filterParsers, sortBy: s => parseInt(s, 10) },
      stringifiers: filterStringifiers,
    }
  )

  const dispatch = useDispatch()
  const isMobile = useSelector(isMobileSelector)
  const tags = useSelector(state => state.tags.tags)
  const isFetchingTags = useSelector(state => state.tags.isLoading)
  const { id: tagId } = useParams()
  const isFetching = useSelector(state => state.tagSongs.isFetching)
  const pageCount = useSelector(state => state.tagSongs.pagination.pageCount)
  const songs = useSelector(
    queryParams.sortBy === SORT_OPTIONS.POPULARITY
      ? tagSongSelector
      : tagSongSelectorGrouped
  )
  const isLoadingMore = useSelector(state => state.tagSongs.isLoading)
  const isLoggedIn = useSelector(loggedInSelector)
  const locale = useSelector(localeSelector)
  const groupBy =
    queryParams.sortBy === SORT_OPTIONS.POPULARITY ? null : 'releasedate'
  const [fetchStarted, setFetchStarted] = useState(false)

  const buildPaginationUrl = usePaginationUrl(queryParams)

  // The tag selected through the URL parameter, or null if it doesn't exist
  // or the tags haven't been fetched yet
  const tag = useMemo(() => {
    if (!tags) {
      return null
    }

    return tags.find(t => t.id == tagId || t.slug == tagId)
  }, [tags, tagId])

  // Fetches and caches the tags (unless some other view/component already did)
  useEffect(() => {
    if ((!tags || !tags.length) && !isFetchingTags) {
      dispatch(fetchTags())
    }
  }, [tags, isFetchingTags])

  // Fetches the songs for the tag
  useEffect(() => {
    if (!tag) {
      return
    }

    const params = generateQueryParams({ locale, queryParams })

    dispatch(
      fetchTagSongs({
        ...params,
        filters: {
          ...params.filters,
          genres: [tag.id],
        },
      })
    )

    setFetchStarted(true)

    return () => dispatch(clearSongList())
  }, [locale, queryParams, tag, isLoggedIn, dispatch, setFetchStarted])

  const onSortChange = useCallback(
    sortBy => setQueryParams({ ...queryParams, sortBy }),
    [queryParams, setQueryParams]
  )

  // If there's no tag but we do have a tags list, it means that the user
  // is trying to load a tag that doesn't exist
  // In this case, we redirect him back to /genres
  if (!tag && tags?.length) {
    return <Redirect to="/genres" />
  }

  return (
    <div className={styles.base}>
      {tag?.name && (
        <Helmet defer={false}>
          <title key="pagetitle">{tag.name} | DJcity</title>
        </Helmet>
      )}
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage defaultMessage="Genres" id="djcity.common.genres" />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            <FormattedMessage
              defaultMessage="{tag_name}"
              id="djcity.common.genres.name"
              values={{ tag_name: tag?.name || 'Genres' }}
            />
          </Heading>
          {!isMobile && (
            <div>
              <TogglePanel
                items={[
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.popularity),
                    value: SORT_OPTIONS.POPULARITY,
                  },
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.date),
                    value: SORT_OPTIONS.DATE_DESC,
                  },
                ]}
                onChange={onSortChange}
                showSeparator
                value={queryParams.sortBy}
              />
              <FilterPopover filters={queryParams} onChange={setQueryParams} />
              <Link to="/genres">
                <button className="secondary">
                  <FormattedMessage
                    defaultMessage="Back to all genres"
                    id="djcity.common.genres.back.button"
                  />
                </button>
              </Link>
            </div>
          )}
        </div>
        <Knotch className={styles.knotch} size="big" />
        {isMobile && (
          <Link to="/genres">
            <button className="secondary">
              <FormattedMessage
                defaultMessage="Back to all genres"
                id="djcity.common.genres.back.button"
              />
            </button>
          </Link>
        )}
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <DropdownPopover
            isMobile={isMobile}
            items={{
              [SORT_OPTIONS.POPULARITY]: intl.formatMessage(
                LOCALIZED_MESSAGES.popularity
              ),
              [SORT_OPTIONS.DATE_DESC]: intl.formatMessage(
                LOCALIZED_MESSAGES.date
              ),
            }}
            onChange={onSortChange}
            value={queryParams.sortBy}
          />
          <FilterPopover
            filters={queryParams}
            isMobile={isMobile}
            onChange={setQueryParams}
          />
        </div>
      )}
      {(isFetching || isFetchingTags || !fetchStarted) && !songs.length ? (
        <Loading />
      ) : (
        <Pagination
          buildUrl={buildPaginationUrl}
          page={queryParams.page}
          pageCount={pageCount}
        >
          {groupBy ? (
            songs.map((group, i) => (
              <SongTable
                className={styles.table}
                firstColumnHeaderLabel={intl.formatDate(
                  new Date(group[groupBy]),
                  DEFAULT_DATE_OPTION
                )}
                isLoading={isLoadingMore}
                key={group[groupBy]}
                nextSongs={i + 1 === songs.length ? [] : songs[i + 1].songs}
                songs={group.songs}
                trackList={TRACKLIST.GENRES}
              />
            ))
          ) : (
            <SongTable
              className={styles.table}
              firstColumnHeaderLabel="Records"
              isLoading={isLoadingMore}
              songs={songs}
              trackList={TRACKLIST.GENRES}
            />
          )}
          {songs.length === 0 ? <NoRecordsLabel /> : null}
        </Pagination>
      )}
    </div>
  )
}

Tags.displayName = 'Tags'
Tags.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func,
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(Tags)
