import {
  RECEIVE_DOWNLOAD_ALL_TRACKS,
  RECEIVE_DOWNLOAD_TRACK,
  SET_DOWNLOAD_TRACK_STATUS,
} from '../action_constants'

export function setDownloadTrackStatus({ songId, trackId, isDownloading }) {
  return {
    type: SET_DOWNLOAD_TRACK_STATUS,
    songId,
    trackId,
    isDownloading,
  }
}

export function receiveDownloadTrack({ songId, trackId, trackList }) {
  return {
    type: RECEIVE_DOWNLOAD_TRACK,
    songId,
    trackId,
    trackList,
  }
}

export function receiveDownloadAllTracks({ songId, trackList }) {
  return {
    type: RECEIVE_DOWNLOAD_ALL_TRACKS,
    songId,
    trackList,
  }
}
