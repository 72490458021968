import { Browse } from '../../browse'
import { Layout, Sidebar } from '../../layout'
import NewReleases from './new_releases'
import React from 'react'

const display = () => {
  return (
    <Layout tabTitle="new_releases">
      <Sidebar>
        <Browse />
      </Sidebar>
      <NewReleases />
    </Layout>
  )
}

export { display as NewReleasesDisplay }

export { NewReleases }

export default NewReleases
