import { Link as ReactRouterLink } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const Link = ({
  className,
  color,
  fullPageLoad,
  newWindow,
  to,
  underline,
  fontWeight,
  fontSize,
  ...rest
}) => {
  const cls = classNames(
    styles.link,
    {
      [styles.red]: color === 'red',
      [styles.white]: color === 'white',
      [styles.black]: color === 'black',
      [styles.underline]: !!underline,
    },
    className,
    fontWeight ? styles.bold : null,
    fontSize ? styles.fontSize : null
  )
  if (fullPageLoad) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className={cls}
        href={to}
        target={newWindow ? '_blank' : null}
        {...rest}
      />
    )
  }
  return (
    <ReactRouterLink
      className={cls}
      to={{ pathname: to, previousLocation: location.pathname }}
      {...rest}
    />
  )
}

Link.displayName = 'Link'

Link.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['red', 'white', 'black']),
  fontSize: PropTypes.any,
  fontWeight: PropTypes.any,
  fullPageLoad: PropTypes.bool,
  newWindow: PropTypes.bool,
  to: PropTypes.string,
  underline: PropTypes.bool,
}
