import { DarkSwitch } from 'spa/components/dark_switch'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { loggedInSelector } from 'spa/selectors/users'
import { LOGIN_MODAL, openModal } from 'spa/action_creators/modals'
import { Logo } from 'spa/components/logo'
import { NavigationMenu } from 'spa/components/navigation_menu'
import { SongSearchBox } from 'spa/components/song_search_box'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import LocaleList from '../layout/locale/locale_list'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import localizedMessages from './localizedMessages'
import styles from './styles'

const Header = ({ intl }) => {
  const dispatch = useDispatch()

  // Selectors
  const isLoggedIn = useSelector(loggedInSelector)

  const onLoginClick = useCallback(() => dispatch(openModal(LOGIN_MODAL)), [
    dispatch,
  ])

  return (
    <>
      <div className={styles.header}>
        <Link to="/">
          <Logo className={styles.logo} darkMode />
        </Link>
        <NavigationMenu className={styles.navigation} />
        <div className={styles.additionHeaderContainer}>
          <div className={styles.buttonBlock}>
            {!isLoggedIn ? (
              <SongSearchBox className={styles.searchBox} />
            ) : null}
            <DarkSwitch />
            <LocaleList />
            {!isLoggedIn ? (
              <button
                className={classNames('secondary', styles.loginButton)}
                onClick={onLoginClick}
              >
                {intl.formatMessage(localizedMessages.login)}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

Header.displayName = 'Header'

Header.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

const ConnectedHeader = injectIntl(Header)

export { ConnectedHeader as Header }
