/* eslint-disable no-console */
import { AUTO_DISMISS_SECONDS, NOTIFICATION_MESSAGES } from 'spa/shared/utils'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { injectIntl } from 'react-intl'
import { Label } from 'spa/components/label'
import { error as notifyError } from 'react-notification-system-redux'
import { TextInput } from 'spa/components/text_input'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import inputStyles from 'spa/components/text_input/styles'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { localizedMessages } from './messages'
import styles from './styles'

const stripeStyle = {
  fontSize: '16px', // min font size to prevent input zoom on mobile devices
  fontFamily: '"Montserrat", sans-serif',
  backgroundColor: 'transparent',
  color: '#fff',
}

export function Form({
  action,
  disabled,
  intl,
  promo,
  submitText,
  subscription,
}) {
  const [cardName, setCardName] = useState('')
  const [isGeneratingToken, setIsGeneratingToken] = useState(false)
  const [focusedField, setFocusedField] = useState(0)
  const isProcessingPayment = useSelector(
    state => state.currentUserBilling.isProcessing
  )
  const darkMode = useSelector(state => state.darkMode)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const isDisabled =
    disabled ||
    !stripe ||
    !elements ||
    cardName === '' ||
    isGeneratingToken ||
    isProcessingPayment

  const creditCardError = useCallback(
    message => ({
      title: intl.formatMessage(NOTIFICATION_MESSAGES.creditCard),
      message,
      position: 'tr',
      autoDismiss: AUTO_DISMISS_SECONDS,
    }),
    [intl]
  )

  // Even though we shouldn't run async code inside components, we need to
  // do this here. The new Stripe JS API requires that we pass an element to
  // the createToken function, and the card info form component must remain mounted
  // during this process. The only way to ensure this is to generate the token inside
  // the Form component. After the token is generated, however, any subsequent async
  // calls can be done outside the component as usual
  const submit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    if (isDisabled) {
      return
    }

    setIsGeneratingToken(true)
    const cardNumberElement = elements.getElement(CardNumberElement)
    const {
      token: gatewayToken,
      error: stripeError,
    } = await stripe.createToken(cardNumberElement, { name: cardName })
    setIsGeneratingToken(false)

    if (stripeError) {
      return dispatch(notifyError(creditCardError(stripeError.message)))
    }

    const data = {
      cardName,
      gatewayToken: gatewayToken.id,
      promo: promo || undefined,
      subscription: subscription || undefined,
    }

    return dispatch(action(stripe, data))
  }

  return (
    <form className={styles.form} onSubmit={submit}>
      <div>
        <Label
          className={styles.label}
          text={intl.formatMessage(localizedMessages.cardName)}
        >
          <TextInput
            className={styles.textInput}
            onChange={e => setCardName(e.currentTarget.value)}
            style={{ ...stripeStyle, color: darkMode ? '#fff' : '#000' }}
            value={cardName}
          />
        </Label>
      </div>
      <div>
        <Label
          className={styles.label}
          text={intl.formatMessage(localizedMessages.cardNumber)}
        >
          <CardNumberElement
            className={classNames(inputStyles.newTextInput, {
              [inputStyles.newTextInputFocused]: focusedField === 2,
            })}
            onBlur={() => setFocusedField(0)}
            onFocus={() => setFocusedField(2)}
            options={{
              placeholder: '**** **** **** ****',
              style: {
                base: {
                  ...stripeStyle,
                  color: darkMode ? '#fff' : '#000',
                },
              },
            }}
          />
        </Label>
      </div>
      <div className={styles.lineInput}>
        <div className={styles.lineItem}>
          <Label
            className={styles.label}
            text={intl.formatMessage(localizedMessages.cardExpiration)}
          >
            <CardExpiryElement
              className={classNames(inputStyles.newTextInput, {
                [inputStyles.newTextInputFocused]: focusedField === 3,
              })}
              onBlur={() => setFocusedField(0)}
              onFocus={() => setFocusedField(3)}
              options={{
                style: {
                  base: {
                    ...stripeStyle,
                    color: darkMode ? '#fff' : '#000',
                  },
                },
              }}
            />
          </Label>
        </div>
        <div className={styles.lineItem}>
          <Label
            className={styles.label}
            text={intl.formatMessage(localizedMessages.cardCvc)}
          >
            <CardCvcElement
              className={classNames(inputStyles.newTextInput, {
                [inputStyles.newTextInputFocused]: focusedField === 4,
              })}
              onBlur={() => setFocusedField(0)}
              onFocus={() => setFocusedField(4)}
              options={{
                style: {
                  base: {
                    ...stripeStyle,
                    color: darkMode ? '#fff' : '#000',
                  },
                },
              }}
            />
          </Label>
        </div>
      </div>
      <div className={styles.lineInput}>
        <div className={styles.submitBtn}>
          <button
            className={classNames(styles.completeRegistration, {
              inactive: isDisabled,
            })}
            disabled={isDisabled}
            onClick={e => isDisabled && e.preventDefault()}
            type="submit"
          >
            {submitText || intl.formatMessage(localizedMessages.payNow)}
          </button>
        </div>
      </div>
    </form>
  )
}

Form.displayName = 'Form'

Form.propTypes = {
  action: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  promo: PropTypes.object,
  stripe: PropTypes.object,
  submitText: PropTypes.object,
  subscription: PropTypes.object,
}

export const InjectedForm = injectIntl(Form)
