import { defineMessages, injectIntl } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  noImageAavailable: {
    id: 'djcity.common.image.nt_available',
    defaultMessage: 'Image Not Available',
  },
})

export const NoImage = injectIntl(({ className, intl }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <span className={styles.text}>
        {intl.formatMessage(localizedMessages.noImageAavailable)}
      </span>
    </div>
  )
})

NoImage.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}
