import { FilterButtonIcon } from './filter_button_icon'
import { injectIntl } from 'react-intl'
import { Popover } from 'spa/components/popover'
import {
  RELEASE_DATE_LABELS,
  ReleaseDateFilterPanel,
} from './release_date_filter_panel'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const ReleaseDatePopover = ({
  onChange,
  value,
  intl,
  isMobile,
  defaultValue,
}) => {
  const currentValue = value || defaultValue

  return (
    <Popover
      closeOnScroll={false}
      contentClassName={styles.filterPanel}
      renderTrigger={({ handleClick, open, ref }) => (
        <button
          className={classNames({
            popoverTrigger: !open,
            popoverTriggerOpen: open,
            mobile: isMobile,
          })}
          onClick={handleClick}
          ref={ref}
        >
          {isMobile ? (
            <>
              <FilterButtonIcon isMobile={isMobile} isOpen={open} />{' '}
              {intl.formatMessage(RELEASE_DATE_LABELS[currentValue])}
            </>
          ) : (
            <>
              {intl.formatMessage(RELEASE_DATE_LABELS[currentValue])}{' '}
              <FilterButtonIcon isOpen={open} />
            </>
          )}
        </button>
      )}
    >
      <ReleaseDateFilterPanel
        // className={styles.filterPanel}
        isMobile={isMobile}
        onChange={onChange}
        value={currentValue}
      />
    </Popover>
  )
}

ReleaseDatePopover.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

const IntlReleaseDatePopover = injectIntl(ReleaseDatePopover)

export { IntlReleaseDatePopover as ReleaseDatePopover }
