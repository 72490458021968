import * as bannerAssetsApi from '../api/banner_assets'
import {
  RECEIVE_BANNER_ASSETS,
  REQUEST_BANNER_ASSETS,
  REQUEST_CLICK_BANNER_ASSET,
} from '../action_constants'

function requestBannerAssets() {
  return {
    type: REQUEST_BANNER_ASSETS,
  }
}

function receiveBannerAssets({ models }) {
  return {
    type: RECEIVE_BANNER_ASSETS,
    models,
  }
}

function requestClickBannerAsset({ model }) {
  return {
    type: REQUEST_CLICK_BANNER_ASSET,
    model,
  }
}

export function triggeredBannerClick(banner) {
  return function(dispatch) {
    dispatch(requestClickBannerAsset({ model: banner }))
  }
}

export function fetchAll(opts) {
  return function(dispatch, getState) {
    const locale = getState().locale
    const requestOpts = { ...opts, locale: locale.locale }
    dispatch(requestBannerAssets())
    return bannerAssetsApi
      .fetchAll({ device: 'w', ...requestOpts })
      .then(response => {
        dispatch(receiveBannerAssets({ models: response }))
      })
  }
}
