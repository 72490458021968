import { Layout } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'
const Apps = React.lazy(() => import('./apps'))

const display = () => {
  return (
    <Layout fullLayout tabTitle="apps">
      <ModuleLoader>
        <Apps />
      </ModuleLoader>
    </Layout>
  )
}

export { display as AppsDisplay }

export { Apps }

export default Apps
