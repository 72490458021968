import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Heading } from '../typography'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

function FreePackCampaignHeader({ isLoginScreen }) {
  if (isLoginScreen)
    return (
      <>
        <Heading className={styles.heading}>
          <FormattedHTMLMessage
            defaultMessage="Login to Unlock Free Download"
            id="djcity.account.login.campaign.free_download_pack.title"
          />
        </Heading>
        <div className={styles.subtitle}>
          <FormattedMessage
            defaultMessage="Your download link will be emailed to you."
            id="djcity.account.login.campaign.free_download_pack.subtitle"
          />
        </div>
      </>
    )
  else
    return (
      <>
        <Heading className={styles.heading}>
          <FormattedHTMLMessage
            defaultMessage="Create Account to Unlock Free Download"
            id="djcity.account.signup.campaign.free_download_pack.title"
          />
        </Heading>
        <div className={styles.subtitle}>
          <FormattedMessage
            defaultMessage="Your download link will be emailed to you."
            id="djcity.account.signup.campaign.free_download_pack.subtitle"
          />
        </div>
      </>
    )
}

export default FreePackCampaignHeader

FreePackCampaignHeader.propTypes = {
  isLoginScreen: PropTypes.bool,
}
