import { Layout } from '../../layout'
import React from 'react'
import SubscriptionUpdate from '../../subscription/subscription_update'

const display = () => {
  return (
    <Layout fullLayout tabTitle="subscription">
      <SubscriptionUpdate />
    </Layout>
  )
}

export { display as SubscriptionDisplay }
