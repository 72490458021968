import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export class Slide extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    show: PropTypes.bool,
  }

  static defaultProps = {
    show: false,
  }

  render() {
    const { className, children, show } = this.props
    const cls = classNames(styles.slide, className)
    return (
      <CSSTransition
        classNames={{ enter: styles.enter, exit: styles.exit }}
        in={show}
        timeout={{ enter: 500, exit: 500 }}
        unmountOnExit
      >
        <div className={cls}>{children}</div>
      </CSSTransition>
    )
  }
}

export default Slide
