import { injectIntl } from 'react-intl'
import { isMobileSelector } from '../../selectors/device'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

import { AppStoreBadge, PlayStoreBadge, QrCode } from './components'
import { localizedMessages } from './localized_messages'

const MobileQr = ({ intl, showMessage = false }) => {
  const { currentUser, device } = useSelector(s => s)
  const { mobileAppDownloaded } = currentUser.user || {}
  const isMobile = isMobileSelector({ device })

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.badgeContainer}>
        <AppStoreBadge />
        <PlayStoreBadge />
      </div>
      <div className={styles.qrContainer}>
        {!isMobile && !mobileAppDownloaded && <QrCode />}
        {showMessage && (
          <span className={styles.text}>
            {intl.formatMessage(localizedMessages.downloadMobileApp)}
          </span>
        )}
      </div>
    </div>
  )
}

MobileQr.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  showMessage: PropTypes.bool,
}

export default injectIntl(MobileQr)
