import { CategoryButton } from 'spa/components/button/category_button'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { FiltersPropType } from 'spa/api/songs'
import intersection from 'lodash/intersection'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  LATIN: {
    id: 'djcity.common.category.global.latin',
    defaultMessage: 'Latin',
  },
  UK: {
    id: 'djcity.common.category.global.uk',
    defaultMessage: 'UK',
  },
  ASIA: {
    id: 'djcity.common.category.global.asia',
    defaultMessage: 'Asia',
  },
  SOUTHASIA: {
    id: 'djcity.common.category.global.southasia',
    defaultMessage: 'South Asia',
  },
  GERMANY: {
    id: 'djcity.common.category.global.germany',
    defaultMessage: 'Germany',
  },
  INDIA: {
    id: 'djcity.common.category.global.india',
    defaultMessage: 'India',
  },
})

export const GLOBAL_TAGS = Object.freeze({
  LATIN: [3],
  ASIA: [107, 130, 141, 190],
  SOUTHASIA: [
    213, // Punjabi
    244, // South Asian
    245, // Bollywood
    246, // Bhangra
    272, // Pakistan
    283, // Tamil
    290, // Bengali
  ],
  GERMANY: [126, 127, 128, 131, 178, 197],
  UK: [22, 23, 24, 25, 26, 27, 28, 37, 54, 57, 78, 188],
})

class GlobalFilterPanel extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    filters: FiltersPropType,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    showLocaleButtons: true,
  }

  handleGenreTagChange = genres => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, genres, page: 1 })
    }
  }

  handleChange = (genre, checked) => {
    const { onChange } = this.props
    if (onChange && checked) {
      this.handleGenreTagChange(genre)
    }
  }

  render() {
    const { children, filters: { genres } = null, intl } = this.props

    const GenreButton = globalTagKey => (
      <CategoryButton
        checked={intersection(genres, GLOBAL_TAGS[globalTagKey]).length > 0}
        key={globalTagKey}
        labelText={intl.formatMessage(localizeMessages[globalTagKey])}
        onChange={e =>
          this.handleChange(GLOBAL_TAGS[globalTagKey], e.target.checked)
        }
      />
    )

    const buttons = Object.keys(GLOBAL_TAGS).map(GenreButton)

    return (
      <div className={styles.checkboxRow}>
        {buttons}
        {children}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  ...props,
})

const ConnectedGlobalFilterPanel = connect(mapStateToProps)(
  injectIntl(GlobalFilterPanel)
)

export { ConnectedGlobalFilterPanel as GlobalFilterPanel }
