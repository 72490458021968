import {
  CLEAR_SONGS_LIST,
  RECEIVE_DOWNLOAD_TRACK,
  RECEIVE_REMOVE_ALL_FROM_CRATE,
  RECEIVE_REMOVE_ALL_TRACKS_FROM_CRATE,
  RECEIVE_REMOVE_FROM_CRATE,
  RECEIVE_REMOVE_MULTIPLE_FROM_CRATE,
  RECEIVE_UPDATED_SONGS,
  REQUEST_UPDATED_SONGS,
  SET_DOWNLOAD_TRACK_STATUS,
} from '../action_constants'
import { defaultUpdatedPagination } from '../api/songs'
import { findDownloadedTrack } from './current_user'
import cloneDeep from 'lodash/cloneDeep'

const defaultState = {
  isFetching: false,
  pagination: defaultUpdatedPagination,
  tracks: [],
}

const removeTracksFromCrate = (tracks, items) => {
  const itemIndex = {}
  items.forEach(([rid, tid]) => (itemIndex[`${rid}|${tid}`] = true))

  return tracks.map(track => {
    return {
      ...track,
      inCrate: track.inCrate
        ? !itemIndex[`${track.record.rid}|${track.ttid}`]
        : false,
    }
  })
}

export default function updatedSongs(state = defaultState, action) {
  let newState
  let trackIndex

  switch (action.type) {
    case REQUEST_UPDATED_SONGS:
      return {
        isFetching: true,
        pagination: state.pagination,
        tracks: [],
      }
    case RECEIVE_UPDATED_SONGS:
      return {
        isFetching: false,
        pagination: action.pagination,
        tracks: action.tracks,
      }
    case RECEIVE_REMOVE_ALL_FROM_CRATE:
      return {
        ...state,
        tracks: state.tracks.map(track => ({
          ...track,
          inCrate: false,
        })),
      }
    case RECEIVE_REMOVE_MULTIPLE_FROM_CRATE:
      return {
        ...state,
        tracks: removeTracksFromCrate(
          state.tracks,
          action.tracksToRemove.map(ttr => [ttr.rid, ttr.ttid])
        ),
      }
    case RECEIVE_REMOVE_FROM_CRATE:
      return {
        ...state,
        tracks: removeTracksFromCrate(state.tracks, [
          [action.songId, action.trackId],
        ]),
      }
    case RECEIVE_REMOVE_ALL_TRACKS_FROM_CRATE:
      return {
        ...state,
        tracks: removeTracksFromCrate(
          state.tracks,
          action.trackIds.map(trackId => [[action.songId, trackId]])
        ),
      }
    case SET_DOWNLOAD_TRACK_STATUS:
      trackIndex = findDownloadedTrack(
        state.tracks,
        action.songId,
        action.trackId
      )
      if (trackIndex >= 0) {
        newState = cloneDeep(state)
        newState.tracks[trackIndex].isDownloading = action.isDownloading
        return newState
      }
      return state
    case RECEIVE_DOWNLOAD_TRACK:
      trackIndex = findDownloadedTrack(
        state.tracks,
        action.songId,
        action.trackId
      )
      if (trackIndex >= 0) {
        newState = cloneDeep(state)
        newState.tracks[trackIndex].downloadCount += 1
        newState.tracks[trackIndex].isDownloading = false
        return newState
      }
      return state
    case CLEAR_SONGS_LIST:
      return defaultState
    default:
      return state
  }
}
