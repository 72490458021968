import { closeModal } from 'spa/action_creators/modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Heading } from 'spa/components/typography'
import { Knotch } from 'spa/components/knotch'
import { Logo } from 'spa/components/logo'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'

Modal.setAppElement('#root')

import { isMobileSelector } from '../../selectors/device'
import styles from './styles'

export default function ApplicationModal({
  children = null,
  className = null,
  modalKey,
  subtitle = null,
  title = null,
  onClose = null,
}) {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.modals[modalKey].enabled)
  const isMobile = useSelector(isMobileSelector)

  const onCloseClick = useCallback(() => {
    dispatch(closeModal())
    if (onClose) {
      onClose()
    }
  }, [dispatch, onClose])

  useEffect(() => {
    const onKeyDown = e => {
      if (e.code === 'Escape') {
        dispatch(closeModal())
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', onKeyDown)
    }

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [dispatch, isOpen])

  useEffect(() => {
    if (isMobile && isOpen) document.body.classList.add('mobileModal')
    else document.body.classList.remove('mobileModal')
  }, [isMobile, isOpen])

  return (
    <Modal className={classNames(styles.modal, className)} isOpen={isOpen}>
      <header className={styles.modalHeader}>
        <Logo className={styles.logo} darkMode />
        <FontAwesomeIcon
          className={styles.closeButton}
          icon={['fal', 'times-circle']}
          onClick={onCloseClick}
        />
      </header>
      <Heading className={styles.heading}>{title}</Heading>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      {title && (
        <div className={styles.knotches}>
          <Knotch fat="fat" />
          <Knotch fat="fat" />
          <Knotch fat="fat" />
        </div>
      )}
      {children}
    </Modal>
  )
}

ApplicationModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  modalKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  subtitle: PropTypes.node,
  title: PropTypes.node,
}
