import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Popover } from 'spa/components/popover'
import { Table } from 'spa/components/table'
import { TextStyle } from 'spa/components/typography'
import { TrackDropDownTableInfo } from './track_dropdown_table_info'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const TrackDropdownTable = ({ children }) => {
  const versionsPopover = (
    <Popover
      closeOnScroll={false}
      contentClassName={styles.versionPopoverContent}
      renderTrigger={({ handleClick, ref }) => (
        <button
          className={classNames('cleanButton', styles.versionButton)}
          onClick={handleClick}
          ref={ref}
        >
          <FontAwesomeIcon className={styles.infoVersion} icon="info-circle" />
          <TextStyle color="grey" variant="bold">
            <FormattedMessage
              defaultMessage="VERSIONS"
              id="djcity.records.queue.track.version.title"
            />
          </TextStyle>
        </button>
      )}
      variant="light"
    >
      <TrackDropDownTableInfo />
    </Popover>
  )

  return (
    <Table
      className={styles.trackDropdownTable}
      columns={[
        versionsPopover,
        <FormattedHTMLMessage
          defaultMessage="DOWNLOAD"
          id="djcity.records.queue.track.download.title"
          key="download"
        />,
        <FormattedHTMLMessage
          defaultMessage="CRATE"
          id="djcity.records.queue.track.crate.title"
          key="crate"
        />,
        <FormattedHTMLMessage
          defaultMessage="DESKTOP APP"
          id="djcity.records.queue.track.desktop.title"
          key="desktop app"
        />,
      ]}
      headerClassName={styles.tableHeader}
    >
      {children}
    </Table>
  )
}

TrackDropdownTable.propTypes = {
  children: PropTypes.node,
}
