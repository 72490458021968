import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { isMobileSelector } from '../../selectors/device'
import styles from './styles'

export const RadioButton = ({
  className,
  id,
  labelText,
  withRadioIcon,
  ...rest
}) => {
  const isMobile = useSelector(isMobileSelector)
  // hide for mobile by default
  withRadioIcon = withRadioIcon === undefined ? !isMobile : withRadioIcon

  return (
    <div className={classNames(styles.radioButton, className)}>
      <input className={styles.input} id={id} type="radio" {...rest} />
      <label
        className={classNames(
          styles.label,
          withRadioIcon ? styles.labelWithRadioIcon : null
        )}
        htmlFor={id}
      >
        {labelText}
      </label>
    </div>
  )
}

RadioButton.displayName = 'RadioButton'

RadioButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  /** Required to enable clickable (accessible) label */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  withRadioIcon: PropTypes.bool,
}
