import {
  CLEAR_SONG_DETAILS,
  RECEIVE_SONG_DETAILS,
  REQUEST_SONG_DETAILS,
} from '../action_constants'

const defaultState = {
  isFetching: false,
  songId: undefined,
}

export default function songDetails(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_SONG_DETAILS:
      return {
        ...state,
        isFetching: true,
        songId: action.song ? action.song.rid : undefined,
        song: null,
      }
    case RECEIVE_SONG_DETAILS:
      return {
        ...state,
        isFetching: false,
        songId: action.song ? action.song.rid : undefined,
        song: action.song,
      }
    case CLEAR_SONG_DETAILS:
      return {
        ...state,
        isFetching: false,
        songId: undefined,
        song: null,
      }
    default:
      return state
  }
}
