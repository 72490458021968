import { Slide } from './slide'
import { SliderControl } from './controls'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export class ContentSlider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    interval: PropTypes.number,
    loading: PropTypes.bool,
    nav: PropTypes.bool,
  }

  static defaultProps = {
    interval: 10000,
    loading: false,
    nav: true,
  }

  state = {
    slideIndex: 0,
    showControlButtons: false,
  }

  componentDidMount() {
    this.resetIntervalTimer()
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer)
  }

  intervalTimer = null

  changeSlide = slideEvent => {
    const { slideIndex } = this.state
    const maxLength = this.slideArray().length - 1

    this.resetIntervalTimer()

    switch (slideEvent) {
      case 'prev': {
        if (slideIndex > 0) {
          this.setState(state => {
            return {
              slideIndex: state.slideIndex - 1,
            }
          })
        } else {
          this.setState({
            slideIndex: maxLength,
          })
        }
        break
      }
      default: {
        if (slideIndex < maxLength) {
          this.setState(function(state) {
            return {
              slideIndex: state.slideIndex + 1,
            }
          })
        } else {
          this.setState({
            slideIndex: 0,
          })
        }
      }
    }
  }

  resetIntervalTimer() {
    const { interval } = this.props
    clearInterval(this.intervalTimer)
    this.intervalTimer = setInterval(() => {
      this.changeSlide()
    }, interval)
  }

  renderdotNav() {
    const { children, nav } = this.props
    return <SliderControl nav={nav}>{children}</SliderControl>
  }

  slideArray() {
    const { children } = this.props
    const { slideIndex } = this.state
    return React.Children.map(children, function(child, i) {
      if (child.type === Slide) {
        return React.cloneElement(child, { show: i === slideIndex })
      }
    })
  }

  showControls = () => {
    this.setState({ showControlButtons: true })
  }

  hideControls = () => {
    this.setState({ showControlButtons: false })
  }

  render() {
    const { className, children, nav, ...rest } = this.props
    delete rest.loading
    const { showControlButtons } = this.state
    const cls = classNames(styles.contentSlider, className)
    return (
      <div
        className={cls}
        onMouseOut={this.hideControls}
        onMouseOver={this.showControls}
        {...rest}
      >
        {this.slideArray()}
        <SliderControl
          changeSlide={this.changeSlide}
          className={
            showControlButtons
              ? styles.visibleControls
              : styles.nonVisibleControls
          }
          nav={nav}
        >
          {children}
        </SliderControl>
      </div>
    )
  }
}

export default ContentSlider
