import { Layout } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'

const Marketing = React.lazy(() => import('./marketing'))

const display = () => {
  return (
    <Layout fullLayout>
      <ModuleLoader>
        <Marketing />
      </ModuleLoader>
    </Layout>
  )
}

export { display as MarketingDisplay }

export { Marketing }

export default Marketing
