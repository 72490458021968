import { addSongsToQueue } from 'spa/action_creators/player'
import { CategoryButton } from 'spa/components/button/category_button'
import {
  clearSongList,
  fetchUpdatedTracks,
} from 'spa/action_creators/updated_songs'
import { CrateButton } from 'spa/components/track_buttons/crate_button'
import { DEFAULT_DATE_OPTION } from 'spa/shared/utils'
import { defaultUpdatedParams } from 'spa/api/songs'
import { defineMessages } from 'react-intl'
import { DownloadTrackButton } from 'spa/components/track_buttons/download_track_button'
import { DropdownPopover } from 'spa/components/filters/dropdown_popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Heading, TextStyle } from 'spa/components/typography'
import { Knotch } from 'spa/components/knotch'
import { Link } from 'react-router-dom'
import { Loading } from 'spa/components/loading'
import { loggedInSelector } from 'spa/selectors/users'
import { NoRecordsLabel } from 'spa/components/no_records_label'
import { Pagination } from 'spa/components/pagination'
import { Popover } from 'spa/components/popover'
import { SendToDownloaderButton } from 'spa/components/track_buttons/send_to_downloader_button'
import { SongTableRowTitle } from 'spa/components/table/song_table_row_title'
import { Table } from 'spa/components/table'
import { TableSeparator } from 'spa/components/table/table_separator'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo } from 'react'

import { isMobileSelector } from '../../../selectors/device'
import styles from './styles'

const localizedMessages = defineMessages({
  all: {
    id: 'djcity.common.all',
    defaultMessage: 'All',
  },
  myUpdates: {
    id: 'djcity.records.track_updates.myUpdates',
    defaultMessage: 'My Updates',
  },
})

export const UpdatedSongs = ({ intl }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.updatedSongs.isFetching)
  const pagination = useSelector(state => state.updatedSongs.pagination)
  const tracks = useSelector(state => state.updatedSongs.tracks)
  const { autoplay } = useSelector(state => state.userPreferences)
  const isLoggedIn = useSelector(loggedInSelector)
  const isMobile = useSelector(isMobileSelector)
  const [queryParams, setQueryParams] = useQueryParameters(defaultUpdatedParams)
  const myTracks = queryParams.myTracks === 'true'

  const setMyTracks = newMyTracks => setQueryParams({ myTracks: newMyTracks })

  useEffect(() => {
    dispatch(fetchUpdatedTracks(queryParams))
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    return () => dispatch(clearSongList())
  }, [isLoggedIn, queryParams])

  const buildUrl = useCallback(
    page => `/track-updates?${queryString.stringify({ ...queryParams, page })}`,
    [queryParams]
  )

  const trackGroups = useMemo(() => {
    if (tracks) {
      const groups = {}
      tracks.forEach(track => {
        const key = track.changeDate.replace(/T.*/, '')
        if (!groups[key]) {
          groups[key] = []
        }
        groups[key].push(track)
      })

      const keys = Object.keys(groups).sort((a, b) => -a.localeCompare(b))

      return keys.map(key => ({
        changeDate: key,
        tracks: groups[key],
      }))
    }
    return []
  }, [tracks])

  const handleClickBubble = e => {
    const { listIndex } = e.target.dataset
    if (!listIndex) return

    const [songGroupKey, songIndex] = listIndex.split('-')
    if (autoplay) {
      let songsToEnqueue = trackGroups[songGroupKey].tracks
        .map(t => t.record)
        .filter(
          (obj1, i, arr) =>
            arr.findIndex(obj2 => parseInt(obj2.rid) === parseInt(obj1.rid)) ===
            i
        )
      // only if last song is played, enque next group of songs
      if (
        parseInt(songsToEnqueue.at(-1)?.rid) ===
        parseInt(songsToEnqueue[songIndex]?.rid)
      ) {
        const nextSongs = trackGroups[songGroupKey + 1].tracks
          .map(t => t.record)
          .filter(
            (obj1, i, arr) =>
              arr.findIndex(
                obj2 => parseInt(obj2.rid) === parseInt(obj1.rid)
              ) === i
          )
        songsToEnqueue = songsToEnqueue.concat(nextSongs)
      }
      if (songsToEnqueue.length) {
        dispatch(addSongsToQueue(songsToEnqueue))
      }
    }
  }

  let content
  if (isFetching) {
    content = <Loading />
  } else if (!tracks || !tracks.length) {
    content = <NoRecordsLabel />
  } else {
    content = (
      <Pagination
        buildUrl={buildUrl}
        page={pagination.page}
        pageCount={Math.ceil(pagination.totalItems / pagination.pageSize)}
      >
        {trackGroups.map((group, groupIndex) => (
          <Table
            className={styles.table}
            columns={[
              intl.formatDate(group.changeDate, DEFAULT_DATE_OPTION),
              ...(isMobile ? [''] : ['', '']),
            ]}
            key={group.changeDate}
          >
            {group.tracks.map((track, trackIndex) => [
              ...(isMobile
                ? [
                    <div
                      className={styles.updatedTrack}
                      key={`${track.changedTrack}-container`}
                      onClick={handleClickBubble}
                    >
                      <TextStyle
                        color="grey"
                        key={`${track.changedTrack}-change`}
                        tagName="div"
                        variant="bold"
                      >
                        {track.change}
                      </TextStyle>
                      <SongTableRowTitle
                        key={`${track.changedTrack}-name`}
                        song={track.record}
                        trackList={TRACKLIST.UPDATES}
                      />
                    </div>,
                  ]
                : [
                    <div
                      key={`${track.changedTrack}-name`}
                      onClick={handleClickBubble}
                      style={{ display: 'contents' }}
                    >
                      <SongTableRowTitle
                        listIndex={`${groupIndex}-${trackIndex}`}
                        song={track.record}
                        trackList={TRACKLIST.UPDATES}
                      />
                    </div>,
                    <TextStyle
                      color="grey"
                      key={`${track.changedTrack}-change`}
                      tagName="div"
                      variant="bold"
                    >
                      {track.change}
                    </TextStyle>,
                  ]),
              <div
                className={styles.buttons}
                key={`${track.changedTrack}-buttons`}
              >
                <DownloadTrackButton
                  songId={track.record.rid}
                  trackId={track.changedTrack}
                  trackList={TRACKLIST.UPDATES}
                />
                <CrateButton
                  songId={track.record.rid}
                  trackId={track.changedTrack}
                  trackList={TRACKLIST.UPDATES}
                />
                <SendToDownloaderButton
                  songId={track.record.rid}
                  trackId={track.changedTrack}
                  trackList={TRACKLIST.UPDATES}
                />
              </div>,
              <TableSeparator key="separator" />,
            ])}
          </Table>
        ))}
      </Pagination>
    )
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="Record Pool"
            id="djcity.common.record_pool"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            <FormattedMessage
              defaultMessage="Track Updates"
              id="djcity.common.trackUpdates"
            />
            <Popover
              className={styles.infoPopover}
              renderTrigger={({ handleClick, ref }) => (
                <button
                  className={classNames('cleanButton')}
                  onClick={handleClick}
                  ref={ref}
                >
                  <FontAwesomeIcon
                    className={styles.infoIcon}
                    icon="info-circle"
                  />
                </button>
              )}
              variant="light"
            >
              <div className={styles.info}>
                <FormattedMessage
                  defaultMessage="We constantly update the quality and versions of tracks available for the songs in our library. Higher quality versions are labeled as re-mastered. For more information, check out the {link} page."
                  id="djcity.records.track_updates.info"
                  values={{
                    link: (
                      <Link target="_blank" to="/faq">
                        <FormattedMessage
                          defaultMessage="record pool FAQ"
                          id="djcity.records.track_updates.info.link"
                        />
                      </Link>
                    ),
                  }}
                />
              </div>
            </Popover>
          </Heading>
        </div>
        <p>
          <FormattedMessage
            defaultMessage="Notifications for new and higher quality versions of previously released tracks"
            id="djcity.records.updated_songs.sub_header"
          />
        </p>
        <Knotch className={styles.knotch} size="big" />
      </div>
      {isLoggedIn && (
        <div className={styles.filters}>
          {isMobile ? (
            <DropdownPopover
              isMobile={isMobile}
              items={{
                false: intl.formatMessage(localizedMessages.all),
                true: intl.formatMessage(localizedMessages.myUpdates),
              }}
              onChange={setMyTracks}
              value={`${myTracks}`}
            />
          ) : (
            <>
              <CategoryButton
                checked={!myTracks}
                className={styles.filter}
                labelText={intl.formatMessage(localizedMessages.all)}
                onClick={() => setMyTracks(false)}
              />
              <CategoryButton
                checked={myTracks}
                className={styles.filter}
                labelText={intl.formatMessage(localizedMessages.myUpdates)}
                onClick={() => setMyTracks(true)}
              />
            </>
          )}
        </div>
      )}
      {content}
    </div>
  )
}

UpdatedSongs.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(UpdatedSongs)
