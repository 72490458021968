import * as playerActions from 'spa/action_creators/player'
import { bindActionCreators } from 'redux'
import {
  completedSongSelector,
  currentSongSelector,
  songSelector,
} from 'spa/selectors/songs'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { loggedInSelector } from 'spa/selectors/users'
import { openModal, SIGNUP_MODAL } from 'spa/action_creators/modals'
import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles.sass'

const localizedMessages = defineMessages({
  playButton: {
    id: 'djcity.common.icons.play.alt',
    defaultMessage: 'Play',
  },
})

const PlayTrackButton = ({
  className,
  completed,
  currentlyPlaying,
  isLoggedIn,
  listIndex,
  numberIndex,
  goToModal,
  pauseCurrentSong,
  playCurrentSong,
  played,
  playing,
  setCurrentSong,
  song,
  trackList,
  intl,
}) => {
  const onClick = useCallback(() => {
    if (!isLoggedIn) {
      goToModal(SIGNUP_MODAL, { isFromPlayButton: true })
    } else if (!currentlyPlaying) {
      setCurrentSong(song, trackList)
      playCurrentSong()
    } else {
      playing ? pauseCurrentSong() : playCurrentSong()
    }
  }, [
    currentlyPlaying,
    setCurrentSong,
    song,
    trackList,
    playing,
    pauseCurrentSong,
    playCurrentSong,
    isLoggedIn,
    goToModal,
  ])

  if (isLoggedIn && (!song || !song.previewUrl)) {
    return null
  }

  const showReplayIcon =
    (currentlyPlaying && !playing && completed) || (!currentlyPlaying && played)

  return (
    <div className={classNames(styles.playButton, className)}>
      <button
        className={classNames('cleanButton', styles.playTrackButton, {
          [styles.current]: currentlyPlaying && playing,
          [styles.number]: (!currentlyPlaying || completed) && numberIndex,
          [styles.pauseEnabled]: currentlyPlaying && playing,
          [styles.playEnabled]: currentlyPlaying && !playing && !completed,
        })}
        data-list-index={listIndex}
        onClick={onClick}
        onKeyUp={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        title={intl.formatMessage(localizedMessages.playButton)}
      >
        {numberIndex && (
          <span className={styles.numberText}>{numberIndex}</span>
        )}
      </button>
      <div
        className={classNames(styles.replay, {
          [styles.hidden]: !showReplayIcon,
        })}
      />
    </div>
  )
}

PlayTrackButton.propTypes = {
  className: PropTypes.string,
  completed: PropTypes.bool.isRequired,
  currentlyPlaying: PropTypes.bool.isRequired,
  goToModal: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  listIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberIndex: PropTypes.number,
  pauseCurrentSong: PropTypes.func.isRequired,
  playCurrentSong: PropTypes.func.isRequired,
  played: PropTypes.bool,
  playing: PropTypes.bool.isRequired,
  setCurrentSong: PropTypes.func.isRequired,
  song: PropTypes.shape({
    rid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    previewUrl: PropTypes.string,
  }),
  songId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  songs: PropTypes.arrayOf(
    PropTypes.shape({
      rid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      previewUrl: PropTypes.string,
    })
  ),
  trackList: PropTypes.string,
}

const mapStateToProps = (state, { songId, songs }) => {
  const song = songSelector(state, { songId })
  const played = !!(
    state.player.played.includes(parseInt(songId, 10)) ||
    get(song, 'meta.lastPlayed')
  )

  const completedSong = completedSongSelector(state)
  const completed = !!(
    completedSong && parseInt(songId) === parseInt(completedSong.rid)
  )

  const currentlyPlayingSong = currentSongSelector(state)
  const currentlyPlaying = !!(
    currentlyPlayingSong &&
    parseInt(songId) === parseInt(currentlyPlayingSong.rid)
  )

  const isLoggedIn = loggedInSelector(state)

  const playing = state.player.playing

  return {
    completed,
    currentlyPlaying,
    isLoggedIn,
    played,
    playing,
    song,
    songs,
  }
}

const mapDispatchToProps = dispatch => {
  const {
    setCurrentSong,
    playCurrentSong,
    pauseCurrentSong,
  } = bindActionCreators(playerActions, dispatch)

  const goToModal = bindActionCreators(openModal, dispatch)

  return {
    setCurrentSong,
    playCurrentSong,
    pauseCurrentSong,
    goToModal,
  }
}

const ConnectedPlayTrackButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PlayTrackButton))

export { ConnectedPlayTrackButton as PlayTrackButton }
