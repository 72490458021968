import {
  CTYPES,
  getTrackTypeFromTypeValue,
  MAX_BPM,
  MIN_BPM,
  TYPES,
} from '../filters'
import flatten from 'lodash/flatten'
import intersection from 'lodash/intersection'
import union from 'lodash/union'

function matchesGenre(song, genreTags) {
  if (!genreTags || genreTags.length === 0) {
    return true
  }
  return genreTags.indexOf(song.categoryId) >= 0
}

function matchesType(song, types) {
  if (!types.length || types[0] === TYPES.ALL) {
    return true
  }
  return intersection(
    union(flatten(types.map(type => getTrackTypeFromTypeValue(type)))),
    song.types.map(t => t.ttid)
  ).length
}

function matchesCtype(song, ctypes) {
  return (
    !ctypes.length ||
    ctypes.includes(CTYPES.ALL) ||
    (ctypes.includes(CTYPES.REMIXES) &&
      (song.title.includes(' - ') ||
        song.title.includes('mix') ||
        song.title.includes('edit'))) ||
    (ctypes.includes(CTYPES.ORIGINAL) &&
      !(
        song.title.includes(' - ') ||
        song.title.includes('mix') ||
        song.title.includes('edit')
      ))
  )
}

function matchesBpm(song, bpmgt, bpmlt) {
  return song.bpm >= bpmgt && song.bpm <= bpmlt
}

function matchesKeys(song, keys) {
  return keys.length === 0 || keys.indexOf(song.key) >= 0
}

export function applyFilters(songs, filters) {
  return songs.filter(song => {
    return (
      matchesGenre(song, filters.genres) &&
      matchesType(song, filters.types) &&
      matchesCtype(song, filters.ctypes) &&
      matchesBpm(
        song,
        filters.bpmgt ? filters.bpmgt : MIN_BPM,
        filters.bpmlt ? filters.bpmlt : MAX_BPM
      ) &&
      matchesKeys(song, filters.keys)
    )
  })
}
