import {
  CLEAR_EXCLUSIVE_SONGS,
  RECEIVE_EXCLUSIVES_SONGS,
  REQUEST_EXCLUSIVES_SONGS,
} from '../action_constants'
import {
  defaultExclusivesPagination,
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  getExclusives,
} from '../api/songs'
import { SORT_OPTIONS } from '../components/filters'

import { createSongsListActionCreator } from './create_songs_list_action_creator'

export const fetchExclusivesSongs = createSongsListActionCreator({
  defaultFilters,
  defaultPagination: defaultExclusivesPagination,
  defaultSortBy: SORT_OPTIONS.POPULARITY,
  requestActionConstant: REQUEST_EXCLUSIVES_SONGS,
  receiveActionConstant: RECEIVE_EXCLUSIVES_SONGS,
  apiMethod: getExclusives,
  reducerName: 'exclusivesSongs',
  clearActionConstant: CLEAR_EXCLUSIVE_SONGS,
})

export function clearSongList() {
  return {
    type: CLEAR_EXCLUSIVE_SONGS,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}
