import { fetchWithHeaders, responseData } from '../shared/utils'

export function fetchUserFeatures(userId) {
  return fetchWithHeaders(`/users/${userId}/features`, {
    method: 'GET',
  })
    .then(responseData)
    .catch(err => {
      return err
    })
}
