import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ToggleButton from 'spa/components/button/toggle_button'

import styles from './styles'

const TogglePanel = ({
  buttonClass,
  items,
  onChange,
  showSeparator = false,
  value,
}) => {
  const onSelectionMade = onChange
    ? (selectedValue, checked) => {
        if (!checked && selectedValue !== value) {
          onChange(selectedValue)
        }
      }
    : null

  const buttons = items.map(item => (
    <ToggleButton
      checked={value === item.value}
      className={classNames(styles.toggleButton, buttonClass)}
      key={item.value}
      labelText={item.label}
      onChange={onSelectionMade}
      value={item.value}
    />
  ))
  return (
    <div className={styles.togglePanel}>
      {buttons}
      {showSeparator && <div className={styles.separator} />}
    </div>
  )
}

TogglePanel.propTypes = {
  buttonClass: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  showSeparator: PropTypes.bool,
  value: PropTypes.any.isRequired,
}

export { TogglePanel }
