import * as songsApi from '../api/songs'
import { RECEIVE_TAGS, REQUEST_TAG, REQUEST_TAGS } from '../action_constants'

function receiveTag(tag) {
  return {
    type: REQUEST_TAG,
    tag,
  }
}

function requestTag() {
  return {
    type: REQUEST_TAG,
  }
}

export function fetchTag(id) {
  return function(dispatch) {
    dispatch(requestTag())
    return songsApi.tag(id).then(data => {
      dispatch(receiveTag(data.tag))
    })
  }
}

function receiveTags(tags) {
  return {
    type: RECEIVE_TAGS,
    tags,
  }
}

function requestTags() {
  return {
    type: REQUEST_TAGS,
  }
}

export function fetchTags() {
  return function(dispatch, getState) {
    const tagsState = getState().tags
    // Check to see if tags are already being loaded by another component
    if (tagsState.isLoading) {
      return
    }
    dispatch(requestTags())
    return songsApi.tags().then(tags => {
      dispatch(receiveTags(tags))
    })
  }
}
