import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const MenuButtonIcon = ({ className = null, isOpen = false }) => {
  const points = isOpen ? '0,50 100,50 50,0' : '0,0 100,0 50,50'

  return (
    <svg
      className={classNames(styles.menuButtonIcon, className)}
      viewBox="0 0 100 50"
    >
      <polyline points={points} />
    </svg>
  )
}

MenuButtonIcon.displayName = 'FilterButtonIcon'

MenuButtonIcon.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
}
