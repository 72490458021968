import { MenuButtonIcon } from 'spa/components/menu_button_icon'
import { NavMenuTrigger } from './nav_menu_trigger'
import { Wrapper } from 'react-aria-menubutton'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import styles from './styles'

export const NavMenu = ({
  id,
  className,
  title,
  disableChildMenu = false,
  children,
}) => {
  const cls = classNames(styles.navMenu, className)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = ({ isOpen }) => {
    setIsOpen(isOpen)
  }

  return (
    <Wrapper className={cls} id={id} onMenuToggle={handleOpen}>
      {!disableChildMenu && (
        <NavMenuTrigger>
          {title}
          <MenuButtonIcon isOpen={isOpen} />
        </NavMenuTrigger>
      )}
      {children}
    </Wrapper>
  )
}

NavMenu.displayName = 'NavMenu'

NavMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disableChildMenu: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
}
