import {
  defaultFilters,
  defaultGenresPagination,
  defaultPagination,
  defaultSortBy,
  getAll,
} from '../api/songs'
import { RECEIVE_SONGS_BY_TAG, REQUEST_SONGS_BY_TAG } from '../action_constants'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListActionCreator } from './create_songs_list_action_creator'

export const fetchTagSongs = createSongsListActionCreator({
  defaultFilters: defaultFilters,
  defaultPagination: defaultGenresPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_SONGS_BY_TAG,
  receiveActionConstant: RECEIVE_SONGS_BY_TAG,
  apiMethod: getAll,
  reducerName: 'tagSongs',
})

export function clearSongList() {
  return {
    type: CLEAR_SONGS_LIST,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}
