import { ActionSearch } from '../action_buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextHighlight } from 'spa/components/typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import styles from './styles.sass'

export function SearchBox({
  className,
  placeholder,
  onChange,
  onSubmit,
  onFocus,
  onBlur,
  onSuggestionSelected,
  value = '',
  reset,
  showActions = false,
  suggestions,
  isFocused = false,
  inputRef,
  id = '',
}) {
  // Stores the index of the item that's been highlighted
  // because of uses typing up/down
  const [highlightIndex, setHighlightIndex] = useState(null)
  let highlightSection = 0
  let highlightSuggestion = null
  if (suggestions && highlightIndex !== null && highlightIndex >= 0) {
    let count = highlightIndex
    for (let i = 0; i < suggestions.length; i++) {
      const section = suggestions[i]
      if (section.suggestions.length > count) {
        highlightSuggestion = count
        break
      } else {
        count -= section.suggestions.length
        highlightSection += 1
      }
    }
  }

  // Stores a reference to the sugestion box
  const suggestionBoxRef = useRef()

  // Stores a reference to the sugestion that's been highlit
  const litSuggestionRef = useRef()

  // If the highlit suggestion changed, we must scroll into it
  useEffect(() => {
    const sb = suggestionBoxRef.current
    const ls = litSuggestionRef.current
    if (sb && ls) {
      const sbTop = sb.getBoundingClientRect().top
      const lsTop = ls.getBoundingClientRect().top
      requestAnimationFrame(() => {
        sb.scrollTo({
          top: sb.scrollTop + lsTop - sbTop - 5,
          behavior: 'smooth',
        })
      })
    }
  }, [highlightIndex])

  // If the user tried to select an out-of-bounds section (because the
  // suggestions changed or the user typed too much ups or downs)
  // we must clear the highlight selection
  useEffect(() => {
    if (
      !suggestions ||
      highlightSection >= suggestions.length ||
      highlightIndex < 0
    ) {
      setHighlightIndex(null)
    }
  }, [suggestions, highlightSection, highlightIndex])

  // What to do when the user hits "Enter" (tries to submit)
  const handleSubmit = e => {
    e.preventDefault()

    if (suggestions && highlightSuggestion !== null && onSuggestionSelected) {
      onSuggestionSelected(
        suggestions[highlightSection].suggestions[highlightSuggestion]
      )
    } else if (onSubmit) {
      onSubmit(value)
    }
  }

  // What to do when the user hits "up" or "down" when the input box
  // is focused
  const handleKeyDown = e => {
    switch (e.keyCode) {
      // Down arrow
      case 40:
        if (highlightIndex !== null) {
          setHighlightIndex(highlightIndex + 1)
        } else {
          setHighlightIndex(0)
        }
        break

      // Up arrow
      case 38:
        if (highlightIndex !== null) {
          setHighlightIndex(highlightIndex - 1)
        }
        break

      default:
        break
    }
  }

  // Builds the suggestion box, if necessary
  let suggestionBox = null
  if (suggestions && suggestions.length) {
    const pattern = value
      .toLowerCase()
      .trim()
      .replace(/\s+/g, ' ')

    const suggestionNodes = []
    suggestions.forEach((section, i) => {
      if (section.title) {
        suggestionNodes.push(
          <div className={styles.suggestionTitle} key={section.title}>
            {section.title}
          </div>
        )
      }
      section.suggestions.forEach((suggestion, j) => {
        let isHighlighted = i === highlightSection && j === highlightSuggestion

        suggestionNodes.push(
          <div
            className={styles.suggestion}
            onMouseDown={() => {
              onSuggestionSelected && onSuggestionSelected(suggestion)
            }}
            ref={isHighlighted ? litSuggestionRef : null}
          >
            <TextHighlight
              pattern={pattern}
              variant={isHighlighted ? 'bold' : 'default'}
            >
              {suggestion.value}
            </TextHighlight>
          </div>
        )
      })
    })

    suggestionBox = (
      <div className={styles.suggestionBox} ref={suggestionBoxRef}>
        {suggestionNodes}
      </div>
    )
  }

  if (isFocused && value === '' && showActions) {
    suggestionBox = (
      <div className={styles.suggestionBox} ref={suggestionBoxRef}>
        <ActionSearch />
      </div>
    )
  }

  return (
    <div className={classNames(styles.searchBox, className)} id={id}>
      <form onSubmit={handleSubmit}>
        <FontAwesomeIcon icon="search" />
        <input
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={value}
        />
        {reset && <FontAwesomeIcon icon="times" onClick={reset} />}
      </form>
      {suggestionBox}
    </div>
  )
}

SearchBox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.any }),
  isFocused: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  placeholder: PropTypes.string,
  reset: PropTypes.func,
  showActions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      suggestions: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
        })
      ),
    })
  ),
  value: PropTypes.string,
}
