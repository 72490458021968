// Brand Icons
import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'

// Light Icons
import {
  faInfoCircle as faInfoCircleLight,
  faList,
  faPlayCircle as faPlayCircleLight,
  faPlay as faPlayLight,
  faTimesCircle as faTimesCircleLight,
  faTimes as faTimesLight,
  faVolumeDown as faVolumeDownLight,
  faVolume as faVolumeLight,
  faVolumeMute as faVolumeMuteLight,
} from '@fortawesome/pro-light-svg-icons'

// Solid Icons
import {
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowRight,
  faBars,
  faCalendar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCompactDisc,
  faCreditCard,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationCircle,
  faFastBackward,
  faFastForward,
  faFileInvoice,
  faFire,
  faInfoCircle,
  faListAlt,
  faListUl,
  faMinus,
  faMusic,
  faPause,
  faPauseCircle,
  faPlay,
  faPlayCircle,
  faPlus,
  faPrint,
  faSearch,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faStepBackward,
  faStepForward,
  faSync,
  faTags,
  faTimes,
  faTimesCircle,
  faUserCircle,
  faVolume,
  faVolumeDown,
  faVolumeMute,
} from '@fortawesome/pro-solid-svg-icons'

// Regular Icons
import {
  faArchive as faArchiveRegular,
  faCircle as faCircleRegular,
  faDesktop,
  faFastBackward as faFastBackwardRegular,
  faFastForward as faFastForwardRegular,
  faFileAlt,
  faPauseCircle as faPauseCircleRegular,
  faPause as faPauseRegular,
  faPlayCircle as faPlayCircleRegular,
  faPlay as faPlayRegular,
  faShoppingCart,
  faSquare,
  faStar as faStarRegular,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
  faAngleLeft,
  faAngleRight,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faListAlt,
  faPauseCircle,
  faPlayCircle,
  faPlus,
  faMinus,
  faCalendar,
  faFire,
  faListUl,
  faTags,
  faSearch,
  faSort,
  faStar,
  faStepBackward,
  faStepForward,
  faStarRegular,
  faUserCircle,
  faTimes,
  faBars,
  faSync,
  faCircle,
  faCircleRegular,
  faCreditCard,
  faFileInvoice,
  faPrint,
  faSortDown,
  faSortUp,
  faCheck,
  faEllipsisH,
  faEllipsisV,
  faCompactDisc,
  faMusic,
  faArchive,
  faExclamationCircle,
  faCheckCircle,
  faTimesCircle,
  faFileAlt,
  faDesktop,
  faTrash,
  faArchiveRegular,
  faShoppingCart,
  faSquare,
  faPauseCircleRegular,
  faPlayCircleRegular,
  faList,
  faCheckSquare,
  faPause,
  faPlay,
  faArrowRight,
  faFastBackward,
  faFastBackwardRegular,
  faFastForward,
  faFastForwardRegular,
  faInfoCircle,
  faPauseRegular,
  faPlayRegular,
  faVolume,
  faVolumeDown,
  faVolumeMute,
  faVolumeMute,
  faSlidersH,
  faCircleNotch,
  faPlayLight,
  faInfoCircleLight,
  faPlayCircleLight,
  faTimesCircleLight,
  faTimesLight,
  faVolumeLight,
  faVolumeDownLight,
  faVolumeMuteLight
)
