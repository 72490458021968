import * as currentUserActions from '../../action_creators/current_user'
import { defineMessages, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Logo } from '../logo'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import djcIcon from 'spa/images/svg/djc_logo_icon.svg'
import MobileQr from '../mobile_qr'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  allSet: {
    id: 'djcity.billing.payment.allset',
    defaultMessage: "YOU'RE ALL SET!",
  },
  startBrowsing: {
    id: 'djcity.billing.payment.startbrowsing',
    defaultMessage: 'Start Browsing',
  },
  unlimitedAccess: {
    id: 'djcity.billing.payment.unlimitedaccess',
    defaultMessage:
      "You've got unlimited access to a massive catalogue of tracks until {date}",
  },
  massiveCatalogue: {
    id: 'djcity.billing.payment.massivecatalogue',
    defaultMessage:
      "You've got unlimited access to a massive catalogue of tracks.",
  },
  receiptEmailed: {
    id: 'djcity.billing.payment.receiptemailed',
    defaultMessage: "We've emailed you the receipt.",
  },
  checkMarkAlt: {
    id: 'djcity.common.images.payment.checkmark.alt',
    defaultMessage: 'Check Mark',
  },
  title: {
    id: 'djcity.billing.payment.paymentconfirmation.title',
    defaultMessage: 'Payment Confirmed | DJcity',
  },
})

export const PaymentConfirmation = injectIntl(({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(currentUserActions.fetchCurrentUser())
  }, [])

  return (
    <div className={styles.paymentModal}>
      <header>
        <Logo className={styles.logo} darkMode />
        <FontAwesomeIcon
          className={styles.closeButton}
          icon={['fal', 'times-circle']}
          onClick={() => {
            return history.push('/')
          }}
        />
      </header>
      <Helmet defer={false}>
        <title key="pagetitle">
          {intl.formatMessage(localizedMessages.title)}
        </title>
      </Helmet>
      <div className={styles.header}>
        <img src={djcIcon} />
        {intl.formatMessage(localizedMessages.allSet)}
      </div>
      <div className={styles.container}>
        <div className={styles.title}>
          {intl.formatMessage(localizedMessages.massiveCatalogue)}
        </div>
        <div className={styles.subtitle}>
          {intl.formatMessage(localizedMessages.receiptEmailed)}
        </div>
        <MobileQr showMessage={true} />
        <div className={styles.separator}></div>
        <Link className={classNames('button', styles.browseButton)} to="/">
          {intl.formatMessage(localizedMessages.startBrowsing)}
        </Link>
      </div>
    </div>
  )
})

PaymentConfirmation.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}
