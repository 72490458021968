import {
  clearQueue,
  completeSong,
  pauseCurrentSong,
  playCurrentSong,
  removeSongFromQueue,
  selectNextSong,
  selectPrevSong,
} from '../../action_creators/player'
import { DEFAULT_SCRUB_TIME } from '../../shared/utils'
import { defineMessages, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatTime } from '../../shared/utils'
import { isMobileSelector } from '../../selectors/device'
import { Link } from '../link'
import { linkifyArtistNames, makeSongUrl } from 'spa/urls'
import { openModal, PLAYBACK_ERROR_MODAL } from '../../action_creators/modals'
import { QueuePopover } from './queue_popover'
import { setVolume } from '../../action_creators/user_preferences'
import { Shortcuts, VolumeController } from '../track_controllers'
import { Switch } from '../switch'
import { TablePopover } from '../table/table_popover'
import { TextStyle } from '../typography'
import { toggleAutoplay } from '../../action_creators/user_preferences'
import {
  TrackDropdownTable,
  TrackDropdownTableAllTracksRow,
  TrackDropdownTableRow,
} from '../track_dropdown_table'
import { useDispatch, useSelector } from 'react-redux'
import AudioWave from 'spa/components/audio_wave'
import classNames from 'classnames'
import Hotkeys from 'react-hot-keys'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  versions: {
    id: 'djcity.records.queue.player.versions',
    defaultMessage: 'versions',
  },
  version: {
    id: 'djcity.records.queue.player.version',
    defaultMessage: 'version',
  },
  autoplay: {
    id: 'djcity.records.queue.player.autoplay',
    defaultMessage: 'Autoplay',
  },
  autoplayInfo: {
    id: 'djcity.records.queue.player.autoplay.tooltip',
    defaultMessage:
      'Automatically queue up songs in the preview player from the current track list',
  },
})

const Player = ({ intl }) => {
  // Dispatch
  const dispatch = useDispatch()

  // Selectors
  const isMobile = useSelector(isMobileSelector)
  const currentSong = useSelector(
    state => state.songs.songsById[state.player.currentSongId]
  )
  const previewUrl = currentSong && currentSong.previewUrl
  const { autoplay } = useSelector(state => state.userPreferences)
  const { queue, playing: currentlyPlaying } = useSelector(
    state => state.player
  )

  const songsData = queue ? queue.map(s => s.data) : null

  // State Management
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(null)
  const versionCount = currentSong && currentSong.types.length

  const currentSongIdx =
    currentSong && queue
      ? queue.findIndex(
          song => parseInt(song?.id) === parseInt(currentSong.rid)
        )
      : null
  const [playBlocked, setPlayBlocked] = useState(false)

  // Reference to the audio node
  const audioRef = useRef()

  // Set up the audio events
  useEffect(() => {
    const a = audioRef.current
    if (!a) {
      return
    }

    const onDurationChange = () => setDuration(a.duration || null)
    const onVolumeChange = () => dispatch(setVolume(a.volume))
    const onPlayheadMove = () => setCurrentTime(Math.round(a.currentTime))
    const onPause = () => dispatch(pauseCurrentSong())
    const onPlay = () => dispatch(playCurrentSong())
    const onComplete = () => {
      dispatch(completeSong())
      if (
        queue &&
        queue.length &&
        parseInt(queue[queue.length - 1].data.rid) !== parseInt(currentSong.rid)
      ) {
        dispatch(selectNextSong())
        dispatch(playCurrentSong())
      }
    }

    a.addEventListener('durationchange', onDurationChange)
    a.addEventListener('volumechange', onVolumeChange)
    a.addEventListener('timeupdate', onPlayheadMove)
    a.addEventListener('pause', onPause)
    a.addEventListener('play', onPlay)
    a.addEventListener('ended', onComplete)

    return () => {
      a.removeEventListener('durationchange', onDurationChange)
      a.removeEventListener('volumechange', onVolumeChange)
      a.removeEventListener('timeupdate', onPlayheadMove)
      a.removeEventListener('pause', onPause)
      a.removeEventListener('play', onPlay)
      a.removeEventListener('ended', onComplete)
    }
  }, [
    audioRef.current,
    dispatch,
    queue,
    currentSong,
    setCurrentTime,
    setDuration,
  ])

  const handleClickPlayPrevSong = useCallback(() => {
    dispatch(selectPrevSong())
  }, [dispatch])

  const handleClickPlayNextSong = useCallback(() => {
    dispatch(selectNextSong())
  }, [dispatch])

  const handleClickWave = useCallback(
    p => {
      audioRef.current.currentTime = (duration || 0) * p
    },
    [duration]
  )

  // Tries to play the song
  // If it's blocked by the browser, turns on the flag that indicates this
  const play = useCallback(() => {
    if (!audioRef.current) {
      return
    }

    const playPromise = audioRef.current.play()
    if (playPromise) {
      playPromise.catch(e => {
        if (e.name === 'NotAllowedError') {
          setPlayBlocked(true)
        }
      })
    }
  }, [audioRef.current, setPlayBlocked])

  // When the "blocked by the browser" flag is turned on, shows the error modal
  // As soon as the user clicks the screen, the "blocked by the browser"
  // is turned off - the user interacted with the browser, therefore sounds
  // can be played
  useEffect(() => {
    if (!audioRef.current) {
      return
    }

    if (playBlocked) {
      const onUserClick = () => setPlayBlocked(false)
      document.body.addEventListener('mousedown', onUserClick)
      dispatch(openModal(PLAYBACK_ERROR_MODAL))

      return () => document.body.removeEventListener('mousedown', onUserClick)
    }
  }, [audioRef.current, dispatch, playBlocked, setPlayBlocked, play])

  // The effect that plays the song as a response to state change
  useEffect(() => {
    if (!audioRef.current) {
      return
    }

    if (previewUrl && currentlyPlaying && !playBlocked) {
      play()
    }
  }, [audioRef.current, previewUrl, currentlyPlaying, queue, playBlocked, play])

  // The effect that pauses the song as a response to state change
  useEffect(() => {
    if (!audioRef.current) {
      return
    }

    if (!currentlyPlaying) {
      audioRef.current.pause()
    }
  }, [audioRef.current, currentlyPlaying])

  // When the current song changes, we reset the playhead
  useEffect(() => {
    setCurrentTime(0)
  }, [previewUrl, setCurrentTime])

  const handleClickPlayPauseButton = useCallback(() => {
    if (!audioRef.current) {
      return
    }
    if (audioRef.current.paused) {
      play()
    } else {
      audioRef.current.pause()
    }
  }, [audioRef.current, play, previewUrl])

  const clearPlayerQueue = useCallback(() => dispatch(clearQueue()), [dispatch])

  const removeSong = useCallback(song => dispatch(removeSongFromQueue(song)), [
    dispatch,
  ])

  const handleAutoplay = useCallback(() => dispatch(toggleAutoplay()), [
    dispatch,
  ])

  // Keyboard events
  const onKeyDown = useCallback(
    (keyName, e) => {
      e.stopPropagation()
      e.preventDefault()
      if (!audioRef.current) {
        return
      }

      switch (keyName) {
        case 'space':
          handleClickPlayPauseButton()
          break
        case 'shift+up':
          audioRef.current.volume = Math.min(audioRef.current.volume + 0.1, 1)
          break
        case 'shift+down':
          audioRef.current.volume = Math.max(audioRef.current.volume - 0.1, 0)
          break
        case 'shift+left':
          if (currentSongIdx !== 0) {
            handleClickPlayPrevSong()
          }
          break
        case 'shift+right':
          if (currentSongIdx !== queue.length - 1) {
            handleClickPlayNextSong()
          }
          break
        case 'right':
          audioRef.current.currentTime = Math.min(
            currentTime + DEFAULT_SCRUB_TIME,
            duration
          )
          break
        case 'left':
          audioRef.current.currentTime = Math.max(
            currentTime - DEFAULT_SCRUB_TIME,
            0
          )
          break
        default:
          break
      }
    },
    [
      currentSongIdx,
      currentTime,
      audioRef.current,
      queue,
      handleClickPlayPauseButton,
    ]
  )

  if (!currentSong) {
    return null
  }

  return queue.length ? (
    <Hotkeys
      keyName="space,shift+up,shift+down,shift+left,shift+right,right,left,1,2,3,4,5"
      onKeyDown={onKeyDown}
    >
      <div
        className={classNames(styles.player, {
          [styles.responsive]: isMobile,
        })}
        id="player"
      >
        <audio ref={audioRef} src={previewUrl} />
        <div className={styles.playerContent}>
          <div className={styles.songContainer}>
            <QueuePopover
              clearQueue={clearPlayerQueue}
              removeSongFromQueue={removeSong}
              songIdx={currentSongIdx}
              songs={songsData}
            />
            <div className={styles.songInfo}>
              <Link to={makeSongUrl(currentSong)}>
                <TextStyle
                  color="white"
                  title={currentSong.title}
                  truncate
                  variant="bold"
                >
                  {currentSong.title}
                </TextStyle>
              </Link>
              <TextStyle
                className={classNames('artist-names', styles.artist)}
                color="grey"
                tagName="p"
                title={`${currentSong.artist} ${currentSong.featuring || ''}`}
                truncate
              >
                {linkifyArtistNames(currentSong)}
              </TextStyle>
            </div>
          </div>
          {!isMobile && (
            <TablePopover
              buttonIcon="ellipsis-h"
              buttonText={
                <TextStyle
                  className={styles.versionCount}
                  color="grey"
                  tagName="span"
                >
                  {versionCount}{' '}
                  {versionCount > 1
                    ? ` ${intl.formatMessage(localizeMessages.versions)}`
                    : `${intl.formatMessage(localizeMessages.version)}`}
                </TextStyle>
              }
              caretColor="white"
              className={styles.buttonIcon}
              contentClassName={styles.tablePopoverContent}
            >
              <TrackDropdownTable className={styles.versionButton}>
                {currentSong.types.map(track => {
                  return (
                    <TrackDropdownTableRow
                      key={'track_' + track.tid}
                      songId={currentSong.rid}
                      track={track}
                    />
                  )
                })}
                <TrackDropdownTableAllTracksRow
                  songId={currentSong.rid}
                  tracks={currentSong.types}
                />
              </TrackDropdownTable>
            </TablePopover>
          )}

          <div className={styles.waveform}>
            <TextStyle className={styles.time} color="white" variant="bold">
              {formatTime(currentTime)}
            </TextStyle>
            <AudioWave
              className={styles.waveformContainer}
              onClick={handleClickWave}
              progress={duration ? currentTime / duration : 0}
              songUrl={previewUrl}
            />
            <TextStyle className={styles.duration} color="white" variant="bold">
              {duration ? formatTime(duration) : null}
            </TextStyle>
          </div>
          <Switch
            className={styles.autoplay}
            isActive={autoplay}
            labelText={
              <div className={styles.autoplayInfo}>
                <span>{intl.formatMessage(localizeMessages.autoplay)}</span>
              </div>
            }
            onClick={handleAutoplay}
          />
          {!isMobile && <Shortcuts />}
          {!isMobile && <VolumeController audioRef={audioRef} />}

          <div className={styles.buttonWrapper}>
            <button
              className={classNames('cleanButton', styles.playerButton, {
                inactive: currentSongIdx === 0,
              })}
              onClick={currentSongIdx > 0 ? handleClickPlayPrevSong : null}
            >
              <FontAwesomeIcon icon={['far', 'fast-backward']} />
            </button>
            <button
              className={classNames(
                'cleanButton',
                styles.playerPlayPauseButton
              )}
              onClick={handleClickPlayPauseButton}
              onKeyUp={e => e.preventDefault()}
            >
              <FontAwesomeIcon
                icon={['fas', currentlyPlaying ? 'pause' : 'play']}
              />
            </button>
            <button
              className={classNames('cleanButton', styles.playerButton, {
                inactive: currentSongIdx === queue.length - 1,
              })}
              onClick={
                currentSongIdx < queue.length - 1
                  ? handleClickPlayNextSong
                  : null
              }
            >
              <FontAwesomeIcon icon={['far', 'fast-forward']} />
            </button>
          </div>
        </div>
      </div>
    </Hotkeys>
  ) : null
}

Player.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(Player)
