import { Layout } from '../layout'
import { ModuleLoader } from '../module_loader/module_loader'
import React from 'react'
const Marketing = React.lazy(() => import('../pages/marketing/marketing'))
const LoginView = React.lazy(() => import('./login_view'))

const display = () => {
  return (
    <Layout fullLayout tabTitle="login">
      <ModuleLoader>
        <Marketing />
        <LoginView />
      </ModuleLoader>
    </Layout>
  )
}

export { display as LoginDisplay }
