import { Browse } from 'spa/components/browse'
import { Layout, Sidebar } from 'spa/components/layout'
import React from 'react'
import TopDownloads from './top_downloads'

const display = () => {
  return (
    <Layout tabTitle="monthly_charts">
      <Sidebar>
        <Browse />
      </Sidebar>
      <TopDownloads />
    </Layout>
  )
}

export { display as TopDownloadsDisplay }

export { TopDownloads }

export default TopDownloads
