import { AUTO_DISMISS_SECONDS, NOTIFICATION_MESSAGES } from '../shared/utils'
import { clearNewReleases } from '../reducers/new_songs'
import { error, success } from 'react-notification-system-redux'
import { fetchWithHeaders, parseResponse } from '../shared/utils'
import { intl } from '../shared/IntlGlobalProvider'
import { RECEIVE_PREFERENCES, REQUEST_PREFERENCES } from '../action_constants'

const successNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.yourPreferencesTitle),
    message: intl.formatMessage(
      NOTIFICATION_MESSAGES.yourPreferencesActionSuccess
    ),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const errorNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.yourPreferencesTitle),
    message: intl.formatMessage(
      NOTIFICATION_MESSAGES.yourPreferencesActionError
    ),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

export const requestPreferences = () => {
  return {
    type: REQUEST_PREFERENCES,
  }
}

export const receivePreferences = preferences => {
  return {
    type: RECEIVE_PREFERENCES,
    preferences,
  }
}

export const getPreferences = () => dispatch => {
  dispatch(requestPreferences())
  return fetchWithHeaders('/users/preferences', {
    method: 'GET',
  })
    .then(parseResponse)
    .then(res => res.data)
    .then(data => dispatch(receivePreferences(data)))
    .catch(err =>
      dispatch(
        error({
          ...errorNotification(intl),
          message: err.message,
        })
      )
    )
}

export const selectPreference = preferences => dispatch => {
  return fetchWithHeaders('/users/preferences', {
    method: 'PUT',
    body: JSON.stringify(preferences),
  })
    .then(parseResponse)
    .then(res => res.data)
    .then(data => dispatch(receivePreferences(data)))
    .then(() => dispatch(clearNewReleases())) // Clear new releases because preference dependent
    .then(() => dispatch(getPreferences()))
    .then(() => dispatch(success(successNotification(intl))))
    .catch(err =>
      dispatch(
        error({
          ...errorNotification(intl),
          message: err.message,
        })
      )
    )
}
