import {
  RECEIVE_BANNER_ASSETS,
  REQUEST_BANNER_ASSETS,
} from '../action_constants'

const defaultState = {
  isFetching: false,
  models: [],
}

export default function bannerAssets(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_BANNER_ASSETS:
      return { ...state, isFetching: true }
    case RECEIVE_BANNER_ASSETS:
      return { ...state, isFetching: false, models: action.models }
    default:
      return state
  }
}
