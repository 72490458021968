import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Link } from 'spa/components/link'
import { loggedInSelector } from 'spa/selectors/users'
import { logout } from 'spa/action_creators/current_user'
import { MenuButtonIcon } from 'spa/components/menu_button_icon'
import { Popover } from 'spa/components/popover'
import { TextStyle } from 'spa/components/typography'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

export const AccountPopover = ({ className = null }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.currentUser.user)
  const isLoggedIn = useSelector(loggedInSelector)

  const onLogoutClick = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  if (!isLoggedIn) {
    return <div className={className} />
  }

  return (
    <Popover
      className={classNames(styles.accountPopover, className)}
      closeOnClick
      contentClassName={styles.accountPopoverContent}
      renderTrigger={({ handleClick, open, ref }) => (
        <button
          className={classNames(
            'cleanButton',
            'simple',
            styles.subMenuAccountBtn
          )}
          onClick={handleClick}
          ref={ref}
        >
          <div className={styles.userAvatar}>
            <FontAwesomeIcon icon="user-circle" />
          </div>
          <TextStyle className={styles.userName} color="white" variant="label">
            {user.firstName}
          </TextStyle>
          <MenuButtonIcon isOpen={open} />
        </button>
      )}
    >
      <ul>
        <li className={styles.accountPopoverItem}>
          <Link to="/account">
            <TextStyle
              color={location.pathname === '/account' ? 'red' : 'black'}
              variant="bold"
            >
              <FormattedMessage
                defaultMessage="Your Account"
                id="djcity.common.navigation.yourAccount"
              />
            </TextStyle>
          </Link>
        </li>
        <li className={styles.accountPopoverItem}>
          <Link to="/account/billing">
            <TextStyle
              color={location.pathname === '/account/billing' ? 'red' : 'black'}
              variant="bold"
            >
              <FormattedMessage
                defaultMessage="Subscriptions"
                id="djcity.common.navigation.subscriptions"
              />
            </TextStyle>
          </Link>
        </li>
        <li className={styles.accountPopoverItem}>
          <button className="cleanButton" onClick={onLogoutClick}>
            <TextStyle color="black" variant="bold">
              <FormattedMessage
                defaultMessage="Log Out"
                id="djcity.common.navigation.logout"
              />
            </TextStyle>
          </button>
        </li>
      </ul>
    </Popover>
  )
}

AccountPopover.propTypes = {
  className: PropTypes.string,
}
