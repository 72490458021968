import { TextStyle } from 'spa/components/typography/text_style'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const ActionConfirm = ({
  primaryAction,
  secondaryAction,
  title,
  primaryActionText,
  secondaryActionText,
}) => {
  return (
    <div className={classNames(styles.center, styles.questionTextWrapper)}>
      <TextStyle className={styles.headerText} uppercase>
        {title}
      </TextStyle>
      <button className={styles.cancelButton} onClick={primaryAction}>
        {primaryActionText}
      </button>
      {secondaryAction && (
        <button
          className={classNames('secondary', styles.clearButton)}
          onClick={secondaryAction}
        >
          {secondaryActionText}
        </button>
      )}
    </div>
  )
}

ActionConfirm.propTypes = {
  primaryAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  title: PropTypes.string,
}

export default ActionConfirm
