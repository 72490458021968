import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const PopoverHeading = ({ className, children, separator }) => {
  const cls = classNames(styles.popoverHeading, className)
  return (
    <div className={cls}>
      {separator && <span className={styles.headingSeparator} />}
      {children}
    </div>
  )
}

PopoverHeading.displayName = 'PopoverHeading'

PopoverHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  separator: PropTypes.bool,
}
