import {
  RECEIVE_POSTS_ERROR,
  RECEIVE_POSTS_SUCCESS,
  REQUEST_POSTS,
} from '../action_constants'
import keyBy from 'lodash/keyBy'

const defaultState = {
  isFetching: false,
  items: [],
}

export default function posts(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_POSTS:
      return { ...state, isFetching: true }
    case RECEIVE_POSTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: keyBy(action.items, 'url'),
      }
    case RECEIVE_POSTS_ERROR:
      return { ...state, isFetching: false, error: action.error }
    default:
      return state
  }
}
