import {
  addSongToQueue as addSongToQueueAction,
  removeSongFromQueue as removeSongFromQueueAction,
} from '../../../action_creators/player'
import {
  clearSongList as clearHotBox,
  fetchHotBoxSongs,
} from 'spa/action_creators/hot_box_songs'
import { fetchExclusivesSongs } from 'spa/action_creators/exclusives_songs'
import { FormattedMessage } from 'react-intl'
import {
  hotBoxSongsSelector,
  queuedSongsSelector,
  topExclusivesSongsSelector,
} from 'spa/selectors/songs'
import { Knotch } from 'spa/components/knotch'
import { Link } from 'react-router-dom'
import { Loading } from 'spa/components/loading'
import { loggedInSelector } from 'spa/selectors/users'
import { Sidebar } from 'spa/components/layout'
import { SimpleSongsList } from 'spa/components/simple_songs_list'
import { SORT_OPTIONS } from 'spa/components/filters'
import { TextStyle } from 'spa/components/typography'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect } from 'react'
import styles from './styles'

function HomeSidebar() {
  const dispatch = useDispatch()
  const chartSongs = useSelector(hotBoxSongsSelector)
  const exclusiveSongs = useSelector(topExclusivesSongsSelector)
  const queuedSongIds = useSelector(queuedSongsSelector).map(song => song.rid)
  const isLoggedIn = useSelector(loggedInSelector)

  const addSongToQueue = useCallback(
    (song, trackList) => dispatch(addSongToQueueAction(song, trackList)),
    [dispatch]
  )

  const removeSongFromQueue = useCallback(
    song => dispatch(removeSongFromQueueAction(song)),
    [dispatch]
  )

  useEffect(() => {
    dispatch(clearHotBox())
    dispatch(fetchHotBoxSongs())
    dispatch(
      fetchExclusivesSongs({
        filters: { regionId: 0 },
        pagination: { pageSize: 15 },
        sortBy: SORT_OPTIONS.POPULARITY,
      })
    )
  }, [dispatch, isLoggedIn])

  return (
    <Sidebar id="homeSidebar" large>
      <div className={styles.sidebarRow}>
        <TextStyle uppercase variant="extra-bold">
          <Knotch
            align="left"
            className={styles.sidebarKnotch}
            displayType="inlineBlock"
            size="small"
          />
          <Link className={styles.listTitle} to="/most-popular">
            <FormattedMessage
              defaultMessage="Most Popular"
              id="djcity.common.most_popular"
            />
          </Link>
        </TextStyle>
        {!chartSongs || chartSongs.length === 0 ? (
          <Loading />
        ) : (
          <SimpleSongsList
            addSongToQueue={addSongToQueue}
            className={styles.hotBoxSongsList}
            id="mostPopularTable"
            queuedSongIds={queuedSongIds}
            removeSongFromQueue={removeSongFromQueue}
            showNumbers
            songs={chartSongs}
            trackList={TRACKLIST.HOTBOX}
          />
        )}
      </div>
      <div>
        <TextStyle uppercase variant="extra-bold">
          <Knotch
            align="left"
            className={styles.sidebarKnotch}
            displayType="inlineBlock"
            size="small"
          />
          <Link className={styles.listTitle} to="/exclusives">
            <FormattedMessage
              defaultMessage="DJCITY EXCLUSIVES"
              id="djcity.common.dj_exclusives"
            />
          </Link>
        </TextStyle>
        {!exclusiveSongs || exclusiveSongs.length === 0 ? (
          <Loading />
        ) : (
          <SimpleSongsList
            addSongToQueue={addSongToQueue}
            className={styles.hotBoxSongsList}
            queuedSongIds={queuedSongIds}
            removeSongFromQueue={removeSongFromQueue}
            showNumbers
            showTitle={false}
            songs={exclusiveSongs}
            trackList={TRACKLIST.EXCLUSIVES}
          />
        )}
      </div>
    </Sidebar>
  )
}

HomeSidebar.displayName = 'HomeSidebar'

export default HomeSidebar
