import {
  RECEIVE_ADD_BATCH_TO_CRATE,
  RECEIVE_ADD_TO_CRATE,
  RECEIVE_CRATE,
  RECEIVE_DOWNLOAD_TRACK,
  RECEIVE_REMOVE_ALL_FROM_CRATE,
  RECEIVE_REMOVE_BATCH_FROM_CRATE,
  RECEIVE_REMOVE_FROM_CRATE,
  RECEIVE_REMOVE_MULTIPLE_FROM_CRATE,
  RECEIVE_SEND_CRATE_TO_DOWNLOADER,
  REQUEST_ADD_BATCH_TO_CRATE,
  REQUEST_ADD_TO_CRATE,
  REQUEST_CRATE,
  REQUEST_REMOVE_ALL_FROM_CRATE,
  REQUEST_REMOVE_FROM_CRATE,
  REQUEST_REMOVE_MULTIPLE_FROM_CRATE,
  REQUEST_SEND_CRATE_TO_DOWNLOADER,
  RESET_CRATE,
  SET_DOWNLOAD_TRACK_STATUS,
} from '../action_constants'

import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

const defaultState = {
  isFetching: false,
  isLoading: false,
  items: [],
  metadata: {
    totalItems: 0,
    pageSize: 15,
  },
}

function indexTracksToRemove(tracksToRemove) {
  const result = {}
  tracksToRemove.forEach(track => {
    result[track.rid] = result[track.rid] || {}
    result[track.rid][track.ttid] = true
  })

  return result
}

function removeTracks(items, trackIndex) {
  return items.filter(
    item => !get(trackIndex, [item.track.record.rid, item.track.ttid])
  )
}

export default function crate(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_ADD_TO_CRATE:
    case REQUEST_ADD_BATCH_TO_CRATE:
      return { ...state, isSaving: true }
    case RECEIVE_ADD_TO_CRATE:
      return {
        ...state,
        isLoading: false,
        items: [action.item, ...state.items],
        metadata: {
          ...state.metadata,
          totalItems: state.metadata.totalItems + 1,
        },
      }
    case RECEIVE_ADD_BATCH_TO_CRATE:
      return {
        ...state,
        isLoading: false,
        items: [...action.items, ...state.items],
        metadata: {
          ...state.metadata,
          totalItems: state.metadata.totalItems + action.items.length,
        },
      }
    case REQUEST_CRATE:
      return { ...state, isFetching: true }
    case RECEIVE_CRATE:
      return {
        ...state,
        isFetching: false,
        items: uniqBy(state.items.concat(action.items), 'id'),
        metadata: action.metadata,
      }
    case REQUEST_REMOVE_FROM_CRATE:
      return { ...state, isLoading: true }
    case RECEIVE_REMOVE_FROM_CRATE:
      return {
        ...state,
        isLoading: false,
        items: removeTracks(
          state.items,
          indexTracksToRemove([
            {
              rid: action.songId,
              ttid: action.trackId,
            },
          ])
        ),
        metadata: {
          ...state.metadata,
          totalItems: state.metadata.totalItems - 1,
        },
      }
    case RECEIVE_REMOVE_BATCH_FROM_CRATE:
      return {
        ...state,
        isLoading: false,
        items: state.items.filter(
          item =>
            !(
              parseInt(item.track.record.rid) === parseInt(action.songId) &&
              action.trackIds.includes(Number(item.track.ttid))
            )
        ),
        metadata: {
          ...state.metadata,
          totalItems: state.metadata.totalItems - action.trackIds.length,
        },
      }

    case REQUEST_REMOVE_MULTIPLE_FROM_CRATE:
      return { ...state, isLoading: true }
    case RECEIVE_REMOVE_MULTIPLE_FROM_CRATE:
      return {
        ...state,
        isLoading: false,
        items: removeTracks(
          state.items,
          indexTracksToRemove(action.tracksToRemove)
        ),
        metadata: {
          ...state.metadata,
          totalItems: state.metadata.totalItems - action.tracksToRemove.length,
        },
      }
    case REQUEST_REMOVE_ALL_FROM_CRATE:
      return { ...state, isLoading: true }
    case RECEIVE_REMOVE_ALL_FROM_CRATE:
      return { ...defaultState }
    case REQUEST_SEND_CRATE_TO_DOWNLOADER:
      return { ...state, isLoading: true }
    case RECEIVE_SEND_CRATE_TO_DOWNLOADER:
      return {
        ...state,
        isLoading: false,
      }
    case SET_DOWNLOAD_TRACK_STATUS: {
      const index = findIndex(state.items, function(i) {
        return (
          i.track.ttid === action.trackId &&
          parseInt(i.track.record.rid) === parseInt(action.songId)
        )
      })
      return {
        ...state,
        items: state.items.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              track: {
                ...item.track,
                isDownloading: action.isDownloading,
              },
            }
          }
          return item
        }),
      }
    }
    case RECEIVE_DOWNLOAD_TRACK: {
      const index = findIndex(state.items, function(i) {
        return (
          i.track.ttid === action.trackId &&
          parseInt(i.track.record.rid) === parseInt(action.songId)
        )
      })
      return {
        ...state,
        items: state.items.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              track: {
                ...item.track,
                downloadCount: item.track.downloadCount + 1,
                isDownloading: false,
              },
            }
          }
          return item
        }),
      }
    }
    case RESET_CRATE:
      return {
        ...defaultState,
        isLoading: state.isLoading,
        metadata: state.metadata,
      }
    default:
      return state
  }
}
