import { defaultFilters, defaultPagination, defaultSortBy } from '../api/songs'
import {
  RECEIVE_EXCLUSIVES_SONGS,
  REQUEST_EXCLUSIVES_SONGS,
} from '../action_constants'
import { SORT_OPTIONS } from '../components/filters/sort_panel'

import { CLEAR_EXCLUSIVE_SONGS } from '../action_constants'
import { createSongsListReducer } from './create_songs_list_reducer'

export default createSongsListReducer({
  defaultFilters,
  defaultPagination,
  defaultSortBy: SORT_OPTIONS.POPULARITY,
  requestActionConstant: REQUEST_EXCLUSIVES_SONGS,
  receiveActionConstant: RECEIVE_EXCLUSIVES_SONGS,
  clearActionConstant: CLEAR_EXCLUSIVE_SONGS,
})

export function clearSongList() {
  return {
    type: CLEAR_EXCLUSIVE_SONGS,
    filters: defaultFilters,
    sortBy: defaultSortBy,
    pagination: defaultPagination,
  }
}
