import { FilterButtonIcon } from './filter_button_icon'
import { FilterPanel } from './filter_panel'
import { injectIntl } from 'react-intl'
import { Popover } from 'spa/components/popover'
import { RadioButton } from '../radio_button'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

class DropdownPopover extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatDate: PropTypes.func,
      formatMessage: PropTypes.func,
    }).isRequired,
    isMobile: PropTypes.bool,
    items: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    value: PropTypes.string,
  }

  onSelection = value => {
    const { onChange } = this.props
    value = /^\d+$/.test(value) ? parseInt(value) : value
    onChange(value)
  }

  render() {
    const { value, items, isMobile, title } = this.props

    return (
      <Popover
        closeOnScroll={false}
        contentClassName={styles.filterPanel}
        renderTrigger={({ handleClick, open, ref }) => (
          <button
            className={classNames({
              popoverTrigger: !open,
              popoverTriggerOpen: open,
              mobile: isMobile,
            })}
            onClick={handleClick}
            ref={ref}
          >
            {isMobile ? (
              <>
                <FilterButtonIcon isMobile={isMobile} isOpen={open} />{' '}
                {items[value]}
              </>
            ) : (
              <>
                {items[value]} <FilterButtonIcon isOpen={open} />
              </>
            )}
          </button>
        )}
      >
        {!title ? (
          Object.keys(items).map(item => (
            <RadioButton
              checked={value == item}
              className={styles.radioButton}
              id={item}
              key={item}
              labelText={items[item]}
              name="dropdown"
              onChange={e => this.onSelection(e.target.id)}
            />
          ))
        ) : (
          <FilterPanel className={styles.filterPanel} title={title}>
            {Object.keys(items).map(item => (
              <RadioButton
                checked={value == item}
                className={styles.radioButton}
                id={item}
                key={item}
                labelText={items[item]}
                name="dropdown"
                onChange={e => this.onSelection(e.target.id)}
              />
            ))}
          </FilterPanel>
        )}
      </Popover>
    )
  }
}

const ConnectedDropdownPopover = injectIntl(DropdownPopover)
export { ConnectedDropdownPopover as DropdownPopover }
