import { ChangePropType, TrackPropType } from 'spa/api/songs'
import { CrateButton } from 'spa/components/track_buttons/crate_button'
import { DownloadTrackButton } from 'spa/components/track_buttons/download_track_button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SendToDownloaderButton } from 'spa/components/track_buttons/send_to_downloader_button'
import { TextStyle } from 'spa/components/typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const TrackDropdownTableRow = ({ change, songId, track, trackList }) => {
  const { downloadCount } = track

  return (
    <>
      <div className={styles.versionCell}>
        <div className={styles.check}>
          {downloadCount > 0 ? <FontAwesomeIcon icon="check" /> : null}
        </div>
        <TextStyle className={styles.versionText} variant="extra-bold">
          {track.type}
        </TextStyle>
        {change && (
          <TextStyle
            className={classNames(styles.versionText, styles.changeText)}
            color="red"
          >
            {change.change}
          </TextStyle>
        )}
      </div>
      <div className={styles.functionsCell}>
        <DownloadTrackButton
          songId={songId}
          trackId={track.tid}
          trackList={trackList}
        />
      </div>
      <div className={styles.functionsCell}>
        <CrateButton
          className={styles.crateButton}
          songId={songId}
          trackId={track.ttid}
          trackList={trackList}
        />
      </div>
      <div className={styles.functionsCell}>
        <SendToDownloaderButton
          songId={songId}
          trackId={track.tid}
          trackList={trackList}
        />
      </div>
    </>
  )
}

TrackDropdownTableRow.propTypes = {
  change: ChangePropType,
  songId: PropTypes.number.isRequired,
  track: TrackPropType.isRequired,
  trackList: PropTypes.string,
}
