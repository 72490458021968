import {
  CLEAR_AUTOCOMPLETE_SONGS,
  RECEIVE_AUTOCOMPLETE_SONGS,
  RECEIVE_SEARCH_SONGS,
  REQUEST_AUTOCOMPLETE_SONGS,
  SAVE_AUTOCOMPLETE_KEYSTROKE,
} from '../action_constants'
import { defaultFilters, defaultPagination } from '../api/songs'
import uniq from 'lodash/uniq'

const defaultState = {
  isFetching: false,
  filters: defaultFilters,
  pagination: defaultPagination,
  searchTerm: '',
  sortBy: 9, // Relevance
  suggestions: [],
  queryLog: [],
}

export default function autocompleteSongs(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_AUTOCOMPLETE_SONGS:
      return {
        ...state,
        isFetching: true,
        filters: action.filters,
        searchTerm: action.searchTerm,
        sortBy: action.sortBy,
      }
    case RECEIVE_AUTOCOMPLETE_SONGS:
      return {
        ...state,
        isFetching: false,
        suggestions: action.suggestions,
        filters: action.filters,
        searchTerm: action.searchTerm,
        sortBy: action.sortBy,
      }
    case CLEAR_AUTOCOMPLETE_SONGS:
      return {
        ...state,
        suggestions: [],
        isFetching: false,
      }
    case SAVE_AUTOCOMPLETE_KEYSTROKE:
      return {
        ...state,
        queryLog: uniq([...state.queryLog, action.input]),
      }
    case RECEIVE_SEARCH_SONGS:
      return {
        ...state,
        queryLog: [],
      }
    default:
      return state
  }
}
