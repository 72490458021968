import {
  closeModal,
  FORGOT_PASSWORD_MODAL,
  LOGIN_MODAL,
  openModal,
} from 'spa/action_creators/modals'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { forgotPassword } from 'spa/action_creators/current_user'
import { Label } from 'spa/components/label'
import { SpinnerButton } from 'spa/components/button'
import { TextInput } from 'spa/components/text_input'
import { useDispatch, useSelector } from 'react-redux'
import ApplicationModal from 'spa/components/application_modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles'

const localizedMessages = defineMessages({
  emailAddress: {
    id: 'djcity.account.login.email',
    defaultMessage: 'Email Address',
  },
})

function ForgotPassword({ intl }) {
  const dispatch = useDispatch()
  const isOpen = useSelector(
    state => state.modals[FORGOT_PASSWORD_MODAL].enabled
  )
  const isResetting = useSelector(state => state.isResettingPassword)
  const [email, setEmail] = useState('')

  const isDisabled = email === ''

  useEffect(() => {
    setEmail('')
  }, [isOpen, setEmail])

  const onLoginClick = useCallback(() => {
    dispatch(openModal(LOGIN_MODAL))
  }, [dispatch])

  const onSubmit = useCallback(
    async e => {
      e.preventDefault()

      if (!isDisabled) {
        await dispatch(forgotPassword({ email }))
        dispatch(closeModal())
      }
    },
    [dispatch, email, isDisabled]
  )

  return (
    <ApplicationModal
      modalKey={FORGOT_PASSWORD_MODAL}
      title={
        <FormattedMessage
          defaultMessage="Forgot Password"
          id="djcity.account.forgot_password.label"
        />
      }
    >
      <form className={styles.forgotPasswordForm} onSubmit={onSubmit}>
        <Label
          className={styles.label}
          text={intl.formatMessage(localizedMessages.emailAddress)}
        >
          <TextInput
            className={styles.input}
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        </Label>
        <button
          className={classNames('cleanButton', styles.loginButton)}
          onMouseDown={onLoginClick}
        >
          <FormattedMessage
            defaultMessage="Login"
            id="djcity.account.login.label"
          />
        </button>
        <SpinnerButton
          disabled={isDisabled || isResetting}
          loading={isResetting}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Reset Password"
            id="djcity.account.reset_password.label"
          />
        </SpinnerButton>
      </form>
    </ApplicationModal>
  )
}

ForgotPassword.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(ForgotPassword)
