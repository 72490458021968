import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { countries } from './countries'
import { maintenance } from './maintenance'
import { reducer as notifications } from 'react-notification-system-redux'
import { territories } from './territories'
import artistSongs from './artist_songs'
import autocompleteRemixers from './autocompleteRemixers'
import autocompleteSongs from './autocompleteSongs'
import bannerAssets from './banner_assets'
import crate from './crate'
import currentUser from './current_user'
import currentUserBilling from './current_user_billing'
import darkMode from './dark_mode'
import device from './device'
import exclusivesSongs from './exclusives_songs'
import features from './features'
import globalSongs from './global_songs'
import hotBoxSongs from './hot_box_songs'
import isLoggingIn from './is_logging_in'
import isResettingPassword from './is_resetting_password'
import isSigningUp from './is_signing_up'
import locale from './locale'
import mobileOverlay from './mobile_overlay'
import mobilePopover from './mobile_popover'
import modals from './modals'
import newSongs from './new_songs'
import paymentMethods from './payment_method'
import player from './player'
import playlistsData from './playlists_data'
import playlistsSongs from './playlists_songs'
import posts from './posts'
import preferences from './preferences'
import promos from './promos'
import queue from './queue'
import recommendedSongs from './recommended_songs'
import referral from './referral'
import remixers from './remixers'
import remixSongs from './remix_songs'
import searchSongs from './search_songs'
import smashVision from './smash_vision'
import songDetails from './song_details'
import songs from './songs'
import stringResources from './string_resources'
import subscriptions from './subscriptions'
import tags from './tags'
import tagSongs from './tag_songs'
import thirdParty from './third_party'
import topDownloadsDates from './top_download_dates'
import topDownloadsSongs from './top_downloads_songs'
import updatedSongs from './updated_songs'
import userPreferences from './user_preferences'
import userProperties from './user_properties'

export default history =>
  combineReducers({
    autocompleteRemixers,
    autocompleteSongs,
    bannerAssets,
    countries,
    crate,
    currentUser,
    currentUserBilling,
    darkMode,
    device,
    exclusivesSongs,
    features,
    hotBoxSongs,
    globalSongs,
    isLoggingIn,
    isResettingPassword,
    isSigningUp,
    locale,
    maintenance,
    mobileOverlay,
    mobilePopover,
    modals,
    newSongs,
    notifications,
    paymentMethods,
    player,
    playlistsData,
    playlistsSongs,
    posts,
    preferences,
    promos,
    queue,
    recommendedSongs,
    referral,
    remixSongs,
    remixers,
    router: connectRouter(history),
    artistSongs,
    searchSongs,
    smashVision,
    songDetails,
    songs,
    stringResources,
    subscriptions,
    tagSongs,
    tags,
    territories,
    thirdParty,
    topDownloadsDates,
    topDownloadsSongs,
    updatedSongs,
    userPreferences,
    userProperties,
  })
