import PropTypes from 'prop-types'
import React from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { InjectedForm } from './form'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY).catch(e => {
  // eslint-disable-next-line no-console
  console.error('Error loading Stripe', e)

  return null
})

const stripeOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap',
    },
  ],
}

export function StripeForm({
  action,
  currentUser,
  disabled,
  submitText,
  promo,
  subscription,
}) {
  return (
    <Elements options={stripeOptions} stripe={stripePromise}>
      <InjectedForm
        action={action}
        currentUser={currentUser}
        disabled={disabled}
        promo={promo}
        submitText={submitText}
        subscription={subscription}
      />
    </Elements>
  )
}

StripeForm.displayName = 'djcity.billing.stripe_form'

StripeForm.propTypes = {
  action: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  promo: PropTypes.object,
  submitText: PropTypes.object,
  subscription: PropTypes.object,
}
