import * as crateApi from '../api/crate'
import * as playerActions from '../action_creators/player'
import * as songDetailsActions from '../action_creators/song_details'
import * as songsApi from '../api/songs'
import {
  AUTO_DISMISS_SECONDS,
  MAX_DOWNLOAD_COUNT,
  NOTIFICATION_MESSAGES,
} from '../shared/utils'
import { error, success } from 'react-notification-system-redux'
import { intl } from '../shared/IntlGlobalProvider'
import { loggedInSelector } from '../selectors/users'
import { openModal, SIGNUP_MODAL } from '../action_creators/modals'
import {
  RECEIVE_ADD_BATCH_TO_CRATE,
  RECEIVE_ADD_TO_CRATE,
  RECEIVE_CRATE,
  RECEIVE_REMOVE_ALL_FROM_CRATE,
  RECEIVE_REMOVE_BATCH_FROM_CRATE,
  RECEIVE_REMOVE_FROM_CRATE,
  RECEIVE_REMOVE_MULTIPLE_FROM_CRATE,
  RECEIVE_SEND_CRATE_TO_DOWNLOADER,
  REQUEST_ADD_BATCH_TO_CRATE,
  REQUEST_ADD_TO_CRATE,
  REQUEST_CRATE,
  REQUEST_REMOVE_ALL_FROM_CRATE,
  REQUEST_REMOVE_BATCH_FROM_CRATE,
  REQUEST_REMOVE_FROM_CRATE,
  REQUEST_REMOVE_MULTIPLE_FROM_CRATE,
  REQUEST_SEND_CRATE_TO_DOWNLOADER,
  RESET_CRATE,
} from '../action_constants'
import { trackInCrateSelector } from '../selectors/songs'

const notification = (intl, message) => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.yourCrate),
    message: message ? intl.formatMessage(message) : '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

function requestAddToCrate({ songId, trackId }) {
  return {
    type: REQUEST_ADD_TO_CRATE,
    songId,
    trackId,
  }
}

function receiveAddToCrate(item, trackList) {
  return {
    type: RECEIVE_ADD_TO_CRATE,
    item,
    trackList,
  }
}

function requestAddBatchToCrate({ songId, trackIds }) {
  return {
    type: REQUEST_ADD_BATCH_TO_CRATE,
    songId,
    trackIds,
  }
}

function receiveAddBatchToCrate(items, trackList) {
  return {
    type: RECEIVE_ADD_BATCH_TO_CRATE,
    items,
    trackList,
  }
}

function requestRemoveBatchFromCrate() {
  return {
    type: REQUEST_REMOVE_BATCH_FROM_CRATE,
  }
}

function receiveRemoveBatchFromCrate(payload) {
  return {
    type: RECEIVE_REMOVE_BATCH_FROM_CRATE,
    ...payload,
  }
}

export function addToCrate(params) {
  return function(dispatch, getState) {
    dispatch(requestAddToCrate(params))
    const loggedIn = loggedInSelector(getState())
    if (!loggedIn) {
      dispatch(
        error({
          ...notification(intl, NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(
              NOTIFICATION_MESSAGES.subscribeLabelCrate
            ),
            callback: () => {
              dispatch(openModal(SIGNUP_MODAL))
            },
          },
        })
      )
      return
    }
    return crateApi
      .addToCrate({ rid: params.songId, ttid: params.trackId })
      .then(response => {
        switch (response.status) {
          case 200:
            dispatch(
              success({
                ...notification(intl, NOTIFICATION_MESSAGES.trackAddedCrate),
              })
            )
            dispatch(receiveAddToCrate(response.data.item[0], params.trackList))
            return
          case 400:
            dispatch(
              error({
                ...notification(intl),
                message: response.data.error.userDescription,
              })
            )
            return
        }
      })
  }
}

export function addAllTracksToCrate(params) {
  const { songId } = params
  return async function(dispatch, getState) {
    const loggedIn = loggedInSelector(getState())
    const { songsById } = getState().songs
    if (!loggedIn) {
      dispatch(
        error({
          ...notification(intl, NOTIFICATION_MESSAGES.noMemberMessage),
          action: {
            label: intl.formatMessage(
              NOTIFICATION_MESSAGES.subscribeLabelCrate
            ),
            callback: () => {
              dispatch(openModal(SIGNUP_MODAL))
            },
          },
        })
      )
      return
    }

    dispatch(requestAddBatchToCrate(params))
    const tracksToCrate = songsById[songId].types.filter(
      ({ inCrate }) => !inCrate
    )
    const trackIds = tracksToCrate.map(version => [
      songId,
      parseInt(version.ttid || version.tid),
    ])
    if (
      tracksToCrate.some(
        ({ downloadCount }) => downloadCount >= MAX_DOWNLOAD_COUNT
      )
    ) {
      return dispatch(
        error({
          ...notification(intl),
          message: intl.formatMessage(NOTIFICATION_MESSAGES.batchError),
        })
      )
    }
    return crateApi.addAllTracksToCrate({ tracks: trackIds }).then(response => {
      switch (response.status) {
        case 200:
          dispatch(
            success({
              ...notification(intl, NOTIFICATION_MESSAGES.tracksAddedCrate),
            })
          )
          dispatch(
            receiveAddBatchToCrate(
              response.data.map(item => item[0]),
              params.trackList
            )
          )
          return
        case 400:
          dispatch(
            error({
              ...notification(intl),
              message: response.data.error.userDescription,
            })
          )
          return
      }
    })
  }
}

function requestCrate() {
  return {
    type: REQUEST_CRATE,
  }
}

function receiveCrate({ items, metadata }) {
  return {
    type: RECEIVE_CRATE,
    items,
    metadata,
  }
}

export function resetCrate() {
  return {
    type: RESET_CRATE,
  }
}

export function getCrate(params = {}) {
  return function(dispatch, getState) {
    const user = getState().currentUser.user
    if (!user) {
      dispatch(resetCrate())
      return
    }
    dispatch(requestCrate())
    return crateApi.getCrate({ ...params }).then(response => {
      dispatch(
        receiveCrate({
          items: response.items || [],
          metadata: response.metadata || {},
        })
      )
    })
  }
}

function requestRemoveFromCrate() {
  return {
    type: REQUEST_REMOVE_FROM_CRATE,
  }
}

export function receiveRemoveFromCrate({ songId, trackId }) {
  return {
    type: RECEIVE_REMOVE_FROM_CRATE,
    songId,
    trackId,
  }
}

function requestRemoveMultipleFromCrate() {
  return {
    type: REQUEST_REMOVE_MULTIPLE_FROM_CRATE,
  }
}

function receiveRemoveMultipleFromCrate(tracksToRemove) {
  return {
    type: RECEIVE_REMOVE_MULTIPLE_FROM_CRATE,
    tracksToRemove,
  }
}

export function removeTrack({ songId, trackId, displayNotification = true }) {
  return function(dispatch, getState) {
    const track = trackInCrateSelector(getState(), { songId, trackId })
    if (!track) {
      return
    }

    const downloadCount = track ? track.downloadCount : 0

    dispatch(requestRemoveFromCrate())
    return crateApi.removeTrack({ songId, trackId }).then(response => {
      if (
        displayNotification &&
        response.removedItems[0][0].removed_items > 0
      ) {
        dispatch(
          success({
            ...notification(intl, NOTIFICATION_MESSAGES.trackRemovedCrate),
            action:
              downloadCount < MAX_DOWNLOAD_COUNT
                ? {
                    label: intl.formatMessage(NOTIFICATION_MESSAGES.undolabel),
                    callback: () => {
                      dispatch(addToCrate({ songId, trackId }))
                    },
                  }
                : null,
          })
        )
      }
      dispatch(receiveRemoveFromCrate({ songId, trackId }))
    })
  }
}

export function removeAllTracks({ songId }) {
  return function(dispatch, getState) {
    dispatch(requestRemoveBatchFromCrate())
    const { songsById } = getState().songs
    const trackIds = songsById[songId].types
      .filter(version => version.inCrate)
      .map(version => parseInt(version.ttid || version.tid))
    return crateApi
      .removeAllTracks({ tracks: trackIds.map(ttid => [songId, ttid]) })
      .then(response => {
        if (response.tracksUpdated) {
          dispatch(
            success({
              ...notification(intl, NOTIFICATION_MESSAGES.tracksRemovedCrate),
              action: {
                label: intl.formatMessage(NOTIFICATION_MESSAGES.undolabel),
                callback: () => {
                  dispatch(addAllTracksToCrate({ songId }))
                },
              },
            })
          )
          dispatch(receiveRemoveBatchFromCrate({ songId, trackIds }))
        }
      })
  }
}

export function playSong({ id }) {
  return function(dispatch) {
    return songsApi.details(id).then(response => {
      dispatch(songDetailsActions.receiveSongDetails({ song: response.record }))
      dispatch(playerActions.setCurrentSong(response.record))
    })
  }
}

function requestRemoveTracks() {
  return {
    type: REQUEST_REMOVE_ALL_FROM_CRATE,
  }
}

function receiveRemoveTracks() {
  return {
    type: RECEIVE_REMOVE_ALL_FROM_CRATE,
  }
}

export function removeTracks() {
  return function(dispatch) {
    dispatch(requestRemoveTracks())
    return crateApi.removeTracks().then(() => {
      dispatch(
        success({
          ...notification(intl, NOTIFICATION_MESSAGES.allTracksRemovedCrate),
        })
      )
      dispatch(receiveRemoveTracks())
    })
  }
}

function requestSendCrateToDownloader() {
  return {
    type: REQUEST_SEND_CRATE_TO_DOWNLOADER,
  }
}

function receiveSendCrateToDownloader(items) {
  return {
    type: RECEIVE_SEND_CRATE_TO_DOWNLOADER,
    items,
  }
}

export function sendCrateToDownloader() {
  return function(dispatch) {
    dispatch(requestSendCrateToDownloader())
    dispatch(requestRemoveMultipleFromCrate())
    return crateApi
      .sendToDownloader()
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.data.error)
        }
        return res.data
      })
      .then(({ items }) => {
        const dataItems = items || []
        const enqueuedItems = dataItems.filter(
          ({ alreadyInDownloadQueue, maxDownloadCountExceeded }) =>
            !alreadyInDownloadQueue && !maxDownloadCountExceeded
        )
        const enqueuedMessage =
          enqueuedItems.length > 0
            ? intl.formatMessage(NOTIFICATION_MESSAGES.tracksQueued)
            : ''
        const alreadyInDownloadQueueMessage = dataItems.some(
          ({ alreadyInDownloadQueue }) => alreadyInDownloadQueue
        )
          ? intl.formatMessage(NOTIFICATION_MESSAGES.duplicated)
          : ''
        const maxDownloadCountExceededMessage = dataItems.some(
          ({ maxDownloadCountExceeded }) => maxDownloadCountExceeded
        )
          ? intl.formatMessage(NOTIFICATION_MESSAGES.downloadLimit)
          : ''
        dispatch(
          success({
            ...notification(intl),
            message: `${enqueuedMessage} ${alreadyInDownloadQueueMessage} ${maxDownloadCountExceededMessage}`,
          })
        )
        dispatch(receiveSendCrateToDownloader(enqueuedItems))
        dispatch(receiveRemoveMultipleFromCrate(enqueuedItems))
      })
      .catch(err => {
        return dispatch(
          error({
            title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
            message: err.userDescription,
            position: 'tr',
            autoDismiss: AUTO_DISMISS_SECONDS,
          })
        )
      })
  }
}
