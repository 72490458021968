import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const ToggleButton = ({ className, checked, labelText, onChange, value }) => (
  <label
    className={classNames(styles.toggleButton, 'button', className, {
      secondary: !checked,
    })}
  >
    {labelText}
    <input
      checked={checked}
      className={styles.input}
      onChange={e => onChange && onChange(value, e.checked)}
      type="checkbox"
    />
  </label>
)

ToggleButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

ToggleButton.defaultProps = {
  checked: false,
  className: null,
  labelText: null,
  onChange: () => {},
  value: null,
}

export default ToggleButton
