import { calculateDaysPast } from '../../shared/utils'
import { detectAdBlocker } from 'spa/action_creators/mixpanel'
import { fetchCurrentUser } from 'spa/action_creators/current_user'
import { getCrate, resetCrate } from 'spa/action_creators/crate'
import { getDownloadQueue } from 'spa/action_creators/download_queue'
import { getUserLocationProperties } from 'spa/action_creators/geo'
import { loggedInSelector } from 'spa/selectors/users'
import { PROMOS } from 'spa/shared/utils'
import { removeReferral, setReferral } from 'spa/action_creators/referral'
import {
  requestPromosPricing,
  setActivePromo,
} from 'spa/action_creators/promos'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import PropTypes from 'prop-types'

const gaEnabled =
  window &&
  !window.Cypress &&
  !process.env.IS_TEST &&
  process.env.GA_TRACKING_ID

if (gaEnabled) {
  const ReactGA = require('react-ga')
  ReactGA.initialize(process.env.GA_TRACKING_ID, {
    debug: process.env.NODE_ENV !== 'production',
  })
  ReactGA.pageview(window.location.pathname + window.location.search)
}

function Init({ children }) {
  const dispatch = useDispatch()
  const { currentUser, promos, referral, userPreferences } = useSelector(s => s)
  const { trackingChecked } = userPreferences
  const { activePromo, promoData } = promos
  const { token } = currentUser

  const isLoggedIn = useSelector(loggedInSelector)
  const [params] = useQueryParameters()
  const pathname = useLocation().pathname
  const offerName = pathname?.includes('/offer/')
    ? pathname.replace('/offer/', '')
    : null

  useEffect(() => {
    if (token && isLoggedIn) {
      dispatch(fetchCurrentUser())
    }
  }, [dispatch, isLoggedIn, token])

  useEffect(() => {
    dispatch(resetCrate())
    if (isLoggedIn) {
      dispatch(getCrate())
      dispatch(getDownloadQueue())
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    dispatch(getUserLocationProperties())
    if (!(params.partner || offerName)) {
      if (!activePromo) dispatch(setActivePromo(PROMOS.NETNEW))
      if (!promoData)
        dispatch(requestPromosPricing({ promoName: PROMOS.NETNEW }))
    }
  }, [])

  useEffect(() => {
    if (!trackingChecked) {
      dispatch(detectAdBlocker())
    }
  }, [trackingChecked])

  useEffect(() => {
    const { a_aid, a_bid, a_cid, chan } = params
    const hasParams = a_aid || a_bid || a_cid || chan

    if (referral.timestamp && calculateDaysPast(referral.timestamp) > 28) {
      return dispatch(removeReferral())
    }

    if (hasParams && a_aid !== referral.affiliateId) {
      const data = {
        affiliateId: a_aid || referral.affiliateId,
        bannerId: a_bid || referral.bannerId,
        campaignId: a_cid || referral.campaignId,
        channel: chan || referral.channel || 'djcity',
        timestamp: Date.now(),
      }
      dispatch(setReferral(data))
    }
  }, [])

  return children
}

Init.propTypes = {
  children: PropTypes.node,
}

export default Init
