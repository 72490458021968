import {
  ADD_PROMO_CODE,
  CLEAR_CURRENT_USER,
  ERROR_RECEIVING_PROMO,
  ERROR_RECEIVING_PROMO_PRICING,
  ERROR_RECEIVING_PROMO_TOKEN,
  ERROR_RECEIVING_PROMO_VALIDATION,
  RECEIVE_PROMO,
  RECEIVE_PROMO_PRICING,
  RECEIVE_PROMO_TOKEN,
  RECEIVE_PROMO_VALIDATION,
  REMOVE_PROMO_CODE,
  REQUEST_PROMO,
  REQUEST_PROMO_PRICING,
  REQUEST_PROMO_TOKEN,
  REQUEST_PROMO_VALIDATION,
  RESET_CHECKOUT_ERRORS,
  RESET_PROMO,
  // REQUEST_PROMO_TOKEN_VALIDATION,
  SET_ACTIVE_PROMO,
} from '../action_constants'

const defaultState = {
  activePromo: null,
  isFetching: false,
  promoCode: undefined,
  promoData: null,
  promoToken: null,
  promoError: null,
}

export default function promos(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_CURRENT_USER:
      return defaultState
    case ADD_PROMO_CODE:
      return {
        ...state,
        ...action.payload,
      }
    case REMOVE_PROMO_CODE:
      return {
        ...state,
        code: null,
        promoCode: null,
        promoError: null,
      }
    case REQUEST_PROMO:
      return {
        ...state,
        ...action.payload,
        isFetching: true,
      }
    case RECEIVE_PROMO:
      return {
        ...state,
        promoData: {
          ...action.payload.promo,
          ...action.payload.pricing,
          ...action.payload.requirements,
          messages: action.payload.messages,
        },
        promoCode: action.payload.promoCode,
        promoError: null,
        promoName: action.payload.promoName,
        isFetching: false,
      }
    case ERROR_RECEIVING_PROMO:
      return {
        ...defaultState,
        promoData: state.promoData,
        promoError: action.payload,
      }
    case REQUEST_PROMO_PRICING:
      return {
        ...state,
        ...action.payload,
        isFetching: true,
      }
    case RECEIVE_PROMO_PRICING:
      return {
        ...state,
        promoData: {
          ...action.payload.promo,
          ...action.payload.pricing,
          messages: action.payload.messages,
        },
        promoError: null,
        isFetching: false,
      }
    case ERROR_RECEIVING_PROMO_PRICING:
      return {
        ...state,
        isFetching: false,
        promoCode: undefined,
        promoError: action.payload,
      }
    case REQUEST_PROMO_TOKEN:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PROMO_TOKEN:
      return {
        ...state,
        ...action.payload,
        promoError: null,
        isFetching: false,
      }
    case ERROR_RECEIVING_PROMO_TOKEN:
      return {
        ...state,
        isFetching: false,
        promoError: action.payload,
      }
    case REQUEST_PROMO_VALIDATION:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PROMO_VALIDATION:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      }
    case ERROR_RECEIVING_PROMO_VALIDATION:
      return {
        ...state,
        isFetching: false,
        promoError: action.payload,
      }
    case SET_ACTIVE_PROMO:
      return {
        ...state,
        activePromo: action.payload,
      }
    case RESET_PROMO:
      return defaultState
    case RESET_CHECKOUT_ERRORS:
      return {
        ...state,
        promoError: null,
      }
    default:
      return state
  }
}
