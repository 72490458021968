import {
  RECEIVE_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD,
} from '../action_constants'

export default function isResettingPassword(state = false, action) {
  switch (action.type) {
    case REQUEST_RESET_PASSWORD:
      return true

    case RECEIVE_RESET_PASSWORD:
      return false

    default:
      return state
  }
}
