import { actionButtons } from './data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'
import { Knotch } from 'spa/components/knotch'
import { TextStyle } from 'spa/components/typography'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.sass'

export const ActionButtons = injectIntl(({ intl }) => {
  const history = useHistory()
  return (
    <div className={styles.homeActions}>
      <TextStyle uppercase variant="extra-bold">
        <Knotch
          align="left"
          className={styles.sidebarKnotch}
          displayType="inlineBlock"
          size="small"
        />
        <span>
          {intl.formatMessage({
            defaultMessage: 'The good stuff',
            id: 'djcity.common.home.actions',
          })}
        </span>
      </TextStyle>
      <div className={styles.actionButtons}>
        {actionButtons.map(obj => (
          <button
            className={styles.actionButton}
            key={'actBtn' + obj.endpoint}
            onClick={() => history.push(obj.endpoint)}
          >
            <span className={styles.actionText}>
              {intl.formatMessage(obj.message)}
            </span>
          </button>
        ))}
      </div>
    </div>
  )
})

ActionButtons.displayName = 'ActionButtons'

ActionButtons.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export const ActionSearch = injectIntl(({ intl }) => {
  const history = useHistory()
  return (
    <div className={styles.searchActions}>
      <span>
        {intl.formatMessage({
          id: 'djcity.common.home.actions',
          defaultMessage: 'DISCOVER MORE',
        })}
      </span>
      <ul>
        {actionButtons.map(obj => (
          <li
            key={'actSrch' + obj.endpoint}
            onMouseDownCapture={() => history.push(obj.endpoint)}
          >
            <span className={styles.liIcons}>
              <FontAwesomeIcon icon={obj.icon} size="1x" />
            </span>
            <span>{intl.formatMessage(obj.message)}</span>
          </li>
        ))}
      </ul>
    </div>
  )
})
