import { Browse } from '../../browse'
import { Layout, Sidebar } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'
const Exclusives = React.lazy(() => import('./exclusives'))

const display = () => {
  return (
    <Layout tabTitle="exclusives">
      <Sidebar>
        <Browse />
      </Sidebar>
      <ModuleLoader>
        <Exclusives />
      </ModuleLoader>
    </Layout>
  )
}

export { display as ExclusivesDisplay }

export { Exclusives }

export default Exclusives
