import { Browse } from '../../browse'
import { Layout, Sidebar } from '../../layout'
import Artists from './artists'
import React from 'react'

const display = () => {
  return (
    <Layout tabTitle="artist">
      <Sidebar>
        <Browse />
      </Sidebar>
      <Artists />
    </Layout>
  )
}

export { display as ArtistsDisplay }

export { Artists }

export default Artists
