import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedDate, FormattedHTMLMessage } from 'react-intl'
import { isMobileSelector } from '../../selectors/device'
import { Link } from 'react-router-dom'
import { SongPropType } from 'spa/api/songs'
import { SongTableRowTitle } from './song_table_row_title'
import { SongVersionsPopover } from './song_versions_popover'
import { TableSeparator } from './table_separator'
import { TextStyle } from '../typography'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import find from 'lodash/find'
import first from 'lodash/first'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles'

export const SongTableRow = ({
  canHide,
  showDateAdded,
  index,
  listIndex,
  onAddSongToQueue,
  onHide,
  onRemoveSongFromQueue,
  onSelect,
  song,
  currentlyInQueue,
  trackList,
}) => {
  const isMobile = useSelector(isMobileSelector)
  const primaryCategory = first(song.tags, x => x.name === song.category)
  const categoryId = primaryCategory ? primaryCategory.id : song.categoryId
  const tags = useSelector(state => state.tags.tags)
  const tag = find(tags, { id: categoryId })

  const onHideButtonClick = useCallback(() => {
    onHide(song.rid)
  }, [onHide, song.rid])

  const customDateFormat = date => {
    const days = (Date.now() - new Date(date).getTime()) / (1000 * 3600 * 24)

    if (days < 1)
      return (
        <FormattedHTMLMessage
          defaultMessage="Today"
          id="djcity.records.recordpool.track.date_added.today"
        />
      )

    if (days < 2)
      return (
        <FormattedHTMLMessage
          defaultMessage="Yesterday"
          id="djcity.records.recordpool.track.date_added.yesterday"
        />
      )

    if (days < 8)
      return (
        <FormattedHTMLMessage
          defaultMessage="{n} days ago"
          id="djcity.records.recordpool.track.date_added.n_days_ago"
          values={{ n: Math.floor(days) }}
        />
      )

    return (
      <FormattedDate
        day="numeric"
        month="short"
        value={new Date(date)}
        year="numeric"
      />
    )
  }

  return (
    <>
      <SongTableRowTitle
        listIndex={listIndex}
        numberIndex={index}
        onSelect={onSelect}
        song={song}
        trackList={trackList}
      />
      {!isMobile && (
        <>
          {showDateAdded && (
            <TextStyle color="grey">
              {customDateFormat(song.insertDate)}
            </TextStyle>
          )}
          <TextStyle variant="bold">{song.bpm}</TextStyle>
          {tag && (
            <Link to={`/genre/${tag.slug}`}>
              <TextStyle color="red" variant="bold">
                {song.category.substring(0, 17)}
              </TextStyle>
            </Link>
          )}
        </>
      )}
      <SongVersionsPopover
        currentlyInQueue={currentlyInQueue}
        onAddSongToQueue={onAddSongToQueue}
        onRemoveSongFromQueue={onRemoveSongFromQueue}
        songId={song.rid}
        trackList={trackList}
      />
      {canHide && (
        <button
          className={classNames('cleanButton', styles.action)}
          onClick={onHideButtonClick}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      )}
      <TableSeparator />
    </>
  )
}

export default SongTableRow

SongTableRow.propTypes = {
  canHide: PropTypes.bool,
  currentlyInQueue: PropTypes.bool,
  index: PropTypes.number,
  listIndex: PropTypes.number,
  onAddSongToQueue: PropTypes.func,
  onHide: PropTypes.func,
  onRemoveSongFromQueue: PropTypes.func,
  onSelect: PropTypes.func,
  showDateAdded: PropTypes.bool,
  song: SongPropType,
  trackList: PropTypes.string,
}
