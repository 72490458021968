import { ClickOutside } from '../click_outside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export class Popover extends React.PureComponent {
  static propTypes = {
    arrow: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    closeButton: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    closeOnScroll: PropTypes.bool,
    contentClassName: PropTypes.string,
    fixed: PropTypes.bool,
    handleClosePopover: PropTypes.func,
    horizontalDirection: PropTypes.string,
    id: PropTypes.string,
    itemClick: PropTypes.bool,
    openPopover: PropTypes.bool,
    renderTrigger: PropTypes.func.isRequired,
    style: PropTypes.object,
    variant: PropTypes.oneOf(['light', 'dark']),
    verticalDirection: PropTypes.string,
  }

  static defaultProps = {
    closeButton: false,
    closeOnScroll: true,
    closeOnClick: false,
    fixed: false,
  }

  state = {
    open: false,
    verticalPosition: null,
    horizontalPosition: null,
    style: null,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, {
      passive: true,
      capture: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { fixed, openPopover } = this.props
    const { open } = this.state

    // Sets the popover position whenever it is open
    if (!prevState.open && open) {
      this.setPopoverPosition()
    }

    // Close popover when clicking list item
    if (openPopover) {
      this.setState({ open: false })
    }

    // If the popover is fixed, we need to recalculate the position
    // whenever the window is resized
    if (fixed && !prevState.open && open) {
      window.addEventListener('resize', this.setPopoverPosition)
      window.addEventListener('touchmove', this.setPopoverPosition)
    }
    if (!fixed || (fixed && prevState.open && !open)) {
      window.removeEventListener('resize', this.setPopoverPosition)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.setPopoverPosition)
    window.removeEventListener('touchmove', this.setPopoverPosition)
  }

  triggerRef = React.createRef()
  contentRef = React.createRef()

  setPopoverPosition = () => {
    const { fixed, verticalDirection, horizontalDirection } = this.props
    const newState = {}

    // Computes the trigger's coordinates
    let triggerCoords = this.triggerRef.current.getBoundingClientRect()
    let contentCoords = this.contentRef.current.getBoundingClientRect()
    let verticalMiddle = (triggerCoords.top + triggerCoords.bottom) / 2
    let horizontalMiddle = (triggerCoords.left + triggerCoords.right) / 2

    // If the popover's CSS position must be "fixed" instead of "absolute"
    if (fixed) {
      newState.verticalPosition = null
      newState.horizontalPosition = null

      verticalMiddle = triggerCoords.top + triggerCoords.height
      horizontalMiddle = triggerCoords.left + triggerCoords.width

      let translateX, translateY

      if (verticalMiddle < window.innerHeight / 2) {
        translateY = 0
      } else {
        translateY = -100
      }

      if (horizontalMiddle < window.innerWidth / 2) {
        translateX = 0
      } else {
        translateX = -100
      }

      newState.style = {
        position: 'fixed',
        top: `${verticalMiddle}px`,
        left: `${horizontalMiddle}px`,
        transform: `translate(${translateX}%, ${translateY}%)`,
      }
    }

    // If the popover's CSS position must be "absolute"
    else {
      // Checks if it needs to be rendered above
      // or below the trigger

      const FOOTER_AND_OR_PLAYER_HEIGHT = Math.max(
        window.innerHeight -
          (
            document.querySelector('#player') ||
            document.querySelector('#footer')
          ).getBoundingClientRect().top,
        0
      )

      const HEADER_HEIGHT = Math.max(
        document.querySelector('#header>:last-child').getBoundingClientRect()
          .bottom,
        0
      )

      const enoughSpaceAbove =
        contentCoords.height < triggerCoords.top - HEADER_HEIGHT

      const enoughSpaceBelow =
        contentCoords.height <
        window.innerHeight - triggerCoords.y - FOOTER_AND_OR_PLAYER_HEIGHT

      // Is in footer and/or player area
      if (triggerCoords.y >= window.innerHeight - FOOTER_AND_OR_PLAYER_HEIGHT) {
        newState.verticalPosition = 'top'
      }
      // Is in header area
      else if (triggerCoords.y <= HEADER_HEIGHT) {
        newState.verticalPosition = 'bottom'
      }
      // Is in body area
      else {
        // The viewport height is lower than the popover
        // it would be equally expressed as contentCoords.height > window.innerHeight
        if (!enoughSpaceBelow && !enoughSpaceAbove) {
          newState.verticalPosition = 'bottom'
        }

        // Enforce the popover to render from top to bottom if its own height
        // is higher than the trigger button distance from the top
        if (!enoughSpaceAbove && enoughSpaceBelow) {
          newState.verticalPosition = 'bottom'
        }

        // Enforce the popover to render from bottom to top if its own height
        // is higher than the trigger button distance from the bottom
        if (!enoughSpaceBelow && enoughSpaceAbove) {
          newState.verticalPosition = 'top'
        }
      }

      // // Override styling in some cases
      // if (newState.verticalPosition === "bottom") {
      //   if (triggerCoords.y <= HEADER_AREA_HEIGHT) {
      //     newState.style = { zIndex: 12000 };
      //   }
      // }

      // Same with the horizontal position
      newState.horizontalPosition =
        horizontalDirection || horizontalMiddle < window.innerWidth / 2
          ? 'left'
          : 'right'

      newState.verticalPosition =
        verticalDirection || newState.verticalPosition || 'bottom'

      newState.style = null
    }

    this.setState(newState)
  }

  handleScroll = () => {
    const { closeOnScroll } = this.props
    const { open } = this.state

    if (closeOnScroll && open) {
      this.setState({ open: false })
    }
  }

  closeModal = () => {
    const { open } = this.state

    this.setState({ open: !open })
  }

  handleClickOutside = e => {
    if (this.triggerRef.current && this.triggerRef.current.contains(e.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const {
      arrow,
      children,
      className,
      contentClassName,
      id,
      renderTrigger,
      variant = 'light',
      closeButton,
      closeOnClick,
      style: propStyle,
    } = this.props
    const { open, verticalPosition, horizontalPosition, style } = this.state

    return (
      <div
        className={classNames(styles.popover, className)}
        id={id}
        style={propStyle}
      >
        {renderTrigger({
          ref: this.triggerRef,
          handleClick: this.closeModal,
          open,
        })}
        {open && (
          <ClickOutside
            className={classNames(styles.popoverContent, contentClassName, {
              [styles.popoverContentPositionTop]: verticalPosition === 'top',
              [styles.popoverContentPositionBottom]:
                verticalPosition === 'bottom',
              [styles.popoverContentPositionLeft]:
                horizontalPosition === 'left',
              [styles.popoverContentPositionRight]:
                horizontalPosition === 'right',
              [styles.popoverContentLight]: variant === 'light',
              [styles.popoverContentDark]: variant === 'dark',
              [styles.popoverContentArrow]: !!arrow,
            })}
            onClick={closeOnClick ? this.closeModal : undefined}
            onClickOutside={this.handleClickOutside}
            ref={this.contentRef}
            style={style}
          >
            {closeButton && (
              <div className={styles.close} onClick={this.closeModal}>
                <FontAwesomeIcon icon="times" />
              </div>
            )}

            {children}
          </ClickOutside>
        )}
      </div>
    )
  }
}
