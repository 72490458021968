import { defineMessages } from 'react-intl'

export const LOCALIZED_MESSAGES = defineMessages({
  records: {
    id: 'djcity.common.records',
    defaultMessage: 'Records',
  },
  world: {
    id: 'djcity.common.world',
    defaultMessage: 'World',
  },
  latin: {
    id: 'djcity.common.latin',
    defaultMessage: 'Latin',
  },
  uk: {
    id: 'djcity.common.uk',
    defaultMessage: 'UK',
  },
  germany: {
    id: 'djcity.common.germany',
    defaultMessage: 'Germany',
  },
  japan: {
    id: 'djcity.common.japan',
    defaultMessage: 'Japan',
  },
  south_asia: {
    id: 'djcity.common.south_asia',
    defaultMessage: 'South Asia',
  },
  popularity: {
    id: 'djcity.common.sort.label.popularity',
    defaultMessage: 'Popularity',
  },
  date: {
    id: 'djcity.common.sort.label.date',
    defaultMessage: 'Date',
  },
})
