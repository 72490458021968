import { RECEIVE_PREFERENCES, REQUEST_PREFERENCES } from '../action_constants'

const defaultState = {
  preferences: [],
  isLoading: false,
}

const preferences = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_PREFERENCES:
      return { ...state, isLoading: true }
    case RECEIVE_PREFERENCES:
      return {
        ...state,
        isLoading: false,
        preferences: action.preferences,
      }
    default:
      return state
  }
}

export default preferences
