import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const Navigation = ({ children, className }) => {
  const isLoadingStringResources = useSelector(
    state => state.stringResources.isFetching
  )
  return !isLoadingStringResources ? (
    <nav className={classNames(styles.navigation, className)}>
      <ul className={styles.navList}>{children}</ul>
    </nav>
  ) : null
}

Navigation.displayName = 'Navigation'

Navigation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLoadingStringResources: PropTypes.bool,
}
