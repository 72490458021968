import { REFERRAL_SET, REFERRAL_UNSET } from '../action_constants'

const defaultState = {
  affiliateId: null,
  bannerId: null,
  campaignId: null,
  channel: null,
  timestamp: null,
}

export default function referral(state = defaultState, action) {
  switch (action.type) {
    case REFERRAL_SET:
      return { ...state, ...action.payload }
    case REFERRAL_UNSET:
      return defaultState
    default:
      return state
  }
}
