import { applyMiddleware, compose, createStore } from 'redux'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import { migrations } from './storeMigrations'
import { routerMiddleware } from 'connected-react-router'
import { userImpersonationMiddleware } from '../../reducers/user_impersonation'
import createRootReducer from '../../reducers'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

const mpEnabled =
  window && !window.Cypress && !process.env.IS_TEST && process.env.MIXPANEL_KEY

export default history => {
  const persistConfig = {
    key: 'root',
    whitelist: [
      'darkMode',
      'locale',
      'mobilePopover',
      'player',
      'referral',
      'songs',
      'userPreferences',
    ],
    storage,
    version: 1,
    migrate: createMigrate(migrations, { debug: false }),
  }

  // Persist device data in prod (user can't switch from one device to another)
  // this allows in dev/staging to simulate different devices in the same browser
  if (process.NODE_ENV === 'production') persistConfig.whitelist.push('device')

  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
  )

  const middleware = [thunk]

  if (mpEnabled)
    middleware.push(require('../../reducers/mixpanel').mixpanelMiddleware)

  middleware.push(routerMiddleware(history), userImpersonationMiddleware)

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          actionCreators: require('./actionCreatorsDev'),
          // serialize: { immutable: Immutable },
          trace: true,
          traceLimit: 25,
        })
      : compose

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware))
  )

  let persistor = persistStore(store)
  return { store, persistor }
}
