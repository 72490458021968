import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Knotch } from '../knotch'
import { TextStyle } from '../typography'
import classNames from 'classnames'
import React from 'react'

import styles from './styles'

const EmptyCrate = () => {
  return (
    <div className={styles.columnCentered}>
      <FontAwesomeIcon className={styles.crateIcon} icon={['far', 'archive']} />
      <TextStyle className={styles.headerText}>
        <FormattedMessage
          defaultMessage="Your Crate is Empty!"
          id="djcity.records.your_crate.notification.empty"
        />
      </TextStyle>
      <Knotch className={styles.knotchMargin} />
      <TextStyle className={styles.bodyText}>
        <FormattedMessage
          defaultMessage="Add track versions to your crate so you can download them later onto your desktop!"
          id="djcity.records.your_crate.notification.message"
        />
      </TextStyle>
      <div className={classNames('button', styles.clearButton)}>
        <FormattedMessage
          defaultMessage="Got It"
          id="djcity.records.your_crate.notification.got"
        />
      </div>
    </div>
  )
}

export default EmptyCrate
