import { DownloadPropType } from 'spa/api/songs'
import { Link } from 'spa/components/link'
import { linkifyArtistNames } from 'spa/urls'
import { PlayTrackButton } from 'spa/components/track_buttons/play_track_button'
import { TextStyle } from '../typography'
import classNames from 'classnames'
import CrateOptions from './crate_options'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export class CrateListItem extends React.PureComponent {
  static propTypes = {
    deleteItem: PropTypes.string,
    handleOpenPopover: PropTypes.func,
    onRemoveFromCrate: PropTypes.func,
    track: DownloadPropType,
  }

  render() {
    const {
      track,
      handleOpenPopover,
      onRemoveFromCrate,
      deleteItem,
    } = this.props
    const song = track.record
    return (
      <li className={styles.crateListItem}>
        <PlayTrackButton songId={song.rid} ttid={track.ttid} />
        <div className={styles.trackTitle}>
          <TextStyle>({track.trackType})</TextStyle>{' '}
          <Link onClick={handleOpenPopover} to={`/records/${song.rid}`}>
            <TextStyle variant="bold">{song.title}</TextStyle>
          </Link>
          <TextStyle
            className={classNames('artist-names', styles.crateFeaturing)}
            color="grey"
            tagName="p"
          >
            {linkifyArtistNames(song)}
          </TextStyle>
        </div>

        <CrateOptions
          deleteItem={deleteItem}
          onRemoveFromCrate={onRemoveFromCrate}
          track={track}
        />
      </li>
    )
  }
}
