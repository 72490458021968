import { defineMessages, injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { maintenanceSelector } from 'spa/selectors/maintenance'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  isDesktopSelector,
  isMobileSelector,
  isTabletSelector,
} from '../../selectors/device'
import styles from './styles'

const localizedMessages = defineMessages({
  title: {
    id: 'djcity.common.tab.title',
    defaultMessage: 'DJcity | DJ Record Pool and Music Industry News',
  },
  metaDescription: {
    id: 'djcity.common.meta.description',
    defaultMessage:
      'Get all the tracks you need with access to 150+ genres, optimized for the top DJ software. DJcity is the leading music discovery & delivery platform for DJs around the world.',
  },
})

function AppWrapper({ children, intl }) {
  const isMaintenanceMode = useSelector(maintenanceSelector)
  const darkMode = useSelector(state => state.darkMode)
  const isMobile = useSelector(isMobileSelector)
  const isDesktop = useSelector(isDesktopSelector)
  const isTablet = useSelector(isTabletSelector)

  useEffect(() => {
    const { classList } = document.body
    if (darkMode && !classList.contains('darkMode')) {
      classList.add('darkMode')
    } else {
      classList.remove('darkMode')
    }
  }, [darkMode])

  useEffect(() => {
    const { classList } = document.documentElement
    if ((isDesktop || isTablet) && !classList.contains('desktop-site'))
      classList.add('desktop-site')
    if (isMobile && !classList.contains('mobile-site'))
      classList.add('mobile-site')
    return () => {
      classList.remove('desktop-site')
      classList.remove('mobile-site')
    }
  }, [])

  return (
    <>
      <Helmet defer={false}>
        <title key="pagetitle">
          {intl.formatMessage(localizedMessages.title)}
        </title>

        {/* General SEO metadata */}
        <meta
          content={intl.formatMessage(localizedMessages.metaDescription)}
          key="meta-description"
          name="description"
        />

        {/* Facebook metadata */}
        <meta
          content="DJcity"
          key="meta-og:site_name"
          property="og:site_name"
        />
        <meta content="DJcity" key="meta-og:title" property="og:title" />
        <meta content="website" key="meta-og:type" property="og:type" />
        <meta
          content="http://d1udlrob6czrvo.cloudfront.net/logo/djcity-fb-og.png"
          key="meta-og:image"
          property="og:image"
        />
        <meta
          content="250694761681434"
          key="meta-fb:admins"
          property="fb:admins"
        />

        {/* Twitter metadata */}
        <meta
          content="summary_large_image"
          key="meta-twitter:card"
          name="twitter:card"
        />
        <meta
          content="DJcity"
          key="meta-twitter:title"
          property="twitter:title"
        />
        <meta content="@djcity" key="meta-twitter:site" name="twitter:site" />
        <meta
          content="@djcity"
          key="meta-twitter:creator"
          name="twitter:creator"
        />
        <meta
          content="http://d1udlrob6czrvo.cloudfront.net/logo/djcity-fb-og.png"
          key="meta-twitter:image"
          name="twitter:image"
        />
      </Helmet>
      <div
        className={classNames(styles.appWrapper, {
          [styles.maintenanceMode]: isMaintenanceMode,
        })}
      >
        {children}
      </div>
    </>
  )
}

AppWrapper.propTypes = {
  children: PropTypes.node,
  intl: PropTypes.object,
}

const LocalizedSAppWrapper = injectIntl(AppWrapper)

export { LocalizedSAppWrapper as AppWrapper }
