export const REGIONS = {
  1: 'United States',
  2: 'Japan',
  3: 'United Kingdom',
  4: 'Germany',
  5: 'New York',
  6: 'Latin America',
  7: 'Australia',
}

export const REGIONS_SHORT = {
  1: 'US',
  2: 'JP',
  3: 'UK',
  4: 'Germany',
  5: 'NY',
  6: 'Lat Am',
  7: 'Australia',
}
