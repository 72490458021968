import { RECEIVE_LOGIN, REQUEST_LOGIN } from '../action_constants'

export default function isLoggingIn(state = false, action) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return true

    case RECEIVE_LOGIN:
      return false

    default:
      return state
  }
}
