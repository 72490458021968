import { DEFAULT_LOCALE } from '../../locale_data/config'
import { loadFromLocalStorage, saveToLocalStorage } from '../shared/utils'
import { SET_LOCALE } from '../action_constants'

const LOCALE_KEY = 'locale'

const defaultState = {
  locale: loadFromLocalStorage(LOCALE_KEY) || DEFAULT_LOCALE,
}

function saveLocale(locale) {
  saveToLocalStorage(LOCALE_KEY, locale)
}

export default function locale(state = defaultState, action) {
  if (!action.locale) {
    return state
  }

  switch (action.type) {
    case SET_LOCALE:
      saveLocale(action.locale)
      return { ...state, locale: action.locale }
    default:
      return state
  }
}
