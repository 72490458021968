import {
  CLEAR_NEW_SONGS,
  RECEIVE_NEW_SONGS,
  REQUEST_NEW_SONGS,
} from '../action_constants'
import { defaultFilters, defaultPagination, defaultSortBy } from '../api/songs'

import { createSongsListReducer } from './create_songs_list_reducer'

export default createSongsListReducer({
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_NEW_SONGS,
  receiveActionConstant: RECEIVE_NEW_SONGS,
  clearActionConstant: CLEAR_NEW_SONGS,
})

export function clearNewReleases() {
  return {
    type: CLEAR_NEW_SONGS,
    filters: defaultFilters,
    sortBy: defaultSortBy,
    pagination: defaultPagination,
  }
}
