import { Browse } from '../../browse'
import { Layout, Sidebar } from '../../layout'
import React from 'react'
import Remixers from './remixers'

const display = () => {
  return (
    <Layout tabTitle="remixers">
      <Sidebar>
        <Browse />
      </Sidebar>
      <Remixers />
    </Layout>
  )
}

export { display as RemixersDisplay }

export { Remixers }

export default Remixers
