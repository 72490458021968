import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import NotificationBus from 'react-notification-system-redux'
import notificationStyles from './notification_styles'
import notificationStylesDarkMode from './notification_styles_dark_mode'
import React, { useMemo } from 'react'

import { isMobileSelector } from '../../selectors/device'
import styles from './styles'

const iconTemplate = iconType => {
  return (
    <div className={styles.notificationIcon}>
      <div className={styles.iconBack} />
      <FontAwesomeIcon icon={iconType} />
    </div>
  )
}

export const Notifications = () => {
  const notifications = useSelector(state => state.notifications)
  const darkMode = useSelector(state => state.darkMode)
  const isMobile = useSelector(isMobileSelector)

  const getStyle = () => {
    const style = darkMode ? notificationStylesDarkMode : notificationStyles

    // apply mobile style if necessary
    if (isMobile) {
      style.Containers.DefaultStyle.width = '100%'
      style.Containers.DefaultStyle.padding = 0
      style.Containers.tr.top = '40px'
    }

    return style
  }

  const notificationNodes = useMemo(
    () =>
      notifications.map(item => {
        // Allow for default action to be present, children overrides action
        if (item.action) {
          return item
        }
        switch (item.level) {
          case 'success':
            return {
              ...item,
              children: iconTemplate('check-circle'),
            }
          case 'error':
            return {
              ...item,
              children: iconTemplate('times-circle'),
            }
          case 'warning':
            return {
              ...item,
              children: iconTemplate('exclamation-circle'),
            }
          default:
            return {
              ...item,
              children: iconTemplate('exclamation-circle'),
            }
        }
      }),
    [notifications]
  )

  return (
    <NotificationBus
      key={darkMode ? 'dark-mode' : 'light-mode'}
      notifications={notificationNodes}
      style={getStyle()}
    />
  )
}

Notifications.displayName = 'Notifications'
