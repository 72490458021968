import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import styles from './styles'

const audioContext = new (window.AudioContext || window.webkitAudioContext)()
const DEFAULT_CANVAS_WIDTH = 1024
const DEFAULT_CANVAS_HEIGHT = 64

const AudioWave = ({
  className = null,
  onClick = null,
  progress = 0,
  songUrl = null,
}) => {
  const backCanvas = useRef()
  const frontCanvas = useRef()
  const [audioData, setAudioData] = useState(null)

  // This effect fetches the audio file and stores the audio samples
  useEffect(() => {
    if (!songUrl) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    setAudioData(null)
    fetch(songUrl, { signal })
      .then(response => response.arrayBuffer())
      .then(arrayBuffer =>
        audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
          const rawData = audioBuffer.getChannelData(0)
          const samples = []
          const blockSize = Math.floor(rawData.length / DEFAULT_CANVAS_WIDTH)

          for (let i = 0; i < DEFAULT_CANVAS_WIDTH; i++) {
            let sum = 0
            for (let j = i * blockSize; j < (i + 1) * blockSize; j++) {
              sum += rawData[j]
            }
            sum /= blockSize
            samples.push(sum)
          }

          setAudioData(samples)
        })
      )

    return () => controller.abort()
  }, [songUrl, setAudioData])

  // When the audioData changes, we redraw the back canvas
  useEffect(() => {
    const canvasContext = backCanvas.current.getContext('2d')
    canvasContext.setTransform(1, 0, 0, 1, 0, 0)
    canvasContext.clearRect(0, 0, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT)
    canvasContext.fillStyle = '#ccc'
    canvasContext.translate(0, DEFAULT_CANVAS_HEIGHT / 2)
    const minSample = audioData ? Math.min(...audioData) : 0
    const maxSample = audioData ? Math.max(...audioData) : 0
    let scale = Math.max(Math.abs(minSample), Math.abs(maxSample))
    if (scale === 0) {
      canvasContext.fillRect(0, 2, DEFAULT_CANVAS_WIDTH, -2)
      return
    }
    scale = DEFAULT_CANVAS_HEIGHT / scale
    for (let i = 0; i < audioData.length; i++) {
      const height = Math.pow(Math.abs(audioData[i]), 0.9) * scale + 2
      canvasContext.fillRect(i - 1.5, -height / 2, 3, height)
    }
  }, [audioData])

  // When the audioData or the progress change, we redraw the front canvas
  useEffect(() => {
    const canvasContext = frontCanvas.current.getContext('2d')
    canvasContext.clearRect(0, 0, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT)

    // Draws a white rectangle
    canvasContext.globalCompositeOperation = 'source-over'
    canvasContext.fillStyle = '#fff'
    canvasContext.fillRect(
      0,
      0,
      DEFAULT_CANVAS_WIDTH * progress,
      DEFAULT_CANVAS_HEIGHT
    )

    // Cuts out the rectangle with the audio wave from the back canvas
    canvasContext.globalCompositeOperation = 'destination-in'
    canvasContext.drawImage(backCanvas.current, 0, 0)
  }, [audioData, progress])

  const clickCallback = useCallback(
    e => {
      if (!onClick) {
        return
      }

      const bounds = e.target.getBoundingClientRect()
      onClick((e.clientX - bounds.left) / bounds.width)
    },
    [onClick]
  )

  const cursorStyle = {
    left: `${progress * 100}%`,
  }

  return (
    <div
      className={classNames(styles.audioWave, className)}
      onMouseDown={clickCallback}
    >
      <canvas
        height={DEFAULT_CANVAS_HEIGHT}
        ref={backCanvas}
        width={DEFAULT_CANVAS_WIDTH}
      />
      <canvas
        height={DEFAULT_CANVAS_HEIGHT}
        ref={frontCanvas}
        width={DEFAULT_CANVAS_WIDTH}
      />
      <div className={styles.cursor} style={cursorStyle} />
    </div>
  )
}

AudioWave.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  progress: PropTypes.number,
  songUrl: PropTypes.string,
}

export default AudioWave
