import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const FilterButtonIcon = ({ isOpen, isMobile }) => {
  return (
    <FontAwesomeIcon
      className={styles.popoverTriggerIcon}
      icon={`chevron${isMobile ? '' : '-circle'}-${isOpen ? 'up' : 'down'}`}
    />
  )
}

FilterButtonIcon.displayName = 'FilterButtonIcon'

FilterButtonIcon.propTypes = {
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
}

FilterButtonIcon.defaultProps = {
  isMobile: false,
  isOpen: false,
}
