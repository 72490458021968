import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Heading } from '../../typography'
import Knotch from '../../knotch/knotch'
import React from 'react'
import styles from './styles'

export class ReturnPolicy extends React.PureComponent {
  render() {
    return (
      <div>
        <Heading className={styles.heading}>
          <FormattedMessage
            defaultMessage="RETURN POLICY"
            id="djcity.common.policy.title"
          />
        </Heading>
        <p>
          <Knotch
            align="left"
            className={styles.knotch}
            displayType="inlineBlock"
            size="big"
          />
        </p>
        <div className={styles.faqContainer}>
          <div className={styles.faqItem}>
            <FormattedHTMLMessage
              defaultMessage="Placeholder"
              id="djcity.common.store.policy.description"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ReturnPolicy
