import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { ELLIPSIS, getRange } from './get_range'
import { TextStyle } from '../typography'

import styles from './styles'

function scrollToTop() {
  if (window) {
    window.scrollTo(0, 0)
  }
}

export const PageLink = ({ children, className, current, href }) => (
  <TextStyle
    className={classNames(
      styles.pageLink,
      {
        [styles.pageLinkCurrent]: current,
        [styles.pageLinkDisabled]: !href,
      },
      className
    )}
    variant="extra-bold"
  >
    {href ? (
      <Link onClick={scrollToTop} to={href}>
        {children}
      </Link>
    ) : (
      <div className={styles.linkStyle}>{children}</div>
    )}
  </TextStyle>
)

PageLink.displayName = 'PageLink'
PageLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  current: PropTypes.bool,
  href: PropTypes.string,
  page: PropTypes.number,
}

export class Pagination extends React.PureComponent {
  static propTypes = {
    buildUrl: PropTypes.func.isRequired,
    children: PropTypes.node,
    /** Current page index */
    page: PropTypes.number.isRequired,
    /** Total number of pages */
    pageCount: PropTypes.number.isRequired,
  }

  render() {
    const { buildUrl, page, pageCount, children } = this.props
    if (pageCount <= 1) {
      return children
    }
    const range = getRange(page, pageCount)
    return (
      <React.Fragment>
        {children}
        <div className={styles.pagination}>
          <ul className={styles.paginationList}>
            <li className={styles.paginationListItem}>
              <PageLink
                className={styles.prevLink}
                href={buildUrl && page > 1 ? buildUrl(page - 1) : undefined}
                page={page - 1}
              >
                <FontAwesomeIcon icon="angle-left" />
              </PageLink>
            </li>
            {range.map((i, index) => (
              <li className={styles.paginationListItem} key={`${i}:${index}`}>
                {i === ELLIPSIS ? (
                  <TextStyle className={styles.ellipsis} variant="extra-bold">
                    {String.fromCharCode(i.substr(2, 4))}
                  </TextStyle>
                ) : (
                  <PageLink
                    current={page === i}
                    href={buildUrl ? buildUrl(i) : undefined}
                    page={i}
                  >
                    {i}
                  </PageLink>
                )}
              </li>
            ))}
            <li className={styles.paginationListItem}>
              <PageLink
                className={styles.nextLink}
                href={
                  buildUrl && page < pageCount ? buildUrl(page + 1) : undefined
                }
                page={page + 1}
              >
                <FontAwesomeIcon icon="angle-right" />
              </PageLink>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }
}
