import { useCallback, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

export function useQueryParameters(defaultParams, opts = {}) {
  // DOM values
  const history = useHistory()
  const { search, hash, pathname } = useLocation()

  // Store the initial values for default parameters,
  // parsers and stringifiers
  const defaultParamsRef = useRef({ ...defaultParams })
  const parsersRef = useRef(opts.parsers ? { ...opts.parsers } : {})
  const stringifiersRef = useRef(
    opts.stringifiers ? { ...opts.stringifiers } : {}
  )

  // Stores the default parametes as strings, for later comparison
  const defaultParamsString = useMemo(() => {
    const result = {}
    Object.entries(defaultParamsRef.current).forEach(([key, value]) => {
      if (stringifiersRef.current[key]) {
        result[key] = stringifiersRef.current[key](value)
      } else {
        result[key] = String(value)
      }
    })

    return result
  }, [defaultParamsRef.current, stringifiersRef.current])

  // Converts the URL's query parameters into an object
  const queryParams = useMemo(() => {
    const params = queryString.parse(search || hash)
    Object.entries(params).forEach(([key, value]) => {
      if (parsersRef.current[key]) {
        params[key] = parsersRef.current[key](value)
      }
    })

    return {
      ...defaultParamsRef.current,
      ...params,
    }
  }, [defaultParamsRef.current, search, parsersRef.current])

  // The function that sets the query parameters
  const setQueryParams = useCallback(
    params => {
      const newParams = {}
      Object.entries(params).forEach(([key, value]) => {
        let newParam
        if (stringifiersRef.current[key]) {
          newParam = stringifiersRef.current[key](value)
        } else {
          newParam = String(value)
        }
        if (newParam !== defaultParamsString[key]) {
          newParams[key] = newParam
        }
      })

      history.push(`${pathname}?${queryString.stringify(newParams)}`)
    },
    [defaultParamsString, pathname, stringifiersRef.current]
  )

  return [queryParams, setQueryParams]
}
