import {
  CLEAR_SONGS_LIST,
  RECEIVE_UPDATED_SONGS,
  REQUEST_UPDATED_SONGS,
} from '../action_constants'
import {
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  getUpdated,
} from '../api/songs'

function requestUpdatedSongs() {
  return { type: REQUEST_UPDATED_SONGS }
}

function receiveUpdatedSongs({ tracks, pagination }) {
  return { type: RECEIVE_UPDATED_SONGS, tracks, pagination }
}

export function fetchUpdatedTracks(opts = {}) {
  return function(dispatch) {
    dispatch(requestUpdatedSongs())
    return getUpdated(opts).then(res => {
      dispatch(
        receiveUpdatedSongs({
          tracks: res.tracks,
          pagination: res.metadata,
        })
      )
    })
  }
}

export function clearSongList() {
  return {
    type: CLEAR_SONGS_LIST,
    filters: defaultFilters,
    sortBy: defaultSortBy,
    pagination: defaultPagination,
  }
}
