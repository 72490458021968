import { ModuleLoader } from '../module_loader/module_loader'
import React from 'react'
const ConnectedPaymentDetails = React.lazy(() => import('./payment_details'))

const display = () => {
  return (
    <ModuleLoader>
      <ConnectedPaymentDetails />
    </ModuleLoader>
  )
}

export { display as PaymentDetailsDisplay }
