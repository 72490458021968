import { FilterPanel } from './filter_panel'
import { FormattedMessage } from 'react-intl'
import { RadioButton } from '../radio_button'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const TYPES = Object.freeze({
  ALL: 1,
  ACAP: 2,
  INST: 3,
  INTRO: 4,
  CLEAN: 5,
  DIRTY: 6,
})

export const CTYPES = Object.freeze({
  ALL: 1,
  ORIGINAL: 2,
  REMIXES: 3,
})

export const CATALOG = { TYPES, CTYPES }

export const TYPE_TO_LABEL = Object.freeze({
  ALL: 'All',
  ACAP: 'Acapella',
  INST: 'Instrumental',
  INTRO: 'Intro',
  CLEAN: 'Clean',
  DIRTY: 'Dirty',
})

export const CTYPE_TO_LABEL = Object.freeze({
  ALL: 'All',
  ORIGINAL: 'Originals',
  REMIXES: 'Remixes',
})

export const LABELS = {
  TYPES: TYPE_TO_LABEL,
  CTYPES: CTYPE_TO_LABEL,
}

//@TODO Cleanup and move to server side
export const TYPE_TO_TRACK_TYPE = Object.freeze({
  2: [5, 8, 9, 15, 16, 17],
  3: [4, 13, 14],
  4: [10, 11, 12],
  5: [2, 10],
  6: [3, 11],
})

export const getLabelForTypeValue = type => {
  const key = Object.keys(TYPES).find(key => TYPES[key] === type)
  return TYPE_TO_LABEL[key]
}

export const getTrackTypeFromTypeValue = type => {
  return TYPE_TO_TRACK_TYPE[type]
}

const ID_PREFIXES = {
  TYPES: 'type-',
  CTYPES: 'ctype-',
}

function parseValFromId(variant, id) {
  return parseInt(id.replace(ID_PREFIXES[variant], ''))
}

function formatValAsId(variant, val) {
  return `${ID_PREFIXES[variant]}${val}`
}

export const TypeFilterColumn = ({ onChange, type = 1, variant = 'TYPES' }) => {
  const handleChange = e => {
    onChange(variant.toLowerCase(), [parseValFromId(variant, e.target.id)])
  }

  return (
    <div
      className={
        variant === 'CTYPES'
          ? styles.ctypeFilterColumn
          : styles.typeFilterColumn
      }
    >
      {Object.keys(CATALOG[variant]).map(key => (
        <RadioButton
          checked={type === CATALOG[variant][key]}
          className={styles.radioButton}
          id={formatValAsId(variant, CATALOG[variant][key])}
          key={key}
          labelText={LABELS[variant][key]}
          name={variant.toLowerCase()}
          onChange={handleChange}
          withRadioIcon={true}
        />
      ))}
    </div>
  )
}

TypeFilterColumn.displayName = 'TypeFilterColumn'

TypeFilterColumn.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TYPES)),
  variant: PropTypes.oneOf(['TYPES', 'CTYPES']),
}

export const TypeFilterPanel = ({
  typesClass,
  ctypesClass,
  onChange,
  types,
  ctypes,
}) => (
  <>
    <FilterPanel
      className={typesClass}
      title={
        <FormattedMessage
          defaultMessage="Show Only"
          id="djcity.common.filter.showOnly"
        />
      }
    >
      <TypeFilterColumn onChange={onChange} type={types[0]} variant="TYPES" />
    </FilterPanel>
    <FilterPanel
      className={ctypesClass}
      title={
        <FormattedMessage
          defaultMessage="Content Type"
          id="djcity.common.filter.contentType"
        />
      }
    >
      <TypeFilterColumn onChange={onChange} type={ctypes[0]} variant="CTYPES" />
    </FilterPanel>
  </>
)

TypeFilterPanel.displayName = 'TypeFilterPanel'

TypeFilterPanel.propTypes = {
  ctypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CTYPES))),
  ctypesClass: PropTypes.string,
  onChange: PropTypes.func,
  types: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TYPES))),
  typesClass: PropTypes.string,
}
