import { Browse } from '../../browse'
import { Layout, Sidebar } from '../../layout'
import React from 'react'
import RemixerSearch from './remixer_search'

const display = () => {
  return (
    <Layout tabTitle="remixers">
      <Sidebar>
        <Browse />
      </Sidebar>
      <RemixerSearch />
    </Layout>
  )
}

export { display as RemixerSearchDisplay }

export { RemixerSearch }

export default RemixerSearch
