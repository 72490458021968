import { activeSubscriptionSelector } from 'spa/selectors/users'
import { defineMessages, injectIntl } from 'react-intl'
import { download } from 'spa/action_creators/tracks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MAX_DOWNLOAD_COUNT } from 'spa/shared/utils'
import { trackSelector } from 'spa/selectors/songs'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles.sass'

const localizedMessages = defineMessages({
  download: {
    id: 'djcity.common.icons.download.alt',
    defaultMessage: 'Download to Desktop',
  },
})

const DownloadTrackButton = ({
  className,
  songId: songIdString,
  trackId: trackIdString,
  trackList,
  intl,
}) => {
  const songId = parseInt(songIdString, 10)
  const trackId = parseInt(trackIdString, 10)
  const dispatch = useDispatch()
  const track = useSelector(state => trackSelector(state, { songId, trackId }))
  const activeSubscription = useSelector(activeSubscriptionSelector)
  const enabled = !!(
    (!songId && !trackId) ||
    (activeSubscription && track && track.downloadCount < MAX_DOWNLOAD_COUNT)
  )

  const clickHandler = useCallback(async () => {
    if (songId && trackId && !track.isDownloading) {
      await dispatch(download({ songId, trackId, trackList }))
    }
  }, [songId, trackId, track?.isDownloading, trackList])

  return track?.isDownloading ? (
    <button className="cleanButton">
      <FontAwesomeIcon icon="circle-notch" spin />
    </button>
  ) : (
    <button
      className={classNames(
        'cleanButton',
        styles.downloadTrackButton,
        className,
        {
          [styles.enabled]: enabled,
        }
      )}
      onClick={clickHandler}
    >
      <svg
        alt={intl.formatMessage(localizedMessages.download)}
        viewBox="0 0 13 15"
      >
        <path
          clipRule="evenodd"
          d="M12.25 5.18732H8.75V0H3.5V5.18732H0L6.125 11.2392L12.25 5.18732Z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M0 12.9685V14.6976H12.25V12.9685H0Z"
          fillRule="evenodd"
        />
      </svg>
    </button>
  )
}

DownloadTrackButton.displayName = 'DownloadTrackButton'

DownloadTrackButton.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  songId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackList: PropTypes.string,
}

const ConnectedDownloadTrackButton = injectIntl(DownloadTrackButton)

export { ConnectedDownloadTrackButton as DownloadTrackButton }
