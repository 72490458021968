import { Browse } from '../../browse'
import { Layout, Sidebar } from '../../layout'
import React from 'react'
import TagSearch from './tag_search'

const display = () => {
  return (
    <Layout tabTitle="genres">
      <Sidebar>
        <Browse />
      </Sidebar>
      <TagSearch />
    </Layout>
  )
}

export { display as TagSearchDisplay }

export { TagSearch }

export default TagSearch
