import * as songsApi from '../api/songs'
import {
  ADD_SONG_TO_QUEUE,
  ADD_SONGS_TO_EXISTING_QUEUE,
  ADD_SONGS_TO_QUEUE,
  CLEAR_QUEUE,
  COMPLETE_SONG,
  PAUSE_CURRENT_SONG,
  PLAY_CURRENT_SONG,
  RECEIVE_EXCLUSIVES_SONGS,
  RECEIVE_GLOBAL_SONGS,
  RECEIVE_NEW_SONGS,
  RECEIVE_SONGS_BY_REMIXER,
  RECEIVE_SONGS_BY_TAG,
  RECEIVE_SONGS_HOT_BOX,
  REMOVE_SONG_FROM_QUEUE,
  SELECT_NEXT_SONG,
  SELECT_PREV_SONG,
  SET_CURRENT_SONG,
  UPDATED_SONGS_AS_PLAYED,
  UPDATING_SONGS_AS_PLAYED,
} from '../action_constants'
import { AUTO_DISMISS_SECONDS, NOTIFICATION_MESSAGES } from '../shared/utils'
import { cloneDeep, difference, isEmpty } from 'lodash'
import { groupByDate } from 'spa/selectors/songs'
import { intl } from '../shared/IntlGlobalProvider'
import { SORT_OPTIONS } from '../components/filters'
import { success } from 'react-notification-system-redux'
import { TRACKLIST } from '../shared/constants'
import { userIdSelector } from '../selectors/users'

const notification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.playerTitle),
    message: '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

export function setCurrentSong(song, trackListReference) {
  return function(dispatch, getState) {
    const state = getState()
    const setCurrentSongAction = {
      type: SET_CURRENT_SONG,
      song,
      trackListReference,
    }

    let songsList, apiMethod, actionConstant

    switch (trackListReference) {
      case TRACKLIST.EXCLUSIVES:
        songsList = 'exclusivesSongs'
        apiMethod = 'getExclusives'
        actionConstant = RECEIVE_EXCLUSIVES_SONGS
        break
      case TRACKLIST.GENRES:
        songsList = 'tagSongs'
        apiMethod = 'getAll'
        actionConstant = RECEIVE_SONGS_BY_TAG
        break
      case TRACKLIST.GLOBAL:
        songsList = 'globalSongs'
        apiMethod = 'getAll'
        actionConstant = RECEIVE_GLOBAL_SONGS
        break
      case TRACKLIST.HOTBOX:
        songsList = 'hotBoxSongs'
        apiMethod = 'hotBox'
        actionConstant = RECEIVE_SONGS_HOT_BOX
        break
      case TRACKLIST.NEW_RELEASES:
        songsList = 'newSongs'
        apiMethod = 'getNew'
        actionConstant = RECEIVE_NEW_SONGS
        break
      case TRACKLIST.REMIXERS:
        songsList = 'remixSongs'
        apiMethod = 'getAll'
        actionConstant = RECEIVE_SONGS_BY_REMIXER
        break
    }

    if (songsList) {
      setCurrentSongAction.playingPage = {
        filters: cloneDeep(state[songsList].filters),
        pagination: cloneDeep(state[songsList].pagination),
        sortBy: state[songsList].sortBy,
        apiMethod,
        actionConstant,
        lastPageSongIds: cloneDeep(state[songsList].songIds),
      }
    } else
      setCurrentSongAction.playingPage = trackListReference
        ? undefined
        : state.player.playingPage

    dispatch(setCurrentSongAction)

    if (
      state.player.queue &&
      state.player.queue.length &&
      parseInt(state.player.queue.at(-1).id) === parseInt(song.rid) &&
      state.userPreferences.autoplay
    ) {
      dispatch(addNextSongsGroupToExistingQueue())
    }
  }
}

export function playCurrentSong() {
  return {
    type: PLAY_CURRENT_SONG,
  }
}

export function pauseCurrentSong() {
  return {
    type: PAUSE_CURRENT_SONG,
  }
}

export function selectNextSong() {
  return function(dispatch, getState) {
    const state = getState()

    dispatch({
      type: SELECT_NEXT_SONG,
    })

    if (
      state.player.queue &&
      state.player.queue.length &&
      parseInt(state.player.queue.at(-2).id) ===
        parseInt(state.player.currentSongId) &&
      state.userPreferences.autoplay
    ) {
      dispatch(addNextSongsGroupToExistingQueue())
    }
  }
}

export function selectPrevSong() {
  return {
    type: SELECT_PREV_SONG,
  }
}

export function completeSong() {
  return {
    type: COMPLETE_SONG,
  }
}

export function addSongToQueue(song, trackList) {
  return function(dispatch, getState) {
    const playerState = getState().player
    dispatch(
      success({
        ...notification(intl),
        message: intl.formatMessage(
          NOTIFICATION_MESSAGES.songAddedToPreviewQueue
        ),
      })
    )
    dispatch({
      type: ADD_SONG_TO_QUEUE,
      song,
      trackList,
    })
    // If there are no songs in queue, and current song id is not set, then set the current song to play.
    if (isEmpty(playerState.queue) && !playerState.currentSongId) {
      dispatch(setCurrentSong(song, trackList))
      dispatch(playCurrentSong(song))
    }
    return
  }
}

export function addSongsToQueue(songs) {
  return function(dispatch, getState) {
    const state = getState()

    dispatch({
      type: ADD_SONGS_TO_QUEUE,
      songs,
    })

    if (
      parseInt(songs.at(-1).rid) === parseInt(state.player.currentSongId) &&
      state.userPreferences.autoplay
    ) {
      dispatch(addNextSongsGroupToExistingQueue())
    }
  }
}

export function updatingSongsAsPlayed(rids) {
  return {
    type: UPDATING_SONGS_AS_PLAYED,
    rids,
  }
}

export function updatedSongsAsPlayed(rids) {
  return {
    type: UPDATED_SONGS_AS_PLAYED,
    rids,
  }
}

export function updateSongsAsPlayed() {
  return function(dispatch, getState) {
    const state = getState()
    const { markPlayed, played } = state.player
    const userId = userIdSelector(state)
    const unsentSongs = difference(played, markPlayed)
    if (unsentSongs.length > 0 && userId) {
      dispatch(updatingSongsAsPlayed(unsentSongs))
      return songsApi.played(unsentSongs).then(() => {
        dispatch(updatedSongsAsPlayed(unsentSongs))
      })
    }
  }
}

export function removeSongFromQueue(song) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_SONG_FROM_QUEUE,
      song,
    })
    dispatch(
      success({
        ...notification(intl),
        message: intl.formatMessage(
          NOTIFICATION_MESSAGES.songRemovedFromPreviewQueue
        ),
      })
    )
    return
  }
}

export function clearQueue() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_QUEUE,
    })
    return
  }
}

function getNextSongGroupDate(state) {
  const artistSongs = state.artistSongs
  const updatedSongs = state.updatedSongs
  const player = state.player
  const songsById = state.songs.songsById
  let groups

  if (player.trackListReference === TRACKLIST.HISTORY) {
    groups = groupByDate(state.currentUser.previouslyDownloaded, 'downloadDate')
      .sort((a, b) =>
        new Date(a['downloadDate']) < new Date(b['downloadDate']) ? 1 : -1
      )
      .map(a => ({ songs: a.songs.map(t => t.record) }))
  } else if (
    updatedSongs &&
    updatedSongs.tracks &&
    updatedSongs.tracks.length
  ) {
    groups = groupByDate(updatedSongs.tracks, 'changeDate')
      .sort((a, b) =>
        new Date(a['changeDate']) < new Date(b['changeDate']) ? 1 : -1
      )
      .map(a => ({ songs: a.songs.map(t => t.record) }))
  } else if (
    // artistSongs has different format than the rest because filtering is done in the frontend
    artistSongs &&
    artistSongs.filteredSongs &&
    artistSongs.filteredSongs.length &&
    (artistSongs.sortBy === SORT_OPTIONS.DATE_ASC ||
      artistSongs.sortBy === SORT_OPTIONS.DATE_DESC)
  )
    groups = groupByDate(artistSongs.filteredSongs)
  else {
    const currentSongsList =
      player.playingPage &&
      player.playingPage.lastPageSongIds.length &&
      (player.playingPage.sortBy === SORT_OPTIONS.DATE_ASC ||
        player.playingPage.sortBy === SORT_OPTIONS.DATE_DESC)
        ? player.playingPage
        : undefined

    if (!currentSongsList) return []

    const { lastPageSongIds = [] } = currentSongsList
    groups = groupByDate(
      lastPageSongIds.map(id => songsById[id]).filter(x => x)
    )
  }

  if (!groups.length) return []

  const currentGroupIndex = groups.findIndex(group =>
    group.songs.some(
      track => parseInt(track.rid) === parseInt(player.currentSongId)
    )
  )

  // if current playing group is not found in the list, means that the list has loaded the groups of the next page (so the first group sgould be next)
  if (currentGroupIndex === -1) return groups[0].songs

  return currentGroupIndex === groups.length - 1 || // is last group in the list (no other group to load)
    parseInt(groups[currentGroupIndex].songs.at(-1).rid) !==
      parseInt(player.currentSongId) // is NOT last song in the group (load next group only if last song)
    ? []
    : groups[currentGroupIndex + 1].songs
}

function addNextSongsGroupToExistingQueue() {
  return async function(dispatch, getState) {
    const state = getState()
    let songs = getNextSongGroupDate(state)

    if (!songs.length && state.player.playingPage?.apiMethod) {
      const nextPageNumber = state.player.playingPage.pagination.page + 1
      const pageLimit = state.player.playingPage.pagination.pageCount
      if (nextPageNumber > pageLimit) return

      const response = await songsApi[state.player.playingPage.apiMethod]({
        ...state.player.playingPage.filters,
        ...state.player.playingPage.pagination,
        page: nextPageNumber,
        sortBy: state.player.playingPage.sortBy,
      })

      songs = response.data

      if (!songs || !songs.length) return

      state.player.playingPage.pagination.page = nextPageNumber
      state.player.playingPage.lastPageSongIds = songs.map(song => song.rid)

      dispatch({
        type: state.player.playingPage.actionConstant,
        filters: state.player.playingPage.filters,
        pagination: state.player.playingPage.pagination,
        sortBy: state.player.playingPage.sortBy,
        songs,
        loadPlayerSongsInBackround: true,
      })
    }
    if (songs.length) {
      const nextSongs = getNextSongGroupDate(state)
      songs = nextSongs.length ? nextSongs : songs

      dispatch({
        type: ADD_SONGS_TO_EXISTING_QUEUE,
        songs,
      })
    }
  }
}
