import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Styles from './styles'

export function Knotch({
  align,
  verticalAlign,
  fat,
  displayType,
  size,
  className,
}) {
  return (
    <div
      className={classNames(
        Styles.base,
        Styles[verticalAlign],
        Styles[align],
        Styles[fat],
        Styles[displayType || 'block'],
        Styles[size || 'big'],
        className
      )}
    />
  )
}

Knotch.propTypes = {
  align: PropTypes.oneOf(['left', 'center']),
  className: PropTypes.string,
  displayType: PropTypes.oneOf(['block', 'inlineBlock']),
  fat: PropTypes.string,
  size: PropTypes.oneOf(['big', 'small']),
  verticalAlign: PropTypes.string,
}

export default Knotch
