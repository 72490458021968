import { addSongsToQueue } from 'spa/action_creators/player'
import { filterInt } from 'spa/shared/utils'
import { SimpleSongsListItem } from './simple_songs_list_item'
import { SongPropType } from 'spa/api/songs'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const SimpleSongsList = ({
  className,
  showArtist = true,
  showBorders,
  showNumbers,
  songs,
  queuedSongIds,
  variant,
  addSongToQueue,
  removeSongFromQueue,
  trackList,
  fixedPopover = false,
  showPopularity,
  id,
}) => {
  const dispatch = useDispatch()
  const { autoplay } = useSelector(state => state.userPreferences)
  const { currentSongId } = useSelector(state => state.player)

  const handleClickBubble = e => {
    const { listIndex } = e.target.dataset
    const songIndex = filterInt(listIndex)
    if (
      autoplay &&
      typeof songIndex === 'number' &&
      songs[songIndex] &&
      parseInt(currentSongId) !== parseInt(songs[songIndex].rid)
    ) {
      if (songs.length) dispatch(addSongsToQueue(songs))
    }
  }
  return (
    <div id={id} onClick={handleClickBubble}>
      <ul className={classNames(styles.simpleSongsList, className)}>
        {songs.map((song, index) => (
          <SimpleSongsListItem
            addSongToQueue={addSongToQueue}
            currentlyInQueue={
              queuedSongIds ? queuedSongIds.includes(parseInt(song.rid)) : false
            }
            fixedPopover={fixedPopover}
            index={showNumbers ? index + 1 : undefined}
            key={song.rid}
            listIndex={index}
            removeSongFromQueue={removeSongFromQueue}
            showArtist={showArtist}
            showBorder={showBorders}
            showPopularity={showPopularity}
            song={song}
            songs={songs}
            trackList={trackList}
            variant={variant}
          />
        ))}
      </ul>
    </div>
  )
}

SimpleSongsList.propTypes = {
  addSongToQueue: PropTypes.func,
  className: PropTypes.string,
  compact: PropTypes.bool,
  fixedPopover: PropTypes.bool,
  id: PropTypes.string,
  queuedSongIds: PropTypes.arrayOf(PropTypes.number),
  removeSongFromQueue: PropTypes.func,
  showArtist: PropTypes.bool,
  showBorders: PropTypes.bool,
  showNumbers: PropTypes.bool,
  showPopularity: PropTypes.bool,
  songs: PropTypes.arrayOf(SongPropType),
  trackList: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
}
