import {
  CLEAR_NEW_SONGS,
  RECEIVE_NEW_SONGS,
  REQUEST_NEW_SONGS,
} from '../action_constants'
import {
  defaultFilters,
  defaultNewReleasesPagination,
  defaultPagination,
  defaultSortBy,
  getNew,
} from '../api/songs'

import { createSongsListActionCreator } from './create_songs_list_action_creator'

export const fetchNewSongs = createSongsListActionCreator({
  defaultFilters,
  defaultPagination: defaultNewReleasesPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_NEW_SONGS,
  receiveActionConstant: RECEIVE_NEW_SONGS,
  apiMethod: getNew,
  reducerName: 'newSongs',
})

export function clearSongList() {
  return {
    type: CLEAR_NEW_SONGS,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}
