import { CategoryFilterPanel, FilterPopover } from 'spa/components/filters'
import { clearSongList, fetchNewSongs } from 'spa/action_creators/new_songs'
import { DEFAULT_DATE_OPTION, generateQueryParams } from 'spa/shared/utils'
import {
  defaultFilters,
  defaultNewReleasesPagination,
  filterParsers,
  filterStringifiers,
} from 'spa/api/songs'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Heading, TextStyle } from 'spa/components/typography'
import { isMobileSelector } from '../../selectors/device'
import { Knotch } from 'spa/components/knotch'
import { Loading } from 'spa/components/loading'
import { localeSelector } from 'spa/selectors/users'
import { loggedInSelector } from 'spa/selectors/users'
import { newSongsSelector } from 'spa/selectors/songs'
import { NoRecordsLabel } from 'spa/components/no_records_label'
import { Pagination } from 'spa/components/pagination'
import { SongTable } from 'spa/components/table'
import { TRACKLIST } from '../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { usePaginationUrl } from 'spa/hooks/use_pagination_url'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styles from './styles'

const NewReleasesTable = ({ intl, subHeading = '' }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.newSongs.isFetching)
  const pageCount = useSelector(state => state.newSongs.pagination.pageCount)
  const songs = useSelector(newSongsSelector)
  const isLoadingMore = useSelector(state => state.songs.isLoading)
  const isLoggedIn = useSelector(loggedInSelector)
  const locale = useSelector(localeSelector)
  const isMobile = useSelector(isMobileSelector)

  const [queryParams, setQueryParams] = useQueryParameters(
    {
      ...defaultFilters,
      ...defaultNewReleasesPagination,
    },
    {
      parsers: filterParsers,
      stringifiers: filterStringifiers,
    }
  )

  const buildPaginationUrl = usePaginationUrl(queryParams)

  // Resets and loads the song list
  useEffect(() => {
    dispatch(fetchNewSongs(generateQueryParams({ locale, queryParams })))

    return () => dispatch(clearSongList())
  }, [locale, queryParams, isLoggedIn, dispatch])

  return (
    <>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="Record Pool"
            id="djcity.common.record_pool"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            <FormattedMessage
              defaultMessage="New Releases"
              id="djcity.common.new_releases"
            />
          </Heading>
          {!isMobile && (
            <FilterPopover
              filters={queryParams}
              id="newReleasesFilter"
              onChange={setQueryParams}
            />
          )}
        </div>
        {subHeading ? <p>{subHeading}</p> : null}
        <Knotch className={styles.knotch} size="big" />
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <div>
            <FormattedMessage
              defaultMessage="Date (newest first)"
              id="djcity.common.sort.label.date.desc"
            />
          </div>
          <FilterPopover
            filters={queryParams}
            isMobile={isMobile}
            onChange={setQueryParams}
          />
        </div>
      )}
      <CategoryFilterPanel filters={queryParams} onChange={setQueryParams} />
      {isFetching && !songs.length ? (
        <Loading intl={intl} />
      ) : (
        <Pagination
          buildUrl={buildPaginationUrl}
          page={queryParams.page}
          pageCount={pageCount}
        >
          <div id="newReleasesTable">
            {songs.map((group, i) => (
              <SongTable
                className={styles.table}
                firstColumnHeaderLabel={intl.formatDate(
                  new Date(group.releasedate),
                  DEFAULT_DATE_OPTION
                )}
                isLoading={isLoadingMore}
                key={group.releasedate}
                nextSongs={i + 1 === songs.length ? [] : songs[i + 1].songs}
                songs={group.songs}
                trackList={TRACKLIST.NEW_RELEASES}
              />
            ))}
          </div>
          {songs.length === 0 ? <NoRecordsLabel /> : null}
        </Pagination>
      )}
    </>
  )
}

NewReleasesTable.displayName = 'NewReleasesTable'
NewReleasesTable.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
  }).isRequired,
  subHeading: PropTypes.string,
}

export default injectIntl(NewReleasesTable)
