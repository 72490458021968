import { registerDarkMode } from 'spa/action_creators/dark_mode'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles'

const Sun = () => (
  <svg className={styles.sun} viewBox="0 0 12 12">
    <path d="M6.00021 2.99438C4.3429 2.99438 2.99463 4.34292 2.99463 6.00023C2.99463 7.65754 4.3429 9.00608 6.00021 9.00608C7.65725 9.00608 9.00579 7.6578 9.00579 6.00023C9.00579 4.34266 7.65725 2.99438 6.00021 2.99438Z" />
    <path d="M6.00002 2.11089C5.67611 2.11089 5.41357 1.84836 5.41357 1.52471V0.586448C5.41357 0.262533 5.67611 0 6.00002 0C6.32394 0 6.58647 0.262533 6.58647 0.586448V1.52471C6.58647 1.84836 6.32367 2.11089 6.00002 2.11089Z" />
    <path d="M6.00002 9.88879C5.67611 9.88879 5.41357 10.1513 5.41357 10.4752V11.4132C5.41357 11.7374 5.67611 12 6.00002 12C6.32394 12 6.58647 11.7374 6.58647 11.4132V10.4752C6.58647 10.1513 6.32367 9.88879 6.00002 9.88879Z" />
    <path d="M8.74971 3.24999C8.52093 3.02093 8.52093 2.64972 8.74971 2.42067L9.41322 1.75716C9.64201 1.52837 10.0135 1.52837 10.2425 1.75716C10.4716 1.98621 10.4716 2.35769 10.2425 2.58648L9.57903 3.24999C9.35025 3.47904 8.97903 3.47904 8.74971 3.24999Z" />
    <path d="M3.24996 8.75024C3.02091 8.52092 2.6497 8.52092 2.42065 8.75024L1.75714 9.41348C1.52835 9.64226 1.52809 10.014 1.75714 10.2428C1.98619 10.4716 2.35767 10.4716 2.58646 10.2428L3.24996 9.57902C3.47902 9.35024 3.47902 8.97876 3.24996 8.75024Z" />
    <path d="M9.88916 5.9999C9.88916 5.67599 10.1517 5.41345 10.4756 5.41345H11.4139C11.7378 5.41345 12.0003 5.67599 12.0003 5.9999C12.0003 6.32382 11.7378 6.58608 11.4139 6.58608H10.4756C10.1517 6.58608 9.88916 6.32382 9.88916 5.9999Z" />
    <path d="M2.11089 5.9999C2.11089 5.67599 1.84836 5.41345 1.52445 5.41345H0.586448C0.262533 5.41345 0 5.67599 0 5.9999C0 6.32382 0.262533 6.58608 0.586448 6.58608H1.52471C1.84836 6.58608 2.11089 6.32382 2.11089 5.9999Z" />
    <path d="M8.74943 8.75033C8.97848 8.52154 9.34996 8.52154 9.57874 8.75033L10.2423 9.41383C10.4713 9.64235 10.4713 10.0141 10.2423 10.2429C10.0132 10.4717 9.64199 10.4717 9.41293 10.2429L8.74943 9.57938C8.52037 9.35033 8.52037 8.97911 8.74943 8.75033Z" />
    <path d="M3.25006 3.25004C3.47912 3.02099 3.47912 2.64978 3.25006 2.42072L2.58656 1.75748C2.3575 1.52843 1.98629 1.52843 1.75724 1.75748C1.52819 1.98627 1.52819 2.35775 1.75724 2.58653L2.42075 3.25004C2.6498 3.47936 3.02101 3.47936 3.25006 3.25004Z" />
  </svg>
)

Sun.displayName = 'Sun'

const Moon = () => (
  <svg className={styles.moon} viewBox="0 0 13 13">
    <path d="M12.6837 7.40401C12.5507 7.37076 12.4177 7.40401 12.3013 7.48712C11.8691 7.85284 11.3704 8.15206 10.8218 8.35154C10.3065 8.55102 9.74131 8.65076 9.14287 8.65076C7.79638 8.65076 6.56624 8.10219 5.6852 7.22115C4.80416 6.34011 4.25559 5.10998 4.25559 3.76348C4.25559 3.19829 4.35533 2.64971 4.52157 2.15101C4.70442 1.61906 4.9704 1.13698 5.31949 0.721399C5.4691 0.538542 5.43585 0.272568 5.253 0.122957C5.13663 0.0398399 5.00364 0.00659309 4.87066 0.0398399C3.45767 0.422178 2.22754 1.26997 1.3465 2.40036C0.498702 3.51413 0 4.89387 0 6.38998C0 8.20193 0.73143 9.84765 1.92831 11.0445C3.1252 12.2414 4.75429 12.9728 6.58287 12.9728C8.11222 12.9728 9.52521 12.4409 10.6556 11.5599C11.8026 10.6622 12.6338 9.38219 12.9829 7.91933C13.0494 7.68661 12.9164 7.45388 12.6837 7.40401Z" />
  </svg>
)

Moon.displayName = 'Moon'

export const DarkSwitch = ({ className }) => {
  const dispatch = useDispatch()
  const isDarkMode = useSelector(state => state.darkMode)

  const onClick = useCallback(() => dispatch(registerDarkMode(!isDarkMode)), [
    dispatch,
    isDarkMode,
  ])

  return (
    <button
      className={classNames(
        'cleanButton',
        styles.darkSwitch,
        {
          [styles.dark]: isDarkMode,
        },
        className
      )}
      onClick={onClick}
    >
      <div className={styles.switch}>{isDarkMode ? <Moon /> : <Sun />}</div>
    </button>
  )
}

DarkSwitch.displayName = 'DarkSwitch'

DarkSwitch.propTypes = {
  className: PropTypes.string,
}
