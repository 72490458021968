import { LOCALES } from '../../../locale_data/config'

/**
 * Retrieves News links based on user locale
 * @param locale
 * @returns {string}
 */
export function getLocalizedNewsLink(locale) {
  switch (locale) {
    case LOCALES.de_DE:
      return 'https://news.djcity.com/de/'
    case LOCALES.ja_JP:
      return 'https://news.djcity.com/jp/'
    case LOCALES.es_ES:
      return 'https://news.djcity.com/latino/'
    default:
      return 'https://news.djcity.com/'
  }
}
