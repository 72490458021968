import {
  CLEAR_SEARCH,
  FILTER_SEARCH_SONGS,
  RECEIVE_ADD_BATCH_TO_CRATE,
  RECEIVE_ADD_TO_CRATE,
  RECEIVE_REMOVE_ALL_FROM_CRATE,
  RECEIVE_REMOVE_BATCH_FROM_CRATE,
  RECEIVE_REMOVE_FROM_CRATE,
  RECEIVE_SEARCH_SONGS,
  RECEIVE_SEND_CRATE_TO_DOWNLOADER,
  REQUEST_SEARCH_SONGS,
} from '../action_constants'
import { defaultFilters, defaultPagination } from '../api/songs'
import { SORT_OPTIONS } from '../components/filters'
import produce from 'immer'

const defaultState = {
  isFetching: false,
  filters: defaultFilters,
  pagination: defaultPagination,
  searchTerm: '',
  sortBy: SORT_OPTIONS.POPULARITY,
  songs: [],
  tags: null,
  exact: false,
}

function findSong(songs, rid, ttid) {
  const song = songs.find(s => parseInt(s.rid) === parseInt(rid))
  if (song) {
    const type = song.types.find(t => t.ttid === parseInt(ttid))
    if (type) {
      return [song, type]
    }
  }

  return []
}

const searchSongs = produce((newState, action) => {
  let song, type

  switch (action.type) {
    case CLEAR_SEARCH:
      newState.isFetching = defaultState.isFetching
      newState.filters = defaultState.filters
      newState.pagination = defaultState.pagination
      newState.searchTerm = defaultState.searchTerm
      newState.sortBy = defaultState.sortBy
      newState.songs = defaultState.songs
      newState.tags = defaultState.tags
      newState.exact = defaultState.exact
      break

    case REQUEST_SEARCH_SONGS:
      newState.isFetching = true
      newState.filters = action.filters
      newState.searchTerm = action.searchTerm
      newState.sortBy = action.sortBy
      newState.exact = action.exact
      break

    case RECEIVE_SEARCH_SONGS:
      newState.isFetching = false
      newState.songs = action.songs
      break

    case FILTER_SEARCH_SONGS:
      newState.filters = action.filters
      newState.sortBy = action.sortBy
      break

    case RECEIVE_ADD_TO_CRATE:
      ;[song, type] = findSong(
        newState.songs,
        action.item.track.record.rid,
        action.item.track.ttid
      )
      if (song && type) {
        type.inCrate = true
      }
      break

    case RECEIVE_ADD_BATCH_TO_CRATE:
      for (let item of action.items) {
        ;[song, type] = findSong(
          newState.songs,
          item.track.record.rid,
          item.track.ttid
        )
        if (song && type) {
          type.inCrate = true
        }
      }

      break

    case RECEIVE_REMOVE_FROM_CRATE:
      ;[song, type] = findSong(newState.songs, action.songId, action.trackId)
      if (song && type) {
        type.inCrate = false
      }
      break

    case RECEIVE_REMOVE_BATCH_FROM_CRATE:
      for (let trackId of action.trackIds) {
        ;[song, type] = findSong(newState.songs, action.songId, trackId)
        if (song && type) {
          type.inCrate = false
        }
      }
      break

    case RECEIVE_REMOVE_ALL_FROM_CRATE:
      newState.songs.forEach(song => {
        song.types.forEach(type => {
          type.inCrate = false
        })
      })
      break

    case RECEIVE_SEND_CRATE_TO_DOWNLOADER:
      action.items.forEach(item => {
        ;[song, type] = findSong(newState.songs, item.rid, item.ttid)
        if (song && type) {
          type.inCrate = false
        }
      })
      break
  }
}, defaultState)

export default searchSongs
