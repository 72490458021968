import { Layout } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'
const AddressCard = React.lazy(() =>
  import('../../billing_address/address_card')
)

const display = () => {
  return (
    <Layout fullLayout tabTitle="update_address">
      <ModuleLoader>
        <AddressCard />
      </ModuleLoader>
    </Layout>
  )
}

export { display as AddressDisplay }
