import { applyFilters } from 'spa/components/search_songs/applyFilters'
import {
  CLEAR_ARTIST_SONGS,
  FILTER_ARTIST_SONGS,
  RECEIVE_ARTIST_SONGS,
  REQUEST_ARTIST_SONGS,
} from '../action_constants'
import { defaultFilters } from '../api/songs'
import { orderByOptions } from 'spa/shared/utils'
import { SORT_OPTIONS } from '../components/filters'
import orderBy from 'lodash/orderBy'
import produce from 'immer'

const defaultState = {
  isFetching: false,
  filters: defaultFilters,
  artistName: '',
  sortBy: SORT_OPTIONS.POPULARITY,
  songs: [],
  filteredSongs: [],
}

const artistSongs = produce((newState, action) => {
  switch (action.type) {
    case CLEAR_ARTIST_SONGS:
      newState.isFetching = defaultState.isFetching
      newState.filters = defaultState.filters
      newState.artistName = defaultState.artistName
      newState.sortBy = defaultState.sortBy
      newState.songs = defaultState.songs
      newState.filteredSongs = defaultState.filteredSongs
      break

    case REQUEST_ARTIST_SONGS:
      newState.isFetching = true
      newState.filters = action.filters
      newState.artistName = action.artistName
      newState.sortBy = action.sortBy
      break

    case RECEIVE_ARTIST_SONGS:
      newState.isFetching = false
      newState.songs = action.songs
      newState.filteredSongs = action.songs
      break

    case FILTER_ARTIST_SONGS:
      newState.filters = action.filters
      newState.sortBy = action.sortBy
      newState.filteredSongs = applyFilters(newState.songs, action.filters)
      if (action.sortBy !== SORT_OPTIONS.POPULARITY) {
        const { field, mode } = orderByOptions[action.sortBy - 1]
        newState.filteredSongs = orderBy(
          newState.filteredSongs,
          [field],
          [mode]
        )
      }
      break
  }
}, defaultState)

export default artistSongs
