import { Browse } from 'spa/components/browse'
import { Layout, Sidebar } from 'spa/components/layout'
import HotBox from './hot_box'
import React from 'react'

const display = () => {
  return (
    <Layout tabTitle="most_popular">
      <Sidebar>
        <Browse />
      </Sidebar>
      <HotBox />
    </Layout>
  )
}

export { display as HotBoxDisplay }

export { HotBox }

export default HotBox
