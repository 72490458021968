import { formatTime } from '../../shared/utils'
import { TextStyle } from '../typography'
import classNames from 'classnames'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.sass'

const TrackProgressBar = ({
  audioRef,
  className,
  currentTime,
  duration,
  handleDragStop,
  progressRef,
}) => {
  return (
    <div
      className={classNames(styles.progressBar, className)}
      onClick={handleDragStop}
      onMouseDown={handleDragStop}
    >
      <progress
        max={100}
        ref={progressRef}
        value={duration ? (audioRef.current?.currentTime * 100) / duration : 0}
      />
      <Draggable
        axis="x"
        bounds="parent"
        offsetParent={progressRef?.current}
        onDrag={handleDragStop}
        // onStop={handleDragStop}
        position={{
          x:
            (audioRef.current?.currentTime / duration) *
            progressRef.current?.offsetWidth,
          y: 0,
        }}
      >
        <div className={styles.indicator} />
      </Draggable>
      <div className={styles.timeIndicator}>
        <TextStyle className={styles.timeIndicatorCurrent} color="grey">
          {formatTime(currentTime)}
        </TextStyle>
        <TextStyle className={styles.timeIndicatorDuration} color="grey">
          {duration ? formatTime(duration) : null}
        </TextStyle>
      </div>
    </div>
  )
}

const RefType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
])

TrackProgressBar.propTypes = {
  audioRef: RefType,
  className: PropTypes.string,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  handleDragStop: PropTypes.func,
  progressRef: RefType,
}

export { TrackProgressBar }
