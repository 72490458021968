import { closeModal } from 'spa/action_creators/modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Heading } from 'spa/components/typography'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'

import { isMobileSelector } from '../../selectors/device'
import styles from './styles'

export default function DarkModal({
  children = null,
  className = null,
  modalKey,
  title = null,
  onClose = null,
  ...props
}) {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.modals[modalKey].enabled)
  const isMobile = useSelector(isMobileSelector)

  const onCloseClick = useCallback(() => {
    dispatch(closeModal())
    if (onClose) {
      onClose()
    }
  }, [dispatch, onClose])

  useEffect(() => {
    const onKeyDown = e => {
      if (e.code === 'Escape') {
        dispatch(closeModal())
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', onKeyDown)
    }

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [dispatch, isOpen])

  useEffect(() => {
    if (isMobile && isOpen) document.body.classList.add('mobileModal')
    else document.body.classList.remove('mobileModal')
  }, [isMobile, isOpen])

  useEffect(() => {
    if (isOpen) document.body.classList.add('darkModal')
    else document.body.classList.remove('darkModal')
  }, [isOpen])

  return (
    <Modal
      className={classNames(styles.darkModal, className)}
      isOpen={isOpen}
      onRequestClose={onCloseClick}
      {...props}
    >
      <header className={styles.modalHeader}>
        <Heading className={styles.heading}>{title}</Heading>
        <FontAwesomeIcon
          className={styles.closeButton}
          icon={['fal', 'times']}
          onClick={onCloseClick}
        />
      </header>
      <main className={styles.modalBody}>{children}</main>
    </Modal>
  )
}

DarkModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  modalKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.node,
}
