import { Knotch } from '../knotch'
import { PROMOS } from '../../shared/utils'
import { useSelector } from 'react-redux'
import FreePackCampaignFooter from '../free_pack_campaign/free_pack_campaign_footer'
import FreePackCampaignHeader from '../free_pack_campaign/free_pack_campaign_header'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styles from './styles'
import WelcomeBackCampaignHeader from '../welcome-back-campaign/welcome_back_campaign_header'

function CampaignLayer({ children, isLoginScreen }) {
  const activePromo = useSelector(state => state.promos.activePromo)
  const [header, setHeader] = useState(null)
  const [footer, setFooter] = useState(null)

  useEffect(() => {
    switch (activePromo) {
      case PROMOS.FDP:
        setHeader(<FreePackCampaignHeader isLoginScreen={isLoginScreen} />)
        setFooter(<FreePackCampaignFooter />)
        break
      case PROMOS.WINBACK:
        setHeader(<WelcomeBackCampaignHeader isLoginScreen={isLoginScreen} />)
        if (isLoginScreen) setFooter(<FreePackCampaignFooter />)
        break
      default:
        setHeader(null)
        setFooter(null)
        break
    }
  }, [activePromo])
  return (
    <>
      {header}
      {header && (
        <div className={styles.knotches}>
          <Knotch fat="fat" />
          <Knotch fat="fat" />
          <Knotch fat="fat" />
        </div>
      )}
      {children}
      {footer}
    </>
  )
}

export default CampaignLayer

CampaignLayer.propTypes = {
  children: PropTypes.node,
  isLoginScreen: PropTypes.bool,
}
