import { defineMessages } from 'react-intl'

export const localizedMessages = defineMessages({
  downloadMobileApp: {
    id: 'djcity.billing.payment.downloadMobileApp',
    defaultMessage:
      'Download the DJCity Mobile App to stay connected to your DJ library like never before.',
  },
  downloadAppQr: {
    id: 'djcity.billing.payment.downloadAppQr',
    defaultMessage: '/assets/cdn/DJcity_Mobile_App_QR.svg',
  },
  downloadIosApp: {
    id: 'djcity.billing.payment.downloadIosApp',
    defaultMessage: '/assets/cdn/apple-store.svg',
  },
  downloadAndroidApp: {
    id: 'djcity.billing.payment.downloadAndroidApp',
    defaultMessage: '/assets/cdn/google-store.svg',
  },
  downloadAppText: {
    id: 'djcity.common.downloadAppPopoverText',
    defaultMessage: 'Download on the go with the DJCity Mobile App',
  },
})
