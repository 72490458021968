import { Layout } from '../../layout'
import {
  OnboardingWalktrough,
  OnboardingWrapper,
} from '../../onboarding_walktrough'
import Home from './home'
import HomeSidebar from './home_sidebar'
import React from 'react'

import '../../onboarding_walktrough/shepherd.plugin.css'

const display = () => {
  return (
    <Layout subscribeBanner tabTitle="home">
      <Home />
      <HomeSidebar />
    </Layout>
  )
}

const display2 = () => {
  return (
    <OnboardingWrapper>
      <Layout subscribeBanner tabTitle="home">
        <Home />
        <HomeSidebar />
        <OnboardingWalktrough />
      </Layout>
    </OnboardingWrapper>
  )
}

export { display as HomeDisplay, display2 as HomeOnboardingDisplay }

export { Home }

export default Home
