import { TextStyle } from '../typography'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const FilterPanel = ({ className, children, title }) => (
  <div className={classNames(className)}>
    <TextStyle className={styles.expanderLabel} variant="extra-bold">
      {title}
    </TextStyle>
    <div className={styles.content}>{children}</div>
  </div>
)

FilterPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
}
