import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const CategoryButton = ({ className, labelText, checked, ...rest }) => {
  return (
    <div className={classNames(styles.categoryFilter, className)}>
      <label
        className={classNames('button', {
          secondary: !checked,
        })}
      >
        {labelText}
        <input
          checked={checked}
          className={styles.input}
          type="checkbox"
          {...rest}
        />
      </label>
    </div>
  )
}

CategoryButton.displayName = 'CategoryButton'

CategoryButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  labelText: PropTypes.any,
  onChange: PropTypes.func,
}
