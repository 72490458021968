export const migrations = {
  0: state => state,
  1: prevState => ({
    ...prevState,
    player: {
      ...prevState.player,
      queue: prevState.player.queue.map(
        sId =>
          sId && {
            id: sId,
            priority: 1,
            data: prevState.songs.songsById[sId],
          }
      ),
    },
  }),
}
