import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../shared/utils'

export async function stripePayment(data = {}) {
  const response = await fetchWithHeaders(`/payments/checkout`, {
    body: JSON.stringify({
      gateway: 'STRIPE',
      ...data,
    }),
    method: 'POST',
  })
  return parseResponse(response)
}

export async function braintreePayment(data = {}) {
  const response = await fetchWithHeaders(`/payments/checkout`, {
    body: JSON.stringify({
      gateway: 'BRAINTREE',
      ...data,
    }),
    method: 'POST',
  })
  return parseResponse(response)
}

export async function ebanxPayment(data = {}) {
  const response = await fetchWithHeaders(`/payments/ebanx/checkout`, {
    body: JSON.stringify(data),
    method: 'POST',
  })
  return parseResponse(response)
}

export async function komojuPayment(data = {}) {
  const response = await fetchWithHeaders(`/payments/komoju/checkout`, {
    body: JSON.stringify(data),
    method: 'POST',
  })
  return parseResponse(response)
}

export async function getTransactions(status) {
  const response = await fetchWithHeaders(
    `/payments/transactions?${getQueryParams({ status })}`,
    { method: 'GET' }
  )
  return parseResponse(response)
}

export async function updateCurrentUserPayment(token) {
  const response = await fetchWithHeaders('/payments/stripe/card', {
    body: JSON.stringify({ token }),
    method: 'POST',
  })
  return parseResponse(response)
}

export async function getCurrentUserPayment() {
  const response = await fetchWithHeaders('/payments/stripe/card', {
    method: 'GET',
  })
  return parseResponse(response)
}

export async function cancelSubscription(userId) {
  const response = await fetchWithHeaders(
    `/subscriptions/unsubscribe?user=${userId}`,
    { method: 'POST' }
  )
  return parseResponse(response)
}

export async function reactivateSubscription(userId) {
  const response = await fetchWithHeaders(
    `/subscriptions/reactivate?user=${userId}`,
    { method: 'POST' }
  )
  return parseResponse(response)
}

export async function validateSubscriptionStatus() {
  const response = await fetchWithHeaders('/subscriptions/status', {
    method: 'POST',
  })
  return parseResponse(response)
}
