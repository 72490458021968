import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const Vinyl = ({ className = null, animated = false }) => {
  return (
    <svg
      className={classNames(styles.vinyl, className, {
        [styles.animated]: animated,
      })}
      fill="none"
      viewBox="0 0 157 157"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="78.5" cy="78.5" r="78.5" />
      <path d="M127.228 34.8206L96.4738 65.4312C99.7442 69.9747 101.181 75.5844 100.497 81.1406C99.8136 86.6968 97.0598 91.7909 92.7856 95.4061C88.5114 99.0214 83.0311 100.892 77.4385 100.644C71.8458 100.397 66.5522 98.0494 62.6142 94.0706C58.5002 89.9167 56.204 84.2992 56.2302 78.4529C56.2563 72.6067 58.6028 67.01 62.7537 62.893C66.4772 59.1787 71.3942 56.9006 76.6352 56.4616C81.8763 56.0227 87.1037 57.4511 91.3931 60.4944L122.008 29.8794C110.173 18.8026 94.482 12.7816 78.2749 13.0979C69.7083 12.9149 61.197 14.5127 53.2801 17.7903C45.3631 21.0679 38.213 25.9538 32.2826 32.1385C26.0897 38.1005 21.1902 45.2733 17.889 53.2105C14.5877 61.1477 12.9555 69.6794 13.0937 78.2747C12.9417 86.8925 14.5671 95.4492 17.8686 103.411C21.1701 111.373 26.0768 118.569 32.2826 124.55C38.2641 130.756 45.4602 135.663 53.422 138.964C61.3838 142.266 69.9405 143.891 78.5584 143.739C87.1876 143.918 95.7614 142.32 103.746 139.043C111.732 135.767 118.957 130.883 124.974 124.694C131.162 118.678 136.046 111.452 139.323 103.467C142.599 95.482 144.198 86.9083 144.019 78.279C144.201 62.1759 138.19 46.6181 127.228 34.8206Z" />
      <path d="M75.5941 75.449C74.9936 76.049 74.5845 76.8136 74.4187 77.6462C74.2528 78.4787 74.3376 79.3417 74.6623 80.1261C74.9869 80.9104 75.537 81.5809 76.2428 82.0525C76.9486 82.5242 77.7784 82.776 78.6273 82.776C79.4762 82.776 80.306 82.5242 81.0118 82.0525C81.7176 81.5809 82.2676 80.9104 82.5923 80.1261C82.917 79.3417 83.0018 78.4787 82.8359 77.6462C82.67 76.8136 82.261 76.049 81.6604 75.449C81.2644 75.0467 80.7922 74.7272 80.2715 74.5091C79.7508 74.291 79.1918 74.1787 78.6273 74.1787C78.0627 74.1787 77.5038 74.291 76.9831 74.5091C76.4624 74.7272 75.9902 75.0467 75.5941 75.449Z" />
    </svg>
  )
}

Vinyl.displayName = 'vinyl'

Vinyl.propTypes = {
  animated: PropTypes.bool,
  className: PropTypes.string,
}
