import { Layout } from '../../layout'
import { News } from './news'
import React from 'react'

const display = () => {
  return (
    <Layout fullLayout tabTitle="news">
      <News />
    </Layout>
  )
}

export { display as NewsDisplay }

export { News }

export default News
