import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

const ClickOutside = React.forwardRef((props, ref) => {
  const handleMouseDown = e => {
    const { onClickOutside } = props
    if (ref.current && !ref.current.contains(e.target)) {
      onClickOutside(e)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  const { style, ...rest } = props

  return <div ref={ref} style={style} {...rest} />
})

ClickOutside.displayName = 'ClickOutside'

ClickOutside.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClickOutside: PropTypes.func.isRequired,
  setRef: PropTypes.func,
  style: PropTypes.object,
}

export { ClickOutside }
