import {
  CLEAR_REMIXER,
  CLEAR_REMIXERS,
  RECEIVE_ALL_REMIXERS,
  RECEIVE_REMIXERS,
  REQUEST_ALL_REMIXERS,
  REQUEST_REMIXERS,
} from '../action_constants'
import { defaultRemixersPagination } from '../api/songs'
import {
  RECEIVE_REMIXER,
  RECEIVE_REMIXER_ERROR,
  REQUEST_REMIXER,
} from '../action_constants'

const defaultState = {
  isFetching: false,
  pagination: defaultRemixersPagination,
}

export default function remixers(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_REMIXER:
      return {
        ...state,
        isFetchingSingle: true,
        remixer: null,
      }
    case RECEIVE_REMIXER:
      return {
        ...state,
        isFetchingSingle: false,
        remixer: action.remixer,
      }
    case REQUEST_REMIXERS:
      return {
        ...state,
        isFetchingSuggestions: true,
        remixerSuggestions: null,
      }
    case RECEIVE_REMIXERS:
      return {
        ...state,
        isFetchingSuggestions: false,
        remixerSuggestions: action.remixers.remixers,
      }
    case REQUEST_ALL_REMIXERS:
      return {
        ...state,
        isFetchingAll: true,
        allRemixers: null,
        pagination: defaultRemixersPagination,
      }
    case RECEIVE_ALL_REMIXERS:
      return {
        ...state,
        isFetchingAll: false,
        allRemixers: action.remixers.remixers,
        pagination: action.remixers.pagination
          ? {
              ...action.remixers.pagination,
              pageCount: Math.ceil(
                action.remixers.pagination.totalItems /
                  action.remixers.pagination.pageSize
              ),
            }
          : defaultRemixersPagination,
      }
    case RECEIVE_REMIXER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case CLEAR_REMIXER:
      return {
        ...state,
        remixer: null,
      }
    case CLEAR_REMIXERS:
      return {
        ...state,
        remixerSuggestions: null,
      }
    default:
      return state
  }
}
