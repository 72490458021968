import { closeModal, UPDATE_PASSWORD_MODAL } from 'spa/action_creators/modals'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { Label } from 'spa/components/label'
import { SpinnerButton } from 'spa/components/button'
import { TextInput } from 'spa/components/text_input'
import { updatePassword } from 'spa/action_creators/current_user'
import { useDispatch, useSelector } from 'react-redux'
import { usePasswordValidation } from 'spa/hooks/use_password_validation'
import { userEmailSelector } from 'spa/selectors/users'
import ApplicationModal from 'spa/components/application_modal'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { isMinimumCharacters } from '../../shared/utils'
import styles from './styles'

const localizedMessages = defineMessages({
  newPassword: {
    id: 'djcity.account.update_password.new_password',
    defaultMessage: 'New Password',
  },
  oldPassword: {
    id: 'djcity.account.update_password.old_password',
    defaultMessage: 'Current Password',
  },
})

function UpdatePasswordModal({ intl }) {
  const dispatch = useDispatch()
  const isOpen = useSelector(
    state => state.modals[UPDATE_PASSWORD_MODAL].enabled
  )
  const isResettingPassword = useSelector(state => state.isResettingPassword)
  const email = useSelector(userEmailSelector)
  const [newPassword, setNewPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')

  const isDisabled = !(
    email &&
    newPassword &&
    oldPassword &&
    isMinimumCharacters(newPassword, 8)
  )

  useEffect(() => {
    setNewPassword('')
    setOldPassword('')
  }, [isOpen, setNewPassword, setOldPassword])

  const onSubmit = async e => {
    e.preventDefault()
    if (!isDisabled) {
      await dispatch(
        updatePassword(
          {
            email,
            newPassword,
            oldPassword,
          },
          true
        )
      )
      dispatch(closeModal())
    }
  }

  const passwordValidator = usePasswordValidation(intl)

  return (
    <ApplicationModal
      modalKey={UPDATE_PASSWORD_MODAL}
      title={
        <FormattedMessage
          defaultMessage="Update Password"
          id="djcity.account.update_password.title"
        />
      }
    >
      <form className={styles.updatePasswordForm} onSubmit={onSubmit}>
        <Label
          className={styles.label}
          text={intl.formatMessage(localizedMessages.oldPassword)}
        >
          <TextInput
            className={styles.input}
            onChange={e => setOldPassword(e.target.value)}
            type="password"
            value={oldPassword}
          />
        </Label>
        <Label
          className={styles.label}
          text={intl.formatMessage(localizedMessages.newPassword)}
        >
          <TextInput
            className={styles.input}
            onChange={e => setNewPassword(e.target.value)}
            type="password"
            validator={passwordValidator}
            value={newPassword}
          />
        </Label>
        <SpinnerButton
          disabled={isDisabled || isResettingPassword}
          loading={isResettingPassword}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Update Password"
            id="djcity.account.update_password.button"
          />
        </SpinnerButton>
      </form>
    </ApplicationModal>
  )
}

UpdatePasswordModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(UpdatePasswordModal)
