import { defineMessages, injectIntl } from 'react-intl'
import { FilterPanel } from './filter_panel'
import { RadioButton } from '../radio_button'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

const localizedMessages = defineMessages({
  month: {
    id: 'djcity.common.filter.month',
    defaultMessage: 'Month',
  },
})

const MonthFilterPanel = ({ intl, isMobile, monthValues, onChange, value }) => {
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e.target.id)
      }
    },
    [onChange]
  )

  return isMobile ? (
    Object.keys(monthValues).map(encodedDate => (
      <RadioButton
        checked={value === encodedDate}
        className={styles.radioButton}
        id={encodedDate}
        key={encodedDate}
        labelText={monthValues[encodedDate]}
        name="month"
        onChange={handleChange}
      />
    ))
  ) : (
    <FilterPanel
      className={styles.filterPanel}
      title={intl.formatMessage(localizedMessages.month)}
    >
      {Object.keys(monthValues).map(encodedDate => (
        <RadioButton
          checked={value === encodedDate}
          className={styles.radioButton}
          id={encodedDate}
          key={encodedDate}
          labelText={monthValues[encodedDate]}
          name="month"
          onChange={handleChange}
        />
      ))}
    </FilterPanel>
  )
}

MonthFilterPanel.displayName = 'MonthFilterPanel'

MonthFilterPanel.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool,
  monthValues: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default injectIntl(MonthFilterPanel)
