import {
  RECEIVE_STRING_RESOURCES,
  REQUEST_STRING_RESOURCES,
} from '../action_constants'
import { SET_LOCALE } from '../action_constants'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

const defaultState = {
  isFetching: false,
  isLoading: false,
  resources: {},
  loaded: [],
}

export default function stringResources(state = defaultState, action) {
  const items = mapValues(keyBy(action.resources, 'name'), 'value')
  let loaded = state.loaded || []
  switch (action.type) {
    case REQUEST_STRING_RESOURCES:
      return { ...state, isFetching: true }
    case RECEIVE_STRING_RESOURCES:
      return {
        ...state,
        isFetching: false,
        resources: {
          ...state.resources,
          ...items,
        },
        loaded: loaded.concat(action.resourceDomains),
      }
    case SET_LOCALE:
      return {
        ...state,
        resources: {},
        loaded: [],
      }
    default:
      return state
  }
}
