import { Layout } from '../layout'
import React from 'react'
const SearchSongs = React.lazy(() => import('./search_songs'))
import { ModuleLoader } from '../module_loader/module_loader'

const display = () => {
  return (
    <Layout tabTitle="search">
      <ModuleLoader>
        <SearchSongs />
      </ModuleLoader>
    </Layout>
  )
}

export { display as SearchSongsDisplay }

export { SearchSongs }

export default SearchSongs
