import { defineMessages, injectIntl } from 'react-intl'
import { Vinyl } from 'spa/components/vinyl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  loading: {
    id: 'djcity.common.images.loading.alt',
    defaultMessage: 'Loading',
  },
  loadingText: {
    id: 'djcity.common.loading',
    defaultMessage: 'Loading...',
  },
})

export const Loading = injectIntl(
  ({ loading = true, absolute, className, intl }) => {
    return loading ? (
      <div
        className={classNames(
          styles.loadingContainer,
          {
            [styles.absolute]: !!absolute,
          },
          className
        )}
      >
        <div className={styles.loading}>
          <Vinyl animated className={styles.vinyl} />
          <span className={styles.loadingText}>
            {intl.formatMessage(localizedMessages.loadingText)}
          </span>
        </div>
      </div>
    ) : null
  }
)

Loading.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}
