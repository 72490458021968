import { FilterPanel } from './filter_panel'
import { RadioButton } from '../radio_button'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

export const LOCALE_VALUES = {
  'en-US': 'US',
  'en-UK': 'UK',
  'es-ES': 'ES',
  'ja-JP': 'JP',
  'de-DE': 'DE',
}

export const LocaleFilterPanel = ({ onChange, value }) => {
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e.target.id)
      }
    },
    [onChange]
  )

  return (
    <FilterPanel title="Locale">
      {Object.keys(LOCALE_VALUES).map(releaseDate => (
        <RadioButton
          checked={value === releaseDate}
          className={styles.radioButton}
          id={releaseDate}
          key={releaseDate}
          labelText={LOCALE_VALUES[releaseDate]}
          name="locale"
          onChange={handleChange}
        />
      ))}
    </FilterPanel>
  )
}

LocaleFilterPanel.displayName = 'LocaleFilterPanel'

LocaleFilterPanel.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}
