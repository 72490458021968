import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

export function InfiniteScroll({
  children,
  className,
  hasMoreItems = false,
  loadMoreItems = null,
  scrollThreshold = 100,
  style,
  tag,
  ...rest
}) {
  const rootNode = useRef()

  const [height, setHeight] = useState(false)
  const [isLoading, setIsLoading] = useState(0)

  const childrenCount = React.Children.count(children)

  // When the number of child elements change, store the new
  // height and turn off the loading flag
  useEffect(() => {
    setHeight(rootNode.current.getBoundingClientRect().height)
    setIsLoading(false)
  }, [childrenCount])

  useEffect(() => {
    // Cases when there's nothing to be done:
    // - New content is being loaded
    // - There's no more content to load
    // - There's no function to load new content
    // - The height of the container is 0 (first render)
    if (isLoading || !hasMoreItems || !loadMoreItems || !height) {
      return
    }

    const onScroll = () => {
      if (
        rootNode.current.scrollHeight - (height + rootNode.current.scrollTop) <=
        scrollThreshold
      ) {
        setIsLoading(true)
        loadMoreItems()
      }
    }

    onScroll()
    rootNode.current.addEventListener('scroll', onScroll)

    return () => {
      rootNode.current.removeEventListener('scroll', onScroll)
    }
  }, [childrenCount, hasMoreItems, height, isLoading, loadMoreItems])

  return React.createElement(
    tag || 'div',
    { className, ref: rootNode, style, ...rest },
    children
  )
  // return (
  //   <div className={className} ref={rootNode} style={style}>
  //     {children}
  //   </div>
  // );
}

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasMoreItems: PropTypes.bool,
  loadMoreItems: PropTypes.func,
  scrollThreshold: PropTypes.number,
  style: PropTypes.object,
  tag: PropTypes.string,
}
