import { defineMessages, injectIntl } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.sass'

const localizedMessages = defineMessages({
  crateAllTracks: {
    id: 'djcity.common.icons.add.crate_all_tracks.alt',
    defaultMessage: 'Add All Tracks To Crate',
  },
  crateAllTracksIcon: {
    id: 'djcity.common.icons.add.crate_all_tracks.src',
    defaultMessage: '/assets/cdn/crate-all-tracks-icon.svg',
  },
})

const CrateAllTracksButton = ({ className, iconClassName, intl, onClick }) => {
  return (
    <button
      className={classNames(
        'cleanButton',
        styles.crateAllTracksButton,
        className
      )}
      onClick={onClick}
    >
      <div
        aria-labelledby={intl.formatMessage(localizedMessages.crateAllTracks)}
        className={iconClassName}
        role="img"
      >
        <div>
          <svg
            height="22"
            viewBox="0 0 21 22"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.4859 2.73901H5.2159C4.80886 2.73901 4.42875 2.94244 4.20297 3.28112L4.00007 3.58547C3.46072 4.39449 4.04067 5.47814 5.013 5.47814H12.6381C13.5431 5.47814 14.1317 4.52576 13.7269 3.71632L13.5748 3.41197C13.3685 2.99954 12.947 2.73901 12.4859 2.73901Z" />
            <path
              className="fillPath"
              d="M2.83043 0C2.37391 0 1.91739 0.273913 1.64348 0.730435L0.445153 2.56317C0.214268 2.91629 0 3.32158 0 3.74348V3.74348V12.3261C0 13.0565 0.63913 13.6957 1.36957 13.6957H12.3261C13.0565 13.6957 13.6957 13.0565 13.6957 12.3261V3.74348C13.6957 3.56087 13.6043 3.28696 13.6043 3.01304L12.0522 0.63913C11.7783 0.273913 11.413 0 10.8652 0H2.83043ZM2.83043 1.36957H10.9565L11.687 2.73913H2.0087L2.83043 1.36957ZM1.36957 4.1087H12.3261V12.3261H1.36957V4.1087Z"
            />
            <path d="M4.56437 2.73901L2.73828 5.02162H14.6078L12.7818 2.73901H4.56437Z" />
            <path
              className="fillPath"
              d="M4.65856 1.82642C4.20204 1.82642 3.74552 2.10033 3.4716 2.55685L2.27328 4.38958C2.04239 4.7427 1.82812 5.14799 1.82812 5.56989V5.56989V14.1525C1.82812 14.8829 2.46726 15.5221 3.19769 15.5221H14.1542C14.8846 15.5221 15.5238 14.8829 15.5238 14.1525V5.56989C15.5238 5.38729 15.4325 5.11337 15.4325 4.83946L13.8803 2.46555C13.6064 2.10033 13.2412 1.82642 12.6933 1.82642H4.65856ZM4.65856 3.19598H12.7846L13.5151 4.56555H3.83682L4.65856 3.19598ZM3.19769 5.93511H14.1542V14.1525H3.19769V5.93511Z"
            />
            <path d="M3.19531 14.1522V5.93481H6.39096V14.1522H3.19531Z" />
            <path d="M5.02344 7.76099H15.98V15.9784H5.02344V7.76099Z" />
            <path d="M6.39249 4.56519L4.56641 6.84779H16.436L14.6099 4.56519H6.39249Z" />
            <path
              className="fillPath"
              d="M6.47887 3.6521C6.02235 3.6521 5.56583 3.92601 5.29192 4.38253L4.09359 6.21527C3.86271 6.56839 3.64844 6.97368 3.64844 7.39558V7.39558V15.9782C3.64844 16.7086 4.28757 17.3478 5.018 17.3478H15.9745C16.705 17.3478 17.3441 16.7086 17.3441 15.9782V7.39558C17.3441 7.21297 17.2528 6.93906 17.2528 6.66514L15.7006 4.29123C15.4267 3.92601 15.0615 3.6521 14.5137 3.6521H6.47887ZM6.47887 5.02166H14.605L15.3354 6.39123H5.65713L6.47887 5.02166ZM5.018 7.7608H15.9745V15.9782H5.018V7.7608ZM9.03539 9.13036C8.57887 9.13036 8.30496 9.49558 8.39626 9.8608C8.39626 10.226 8.76148 10.4999 9.1267 10.4999H11.8658C12.1397 10.4999 12.3223 10.4086 12.505 10.1347C12.5963 9.9521 12.5963 9.67819 12.505 9.40427C12.3223 9.22166 12.1397 9.13036 11.8658 9.13036H9.1267C9.1267 9.13036 9.1267 9.13036 9.03539 9.13036Z"
            />
            <path d="M15.5212 21.9128C18.5468 21.9128 20.9995 19.4601 20.9995 16.4346C20.9995 13.409 18.5468 10.9563 15.5212 10.9563C12.4957 10.9563 10.043 13.409 10.043 16.4346C10.043 19.4601 12.4957 21.9128 15.5212 21.9128Z" />
            <path
              className="fillPath"
              clipRule="evenodd"
              d="M16.2537 15.7045V12.7827H14.7928V15.7045H11.8711V17.1653H14.7928V20.0871H16.2537V17.1653H19.1754V15.7045H16.2537V15.7045Z"
              fillRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </button>
  )
}

CrateAllTracksButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

const ConnectedCrateAllTracksButton = injectIntl(CrateAllTracksButton)

export { ConnectedCrateAllTracksButton as CrateAllTracksButton }
