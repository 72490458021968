import { CategoryButton } from 'spa/components/button/category_button'
import { FilterPanel } from './filter_panel'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const KEYS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'Am',
  'Bm',
  'Cm',
  'Dm',
  'Em',
  'Fm',
  'Gm',
  'Ab',
  'Bb',
  'Db',
  'Eb',
  'F#',
  'Abm',
  'Bbm',
  'Dbm',
  'Ebm',
  'F#m',
]

export function KeyFilterPanel({
  className,
  keys = [],
  onChange,
  contentClassName,
}) {
  const handleChange = selectedKey => {
    if (!onChange) {
      return
    }

    let newKeys
    const keyIndex = keys.indexOf(selectedKey)

    if (keyIndex >= 0) {
      newKeys = keys.slice()
      newKeys.splice(keyIndex, 1)
    } else {
      newKeys = keys.concat(selectedKey)
    }

    onChange(newKeys)
  }

  return (
    <FilterPanel
      className={className}
      title={
        <FormattedMessage
          defaultMessage="Song key"
          id="djcity.common.filter.songKey"
        />
      }
    >
      <div className={classNames(styles.keyContent, contentClassName)}>
        {KEYS.map(key => (
          <CategoryButton
            checked={keys.indexOf(key) >= 0}
            key={key}
            labelText={key}
            onChange={() => handleChange(key)}
          />
        ))}
      </div>
    </FilterPanel>
  )
}

KeyFilterPanel.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.oneOf(KEYS)),
  onChange: PropTypes.func,
  scrollable: PropTypes.bool,
}
