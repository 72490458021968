import { Menu } from 'react-aria-menubutton'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const NavMenuContent = ({ className, ...rest }) => {
  const cls = classNames(styles.navMenuContent, className)
  return <Menu className={cls} {...rest} />
}

NavMenuContent.displayName = 'NavMenuContent'

NavMenuContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
