import {
  ADD_SONG_TO_QUEUE,
  CLEAR_CURRENT_USER,
  ON_ACCESS_SEARCH_RESULT_ACTION,
  ON_FILTER_CHANGE,
  ON_SORT_CHANGE,
  PLAY_CURRENT_SONG,
  RECEIVE_ADD_BATCH_TO_CRATE,
  RECEIVE_ADD_TO_CRATE,
  RECEIVE_CURRENT_USER,
  RECEIVE_DOWNLOAD_ALL_TRACKS,
  RECEIVE_DOWNLOAD_TRACK,
  RECEIVE_PAYMENT_ERROR,
  RECEIVE_PAYMENT_SUCCESS,
  RECEIVE_SEARCH_SONGS,
  RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER,
  RECEIVE_SEND_CRATE_TO_DOWNLOADER,
  RECEIVE_SEND_TRACK_TO_DOWNLOADER,
  REQUEST_CLICK_BANNER_ASSET,
  SIGN_UP_USER,
  UPDATE_CURRENT_USER,
} from '../action_constants'
import { GENRE_TAG_TO_LABEL } from '../components/filters/category_filter_panel'
import { getLabelForTypeValue, SORT_LABEL } from '../components/filters'
import { TRACKLIST } from '../shared/constants'
import find from 'lodash/find'

const mpEnabled =
  window && !window.Cypress && !process.env.IS_TEST && process.env.MIXPANEL_KEY

let mp
if (mpEnabled) {
  const isPrerender = /Prerender/i.test(window.navigator.userAgent)
  mp = require('mixpanel-browser')
  mp.init(process.env.MIXPANEL_KEY)
  mp.register({
    Platform: 'Web App',
    $ignore: isPrerender,
  })
}

let previousLocation

function trackToEvent(record, ttid, recordList, playlists) {
  const isFromTrackDetail = routeToEventName(location) === 'Track Detail'

  let event = {
    'Record Title': record.title,
    'Record Artist': record.artist,
    'Record Remixer Name': record.remixer && record.remixer.name,
    'Record Rid': record.rid,
    'Record Tags': record.tags && record.tags.map(tag => tag.name),
    'Record Release Date': record.releasedate,
    'Record BPM': record.bpm,
    'Available Track Types': record.types && record.types.map(tag => tag.type),
  }
  if (playlists && record.rid) {
    event.Playlist = getPlaylist(playlists, record.rid)
  }
  if (ttid) {
    event['Record Tid'] = ttid
    // Readable Record Track
    const recordType = find(record.types, x => x.ttid == ttid)
    if (recordType) {
      event['Record Track Type'] = recordType.type
    }
  }
  if (recordList) {
    event['Record List'] = recordList
  }

  if (
    isFromTrackDetail &&
    previousLocation &&
    ![TRACKLIST.RELATED, TRACKLIST.RELATED_NEW_STACKED_STYLE].includes(
      recordList
    )
  ) {
    event['Record List'] = routeToEventName({ pathname: previousLocation })
  }
  return event
}

function getPlaylist(data, rid) {
  if (data) return find(data, ['rid', rid])?.genre
}

function signupToEvent(country, fields) {
  return {
    'Email Address': fields.user.email,
    'First Name': fields.user.firstName,
    'Last Name': fields.user.lastName,
    Country: country,
    'Registration Date': new Date().toLocaleDateString(),
  }
}

function filtersToEvent(filters) {
  return {
    'Types Filtered': filters.types.map(t => getLabelForTypeValue(t)),
    'Genres Filtered': filters.tags ? [GENRE_TAG_TO_LABEL[filters.tags]] : null,
    'BPM Low Filter': filters.bpmgt,
    'BPM High Filter': filters.bpmlt,
  }
}

function paymentToEvent(processor, country, error) {
  let event = {
    'Payment Processor': processor,
    Country: country,
  }
  if (error) {
    event['Reason'] = error.userDescription
  }
  return event
}

function bannerToEvent(banner) {
  return {
    'Hero Image URL': banner.webAction.action,
    'Hero Alttag': banner.webAction.altTag,
    'Hero Action URL': banner.webAction.assetUrl,
    'Hero Index': banner.order,
    'Hero Id': banner.baid,
  }
}

function routeToEventName(location) {
  if (!location.pathname) return null

  let path = location.pathname.split('/')[1]

  switch (path) {
    case '':
    case 'home':
      return 'Home'
    case 'exclusives':
      return 'DJcity Exclusives'
    case 'new-releases':
      return 'New Releases'
    case 'most-popular':
      return 'Most Popular'
    case 'monthly-charts':
      return 'Monthly Charts'
    case 'updated':
      return 'Updated'
    case 'playlists':
      return 'Playlists'
    case 'remixer':
      return 'Remixers'
    case 'artist':
      return 'Artists'
    case 'genre':
      return 'Genres'
    case 'global':
      return 'Global'
    case 'records':
      return 'Track Detail'
    case 'search':
      return 'Search'
    default:
      return null
  }
}

function sortByToEvent(sortBy) {
  return {
    'Standard Sort': SORT_LABEL[sortBy],
  }
}

export const mixpanelMiddleware = store => next => action => {
  const { getState } = store
  let country,
    crate,
    currentSongId,
    event,
    eventName,
    itemsSent,
    planType,
    record,
    user
  const { trackingAllowed } = getState().userPreferences
  if (!trackingAllowed) {
    return next(action)
  }
  try {
    switch (action.type) {
      // Identify User
      case RECEIVE_CURRENT_USER:
        mp.identify(action.user.customerId)
        break
      // Log In
      case UPDATE_CURRENT_USER:
        planType = action.fields.user.activeSubscription ? 'Active' : 'Inactive'
        mp.identify(action.fields.customerId)
        mp.people.set({
          $email: action.fields.user.email,
          Email: action.fields.user.email,
          'First Name': action.fields.user.firstName,
          'Last Name': action.fields.user.lastName,
          'Plan Type': planType,
        })
        mp.register({
          'Plan Type': planType,
        })
        mp.track('Log In')
        break
      // Add to Preview Queue
      case ADD_SONG_TO_QUEUE:
        mp.track(
          'Add record to Preview Queue',
          trackToEvent(
            action.song,
            null,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of records added to Preview Queue')
        break
      // Play Record
      case PLAY_CURRENT_SONG:
        currentSongId = getState().player.currentSongId
        mp.track(
          'Play Record',
          trackToEvent(
            getState().songs.songsById[currentSongId],
            null,
            getState().player.trackListReference,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of Played Records')
        break
      // Crate Record
      case RECEIVE_ADD_TO_CRATE:
        record = getState().songs.songsById[action.item.track.record.rid]
        mp.track(
          'Crate Record',
          trackToEvent(
            record ? record : action.item.track.record,
            action.item.track.ttid,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of Crated Records')
        break
      // Crate All Records
      case RECEIVE_ADD_BATCH_TO_CRATE:
        record = getState().songs.songsById[action.items[0].track.record.rid]
        mp.track(
          'Crate All Records',
          trackToEvent(
            record ? record : action.items[0].track.record,
            null,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of Crated Records', action.items.length)
        break
      // Send Individual Track to Desktop App
      case RECEIVE_SEND_TRACK_TO_DOWNLOADER:
        mp.track(
          'Send Individual Track to Desktop App',
          trackToEvent(
            getState().songs.songsById[action.rid],
            action.ttid,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of Records Enqueued')
        break
      case RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER:
        mp.track(
          'Send All Tracks to Desktop App',
          trackToEvent(
            getState().songs.songsById[action.rid],
            action.ttids,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of Records Enqueued', action.ttids.length)
        break
      // Send Tracks to Desktop App
      case RECEIVE_SEND_CRATE_TO_DOWNLOADER:
        crate = getState().crate
        itemsSent = crate.items.length
        mp.track('Send Tracks to Desktop App', {
          '# of Records Enqueued': itemsSent,
        })
        mp.people.increment('Total # of Records Enqueued', itemsSent)
        break
      // Download Track
      case RECEIVE_DOWNLOAD_TRACK:
        mp.track(
          'Download Track',
          trackToEvent(
            getState().songs.songsById[action.songId],
            action.trackId,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment('Total # of Records Downloaded')
        break
      // Download All Tracks
      case RECEIVE_DOWNLOAD_ALL_TRACKS:
        mp.track(
          'Download All Tracks',
          trackToEvent(
            getState().songs.songsById[action.songId],
            null,
            action.trackList,
            getState().playlistsSongs.playlistsLookup
          )
        )
        mp.people.increment(
          'Total # of Records Downloaded',
          getState().songs.songsById[action.songId].types.length
        )
        break
      // Search
      case RECEIVE_SEARCH_SONGS:
        mp.track('Search', {
          'Search String': action.searchTerm,
          'Search Result Count': action.songs.length,
        })
        break
      case ON_ACCESS_SEARCH_RESULT_ACTION:
        mp.track(
          'View Search Result',
          trackToEvent(getState().songs.songsById[action.songId])
        )
        break
      case ON_FILTER_CHANGE:
        mp.track('Filter', filtersToEvent(action.filters))
        break
      case ON_SORT_CHANGE:
        mp.track('Sort', sortByToEvent(action.sortBy))
        break
      case CLEAR_CURRENT_USER:
        mp.reset()
        // Ensure Platform variable stays registered
        mp.register({
          Platform: 'Web App',
        })
        break
      case SIGN_UP_USER:
        user = signupToEvent(
          getState().currentUserBilling.country,
          action.fields
        )
        mp.alias(action.fields.customerId)
        mp.people.set(user)
        mp.track('Sign Up', user)
        break
      case '@@router/LOCATION_CHANGE':
        // View Record Lists
        eventName = routeToEventName(location)
        if (eventName) {
          mp.track('View List', { 'Record List': eventName })
        }
        previousLocation = action.payload.location.previousLocation
        break
      case RECEIVE_PAYMENT_SUCCESS:
      case RECEIVE_PAYMENT_ERROR:
        event =
          action.type === RECEIVE_PAYMENT_SUCCESS
            ? 'Submit Payment Successful'
            : 'Submit Payment Failed'
        country = getState().currentUserBilling.country
        mp.track(event, paymentToEvent(action.processor, country, action.error))
        if (action.type === RECEIVE_PAYMENT_SUCCESS) {
          mp.people.set({ Country: country, 'Plan Type': 'Active' })
          mp.register({
            'Plan Type': 'Active',
          })
        }
        break
      case REQUEST_CLICK_BANNER_ASSET:
        mp.track('Hero Click', bannerToEvent(action.model))
        break
      default:
        break
    }
    return next(action)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(action, e)
    // Ensure Middleware does not hinder redux
    return next(action)
  }
}
