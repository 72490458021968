import { addToCrate, removeTrack } from 'spa/action_creators/crate'
import { defineMessages, injectIntl } from 'react-intl'
import { loggedInSelector } from 'spa/selectors/users'
import { MAX_DOWNLOAD_COUNT } from 'spa/shared/utils'
import { trackSelector } from 'spa/selectors/songs'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles.sass'

const localizedMessages = defineMessages({
  addCrate: {
    id: 'djcity.common.icons.add.crate.alt',
    defaultMessage: 'Add To Crate',
  },
})

const CrateButton = ({
  className,
  intl,
  songId: songIdString,
  trackId: trackIdString,
  trackList,
}) => {
  const songId = parseInt(songIdString, 10)
  const trackId = parseInt(trackIdString, 10)
  const dispatch = useDispatch()
  const track = useSelector(state => trackSelector(state, { songId, trackId }))
  const loggedIn = useSelector(loggedInSelector)
  const enabled = !!(
    (!songId && !trackId) ||
    (loggedIn && track && track.downloadCount < MAX_DOWNLOAD_COUNT)
  )

  let iconClass = null
  if (!enabled) {
    iconClass = styles.disabled
  } else if (track && track.inCrate) {
    iconClass = styles.active
  }

  const clickHandler = useCallback(() => {
    if (track) {
      if (track.inCrate) {
        dispatch(removeTrack({ songId, trackId }))
      } else {
        dispatch(addToCrate({ songId, trackId, trackList }))
      }
    }
  }, [track && track.inCrate, songId, trackId, dispatch])

  return (
    <button
      className={classNames('cleanButton', styles.crateButton, className)}
      onClick={clickHandler}
    >
      <svg
        alt={intl.formatMessage(localizedMessages.addCrate)}
        className={iconClass}
        viewBox="0 0 19 20"
      >
        <mask id="crateIconMask">
          <rect fill="white" height="20" width="19" x="0" y="0" />
          <circle cx="13" cy="14" fill="black" r="6" />
        </mask>
        <path
          d="M3.07031 0C2.5459 0 2.10352 0.298829 1.80469 0.75L0.140625 3.44531C0.0644535 3.59473 0 3.89941 0 4.125V13.5C0 14.3262 0.673829 15 1.5 15H13.5C14.3262 15 15 14.3262 15 13.5V4.125C15 3.89941 14.9355 3.60059 14.8594 3.375L13.1953 0.75C12.8965 0.298829 12.4541 0 11.9297 0H3.07031ZM3.07031 1.5H11.9297L12.8203 3H2.17969L3.07031 1.5ZM1.5 4.5H13.5V13.5H1.5V4.5ZM5.85938 6C5.44629 6.03809 5.1416 6.40723 5.17969 6.82031C5.21777 7.2334 5.58691 7.53809 6 7.5H9C9.26953 7.50293 9.52149 7.36231 9.65918 7.12793C9.79395 6.89356 9.79395 6.60645 9.65918 6.37207C9.52149 6.1377 9.26953 5.99707 9 6H6C5.97656 6 5.95313 6 5.92969 6C5.90625 6 5.88281 6 5.85938 6Z"
          mask="url(#crateIconMask)"
        />
        <path
          clipRule="evenodd"
          d="M13.8 13.2V10H12.2V13.2H9V14.8H12.2V18H13.8V14.8H17V13.2H13.8V13.2Z"
          fillRule="evenodd"
        />
      </svg>
    </button>
  )
}

CrateButton.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  songId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackList: PropTypes.string,
}

const ConnectedCrateButton = injectIntl(CrateButton)

export { ConnectedCrateButton as CrateButton }
