import { defineMessages } from 'react-intl'

export const localizedMessages = defineMessages({
  cardName: {
    id: 'djcity.billing.payment.card.name',
    defaultMessage: 'Name on Card',
  },
  cardNumber: {
    id: 'djcity.billing.payment.card.number',
    defaultMessage: 'Card Number',
  },
  cardExpiration: {
    id: 'djcity.billing.payment.card.expiration',
    defaultMessage: 'Card Expiration',
  },
  cardCvc: {
    id: 'djcity.billing.payment.card.cvc',
    defaultMessage: 'Card CVC',
  },
  payNow: {
    id: 'djcity.billing.payment.card.pay',
    defaultMessage: 'Pay Now',
  },
})
