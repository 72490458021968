import { CLEAR_CURRENT_USER } from '../action_constants'
import { logout } from 'spa/action_creators/current_user'

export const userImpersonationMiddleware = store => next => action => {
  const { getState, dispatch } = store

  const userImpersonationMode = getState()?.currentUser?.user
    ?.userImpersonationMode
  const userImpersonationValidity = getState()?.currentUser?.user
    ?.userImpersonationValidity

  if (
    userImpersonationMode &&
    (!userImpersonationValidity ||
      new Date(userImpersonationValidity).getTime() < new Date().getTime()) &&
    action?.type !== CLEAR_CURRENT_USER
  ) {
    dispatch(logout())
  } else return next(action)
}
