import { DISMISS_MOBILE_MODAL } from '../action_constants'

const defaultState = {
  displayOnMobile: true,
}
if (typeof sessionStorage !== 'undefined') {
  defaultState.displayOnMobile =
    sessionStorage.getItem('displayOnMobile') === 'false' ? false : true
}

export default function mobileOverlay(state = defaultState, action) {
  switch (action.type) {
    case DISMISS_MOBILE_MODAL:
      sessionStorage.setItem('displayOnMobile', 'false')
      return { ...state, displayOnMobile: false }
    default:
      return state
  }
}
