import { Cookies } from './cookies'
import { Layout } from '../../layout'
import React from 'react'

const display = () => {
  return (
    <Layout tabTitle="cookies-policy">
      <Cookies />
    </Layout>
  )
}

export { display as CookiesDisplay }

export { Cookies }

export default Cookies
