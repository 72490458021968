import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  beatsourceLogo: {
    id: 'djcity.common.images.beatsource.logo',
    defaultMessage: 'https://cdna.images.djcity.com/logo/beatsource_logo.png',
  },
  beatsourceLogoAlt: {
    id: 'djcity.common.images.beatsource.logo.alt',
    defaultMessage: 'Beatsource',
  },
  beatsourceSearchLink: {
    id: 'djcity.records.beatsource.search.link',
    defaultMessage: 'https://www.beatsource.com/search',
  },
})

const BeatsourceBanner = ({ intl }) => {
  const isLoadingStringResources = useSelector(
    state => state.stringResources.isFetching
  )
  const bannerLink = intl.formatMessage(localizeMessages.beatsourceSearchLink)

  return (
    <Route
      path="/search"
      render={({ location }) =>
        !isLoadingStringResources && (
          <a
            href={`${bannerLink}${location.search}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className={styles.beatSourceContainer}>
              <div className={styles.textContainer}>
                <FormattedMessage
                  defaultMessage="Can’t find what you’re looking for? Search on"
                  id="djcity.records.beatsource.banner.title"
                />
              </div>
              <div className={styles.imageContainer}>
                <img
                  alt={intl.formatMessage(localizeMessages.beatsourceLogoAlt)}
                  src={intl.formatMessage(localizeMessages.beatsourceLogo)}
                />
              </div>
            </div>
          </a>
        )
      }
    />
  )
}

BeatsourceBanner.displayName = 'BeatsourceBanner'

BeatsourceBanner.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

const ConnectedBeatsourceBanner = injectIntl(BeatsourceBanner)

export { ConnectedBeatsourceBanner as BeatsourceBanner }
