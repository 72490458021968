import {
  availableTopDownloads,
  defaultSortBy,
  defaultTopDownloadsFilters,
  defaultTopDownloadsPagination,
  topDownloads,
} from '../api/songs'
import {
  ERROR_RECEIVING_TOP_DOWNLOAD_DATES,
  RECEIVE_SONGS_TOP_DOWNLOADS,
  RECEIVE_TOP_DOWNLOAD_DATES,
  REQUEST_SONGS_TOP_DOWNLOADS,
  REQUEST_TOP_DOWNLOAD_DATES,
} from '../action_constants'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListActionCreator } from './create_songs_list_action_creator'
import { defaultPagination } from '../api/songs'

export const fetchTopDownloadsSongs = createSongsListActionCreator({
  defaultFilters: defaultTopDownloadsFilters,
  defaultTopDownloadsPagination,
  defaultSortBy: 9,
  requestActionConstant: REQUEST_SONGS_TOP_DOWNLOADS,
  receiveActionConstant: RECEIVE_SONGS_TOP_DOWNLOADS,
  apiMethod: topDownloads,
  reducerName: 'topDownloadsSongs',
  clearActionConstant: CLEAR_SONGS_LIST,
})

export function clearSongList() {
  return {
    type: CLEAR_SONGS_LIST,
    filters: defaultTopDownloadsFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}

function requestTopDownloadsDates() {
  return {
    type: REQUEST_TOP_DOWNLOAD_DATES,
  }
}

function receiveTopDownloadsDates(dates) {
  return {
    dates,
    type: RECEIVE_TOP_DOWNLOAD_DATES,
  }
}

function errorReceivingTopDownloadsDates(error) {
  return {
    error,
    type: ERROR_RECEIVING_TOP_DOWNLOAD_DATES,
  }
}

export function fetchTopDownloadsDates() {
  return async function(dispatch) {
    dispatch(requestTopDownloadsDates())
    try {
      const dates = await availableTopDownloads()
      return dispatch(receiveTopDownloadsDates(dates))
    } catch (e) {
      return dispatch(errorReceivingTopDownloadsDates(e))
    }
  }
}
