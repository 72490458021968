import { CategoryButton } from 'spa/components/button/category_button'
import {
  clearSongList,
  fetchTopDownloadsDates,
  fetchTopDownloadsSongs,
} from 'spa/action_creators/top_downloads_songs'
import { encodeDate, MonthPopover } from 'spa/components/filters'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Heading, TextStyle } from 'spa/components/typography'
import { isMobileSelector } from '../../../selectors/device'
import { isMonthTurnWindow } from 'spa/api/songs'
import { Knotch } from 'spa/components/knotch'
import { Loading } from 'spa/components/loading'
import { LOCALIZED_MESSAGES } from '../../table/messages'
import { NoRecordsLabel } from 'spa/components/no_records_label'
import { SongTable } from 'spa/components/table'
import {
  topDownloadsDateSelector,
  topDownloadsSongsSelector,
} from 'spa/selectors/songs'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import styles from './styles'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const REGIONS = {
  japan: 'japan',
  uk: 'uk',
  germany: 'germany',
  latin: 'latin',
  south_asia: 'south_asia',
}

const REGION_ID_MAP = {
  2: REGIONS.japan,
  3: REGIONS.uk,
  4: REGIONS.germany,
  6: REGIONS.latin,
  8: REGIONS.south_asia,
  [REGIONS.japan]: 2,
  [REGIONS.uk]: 3,
  [REGIONS.germany]: 4,
  [REGIONS.latin]: 6,
  [REGIONS.south_asia]: 8,
}

const REGION_TAGS = {
  [REGIONS.japan]: [107, 130, 141, 190],
  [REGIONS.uk]: [22, 23, 24, 25, 26, 27, 28, 37, 54, 57, 78, 188],
  [REGIONS.germany]: [126, 127, 128, 131, 178, 197],
  [REGIONS.latin]: [3, 30, 49, 50, 98, 104, 184],
  [REGIONS.south_asia]: [213, 244, 245, 246, 272, 283, 290],
}

const defaultQueryParameters = {
  regionId: 0,
  region: null,
  month: null,
  year: null,
}

const parsers = {
  regionId: x => parseInt(x, 10),
  month: x => parseInt(x, 10),
  year: x => parseInt(x, 10),
}

const stringifiers = {
  month: x => x || '',
  year: x => x || '',
}

function TopDownloads({ intl }) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const isMobile = useSelector(isMobileSelector)
  const songs = useSelector(topDownloadsSongsSelector)
  const songsLoading = useSelector(state => state.topDownloadsSongs.isFetching)
  const topDownloadsDates = useSelector(state =>
    topDownloadsDateSelector(state, intl)
  )
  const topDownloadsDatesLoading = useSelector(
    state => state.topDownloadsDates.isFetching
  )
  const [
    queryParams,
    setQueryParams,
  ] = useQueryParameters(defaultQueryParameters, { parsers, stringifiers })
  const { regionId, region, month, year } = queryParams

  const latestDate = Object.keys(topDownloadsDates)[0]

  useEffect(() => {
    if (regionId) {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('region', REGION_ID_MAP[searchParams.get('regionId')])
      searchParams.delete('regionId')
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      })
    }
  }, [])

  useEffect(() => {
    if (!latestDate && !topDownloadsDatesLoading) {
      dispatch(fetchTopDownloadsDates())
    }
  }, [dispatch, latestDate, topDownloadsDatesLoading])

  useEffect(() => {
    dispatch(clearSongList())
  }, [dispatch, queryParams])

  useEffect(() => {
    if (latestDate && !songs.length) {
      const [latestMonth, latestYear] = latestDate.split(':')
      dispatch(
        fetchTopDownloadsSongs({
          pagination: { pageSize: region ? 30 : 50 },
          filters: {
            month: month || latestMonth,
            year: year || latestYear,
            genres: REGION_TAGS[region],
            regionId: REGION_ID_MAP[region] || 0,
          },
          sortBy: 9,
        })
      )

      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
    }
  }, [regionId, region, month, year, latestDate, songs.length])

  const handleMonthPopoverChange = useCallback(
    encodedMonth => {
      dispatch(clearSongList())
      const [newMonth, newYear] = encodedMonth
        .split(':')
        .map(x => parseInt(x, 10))
      setQueryParams({
        ...queryParams,
        month: newMonth,
        year: newYear,
      })
    },
    [dispatch, queryParams, setQueryParams]
  )

  const handleRegionChange = useCallback(
    (newRegion, isChecked) => {
      if (!isChecked) {
        return
      }
      dispatch(clearSongList())
      setQueryParams({
        ...queryParams,
        region: newRegion,
      })
    },
    [dispatch, queryParams, setQueryParams]
  )

  if (!latestDate) {
    return <Loading />
  }

  const sectionTitle = region ? (
    <FormattedMessage defaultMessage="Top 30" id="djcity.common.top_30" />
  ) : (
    <FormattedMessage defaultMessage="Top 50" id="djcity.common.top_50" />
  )

  const [latestMonth, latestYear] = latestDate
    .split(':')
    .map(x => parseInt(x, 10))

  const getLast12 = obj => {
    const sortedObject = {}

    const keys = Object.keys(obj).slice(0, 12)

    for (const key of keys) {
      sortedObject[key] = obj[key]
    }

    return sortedObject
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          {sectionTitle}
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading className={styles.heading}>
            {`${months[(month || latestMonth) - 1]} ${year || latestYear}`}
          </Heading>
          {!isMobile && (
            <MonthPopover
              isMobile={isMobile}
              monthInput={getLast12(topDownloadsDates)}
              onChange={handleMonthPopoverChange}
              skipMonths={isMonthTurnWindow() ? 0 : 1}
              value={encodeDate({
                month: month || latestMonth,
                year: year || latestYear,
              })}
            />
          )}
        </div>
        <p>
          <FormattedMessage
            defaultMessage="An archive of the top tracks downloaded each month"
            id="djcity.records.monthly_chart.sub_header"
          />
        </p>
        <Knotch className={styles.knotch} size="big" />
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <MonthPopover
            isMobile={isMobile}
            monthInput={getLast12(topDownloadsDates)}
            onChange={handleMonthPopoverChange}
            skipMonths={isMonthTurnWindow() ? 0 : 1}
            value={encodeDate({
              month: month || latestMonth,
              year: year || latestYear,
            })}
          />
        </div>
      )}
      <div className={styles.checkboxRow}>
        <CategoryButton
          checked={!region}
          labelText={intl.formatMessage(LOCALIZED_MESSAGES.world)}
          onChange={e => handleRegionChange(null, e.target.checked)}
        />
        <CategoryButton
          checked={region === REGIONS.latin}
          labelText={intl.formatMessage(LOCALIZED_MESSAGES.latin)}
          onChange={e => handleRegionChange(REGIONS.latin, e.target.checked)}
        />
        <CategoryButton
          checked={region === REGIONS.uk}
          labelText={intl.formatMessage(LOCALIZED_MESSAGES.uk)}
          onChange={e => handleRegionChange(REGIONS.uk, e.target.checked)}
        />
        <CategoryButton
          checked={region === REGIONS.germany}
          labelText={intl.formatMessage(LOCALIZED_MESSAGES.germany)}
          onChange={e => handleRegionChange(REGIONS.germany, e.target.checked)}
        />
        <CategoryButton
          checked={region === REGIONS.japan}
          labelText={intl.formatMessage(LOCALIZED_MESSAGES.japan)}
          onChange={e => handleRegionChange(REGIONS.japan, e.target.checked)}
        />
        <CategoryButton
          checked={region === REGIONS.south_asia}
          labelText={intl.formatMessage(LOCALIZED_MESSAGES.south_asia)}
          onChange={e =>
            handleRegionChange(REGIONS.south_asia, e.target.checked)
          }
        />
      </div>
      {songsLoading && !songs.length ? (
        <Loading />
      ) : (
        <>
          <SongTable
            className={styles.table}
            firstColumnHeaderLabel={intl.formatMessage(
              LOCALIZED_MESSAGES.records
            )}
            showNumbers
            songs={songs}
            trackList={TRACKLIST.TOP_50}
          />
          {songs.length === 0 ? <NoRecordsLabel /> : null}
        </>
      )}
    </div>
  )
}

TopDownloads.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

TopDownloads.displayName = 'TopDownloads'

export default injectIntl(TopDownloads)
