import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export function TableSeparator({ className }) {
  return <div className={classNames(styles.tableSeparator, className)} />
}

TableSeparator.propTypes = {
  className: PropTypes.string,
}
