import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { Knotch } from 'spa/components/knotch'
import { Link } from 'spa/components/link'
import { loggedInSelector } from 'spa/selectors/users'
import { TextStyle } from 'spa/components/typography'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  browse: {
    id: 'djcity.common.sidebar.browse',
    defaultMessage: 'Record Pool',
  },
  home: {
    id: 'djcity.common.sidebar.home',
    defaultMessage: 'Home',
  },
  newReleases: {
    id: 'djcity.common.sidebar.newReleases',
    defaultMessage: 'New Releases',
  },
  mostPopular: {
    id: 'djcity.common.sidebar.mostPopular',
    defaultMessage: 'Most Popular',
  },
  exclusives: {
    id: 'djcity.common.sidebar.exclusives',
    defaultMessage: 'DJcity Exclusives',
  },
  playlists: {
    id: 'djcity.common.sidebar.playlists',
    defaultMessage: 'Playlists',
  },
  monthlyCharts: {
    id: 'djcity.common.sidebar.monthlyCharts',
    defaultMessage: 'Monthly Charts',
  },
  genres: {
    id: 'djcity.common.sidebar.genres',
    defaultMessage: 'Genres',
  },
  remixers: {
    id: 'djcity.common.sidebar.remixers',
    defaultMessage: 'Remixers',
  },
  yourRecommendations: {
    id: 'djcity.common.sidebar.yourRecommendations',
    defaultMessage: 'Your Recommendations',
  },
  trackUpdates: {
    id: 'djcity.common.sidebar.trackUpdates',
    defaultMessage: 'Track Updates',
  },
  globalSongs: {
    id: 'djcity.common.sidebar.global_songs',
    defaultMessage: 'Global',
  },
})

export class Browse extends React.Component {
  render() {
    const { location, intl } = this.props
    return (
      <div className={styles.browse}>
        <ul className={styles.links}>
          <li>
            <TextStyle className={styles.title} uppercase variant="extra-bold">
              <Knotch
                className={styles.knotch}
                displayType="inlineBlock"
                size="small"
              />
              {intl.formatMessage(localizeMessages.browse)}
            </TextStyle>
          </li>
          <li className={styles.link}>
            <Link to="/">{intl.formatMessage(localizeMessages.home)}</Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/new-releases',
            })}
          >
            <Link to="/new-releases">
              {intl.formatMessage(localizeMessages.newReleases)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/most-popular',
            })}
          >
            <Link to="/most-popular">
              {intl.formatMessage(localizeMessages.mostPopular)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/exclusives',
            })}
          >
            <Link to="/exclusives">
              {intl.formatMessage(localizeMessages.exclusives)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname.startsWith('/playlists'),
            })}
          >
            <Link to="/playlists/monthly">
              {intl.formatMessage(localizeMessages.playlists)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/monthly-charts',
            })}
          >
            <Link to="/monthly-charts">
              {intl.formatMessage(localizeMessages.monthlyCharts)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname.match(/^\/genres?/),
            })}
          >
            <Link to="/genres">
              {intl.formatMessage(localizeMessages.genres)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname.match(/^\/remixer?/),
            })}
          >
            <Link to="/remixers">
              {intl.formatMessage(localizeMessages.remixers)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/track-updates',
            })}
          >
            <Link to="/track-updates">
              {intl.formatMessage(localizeMessages.trackUpdates)}
            </Link>
          </li>
          <li
            className={classNames(styles.link, {
              [styles.active]: location.pathname === '/global',
            })}
          >
            <Link to="/global">
              {intl.formatMessage(localizeMessages.globalSongs)}
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: loggedInSelector(state),
  }
}

Browse.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, null)(injectIntl(Browse)))
