import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../shared/utils'

export function ipLookup(opts = {}) {
  return fetchWithHeaders(`/iplookup?${getQueryParams(opts)}`, {
    method: 'GET',
  })
    .then(parseResponse)
    .catch(err => {
      return err
    })
}

export function getCountries() {
  return fetchWithHeaders('/countries', {
    method: 'GET',
  }).then(parseResponse)
}

export function getTerritories(countryId = null) {
  const url = countryId ? `/territories/${countryId}` : '/territories'
  return fetchWithHeaders(url, {
    method: 'GET',
  }).then(parseResponse)
}
