import { defineMessages, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'spa/components/popover'
import { PopoverHeading } from '../typography/popover_heading'
import { SongPropType } from 'spa/api/songs'
import { SongsList } from 'spa/components/songs_list'
import { useLocation } from 'react-router-dom'
import ActionConfirm from '../../clear_confirm/clear_confirm'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  cancelButton: {
    id: 'djcity.records.queue.clear.button.cancel',
    defaultMessage: 'cancel',
  },
  clearButton: {
    id: 'djcity.records.queue.clear.button.confirmation',
    defaultMessage: 'yes, clear queue',
  },
  title: {
    id: 'djcity.records.queue.clear.confirmation',
    defaultMessage: 'Are You Sure You Want to Clear All Tracks in Your Queue?',
  },
  track: {
    id: 'djcity.records.queue.player.track',
    defaultMessage: 'track',
  },
  tracks: {
    id: 'djcity.records.queue.player.tracks',
    defaultMessage: 'tracks',
  },
  message: {
    id: 'djcity.records.queue.player.message',
    defaultMessage: 'in preview queue',
  },
  played: {
    id: 'djcity.records.queue.player.already_played',
    defaultMessage: 'previous',
  },
  playingNow: {
    id: 'djcity.records.queue.player.playing_now',
    defaultMessage: 'playing now',
  },
  playingNext: {
    id: 'djcity.records.queue.player.next_play',
    defaultMessage: 'next',
  },
})

const QueuePopover = props => {
  const location = useLocation()
  const [clearScreen, setClearScreen] = useState(false)
  const [isClosed, setIsClosed] = useState(true)

  const { clearQueue, songIdx, songs, removeSongFromQueue, intl } = props

  const onToggleList = () => {
    setClearScreen(!clearScreen)
  }

  const handleClickClearQueue = () => {
    clearQueue()
    setClearScreen(!clearScreen)
  }

  useEffect(() => {
    setIsClosed(true)
  }, [location, setIsClosed])

  const handleClickInside = useCallback(
    cb => {
      setIsClosed(!isClosed)
      cb()
    },
    [setIsClosed]
  )

  return (
    <Popover
      arrow
      className={styles.playerPopover}
      closeOnScroll={false}
      contentClassName={styles.queuePopoverContent}
      openPopover={isClosed}
      renderTrigger={({ handleClick, ref }) => (
        <button
          className={classNames('cleanButton', styles.queueButton)}
          onClick={() => handleClickInside(handleClick)}
          ref={ref}
        >
          <FontAwesomeIcon icon={['fal', 'list']} />
        </button>
      )}
      variant="light"
    >
      <PopoverHeading className={styles.queuePopoverHeading}>
        {songs.length}{' '}
        {songs.length === 1
          ? intl.formatMessage(localizeMessages.track)
          : intl.formatMessage(localizeMessages.tracks)}{' '}
        {intl.formatMessage(localizeMessages.message)}
        <FontAwesomeIcon
          className={styles.trash}
          icon={['far', 'trash']}
          onClick={onToggleList}
        />
      </PopoverHeading>
      {!clearScreen ? (
        <SongsList
          className={styles.queuePopoverTracksList}
          fixedPopover
          removeSongFromQueue={removeSongFromQueue}
          scrollToBottom={true}
          songIdx={songIdx}
          songs={songs}
          variant="dark"
        />
      ) : (
        <ActionConfirm
          primaryAction={() => onToggleList()}
          primaryActionText={intl.formatMessage(localizeMessages.cancelButton)}
          secondaryAction={() => handleClickClearQueue()}
          secondaryActionText={intl.formatMessage(localizeMessages.clearButton)}
          title={intl.formatMessage(localizeMessages.title)}
        />
      )}
    </Popover>
  )
}

QueuePopover.propTypes = {
  clearQueue: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  removeSongFromQueue: PropTypes.func,
  songIdx: PropTypes.number,
  songs: PropTypes.arrayOf(SongPropType).isRequired,
}

const ConnectedQueuePopover = injectIntl(QueuePopover)

export { ConnectedQueuePopover as QueuePopover }
