import { Browse } from 'spa/components/browse'
import { Layout, Sidebar } from 'spa/components/layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'

const Playlists = React.lazy(() => import('./playlists'))

const display = () => {
  return (
    <Layout tabTitle="playlists">
      <Sidebar>
        <Browse />
      </Sidebar>
      <ModuleLoader>
        <Playlists />
      </ModuleLoader>
    </Layout>
  )
}

export { display as PlaylistsDisplay }

export { Playlists }

export default Playlists
