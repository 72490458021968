import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import translations from '../../../locale_data/translations'

const LocaleProvider = ({ children }) => {
  const locale = useSelector(state => state.locale.locale)
  const resources = useSelector(state => state.stringResources.resources)

  const messages = useMemo(() => {
    return {
      ...translations[locale],
      ...resources,
    }
  }, [locale, resources])

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.node,
}

export default LocaleProvider
