import * as songsApi from '../api/songs'
import {
  CLEAR_SONG_DETAILS,
  RECEIVE_SONG_DETAILS,
  REQUEST_SONG_DETAILS,
} from '../action_constants'
import { replace } from 'connected-react-router'

function requestSongDetails({ song }) {
  return {
    type: REQUEST_SONG_DETAILS,
    song,
  }
}

export function receiveSongDetails({ song }) {
  return {
    type: RECEIVE_SONG_DETAILS,
    song,
  }
}

export function fetchSongDetails({ id }) {
  return async function(dispatch, getState) {
    const song = getState().songs.songsById[id]
    dispatch(requestSongDetails({ song }))

    const { record } = await songsApi.details(id)
    if (!record) dispatch(replace('/'))
    dispatch(receiveSongDetails({ song: record }))

    return record
  }
}

export function clearSong() {
  return {
    type: CLEAR_SONG_DETAILS,
  }
}
