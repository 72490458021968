import { ReactSVG } from 'react-svg'
import React from 'react'
import styles from './styles'

import { injectIntl } from 'react-intl'
import { localizedMessages } from './localized_messages'

export const QrCode = injectIntl(({ intl }) => (
  <ReactSVG
    className={styles.mobileQr}
    fallback={() => (
      <img src={intl.formatMessage(localizedMessages.downloadAppQr)} />
    )}
    role="img"
    src={intl.formatMessage(localizedMessages.downloadAppQr)}
  />
))

export const AppStoreBadge = injectIntl(({ intl }) => (
  <a
    href="https://apps.apple.com/us/app/djcity/id485534275"
    rel="noopener noreferrer"
    target="_blank"
  >
    <img
      className={styles.badge}
      src={intl.formatMessage(localizedMessages.downloadIosApp)}
    />
  </a>
))

export const PlayStoreBadge = injectIntl(({ intl }) => (
  <a
    href="https://play.google.com/store/apps/details?id=com.djcity.app"
    rel="noopener noreferrer"
    target="_blank"
  >
    <img
      className={styles.badge}
      src={intl.formatMessage(localizedMessages.downloadAndroidApp)}
    />
  </a>
))
