import { debounce } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setVolume } from '../../action_creators/user_preferences'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.sass'

const VolumeController = ({ audioRef }) => {
  const barRef = useRef()

  const dispatch = useDispatch()
  const { volume } = useSelector(state => state.userPreferences)

  const getColor = vol =>
    `linear-gradient(90deg, red ${Math.floor(vol * 100)}%, #222222 ${Math.floor(
      vol * 100
    )}%)`

  const [vol, setVol] = useState(volume)
  const [color, setColor] = useState(getColor(vol))

  function handleSliderChange(event) {
    audioRef.current.volume = event.target.value
    setVol(event.target.value)
    dispatch(setVolume(event.target.value))
  }

  const toggleMute = () => {
    if (volume > 0) {
      audioRef.current.volume = 0
      dispatch(setVolume(0))
      barRef.current.value = 0
      setColor(getColor(0))
    } else if (volume === 0 && vol === 0) {
      audioRef.current.volume = 0.01
      dispatch(setVolume(0.01))
      barRef.current.value = 0.01
      setColor(getColor(0.01))
    } else {
      audioRef.current.volume = vol
      dispatch(setVolume(vol))
      barRef.current.value = vol
      setColor(getColor(vol))
    }
  }

  const debouncedHandleChange = debounce(event => {
    handleSliderChange(event)
  })

  const handleWheel = event => {
    event.preventDefault()
    let delta = event.deltaY > 0 ? -0.05 : 0.05
    let newValue = Number(vol) + delta
    newValue = newValue < 0.01 ? 0.01 : newValue
    newValue = newValue > 1 ? 1 : newValue
    if (newValue >= 0.01 && newValue <= 1) {
      audioRef.current.volume = newValue
      barRef.current.value = newValue
      setColor(getColor(newValue))
      setVol(newValue)
      dispatch(setVolume(newValue))
    }
  }

  useEffect(() => {
    if (audioRef.current?.volume !== volume) {
      audioRef.current.volume = volume
    }
  }, [audioRef.current])

  useEffect(() => {
    barRef.current.addEventListener('wheel', handleWheel, { passive: false })
    return () => {
      barRef.current.removeEventListener('wheel', handleWheel, {
        passive: false,
      })
    }
  }, [vol])

  const setIcon = volume => {
    return volume === 0
      ? 'volume-mute'
      : volume > 0 && volume < 0.5
      ? 'volume-down'
      : 'volume'
  }

  return (
    <div className={styles.volumeControl}>
      <div className={styles.volumeIcon} onClick={toggleMute}>
        <FontAwesomeIcon icon={['fal', setIcon(volume)]} size="2x" />
      </div>
      <div className={styles.slidercontainer}>
        <input
          className={classNames(styles.slider, {
            [styles.volumeZero]: volume === 0,
          })}
          defaultValue={vol}
          max="1"
          min="0.01"
          onChange={event => {
            event.persist()
            setColor(getColor(event.target.value))
            debouncedHandleChange(event)
          }}
          ref={barRef}
          step={0.01}
          style={{
            background: color,
          }}
          type="range"
        />
      </div>
    </div>
  )
}

const RefType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
])

VolumeController.propTypes = {
  audioRef: RefType,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  handleDragStop: PropTypes.func,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }),
  progressRef: RefType,
}

export default VolumeController
