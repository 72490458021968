import { Layout } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'
const AssetDownload = React.lazy(() => import('./asset_download'))

const display = () => {
  return (
    <Layout fullLayout tabTitle="downloads">
      <ModuleLoader>
        <AssetDownload />
      </ModuleLoader>
    </Layout>
  )
}

export { display as AssetDownloadDisplay }

export { AssetDownload }

export default AssetDownload
