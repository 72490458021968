import { defineMessages } from 'react-intl'

export const localizedMessages = defineMessages({
  changeSubscription: {
    id: 'djcity.common.subscription.change',
    defaultMessage: 'Change Subscription',
  },
  changePlan: {
    id: 'djcity.common.subscription.change.plan',
    defaultMessage: 'Change Plan',
  },
  changeCancel: {
    id: 'djcity.common.subscription.change.cancel',
    defaultMessage: 'Cancel',
  },
  subscriptionInfo: {
    id: 'djcity.common.subscription.current',
    defaultMessage:
      'You are currently enrolled into our {currentPlan} plan. On {nextPlanStart} your subscription renews and you will be charged {nextPlanPrice}{nextPlanCurrency} according to your {nextPlan} plan.',
  },
  currentPlan: {
    id: 'djcity.common.subscription.current.planName',
    defaultMessage: 'You are currently enrolled into our {planName} plan',
  },
  currentPlanStart: {
    id: 'djcity.common.subscription.current.planStart',
    defaultMessage: 'The subscription started on :',
  },
  currentPlanEnd: {
    id: 'djcity.common.subscription.current.planEnd',
    defaultMessage: 'and ends on : ',
  },
})
