import { Browse } from 'spa/components/browse'
import { Layout, Sidebar } from 'spa/components/layout'
import React from 'react'
import UpdatedSongs from './updated_songs'

const display = () => {
  return (
    <Layout tabTitle="track_updates">
      <Sidebar>
        <Browse />
      </Sidebar>
      <UpdatedSongs />
    </Layout>
  )
}

export { display as UpdatedSongsDisplay }

export { UpdatedSongs }

export default UpdatedSongs
