import { closeModal, WARNING_BANNER } from 'spa/action_creators/modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import styles from './styles'

export function BannerWarning() {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.modals[WARNING_BANNER].enabled)
  const data = useSelector(state => state.modals[WARNING_BANNER].data)

  const onCloseClick = () => dispatch(closeModal(WARNING_BANNER))

  return isOpen ? (
    <div className={styles.browserDownloadBanner}>
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: data?.message || 'Warning',
          }}
        ></span>
        {data?.doNotShowAgainFunction && (
          <button
            className={styles.doNotShowAgain}
            onClick={() => onCloseClick() && data.doNotShowAgainFunction()}
          >
            <FormattedMessage
              defaultMessage="Do not show again"
              id="djcity.common.modals.doNotShowAgain"
            />
          </button>
        )}
      </div>
      <button className={styles.dismiss} onClick={onCloseClick}>
        <FormattedMessage
          defaultMessage="Dismiss"
          id="djcity.common.modals.dismiss"
        />
        <FontAwesomeIcon
          className={styles.closeButton}
          icon={['fal', 'times']}
        />
      </button>
    </div>
  ) : null
}
