import {
  defaultHotBoxFilters,
  defaultPagination,
  defaultSortBy,
  hotBox,
} from '../api/songs'
import {
  RECEIVE_SONGS_HOT_BOX,
  REQUEST_SONGS_HOT_BOX,
} from '../action_constants'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListActionCreator } from './create_songs_list_action_creator'
import { SORT_OPTIONS } from '../components/filters'

export const fetchHotBoxSongs = createSongsListActionCreator({
  defaultFilters: defaultHotBoxFilters,
  defaultPagination,
  defaultSortBy: SORT_OPTIONS.POPULARITY,
  requestActionConstant: REQUEST_SONGS_HOT_BOX,
  receiveActionConstant: RECEIVE_SONGS_HOT_BOX,
  apiMethod: hotBox,
  reducerName: 'hotBoxSongs',
  clearActionConstant: CLEAR_SONGS_LIST,
})

export function clearSongList() {
  return {
    type: CLEAR_SONGS_LIST,
    filters: defaultHotBoxFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    songs: [],
    songIds: [],
  }
}
