import { calculateDaysPast } from '../../shared/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'
import { isMobileSelector } from '../../selectors/device'
import { loggedInSelector } from '../../selectors/users'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styles from './styles'

import { localizedMessages } from './localized_messages'
import { QrCode } from './components'

const MobileQrPopover = ({ intl }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { currentUser, device, mobilePopover } = useSelector(s => s)
  const { mobileAppDownloaded } = currentUser?.user || {}
  const { isOpen, lastSeen } = mobilePopover

  const isLoggedIn = loggedInSelector({ currentUser })
  const isMobile = isMobileSelector({ device })

  const handleClose = () => dispatch({ type: 'MOBILE_POPOVER_DISMISS' })

  const weekPast =
    typeof lastSeen === 'number' ? calculateDaysPast(lastSeen) >= 7 : false

  const excludedPage = [
    '/apps',
    '/payment-details',
    '/payment-confirmation',
  ].includes(location.pathname)
  const meetsConditions =
    isLoggedIn && weekPast && !isMobile && !mobileAppDownloaded && !excludedPage

  useEffect(() => {
    if (!isOpen && meetsConditions) {
      dispatch({ type: 'MOBILE_POPOVER_SHOW' })
    }
  }, [isOpen, meetsConditions])

  if (isOpen)
    return (
      <div className={styles.mobileQrPopover}>
        <div className={styles.mobileQrClose}>
          <FontAwesomeIcon icon={['fal', 'times']} onClick={handleClose} />
        </div>
        <QrCode />
        <span className={styles.mobileQrText}>
          {intl?.formatMessage(localizedMessages.downloadAppText)}
        </span>
      </div>
    )
  return null
}

MobileQrPopover.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
}

export default injectIntl(MobileQrPopover)
