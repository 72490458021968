import { defaultHotBoxFilters, defaultPagination } from '../api/songs'
import {
  RECEIVE_SONGS_HOT_BOX,
  REQUEST_SONGS_HOT_BOX,
} from '../action_constants'
import { SORT_OPTIONS } from '../components/filters'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListReducer } from './create_songs_list_reducer'

export default createSongsListReducer({
  defaultFilters: defaultHotBoxFilters,
  defaultPagination,
  defaultSortBy: SORT_OPTIONS.POPULARITY,
  requestActionConstant: REQUEST_SONGS_HOT_BOX,
  receiveActionConstant: RECEIVE_SONGS_HOT_BOX,
  clearActionConstant: CLEAR_SONGS_LIST,
})
