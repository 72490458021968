import {
  defaultSortBy,
  defaultTopDownloadsFilters,
  defaultTopDownloadsPagination,
} from '../api/songs'
import {
  RECEIVE_SONGS_TOP_DOWNLOADS,
  REQUEST_SONGS_TOP_DOWNLOADS,
} from '../action_constants'

import { CLEAR_SONGS_LIST } from '../action_constants'
import { createSongsListReducer } from './create_songs_list_reducer'

export default createSongsListReducer({
  defaultFilters: defaultTopDownloadsFilters,
  defaultPagination: defaultTopDownloadsPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_SONGS_TOP_DOWNLOADS,
  receiveActionConstant: RECEIVE_SONGS_TOP_DOWNLOADS,
  clearActionConstant: CLEAR_SONGS_LIST,
})
