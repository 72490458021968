// Affiliate Marketing
export const REFERRAL_SET = 'REFERRAL_SET'
export const REFERRAL_UNSET = 'REFERRAL_UNSET'

// Current User
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_CURRENT_USER_FIELDS = 'UPDATE_CURRENT_USER_FIELDS'
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER'
export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const SIGN_UP_USER = 'SIGN_UP_USER'
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER'
export const RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS =
  'RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS'
export const REQUEST_CURRENT_USER_PREVIOUS_DOWNLOADS =
  'REQUEST_CURRENT_USER_PREVIOUS_DOWNLOADS'
export const REQUEST_CURRENT_USER_BILLING_HISTORY =
  'REQUEST_CURRENT_USER_BILLING_HISTORY'
export const RECEIVE_CURRENT_USER_BILLING_HISTORY =
  'RECEIVE_CURRENT_USER_BILLING_HISTORY'
export const VALIDATING_SUBSCRIPTION_STATUS = 'VALIDATING_SUBSCRIPTION_STATUS'
export const VALIDATED_SUBSCRIPTION_STATUS = 'VALIDATED_SUBSCRIPTION_STATUS'
export const REQUEST_CURRENT_USER_INVOICE = 'REQUEST_CURRENT_USER_INVOICE'
export const RECEIVE_CURRENT_USER_INVOICE = 'RECEIVE_CURRENT_USER_INVOICE'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const REQUEST_SIGNUP = 'REQUEST_SIGNUP'
export const RECEIVE_SIGNUP = 'RECEIVE_SIGNUP'
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD'

// Global Songs
export const REQUEST_GLOBAL_SONGS = 'REQUEST_GLOBAL_SONGS'
export const RECEIVE_GLOBAL_SONGS = 'RECEIVE_GLOBAL_SONGS'
export const CLEAR_GLOBAL_SONGS = 'CLEAR_GLOBAL_SONGS'

// New Songs
export const REQUEST_NEW_SONGS = 'REQUEST_NEW_SONGS'
export const RECEIVE_NEW_SONGS = 'RECEIVE_NEW_SONGS'
export const CLEAR_NEW_SONGS = 'CLEAR_NEW_SONGS'

// Exclusives Songs
export const REQUEST_EXCLUSIVES_SONGS = 'REQUEST_EXCLUSIVES_SONGS'
export const RECEIVE_EXCLUSIVES_SONGS = 'RECEIVE_EXCLUSIVES_SONGS'
export const CLEAR_EXCLUSIVE_SONGS = 'CLEAR_EXCLUSIVES_SONGS'

// Recommended Songs
export const REQUEST_RECOMMENDED_SONGS = 'REQUEST_RECOMMENDED_SONGS'
export const RECEIVE_RECOMMENDED_SONGS = 'RECEIVE_RECOMMENDED_SONGS'
export const REQUEST_HIDE_RECOMMENDED_SONGS = 'REQUEST_HIDE_RECOMMENDED_SONGS'
export const RECEIVE_HIDE_RECOMMENDED_SONG = 'RECEIVE_HIDE_RECOMMENDED_SONGS'
export const ERROR_HIDE_RECOMMENDED_SONG = 'ERROR_HIDE_RECOMMENDED_SONG'

// Updated Songs
export const REQUEST_UPDATED_SONGS = 'REQUEST_UPDATED_SONGS'
export const RECEIVE_UPDATED_SONGS = 'RECEIVE_UPDATED_SONGS'

// Song Details
export const REQUEST_SONG_DETAILS = 'REQUEST_SONG_DETAILS'
export const RECEIVE_SONG_DETAILS = 'RECEIVE_SONG_DETAILS'
export const REQUEST_TAG = 'REQUEST_TAG'
export const REQUEST_REMIXER = 'REQUEST_REMIXER'
export const RECEIVE_REMIXER = 'RECEIVE_REMIXER'
export const RECEIVE_REMIXER_ERROR = 'RECEIVE_REMIXER_ERROR'
export const CLEAR_REMIXER = 'CLEAR_REMIXER'
export const REQUEST_REMIXERS = 'REQUEST_REMIXERS'
export const RECEIVE_REMIXERS = 'RECEIVE_REMIXERS'
export const REQUEST_ALL_REMIXERS = 'REQUEST_ALL_REMIXERS'
export const RECEIVE_ALL_REMIXERS = 'RECEIVE_ALL_REMIXERS'
export const RECEIVE_REMIXERS_ERROR = 'RECEIVE_REMIXERS_ERROR'
export const CLEAR_REMIXERS = 'CLEAR_REMIXERS'
export const CLEAR_SONGS_LIST = 'CLEAR_SONGS_LIST'
export const REMOVE_SONG_IN_LIST = 'REMOVE_SONG_IN_LIST'
export const CLEAR_SONG_DETAILS = 'CLEAR_SONG_DETAILS'

// Hot Box Songs
export const REQUEST_SONGS_HOT_BOX = 'REQUEST_SONGS_HOT_BOX'
export const RECEIVE_SONGS_HOT_BOX = 'RECEIVE_SONGS_HOT_BOX'

// Top Downloads Songs
export const REQUEST_SONGS_TOP_DOWNLOADS = 'REQUEST_SONGS_TOP_DOWNLOADS'
export const RECEIVE_SONGS_TOP_DOWNLOADS = 'RECEIVE_SONGS_TOP_DOWNLOADS'
export const REQUEST_TOP_DOWNLOAD_DATES = 'REQUEST_TOP_DOWNLOAD_DATES'
export const RECEIVE_TOP_DOWNLOAD_DATES = 'RECEIVE_TOP_DOWNLOAD_DATES'
export const ERROR_RECEIVING_TOP_DOWNLOAD_DATES =
  'ERROR_RECEIVING_TOP_DOWNLOAD_DATES'

// Search Songs
export const RECEIVE_SEARCH_SONGS = 'RECEIVE_SEARCH_SONGS'
export const REQUEST_SEARCH_SONGS = 'REQUEST_SEARCH_SONGS'
export const FILTER_SEARCH_SONGS = 'FILTER_SEARCH_SONGS'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'

// Songs By Artist
export const REQUEST_ARTIST_SONGS = 'REQUEST_ARTIST_SONGS'
export const RECEIVE_ARTIST_SONGS = 'RECEIVE_ARTIST_SONGS'
export const FILTER_ARTIST_SONGS = 'FILTER_ARTIST_SONGS'
export const CLEAR_ARTIST_SONGS = 'CLEAR_ARTIST_SONGS'

// Autocomplete Search
export const RECEIVE_AUTOCOMPLETE_SONGS = 'RECEIVE_AUTOCOMPLETE_SONGS'
export const REQUEST_AUTOCOMPLETE_SONGS = 'REQUEST_AUTOCOMPLETE_SONGS'
export const CLEAR_AUTOCOMPLETE_SONGS = 'CLEAR_AUTOCOMPLETE_SONGS'
export const SAVE_AUTOCOMPLETE_KEYSTROKE = 'SAVE_AUTOCOMPLETE_KEYSTROKE'

// Access Search Result
export const ON_ACCESS_SEARCH_RESULT_ACTION = 'ON_ACCESS_SEARCH_RESULT_ACTION'

// Songs By Category
export const REQUEST_SONGS_BY_CATEGORY = 'REQUEST_SONGS_BY_CATEGORY'
export const RECEIVE_SONGS_BY_CATEGORY = 'RECEIVE_SONGS_BY_CATEGORY'

// Song By Tag
export const REQUEST_SONGS_BY_TAG = 'REQUEST_SONGS_BY_TAG'
export const RECEIVE_SONGS_BY_TAG = 'RECEIVE_SONGS_BY_TAG'

// Song By Remixer
export const REQUEST_SONGS_BY_REMIXER = 'REQUEST_SONGS_BY_REMIXER'
export const RECEIVE_SONGS_BY_REMIXER = 'RECEIVE_SONGS_BY_REMIXER'
// Song Rating
export const REQUEST_RATE_SONG = 'REQUEST_RATE_SONG'
export const RECEIVE_RATE_SONG = 'RECEIVE_RATE_SONG'
// Playlists Songs
export const RECEIVE_PLAYLISTS_SONGS = 'RECEIVE_PLAYLISTS_SONGS'
export const REQUEST_PLAYLISTS_SONGS = 'REQUEST_PLAYLISTS_SONGS'
export const REQUEST_PLAYLISTS_DATA = 'REQUEST_PLAYLISTS_DATA'
export const RECEIVE_PLAYLISTS_DATA = 'RECEIVE_PLAYLISTS_DATA'
export const ERROR_RECEIVING_PLAYLISTS_DATA = 'ERROR_RECEIVING_PLAYLISTS_DATA'

// Locale
export const SET_LOCALE = 'SET_LOCALE'

// ADS Tracking
export const SET_TRACKING = 'SET_TRACKING'

// Player
export const SET_CURRENT_SONG = 'SET_CURRENT_SONG'
export const PLAY_CURRENT_SONG = 'PLAY_CURRENT_SONG'
export const PAUSE_CURRENT_SONG = 'PAUSE_CURRENT_SONG'
export const SET_CURRENT_TRACK = 'SET_CURRENT_TRACK'
export const ADD_SONG_TO_QUEUE = 'ADD_SONG_TO_QUEUE'
export const ADD_SONGS_TO_QUEUE = 'ADD_SONGS_TO_QUEUE'
export const ADD_SONGS_TO_EXISTING_QUEUE = 'ADD_SONGS_TO_EXISTING_QUEUE'
export const REMOVE_SONG_FROM_QUEUE = 'REMOVE_SONG_FROM_QUEUE'
export const SELECT_NEXT_SONG = 'SELECT_NEXT_SONG'
export const SELECT_PREV_SONG = 'SELECT_PREV_SONG'
export const CLEAR_QUEUE = 'CLEAR_QUEUE'
export const COMPLETE_SONG = 'COMPLETE_SONG'
export const TOGGLE_AUTOPLAY = 'TOGGLE_AUTOPLAY'
export const SET_VOLUME = 'SET_VOLUME'
// Played Actions
export const UPDATING_SONGS_AS_PLAYED = 'UPDATING_SONGS_AS_PLAYED'
export const UPDATED_SONGS_AS_PLAYED = 'UPDATED_SONGS_AS_PLAYED'

// Banner Assets
export const REQUEST_BANNER_ASSETS = 'REQUEST_BANNER_ASSETS'
export const RECEIVE_BANNER_ASSETS = 'RECEIVE_BANNER_ASSETS'
export const REQUEST_CLICK_BANNER_ASSET = 'REQUEST_CLICK_BANNER_ASSET'

// Downloads
export const REQUEST_DOWNLOAD_TRACK = 'REQUEST_DOWNLOAD_TRACK'
export const RECEIVE_DOWNLOAD_TRACK = 'RECEIVE_DOWNLOAD_TRACK'
export const RECEIVE_DOWNLOAD_ALL_TRACKS = 'RECEIVE_DOWNLOAD_ALL_TRACKS'
export const SET_DOWNLOAD_TRACK_STATUS = 'SET_DOWNLOAD_TRACK_STATUS'

// Crate
export const REQUEST_ADD_TO_CRATE = 'REQUEST_ADD_TO_CRATE'
export const RECEIVE_ADD_TO_CRATE = 'RECEIVE_ADD_TO_CRATE'
export const RECEIVE_ADD_BATCH_TO_CRATE = 'RECEIVE_ADD_BATCH_TO_CRATE'
export const REQUEST_ADD_BATCH_TO_CRATE = 'REQUEST_ADD_BATCH_TO_CRATE'
export const REQUEST_REMOVE_FROM_CRATE = 'REQUEST_REMOVE_FROM_CRATE'
export const REQUEST_REMOVE_BATCH_FROM_CRATE = 'REQUEST_REMOVE_BATCH_FROM_CRATE'
export const RECEIVE_REMOVE_BATCH_FROM_CRATE = 'RECEIVE_REMOVE_BATCH_FROM_CRATE'
export const RECEIVE_REMOVE_FROM_CRATE = 'RECEIVE_REMOVE_FROM_CRATE'
export const REQUEST_REMOVE_ALL_FROM_CRATE = 'REQUEST_REMOVE_ALL_FROM_CRATE'
export const RECEIVE_REMOVE_ALL_FROM_CRATE = 'RECEIVE_REMOVE_ALL_FROM_CRATE'
export const RECEIVE_REMOVE_ALL_TRACKS_FROM_CRATE =
  'RECEIVE_REMOVE_ALL_TRACKS_FROM_CRATE'
export const REQUEST_REMOVE_MULTIPLE_FROM_CRATE =
  'REQUEST_REMOVE_MULTIPLE_FROM_CRATE'
export const RECEIVE_REMOVE_MULTIPLE_FROM_CRATE =
  'RECEIVE_REMOVE_MULTIPLE_FROM_CRATE'
export const REQUEST_CRATE = 'REQUEST_CRATE'
export const RECEIVE_CRATE = 'RECEIVE_CRATE'
export const RESET_CRATE = 'RESET_CRATE'
export const REQUEST_SEND_CRATE_TO_DOWNLOADER =
  'REQUEST_SEND_CRATE_TO_DOWNLOADER'
export const RECEIVE_SEND_CRATE_TO_DOWNLOADER =
  'RECEIVE_SEND_CRATE_TO_DOWNLOADER'
export const DISMISS_MOBILE_MODAL = 'DISMISS_MOBILE_MODAL'

// Preferences
export const REQUEST_PREFERENCES = 'REQUEST_PREFERENCES'
export const RECEIVE_PREFERENCES = 'RECEIVE_PREFERENCES'

// Filter Change
export const ON_FILTER_CHANGE = 'ON_FILTER_CHANGE'

// Sort Change
export const ON_SORT_CHANGE = 'ON_SORT_CHANGE'

// Payments
export const RECEIVE_PAYMENT_ERROR = 'RECEIVE_PAYMENT_ERROR'
export const RECEIVE_PAYMENT_SUCCESS = 'RECEIVE_PAYMENT_SUCCESS'
export const REQUEST_PAYMENT = 'REQUEST_PAYMENT'

// String Resources
export const REQUEST_STRING_RESOURCES = 'REQUEST_STRING_RESOURCES'
export const RECEIVE_STRING_RESOURCES = 'RECEIVE_STRING_RESOURCES'

// Transactions
export const RECEIVE_PAYMENT_TRANSACTIONS_ERROR =
  'RECEIVE_PAYMENT_TRANSACTIONS_ERROR'
export const RECEIVE_PAYMENT_TRANSACTIONS_SUCCESS =
  'RECEIVE_PAYMENT_TRANSACTIONS_SUCCESS'
export const REQUEST_PAYMENT_TRANSACTIONS = 'REQUEST_PAYMENT_TRANSACTIONS'

// External Posts
export const RECEIVE_POSTS_ERROR = 'RECEIVE_POSTS_ERROR'
export const REQUEST_POSTS = 'REQUEST_POSTS'
export const RECEIVE_POSTS_SUCCESS = 'RECEIVE_POSTS_SUCCESS'

// Tag search
export const REQUEST_TAGS = 'REQUEST_TAGS'
export const RECEIVE_TAGS = 'RECEIVE_TAGS'

// Download Queue
export const REQUEST_SEND_TRACK_TO_DOWNLOADER =
  'REQUEST_SEND_TRACK_TO_DOWNLOADER'
export const RECEIVE_SEND_TRACK_TO_DOWNLOADER =
  'RECEIVE_SEND_TRACK_TO_DOWNLOADER'
export const REQUEST_SEND_ALL_TRACKS_TO_DOWNLOADER =
  'REQUEST_SEND_ALL_TRACKS_TO_DOWNLOADER'
export const RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER =
  'RECEIVE_SEND_ALL_TRACKS_TO_DOWNLOADER'
export const REQUEST_DOWNLOAD_QUEUE = 'REQUEST_DOWNLOAD_QUEUE'
export const RECEIVE_DOWNLOAD_QUEUE = 'RECEIVE_DOWNLOAD_QUEUE'
export const ERROR_RECEIVING_DOWNLOAD_QUEUE = 'ERROR_RECEIVING_DOWNLOAD_QUEUE'
export const MARK_TRACKS_AS_DOWNLOADED = 'MARK_TRACKS_AS_DOWNLOADED'
// Payment Method
export const REQUEST_PAYMENT_METHOD = 'REQUEST_PAYMENT_METHOD'
export const RECEIVE_PAYMENT_METHOD = 'RECEIVE_PAYMENT_METHOD'
export const ERROR_RECEIVING_PAYMENT_METHOD = 'ERROR_RECEIVING_PAYMENT_METHOD'

export const RECEIVE_PAYMENT_METHOD_CHANGE_ERROR =
  'RECEIVE_PAYMENT_METHOD_CHANGE_ERROR'
export const RECEIVE_PAYMENT_METHOD_CHANGE_SUCCESS =
  'RECEIVE_PAYMENT_METHOD_CHANGE_SUCCESS'
export const REQUEST_PAYMENT_METHOD_CHANGE = 'REQUEST_PAYMENT_METHOD_CHANGE'
// Profile
export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE'
export const RECEIVE_USER_PROFILE_SUCCESS = 'RECEIVE_USER_PROFILE_SUCCESS'
export const RECEIVE_USER_PROFILE_ERROR = 'RECEIVE_USER_PROFILE_ERROR'

// Automatic Refresh
export const REFRESHING_USER_START = 'REFRESHING_USER_START'
export const REFRESHING_USER_STOP = 'REFRESHING_USER_STOP'

// Cancel Subscription
export const CANCELING_SUBSCRIPTION = 'CANCELING_SUBSCRIPTION'
export const CANCELED_SUBSCRIPTION = 'CANCELED_SUBSCRIPTION'
export const ERROR_CANCELING_SUBSCRIPTION = 'ERROR_CANCELING_SUBSCRIPTION'

// Reactivate Subscription
export const REACTIVATING_SUBSCRIPTION = 'REACTIVATING_SUBSCRIPTION'
export const REACTIVATED_SUBSCRIPTION = 'REACTIVATED_SUBSCRIPTION'
export const ERROR_REACTIVATING_SUBSCRIPTION = 'ERROR_REACTIVATING_SUBSCRIPTION'

// Features
export const REQUEST_USER_FEATURES = 'REQUEST_USER_FEATURES'
export const RECEIVE_USER_FEATURES = 'RECEIVE_USER_FEATURES'
export const ERROR_RECEIVING_USER_FEATURES = 'ERROR_RECEIVING_USER_FEATURES'

// SmashVision
export const REQUEST_SMASH_VISION_ACCOUNT = 'REQUEST_SMASH_VISION_ACCOUNT'
export const RECEIVE_SMASH_VISION_ACCOUNT = 'RECEIVE_SMASH_VISION_ACCOUNT'
export const ERROR_RECEIVING_SMASH_VISION_ACCOUNT =
  'ERROR_RECEIVING_SMASH_VISION_ACCOUNT'
export const UPDATING_SMASH_VISION_ACCOUNT = 'UPDATING_SMASH_VISION_ACCOUNT'
export const UPDATED_SMASH_VISION_ACCOUNT = 'UPDATED_SMASH_VISION_ACCOUNT'
export const ERROR_UPDATING_SMASH_VISION_ACCOUNT =
  'ERROR_UPDATING_SMASH_VISION_ACCOUNT'

// Third party code injection
export const THIRD_PARTY_CODE_LOADED = 'THIRD_PARTY_CODE_LOADED'
export const THIRD_PARTY_CODE_ERROR = 'THIRD_PARTY_CODE_ERROR'

// Dark mode
export const SET_DARK_MODE = 'SET_DARK_MODE'

// User Properties
export const REQUEST_USER_LOCATION_PROPERTIES =
  'REQUEST_USER_LOCATION_PROPERTIES'
export const RECEIVE_USER_LOCATION_PROPERTIES =
  'RECEIVE_USER_LOCATION_PROPERTIES'
export const ERROR_RECEIVING_USER_LOCATION_PROPERTIES =
  'ERROR_RECEIVING_USER_LOCATION_PROPERTIES'
export const REQUEST_USER_PROPERTIES = 'REQUEST_USER_PROPERTIES'
export const RECEIVE_USER_PROPERTIES = 'RECEIVE_USER_PROPERTIES'
export const ERROR_RECEIVING_USER_PROPERTIES = 'ERROR_RECEIVING_USER_PROPERTIES'

// Promos
export const SET_ACTIVE_PROMO = 'SET_ACTIVE_PROMO'
export const REQUEST_PROMO = 'REQUEST_PROMO'
export const REQUEST_PROMO_PRICING = 'REQUEST_PROMO_PRICING'
export const REQUEST_PROMO_VALIDATION = 'REQUEST_PROMO_VALIDATION'
export const REQUEST_PROMO_TOKEN = 'REQUEST_PROMO_TOKEN'
export const REQUEST_PROMO_TOKEN_VALIDATION = 'REQUEST_PROMO_TOKEN_VALIDATION'
export const RECEIVE_PROMO = 'RECEIVE_PROMO'
export const RECEIVE_PROMO_PRICING = 'RECEIVE_PROMO_PRICING'
export const RECEIVE_PROMO_VALIDATION = 'RECEIVE_PROMO_VALIDATION'
export const RECEIVE_PROMO_TOKEN = 'RECEIVE_PROMO_TOKEN'
export const RECEIVE_PROMO_TOKEN_VALIDATION = 'RECEIVE_PROMO_TOKEN_VALIDATION'
export const ERROR_RECEIVING_PROMO = 'ERROR_RECEIVING_PROMO'
export const ERROR_RECEIVING_PROMO_PRICING = 'ERROR_RECEIVING_PROMO_PRICING'
export const ERROR_RECEIVING_PROMO_VALIDATION =
  'ERROR_RECEIVING_PROMO_VALIDATION'
export const ERROR_RECEIVING_PROMO_TOKEN = 'ERROR_RECEIVING_PROMO_TOKEN'
export const ERROR_RECEIVING_PROMO_TOKEN_VALIDATION =
  'ERROR_RECEIVING_PROMO_TOKEN_VALIDATION'

export const RESET_CHECKOUT_ERRORS = 'RESET_CHECKOUT_ERRORS'

// Promo Code
export const ADD_PROMO_CODE = 'ADD_PROMO_CODE'
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE'
export const RESET_PROMO = 'RESET_PROMO'
export const REQUEST_VIP_CODE = 'REQUEST_VIP_CODE'
export const RECEIVE_VIP_CODE = 'RECEIVE_VIP_CODE'
export const ERROR_RECEIVING_VIP_CODE = 'ERROR_RECEIVING_VIP_CODE'

// Subscriptions
export const REQUEST_SUBSCRIPTIONS = 'REQUEST_SUBSCRIPTIONS'
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS'
export const ERROR_RECEIVING_SUBSCRIPTIONS = 'ERROR_RECEIVING_SUBSCRIPTIONS'
export const SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION'
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION'

// Request User Product / Subscription
export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION'
export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION'
export const ERROR_RECEIVING_SUBSCRIPTION = 'ERROR_RECEIVING_SUBSCRIPTION'

// Change User Product / Subscription
export const REQUEST_SUBSCRIPTION_CHANGE = 'REQUEST_SUBSCRIPTION_CHANGE'
export const RECEIVE_SUBSCRIPTION_CHANGE = 'RECEIVE_SUBSCRIPTION_CHANGE'
export const ERROR_CHANGING_SUBSCRIPTION = 'ERROR_CHANGING_SUBSCRIPTION'

// Bulk Downloads
export const ADD_SONG_TO_BULK_DOWNLOADED = 'ADD_SONG_TO_BULK_DOWNLOADED'
