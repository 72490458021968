import { LOCALES } from '../../../../locale_data/config'
import { MenuButtonIcon } from 'spa/components/menu_button_icon'
import { Popover } from '../../popover'
import { setLocale } from '../../../action_creators/locale'
import { TextStyle } from '../../typography'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import LocaleListItem from './locale_list_item'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import styles from './styles'

const LOCALE_TO_DISPLAY = {
  [LOCALES.en_US]: 'English (US)',
  [LOCALES.en_GB]: 'English (UK)',
  [LOCALES.es_ES]: 'Español',
  [LOCALES.ja_JP]: '日本語',
  [LOCALES.de_DE]: 'Deutsch',
}

const LOCALE_TO_DISPLAY_SHORT = {
  [LOCALES.en_US]: 'US',
  [LOCALES.en_GB]: 'UK',
  [LOCALES.es_ES]: 'ES',
  [LOCALES.ja_JP]: '日本',
  [LOCALES.de_DE]: 'DE',
}

const LocaleList = ({ className = null }) => {
  const dispatch = useDispatch()
  const locale = useSelector(state => state.locale.locale)

  const onChangeLocale = useCallback(
    newLocale => dispatch(setLocale(newLocale)),
    [dispatch]
  )

  const localeList = useMemo(
    () =>
      Object.keys(LOCALE_TO_DISPLAY).map(locale => (
        <LocaleListItem
          handleClick={onChangeLocale}
          key={locale}
          label={LOCALE_TO_DISPLAY[locale]}
          locale={locale}
        />
      )),
    [onChangeLocale]
  )

  return (
    <Popover
      className={className}
      closeOnClick
      contentClassName={styles.localPopoverContent}
      renderTrigger={({ handleClick, open, ref }) => (
        <button
          className={classNames('cleanButton', styles.localeTrigger)}
          onClick={handleClick}
          ref={ref}
        >
          <TextStyle className={styles.localeText}>
            {LOCALE_TO_DISPLAY_SHORT[locale]}
          </TextStyle>
          <MenuButtonIcon isOpen={open} />
        </button>
      )}
    >
      <div className={styles.browse}>
        <ul className={styles.links}>{localeList}</ul>
      </div>
    </Popover>
  )
}

LocaleList.displayName = 'LocaleList'

LocaleList.propTypes = {
  className: PropTypes.string,
}

export default LocaleList
