import {
  ADD_SONG_TO_QUEUE,
  ADD_SONGS_TO_EXISTING_QUEUE,
  ADD_SONGS_TO_QUEUE,
  CLEAR_CURRENT_USER,
  CLEAR_QUEUE,
  COMPLETE_SONG,
  PAUSE_CURRENT_SONG,
  PLAY_CURRENT_SONG,
  REMOVE_SONG_FROM_QUEUE,
  SELECT_NEXT_SONG,
  SELECT_PREV_SONG,
  SET_CURRENT_SONG,
  UPDATED_SONGS_AS_PLAYED,
} from 'spa/action_constants'
import { MaxPriorityQueue } from '../shared/priority_queue'
import { REHYDRATE } from 'redux-persist'
import concat from 'lodash/concat'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

const getCompareValue = song => song?.priority
const unique = songs => uniqBy(songs, 'id')

const PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE = 1000000 // 1M
const PRIORITY_DISTANCE_FOR_MANUAL_ENQUEUE = 1000 // 1K
const PRIORITY_DISTANCE_FOR_CRATE_ENQUEUE = 1

const defaultState = {
  playing: false,
  isPlayerLoading: false,
  currentSongId: undefined,
  trackListReference: undefined,
  /** Array of songs */
  queue: [],
  /** Array of song ids that have been played in session */
  played: [],
  /** Array of song ids that have been marked as played remotely */
  markPlayed: [],
  completedSongId: undefined,
  /** Data for the playing songs' page */
  playingPage: undefined,
}

const getCurrentSongIndex = state =>
  state.queue.length && state.currentSongId
    ? state.queue.findIndex(
        s => parseInt(s.id) === parseInt(state.currentSongId)
      )
    : undefined

export default function player(state = defaultState, action) {
  const currentSongIdx = getCurrentSongIndex(state)
  // const currentSong = state.queue[currentSongIdx];
  const queue = state.queue
    ? MaxPriorityQueue.fromArray(
        unique(state.queue.filter(s => !!s)),
        getCompareValue
      )
    : new MaxPriorityQueue(getCompareValue)

  let songId

  switch (action.type) {
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.player,
            isPlayerLoading: true,
            playing: false,
          }
        : state
    case ADD_SONG_TO_QUEUE: {
      const priority_for_manuallyEnqueued =
        Math.min(
          Math.min(
            ...state.queue.filter(s => s.manuallyEnqueued).map(s => s.priority)
          ),
          state.queue.length && state.queue[currentSongIdx]
            ? state.queue[currentSongIdx].priority
            : 0
        ) - PRIORITY_DISTANCE_FOR_MANUAL_ENQUEUE

      songId = parseInt(action.song.rid, 10)
      queue.enqueue({
        id: songId,
        data: action.song,
        priority: priority_for_manuallyEnqueued,
        manuallyEnqueued: true,
        completed: false,
      })

      return {
        ...state,
        queue: unique(queue.toArray()),
      }
    }
    case ADD_SONGS_TO_QUEUE: {
      const manuallyEnqueuedSongs = state.queue.filter(
        s => s.manuallyEnqueued && !s.completed
      )

      const current_highest_priority = manuallyEnqueuedSongs.length
        ? Math.max(...manuallyEnqueuedSongs.map(s => s.priority)) +
          PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE
        : 0

      const current_lowest_priority = manuallyEnqueuedSongs.length
        ? Math.min(...manuallyEnqueuedSongs.map(s => s.priority)) -
          PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE
        : 0

      queue.clear()

      const currentSongIdx = action.songs.findIndex(
        s => parseInt(s.ri) === parseInt(state.currentSongId)
      )

      action.songs.forEach((song, idx) =>
        queue.enqueue({
          id: song.rid,
          data: song,
          priority:
            idx <= currentSongIdx
              ? current_highest_priority +
                (currentSongIdx - idx) * PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE
              : current_lowest_priority -
                (idx - currentSongIdx) * PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE,
        })
      )

      manuallyEnqueuedSongs.forEach(song => queue.enqueue(song))

      return {
        ...state,
        queue: unique(queue.toArray()),
      }
    }
    case ADD_SONGS_TO_EXISTING_QUEUE: {
      const current_lowest_priority =
        Math.min(...state.queue.map(s => s.priority)) -
        PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE

      action.songs.forEach((song, idx) =>
        queue.enqueue({
          id: song.rid,
          data: song,
          priority:
            current_lowest_priority - idx * PRIORITY_DISTANCE_FOR_AUTO_ENQUEUE,
        })
      )

      return {
        ...state,
        queue: unique(queue.toArray()),
      }
    }
    case REMOVE_SONG_FROM_QUEUE:
      return {
        ...state,
        currentSongId:
          parseInt(state.currentSongId) === parseInt(action.song.rid)
            ? currentSongIdx !== undefined &&
              currentSongIdx !== queue.size() - 1
              ? state.queue[currentSongIdx + 1].id
              : queue.size() === 1
              ? null
              : queue.front().id
            : state.currentSongId,
        queue:
          queue.size() === 1 &&
          parseInt(action.song.rid) === parseInt(state.currentSongId)
            ? []
            : unique(queue.toArray().filter(s => s.id !== action.song.rid)),
      }
    case SELECT_NEXT_SONG:
      return {
        ...state,
        currentSongId: state.queue[currentSongIdx + 1].id,
        played:
          currentSongIdx !== undefined &&
          currentSongIdx !== state.queue.length - 1
            ? uniq([...state.played, state.queue[currentSongIdx].id])
            : state.played,
      }
    case SELECT_PREV_SONG:
      return {
        ...state,
        currentSongId:
          currentSongIdx !== undefined && currentSongIdx > 0
            ? state.queue[currentSongIdx - 1].id
            : undefined,
        played:
          currentSongIdx !== undefined && currentSongIdx > 0
            ? uniq([...state.played, state.queue[currentSongIdx].id])
            : state.played,
      }
    case SET_CURRENT_SONG: {
      songId = parseInt(action.song.rid, 10)
      if (!state.queue.find(s => parseInt(s.id) === parseInt(songId)))
        queue.enqueue({
          id: songId,
          data: action.song,
          priority:
            state.queue.length && state.queue[currentSongIdx]
              ? state.queue[currentSongIdx].priority -
                PRIORITY_DISTANCE_FOR_CRATE_ENQUEUE
              : 0,
        })

      return {
        ...state,
        currentSongId: songId,
        trackListReference: action.trackListReference,
        playingPage: action.playingPage,
        queue: unique(queue.toArray()),
        completedSongId: undefined,
        isPlayerLoading: false,
      }
    }
    case UPDATED_SONGS_AS_PLAYED:
      return {
        ...state,
        markPlayed: uniq(concat(action.rids, [...state.markPlayed])),
      }
    case PLAY_CURRENT_SONG:
      return {
        ...state,
        isPlayerLoading: false,
        playing: true,
        completedSongId: undefined,
        played: uniq([...state.played, state.currentSongId]),
      }
    case PAUSE_CURRENT_SONG:
      return {
        ...state,
        isPlayerLoading: false,
        playing: false,
        completedSongId: undefined,
      }
    case CLEAR_QUEUE:
      return {
        ...state,
        queue: state.currentSongId
          ? unique(
              queue
                .toArray()
                .filter(s => parseInt(s.id) === parseInt(state.currentSongId))
            )
          : [],
      }
    case COMPLETE_SONG:
      return {
        ...state,
        completedSongId: state.currentSongId,
        queue: state.queue.map(s =>
          parseInt(state.currentSongId) === parseInt(s.id) && s.manuallyEnqueued
            ? { ...s, completed: true }
            : s
        ),
      }
    case CLEAR_CURRENT_USER:
      return defaultState
    default:
      return state
  }
}
