import * as localeActions from '../spa/action_creators/locale'
import * as React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { LOCALIZED_ROUTES } from '../locale_data/config'
import { Redirect, withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * Uses locale parameter to set locale upon entry to site
 * @param setLocale
 * @param rest
 * @returns {*}
 * @constructor
 */
const LocalizedRoute = ({ setLocale, ...rest }) => {
  useEffect(() => {
    const {
      computedMatch: {
        params: { locale },
      },
    } = rest
    setLocale(LOCALIZED_ROUTES[locale])
  }, [])

  return <Redirect {...rest} />
}

LocalizedRoute.propTypes = {
  setLocale: PropTypes.func.isRequired,
}

const mapDispatchTopProps = dispatch => {
  return {
    setLocale: bindActionCreators(localeActions, dispatch).setLocale,
  }
}

const ConnectedLocalizedRoute = withRouter(
  connect(null, mapDispatchTopProps)(LocalizedRoute)
)

export { ConnectedLocalizedRoute as LocalizedRoute }
