import * as songsApi from '../api/songs'
import {
  CLEAR_SEARCH,
  FILTER_SEARCH_SONGS,
  RECEIVE_SEARCH_SONGS,
  REQUEST_SEARCH_SONGS,
} from '../action_constants'
import { ON_ACCESS_SEARCH_RESULT_ACTION } from '../action_constants'

function requestSearchSongs({ filters, searchTerm, sortBy, exact }) {
  return {
    type: REQUEST_SEARCH_SONGS,
    filters: { ...songsApi.defaultFilters, ...filters },
    searchTerm,
    sortBy,
    exact,
  }
}

function receiveSearchSongs({ filters, searchTerm, songs, sortBy, exact }) {
  return {
    type: RECEIVE_SEARCH_SONGS,
    filters,
    searchTerm,
    songs,
    sortBy,
    exact,
  }
}

export function viewSearchSong(songId) {
  return {
    type: ON_ACCESS_SEARCH_RESULT_ACTION,
    songId,
  }
}

export function clearSearchSongs() {
  return {
    type: CLEAR_SEARCH,
  }
}

export function searchSongs({ filters, searchTerm, sortBy, exact, fields }) {
  return async function(dispatch, getState) {
    dispatch(requestSearchSongs({ filters, searchTerm, sortBy, exact }))
    const queryLog = getState().autocompleteSongs.queryLog
    const response = await songsApi.search({
      searchTerm,
      exact,
      queryLog,
      fields,
    })

    return dispatch(
      receiveSearchSongs({
        filters,
        searchTerm,
        songs: response.data,
        sortBy,
        exact,
      })
    )
  }
}

export function filterSearchSongs({ filters, sortBy }) {
  return {
    type: FILTER_SEARCH_SONGS,
    filters: { ...songsApi.defaultFilters, ...filters },
    sortBy,
  }
}
