import { CURRENCY_SYMBOL_MAP } from '../../shared/utils'
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
} from 'react-intl'
import { Heading } from '../typography'
import { PROMOS } from '../../shared/utils'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  firstImage: {
    id: 'djcity.common.campaign.free_download_pack.footer.first.image.source',
    defaultMessage:
      'https://www.artlimited.net/user/1/0/5/3/7/9/9/11816402/image-desert-large-open.jpg',
  },
  secondImage: {
    id: 'djcity.common.campaign.free_download_pack.footer.second.image.source',
    defaultMessage:
      'https://www.artlimited.net/user/1/0/5/3/7/9/9/11816402/image-desert-large-open.jpg',
  },
  thirdImage: {
    id: 'djcity.common.campaign.free_download_pack.footer.third.image.source',
    defaultMessage:
      'https://www.artlimited.net/user/1/0/5/3/7/9/9/11816402/image-desert-large-open.jpg',
  },
  firstImageAlt: {
    id: 'djcity.common.campaign.free_download_pack.footer.first.image.alt',
    defaultMessage: 'First image alt description',
  },
  secondImageAlt: {
    id: 'djcity.common.campaign.free_download_pack.footer.second.image.alt',
    defaultMessage: 'Second image alt description',
  },
  thirdImageAlt: {
    id: 'djcity.common.campaign.free_download_pack.footer.third.image.alt',
    defaultMessage: 'Third image alt description',
  },
})

function FreePackCampaignFooter({ intl }) {
  const activePromo = useSelector(state => state.promos.activePromo)
  const paymentConfiguration = useSelector(
    state => state.currentUserBilling.paymentConfiguration
  )
  return (
    <>
      <Heading className={styles.heading}>
        <FormattedHTMLMessage
          defaultMessage="Selected Features Included"
          id="djcity.common.campaign.free_download_pack.footer.title"
        />
      </Heading>
      <div className={styles.hScroll}>
        <div className={styles.squares}>
          <img
            alt={intl.formatMessage(localizedMessages.firstImageAlt)}
            src={intl.formatMessage(localizedMessages.firstImage)}
          />
          <p className={styles.imageSubtitle}>
            <FormattedMessage
              defaultMessage="CURATED LIST"
              id="djcity.common.campaign.free_download_pack.footer.first.image.subtitle"
            />
          </p>
        </div>
        <div className={styles.squares}>
          <img
            alt={intl.formatMessage(localizedMessages.secondImageAlt)}
            src={intl.formatMessage(localizedMessages.secondImage)}
          />
          <p className={styles.imageSubtitle}>
            <FormattedMessage
              defaultMessage="1 SUBSCRIPTION GLOBAL POOLS"
              id="djcity.common.campaign.free_download_pack.footer.second.image.subtitle"
            />
          </p>
        </div>
        <div className={styles.squares}>
          <img
            alt={intl.formatMessage(localizedMessages.thirdImageAlt)}
            src={intl.formatMessage(localizedMessages.thirdImage)}
          />
          <p className={styles.imageSubtitle}>
            <FormattedMessage
              defaultMessage="EXCLUSIVE REMIXERS"
              id="djcity.common.campaign.free_download_pack.footer.third.image.subtitle"
            />
          </p>
        </div>
      </div>
      {activePromo === PROMOS.WINBACK && paymentConfiguration && (
        <div className={styles.subtitle}>
          <FormattedMessage
            defaultMessage="*{currency}{price} for the first month {currency}{plan} per month thereafter. Cancel anytime."
            id="djcity.account.login.campaign.welcome_back.footer.title"
            values={{
              currency: CURRENCY_SYMBOL_MAP[paymentConfiguration.currency],
              price: paymentConfiguration.price,
              plan: paymentConfiguration.plan,
            }}
          />
        </div>
      )}
    </>
  )
}

FreePackCampaignFooter.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(FreePackCampaignFooter)
