import { SET_DARK_MODE } from '../action_constants'

export default function darkMode(state = false, action) {
  switch (action.type) {
    case SET_DARK_MODE:
      return !!action.value

    default:
      return state
  }
}
