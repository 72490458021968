import { defineMessages, FormattedMessage } from 'react-intl'
import { FilterPanel } from './filter_panel'
import { injectIntl } from 'react-intl'
import { RadioButton } from '../radio_button'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

export const RELEASE_DATE_LABELS = defineMessages({
  '1W': {
    defaultMessage: '1 Week',
    id: 'djcity.records.release.date.label.1week',
  },
  '2W': {
    defaultMessage: '2 Week',
    id: 'djcity.records.release.date.label.2week',
  },
  '1M': {
    defaultMessage: '1 Month',
    id: 'djcity.records.release.date.label.1month',
  },
  '1Y': {
    defaultMessage: '1 Year',
    id: 'djcity.records.release.date.label.1year',
  },
})

export const RELEASE_DATE_VALUES = Object.keys(RELEASE_DATE_LABELS)

const ReleaseDateFilterPanel = ({
  className,
  intl,
  isMobile,
  onChange,
  value,
}) => {
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e.target.id)
      }
    },
    [onChange]
  )

  return isMobile ? (
    RELEASE_DATE_VALUES.map(releaseDate => (
      <RadioButton
        checked={value === releaseDate}
        className={styles.radioButton}
        id={releaseDate}
        key={releaseDate}
        labelText={intl.formatMessage(RELEASE_DATE_LABELS[releaseDate])}
        name="releaseDate"
        onChange={handleChange}
      />
    ))
  ) : (
    <FilterPanel
      className={className}
      title={
        <FormattedMessage
          defaultMessage="Date Range"
          id="djcity.common.filter.dateRange"
        />
      }
    >
      {RELEASE_DATE_VALUES.map(releaseDate => (
        <RadioButton
          checked={value === releaseDate}
          className={styles.radioButton}
          id={releaseDate}
          key={releaseDate}
          labelText={intl.formatMessage(RELEASE_DATE_LABELS[releaseDate])}
          name="releaseDate"
          onChange={handleChange}
        />
      ))}
    </FilterPanel>
  )
}

ReleaseDateFilterPanel.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

const IntlReleaseDateFilterPanel = injectIntl(ReleaseDateFilterPanel)

export { IntlReleaseDateFilterPanel as ReleaseDateFilterPanel }
