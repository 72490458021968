import { useCallback, useState } from 'react'

export function useFieldValidation(value, validator = null) {
  const [showError, setShowError] = useState(false)

  const errorMessage = validator ? validator(value) : null

  const onFocus = useCallback(() => setShowError(false), [setShowError])
  const onBlur = useCallback(() => setShowError(!!errorMessage), [
    setShowError,
    errorMessage,
  ])

  const props = { onFocus, onBlur }

  return [props, errorMessage, showError]
}
