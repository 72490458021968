import * as currentUserApi from '../api/current_user'
import { getPreferences } from './preferences'
import { loggedInSelector } from '../selectors/users'
import { SET_LOCALE } from 'spa/action_constants'

export function updateLocale(locale) {
  return {
    type: SET_LOCALE,
    locale,
  }
}
export function setLocale(locale) {
  return async function(dispatch, getState) {
    const state = getState()
    const isLoggedIn = loggedInSelector(state)
    const currentLocale = state.locale.locale

    if (currentLocale === locale) {
      return null
    }

    if (isLoggedIn) {
      await currentUserApi.updateUser({ locale })
      dispatch(getPreferences())
    }
    return dispatch(updateLocale(locale))
  }
}
