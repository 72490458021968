import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../shared/utils'

export function download({ rid, tid }) {
  return fetchWithHeaders(
    `/v1/songs/download?${getQueryParams({
      rid,
      tid,
    })}`,
    {
      method: 'GET',
    }
  ).then(parseResponse)
}

export function markDownloaded({ rid, tid }) {
  return fetchWithHeaders(
    `/v1/songs/downloaded?${getQueryParams({
      rid,
      tid,
    })}`,
    {
      method: 'GET',
    }
  ).then(parseResponse)
}
