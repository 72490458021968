import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import {
  FORGOT_PASSWORD_MODAL,
  LOGIN_MODAL,
  openModal,
  SIGNUP_MODAL,
} from 'spa/action_creators/modals'
import { Label } from 'spa/components/label'
import { login } from 'spa/action_creators/current_user'
import { SpinnerButton } from 'spa/components/button'
import { TextInput } from 'spa/components/text_input'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParameters } from 'spa/hooks/use_query_parameters'
import ApplicationModal from 'spa/components/application_modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { SHOW_DEFAULT_LOGIN_HEADER_PROMOS } from '../../shared/utils'
import CampaignLayer from '../campaign_layer/campaign_layer'
import styles from './styles'

const localizedMessages = defineMessages({
  email: {
    id: 'djcity.account.login.email',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'djcity.account.login.password',
    defaultMessage: 'Password',
  },
})

const defaultQueryParameters = {
  referrer: undefined,
}

function LoginModal({ intl }) {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.modals[LOGIN_MODAL].enabled)
  const isLoggingIn = useSelector(state => state.isLoggingIn)
  const activePromo = useSelector(state => state.promos.activePromo)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [{ referrer }] = useQueryParameters(defaultQueryParameters)

  const onSignupClick = useCallback(() => {
    dispatch(openModal(SIGNUP_MODAL))
  }, [dispatch])

  const onForgotPasswordClick = useCallback(() => {
    dispatch(openModal(FORGOT_PASSWORD_MODAL))
  }, [dispatch])

  useEffect(() => {
    setUsername('')
    setPassword('')
  }, [isOpen, setUsername, setPassword])

  const isDisabled = !username || !password || isLoggingIn

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isDisabled) {
        dispatch(
          login({
            username,
            password,
            referrer,
          })
        )
      }
    },
    [isDisabled, username, password, referrer]
  )

  return (
    <ApplicationModal
      modalKey={LOGIN_MODAL}
      title={
        (SHOW_DEFAULT_LOGIN_HEADER_PROMOS.includes(activePromo) ||
          !activePromo) && (
          <FormattedMessage
            defaultMessage="Login"
            id="djcity.account.login.login"
          />
        )
      }
    >
      <CampaignLayer isLoginScreen>
        <form className={styles.loginForm} onSubmit={onSubmit}>
          <Label
            className={styles.label}
            text={intl.formatMessage(localizedMessages.email)}
          >
            <TextInput
              className={styles.input}
              onChange={e => setUsername(e.currentTarget.value)}
              value={username}
            />
          </Label>
          <Label
            className={styles.label}
            text={intl.formatMessage(localizedMessages.password)}
          >
            <TextInput
              className={styles.input}
              onChange={e => setPassword(e.currentTarget.value)}
              type="password"
              value={password}
            />
          </Label>
          <button
            className={classNames('cleanButton', styles.forgotPassword)}
            onMouseDown={onForgotPasswordClick}
          >
            <FormattedMessage
              defaultMessage="Forgot Password"
              id="djcity.account.login.forgot_password"
            />
          </button>
          <SpinnerButton
            centered="centered"
            disabled={isDisabled}
            loading={isLoggingIn}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Login"
              id="djcity.account.login.login"
            />
          </SpinnerButton>
          <button
            className={classNames('cleanButton', styles.signUpButton)}
            onClick={onSignupClick}
          >
            <FormattedMessage
              defaultMessage="Don't have an account yet?"
              id="djcity.account.signup.noaccount"
            />
            &nbsp;
            <span className={styles.signupLink}>
              <FormattedMessage
                defaultMessage="Sign Up"
                id="djcity.account.signup.signup"
              />
            </span>
          </button>
        </form>
      </CampaignLayer>
    </ApplicationModal>
  )
}

LoginModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(LoginModal)
