import {
  CLOSE_MODAL,
  OPEN_MODAL,
  UPDATE_CURRENT_USER,
} from '../action_constants'
import {
  FORGOT_PASSWORD_MODAL,
  LOGIN_MODAL,
  PLAYBACK_ERROR_MODAL,
  RESET_PASSWORD_MODAL,
  SHORTCUTS_MODAL,
  SIGNUP_MODAL,
  UPDATE_PASSWORD_MODAL,
  WARNING_BANNER,
  WARNING_MODAL,
} from '../action_creators/modals'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  [SHORTCUTS_MODAL]: { enabled: false, data: {} },
  [LOGIN_MODAL]: { enabled: false, data: {} },
  [SIGNUP_MODAL]: { enabled: false, data: {} },
  [FORGOT_PASSWORD_MODAL]: { enabled: false, data: {} },
  [UPDATE_PASSWORD_MODAL]: { enabled: false, data: {} },
  [RESET_PASSWORD_MODAL]: { enabled: false, data: {} },
  [PLAYBACK_ERROR_MODAL]: { enabled: false, data: {} },
  [WARNING_MODAL]: { enabled: false, data: {} },
  [WARNING_BANNER]: { enabled: false, data: {} },
}

export default function modals(state = initialState, action) {
  switch (action.type) {
    case CLOSE_MODAL:
      if (state[action.modal] === undefined) {
        return initialState
      }
      return {
        ...state,
        [action.modal]: {
          enabled: false,
          data: {},
        },
      }
    case LOCATION_CHANGE:
    case UPDATE_CURRENT_USER:
      return initialState

    case OPEN_MODAL:
      if (state[action.modal] === undefined) {
        return initialState
      }
      return {
        ...initialState,
        [action.modal]: {
          enabled: true,
          data: action.data,
        },
      }

    default:
      return state
  }
}
