import { defineMessages } from 'react-intl'
import { FilterPanel } from './filter_panel'
import { injectIntl } from 'react-intl'
import { RadioButton } from '../radio_button'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles'

// NOTE: These mappings match those of the API and should not be changed
export const SORT_OPTIONS = {
  RELEVANCE: 9,
  DATE_DESC: 1,
  DATE_ASC: 2,
  /*
  ARTIST_ASC: 3,
  ARTIST_DESC: 4,
  */
  TITLE_ASC: 5,
  TITLE_DESC: 6,
  BPM_ASC: 7,
  BPM_DESC: 8,
  POPULARITY: 9,
  LAST_MODIFIED_DATE_DESC: 10,
}

export const SORT_LABEL = {
  1: 'Date (newest first)',
  2: 'Date (oldest first)',
  /*
  3: "Artist (a-z)",
  4: "Artist (z-a)",
  */
  5: 'Title (a-z)',
  6: 'Title (z-a)',
  7: 'BPM (lowest first)',
  8: 'BPM (highest first)',
  9: 'Popularity',
  10: 'Last Modified (newest first)',
}

export const SORT_TO_LABEL = defineMessages({
  DATE_DESC: {
    defaultMessage: 'Date (newest first)',
    id: 'djcity.common.sort.label.date.desc',
  },
  DATE_ASC: {
    defaultMessage: 'Date (oldest first)',
    id: 'djcity.common.sort.label.date.asc',
  },
  ARTIST_ASC: {
    defaultMessage: 'Artist (a-z)',
    id: 'djcity.common.sort.label.artist.asc',
  },
  ARTIST_DESC: {
    defaultMessage: 'Artist (z-a)',
    id: 'djcity.common.sort.label.artist.desc',
  },
  TITLE_ASC: {
    defaultMessage: 'Title (a-z)',
    id: 'djcity.common.sort.label.title.asc',
  },
  TITLE_DESC: {
    defaultMessage: 'Title (z-a)',
    id: 'djcity.common.sort.label.title.desc',
  },
  BPM_ASC: {
    defaultMessage: 'BPM (lowest first)',
    id: 'djcity.common.sort.label.bpm.asc',
  },
  BPM_DESC: {
    defaultMessage: 'BPM (highest first)',
    id: 'djcity.common.sort.label.bpm.desc',
  },
  RELEVANCE: {
    defaultMessage: 'Relevance',
    id: 'djcity.common.sort.label.relevance.asc',
  },
  POPULARITY: {
    defaultMessage: 'Popularity',
    id: 'djcity.common.sort.label.popularity.asc',
  },
  LAST_MODIFIED_DATE_DESC: {
    defaultMessage: 'Last Modified (newest first)',
    id: 'djcity.common.sort.label.modified.date.desc',
  },
  SORT_BY: {
    defaultMessage: 'Sort By',
    id: 'djcity.common.sort_by',
  },
})

const ID_PREFIX = 'sort-'

function parseValFromId(id) {
  return parseInt(id.replace(ID_PREFIX, ''))
}

function formatValAsId(val) {
  return `${ID_PREFIX}${val.toString()}`
}

const SortPanel = ({
  intl,
  isFromGenres,
  isFromSearch,
  isMobile,
  onChange,
  value,
}) => {
  const getSortOptions = useCallback(() => {
    if (isFromGenres) {
      return omit(SORT_OPTIONS, [
        'RELEVANCE',
        'DATE_DESC',
        'DATE_ASC',
        'BPM_ASC',
        'BPM_DESC',
        'LAST_MODIFIED_DATE_DESC',
      ])
    } else if (isFromSearch) {
      return omit(SORT_OPTIONS, [
        'POPULARITY',
        'TITLE_ASC',
        'TITLE_DESC',
        'LAST_MODIFIED_DATE_DESC',
      ])
    } else {
      return omit(SORT_OPTIONS, [
        'RELEVANCE',
        'TITLE_ASC',
        'TITLE_DESC',
        'LAST_MODIFIED_DATE_DESC',
      ])
    }
  }, [isFromGenres, isFromSearch])

  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(parseValFromId(e.target.id))
      }
    },
    [onChange]
  )

  return isMobile ? (
    Object.entries(getSortOptions()).map(([key, val]) => (
      <RadioButton
        checked={value === val}
        className={styles.radioButton}
        id={formatValAsId(val)}
        key={val}
        labelText={intl.formatMessage(SORT_TO_LABEL[key])}
        name="sortBy"
        onChange={handleChange}
      />
    ))
  ) : (
    <FilterPanel title={intl.formatMessage(SORT_TO_LABEL.SORT_BY)}>
      {Object.entries(getSortOptions()).map(([key, val]) => (
        <RadioButton
          checked={value === val}
          className={styles.radioButton}
          id={formatValAsId(val)}
          key={val}
          labelText={intl.formatMessage(SORT_TO_LABEL[key])}
          name="sortBy"
          onChange={handleChange}
        />
      ))}
    </FilterPanel>
  )
}

SortPanel.displayName = 'SortPanel'

SortPanel.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isFromGenres: PropTypes.bool,
  isFromSearch: PropTypes.bool,
  isMobile: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number,
}

const ConnectedSortPanel = injectIntl(SortPanel)

export { ConnectedSortPanel as SortPanel }
