import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import React from 'react'
import Styles from './styles'

export default withRouter(({ history }) => {
  return (
    <div className={Styles.footer}>
      <ul className={Styles.links}>
        <li className={Styles.link}>
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              history.push('/')
            }}
          >
            <FormattedMessage
              defaultMessage="Home"
              id="djcity.common.master.footer.home.v2"
            />
          </a>
        </li>
        <li className={Styles.link}>
          <a
            href="/terms-of-use"
            onClick={e => {
              e.preventDefault()
              history.push('/terms-of-use')
            }}
          >
            <FormattedMessage
              defaultMessage="Terms of Use"
              id="djcity.common.terms_of_use"
            />
          </a>
        </li>
        <li className={Styles.link}>
          <a
            href="/privacy-policy"
            onClick={e => {
              e.preventDefault()
              history.push('/privacy-policy')
            }}
          >
            <FormattedMessage
              defaultMessage="Privacy Policy"
              id="djcity.common.master.footer.privacy.v2"
            />
          </a>
        </li>
        <li className={Styles.link}>
          <a
            href="/about-us"
            onClick={e => {
              e.preventDefault()
              history.push('/about-us')
            }}
          >
            <FormattedMessage
              defaultMessage="About Us"
              id="djcity.common.about_us"
            />
          </a>
        </li>
        <li className={Styles.link}>
          <a
            href="/contact-us"
            onClick={e => {
              e.preventDefault()
              history.push('/contact-us')
            }}
          >
            <FormattedMessage
              defaultMessage="Contact Us"
              id="djcity.common.master.footer.contact.v2"
            />
          </a>
        </li>
        <li className={Styles.link}>
          <a href="/faq">
            <FormattedMessage
              defaultMessage="FAQ"
              id="djcity.common.footer.faq"
            />
          </a>
        </li>
      </ul>
    </div>
  )
})
