import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

export const Subheading = ({ className, ...rest }) => {
  const cls = classNames(styles.subheading, className)
  return <h2 className={cls} {...rest} />
}

Subheading.displayName = 'Subheading'

Subheading.propTypes = {
  className: PropTypes.string,
}
