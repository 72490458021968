import { connect } from 'react-redux'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { Heading, TextStyle } from '../../typography'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  lightsOut: {
    id: 'djcity.common.images.lights_out',
    defaultMessage: '/assets/cdn/djcity_lights_out.png',
  },
})

class MaintenancePage extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { intl } = this.props
    return (
      <div className={styles.maintenance}>
        <img
          className={styles.maintenanceImage}
          src={intl.formatMessage(localizedMessages.lightsOut)}
        />
        <Heading className={styles.heading}>
          <FormattedMessage
            defaultMessage="Sorry, DJcity is currently down for scheduled maintenance. We will return shortly."
            id="djcity.common.maintenance.header"
          />
        </Heading>
        <TextStyle className={styles.subtitle} color="black">
          <FormattedMessage
            defaultMessage="Check out DJcity's latest news while you wait."
            id="djcity.common.maintenance.text"
          />{' '}
          <a className={styles.link} href="https://news.djcity.com/">
            <FormattedMessage
              defaultMessage="DJcity News"
              id="djcity.common.maintenance.link"
            />
          </a>
        </TextStyle>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    locale: state.locale.locale,
  }
}
export default injectIntl(withRouter(connect(mapStateToProps)(MaintenancePage)))
