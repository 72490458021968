import { Layout } from '../../layout'
import React from 'react'
import ReturnPolicy from './returnPolicy'

const display = () => {
  return (
    <Layout tabTitle="return_policy">
      <ReturnPolicy />
    </Layout>
  )
}

export { display as ReturnPolicyDisplay }

export { ReturnPolicy }

export default ReturnPolicy
