import Maintenance from './maintenance'
import React from 'react'

const display = () => {
  return <Maintenance />
}

export { display as MaintenanceDisplay }

export { Maintenance }

export default Maintenance
