import {
  activeSubscriptionSelector,
  desktopClientDownloadedSelector,
} from 'spa/selectors/users'
import { addAllTracksToCrate, removeAllTracks } from 'spa/action_creators/crate'
import { AVAILABLE_FEATURES, isFeatureEnabled } from 'spa/selectors/features'
import { batchDownload, throwDownloadError } from 'spa/action_creators/tracks'
import { ChangePropType } from 'spa/api/songs'
import { CrateAllTracksButton } from 'spa/components/track_buttons/crate_all_tracks_button'
import { defineMessages, injectIntl } from 'react-intl'
import { DownloadAllTracksButton } from 'spa/components/track_buttons/download_all_tracks_button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobileSelector, isSafariSelector } from '../../selectors/device'
import { loggedInSelector } from 'spa/selectors/users'
import { MAX_DOWNLOAD_COUNT } from 'spa/shared/utils'
import { sendAllTracksToDownloader } from 'spa/action_creators/download_queue'
import { SendAllTracksToDownloaderButton } from 'spa/components/track_buttons/send_all_tracks_to_downloader_button'
import { TableSeparator } from 'spa/components/table/table_separator'
import { TextStyle } from 'spa/components/typography'
import { tracksInDownloadQueue } from 'spa/selectors/songs'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  allVersions: {
    id: 'djcity.records.queue.track.all_tracks.title',
    defaultMessage: 'Add Versions',
  },
})

const TrackDropdownTableAllTracksRow = injectIntl(
  ({ intl, songId, trackList }) => {
    const dispatch = useDispatch()
    const songsById = useSelector(state => state.songs.songsById)
    const tracks = songsById[songId].types

    const activeSubscription = useSelector(activeSubscriptionSelector)
    const loggedIn = useSelector(loggedInSelector)
    const desktopClientDownloaded = useSelector(desktopClientDownloadedSelector)
    const isBulkDownloadEnabled = useSelector(state =>
      isFeatureEnabled(state.features, AVAILABLE_FEATURES.BULK_DOWNLOAD)
    )
    const isMobile = useSelector(isMobileSelector)
    const isSafari = useSelector(isSafariSelector)
    const isIosSafari = isSafari && isMobile
    const trackIds = tracks.map(track => parseInt(track.ttid))
    const tracksInQueue = useSelector(state =>
      tracksInDownloadQueue(state, { songId, trackIds })
    )
    const someVersionsReachedLimit =
      Array.isArray(tracks) &&
      tracks.some(({ downloadCount }) => downloadCount >= MAX_DOWNLOAD_COUNT)
    const someVersionsAreBeingDownloaded =
      Array.isArray(tracks) && tracks.some(({ isDownloading }) => isDownloading)
    const allVersionsDownloaded =
      Array.isArray(tracks) &&
      tracks.every(({ downloadCount }) => downloadCount >= 1)
    const allVersionsInCrate = tracks.every(track => track.inCrate)

    const crateAllEnabled =
      loggedIn && !someVersionsReachedLimit && !allVersionsInCrate

    const crateIconClass = crateAllEnabled
      ? null
      : allVersionsInCrate
      ? styles.iconActive
      : styles.iconDisabled

    const downloadAllEnabled =
      loggedIn &&
      activeSubscription &&
      !someVersionsReachedLimit &&
      !someVersionsAreBeingDownloaded &&
      isBulkDownloadEnabled &&
      !isIosSafari

    const downloadAllIconClass = downloadAllEnabled ? null : styles.iconDisabled

    const downloaderEnabled =
      loggedIn &&
      activeSubscription &&
      desktopClientDownloaded &&
      !someVersionsReachedLimit

    const downloaderIconClass = !downloaderEnabled
      ? styles.iconDisabled
      : tracksInQueue
      ? styles.iconActive
      : null

    /**
     * Event handlers
     */
    const handleCrateAllClick = useCallback(() => {
      if (!loggedIn) {
        return dispatch(throwDownloadError())
      } else {
        if (allVersionsInCrate) {
          dispatch(removeAllTracks({ songId }))
        } else {
          dispatch(addAllTracksToCrate({ songId, trackList }))
        }
      }
    }, [allVersionsInCrate, loggedIn, songId, trackList, dispatch])

    const handleSendAllToDownloaderClick = useCallback(() => {
      dispatch(sendAllTracksToDownloader({ songId, trackList }))
    }, [songId, trackList])

    const handleDownloadAllClick = useCallback(async () => {
      dispatch(batchDownload({ songId, trackList }))
    }, [songId, trackList])

    return (
      <>
        <TableSeparator />
        <div className={styles.versionCell}>
          <div className={styles.check}>
            {allVersionsDownloaded ? <FontAwesomeIcon icon="check" /> : null}
          </div>
          <TextStyle className={styles.versionText} variant="extra-bold">
            {intl.formatMessage(localizedMessages.allVersions)}
          </TextStyle>
        </div>
        <div className={styles.functionsCell}>
          <DownloadAllTracksButton
            className={downloadAllIconClass}
            onClick={handleDownloadAllClick}
          />
        </div>
        <div className={styles.functionsCell}>
          <CrateAllTracksButton
            className={styles.crateButton}
            iconClassName={crateIconClass}
            onClick={handleCrateAllClick}
          />
        </div>
        <div className={styles.functionsCell}>
          <SendAllTracksToDownloaderButton
            className=""
            iconClassName={downloaderIconClass}
            onClick={handleSendAllToDownloaderClick}
          />
        </div>
      </>
    )
  }
)

TrackDropdownTableAllTracksRow.propTypes = {
  change: ChangePropType,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  songId: PropTypes.number.isRequired,
  trackList: PropTypes.string,
  tracks: PropTypes.array.isRequired,
}

export { TrackDropdownTableAllTracksRow }
