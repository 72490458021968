import {
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  getRecommended,
  hideRecommendation,
} from '../api/songs'
import {
  ERROR_HIDE_RECOMMENDED_SONG,
  RECEIVE_RECOMMENDED_SONGS,
  REQUEST_RECOMMENDED_SONGS,
} from '../action_constants'

import { createSongsListActionCreator } from './create_songs_list_action_creator'
import {
  RECEIVE_HIDE_RECOMMENDED_SONG,
  REMOVE_SONG_IN_LIST,
  REQUEST_HIDE_RECOMMENDED_SONGS,
} from '../action_constants'

export const fetchRecommendedSongs = createSongsListActionCreator({
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_RECOMMENDED_SONGS,
  receiveActionConstant: RECEIVE_RECOMMENDED_SONGS,
  apiMethod: getRecommended,
  reducerName: 'recommendedSongs',
  clearActionConstant: REMOVE_SONG_IN_LIST,
})

export function removeSongInList(rid) {
  return {
    type: REMOVE_SONG_IN_LIST,
    filters: defaultFilters,
    pagination: defaultPagination,
    sortBy: defaultSortBy,
    rid,
  }
}

function removingRecommendedSong(rid) {
  return { type: REQUEST_HIDE_RECOMMENDED_SONGS, rid }
}

function removedRecommendedSong(rid) {
  return { type: RECEIVE_HIDE_RECOMMENDED_SONG, rid }
}

function errorRemovingRecommendedSong(rid) {
  return { type: ERROR_HIDE_RECOMMENDED_SONG, rid }
}

export function removeRecommendation(rid) {
  return async function(dispatch) {
    dispatch(removingRecommendedSong(rid))
    try {
      await hideRecommendation(rid)
      dispatch(removedRecommendedSong(rid))
      dispatch(removeSongInList(rid))
      // Refresh Recommended List
      dispatch(fetchRecommendedSongs())
    } catch (e) {
      dispatch(errorRemovingRecommendedSong(e))
    }
  }
}
