import {
  closeModal,
  openModal,
  SHORTCUTS_MODAL,
} from '../../action_creators/modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const Shortcuts = () => {
  const isOpen = useSelector(state => state.modals[SHORTCUTS_MODAL].enabled)
  const dispatch = useDispatch()

  const toggleModal = () => {
    isOpen ? dispatch(closeModal()) : dispatch(openModal(SHORTCUTS_MODAL))
  }

  return (
    <button className={styles.scButton} onClick={toggleModal}>
      <FontAwesomeIcon icon={['fal', 'info-circle']} size="2x" />
    </button>
  )
}

Shortcuts.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
}

export default Shortcuts
