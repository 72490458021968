import * as geoApi from '../api/geo'
import { createSlice } from '@reduxjs/toolkit'

const countries = createSlice({
  name: 'countries',

  initialState: {
    isFetching: false,
    countries: null,
    error: null,
  },

  reducers: {
    requestCountries: () => ({
      isFetching: true,
      countries: [],
      error: null,
    }),

    receiveCountries: (state, action) => ({
      isFetching: false,
      countries: action.payload.countries.data,
      error: null,
    }),

    errorReceivingCountries: (state, action) => ({
      isFetching: false,
      countries: [],
      error: action.payload.error,
    }),
  },
})

const countriesReducer = countries.reducer

export { countriesReducer as countries }

export const {
  requestCountries,
  receiveCountries,
  errorReceivingCountries,
} = countries.actions

/**
 * Request selectable territories
 * @returns {function(...[*]=)}
 */
export const getCountries = () => async dispatch => {
  try {
    dispatch(requestCountries())
    const countries = await geoApi.getCountries()
    return dispatch(receiveCountries({ countries }))
  } catch (error) {
    return dispatch(errorReceivingCountries({ error }))
  }
}
