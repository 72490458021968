import { defaultFilters, defaultPagination, defaultSortBy } from '../api/songs'
import {
  RECEIVE_RECOMMENDED_SONGS,
  REQUEST_RECOMMENDED_SONGS,
} from '../action_constants'

import { createSongsListReducer } from './create_songs_list_reducer'
import { REMOVE_SONG_IN_LIST } from '../action_constants'

export default createSongsListReducer({
  defaultFilters,
  defaultPagination,
  defaultSortBy,
  requestActionConstant: REQUEST_RECOMMENDED_SONGS,
  receiveActionConstant: RECEIVE_RECOMMENDED_SONGS,
  removeActionConstant: REMOVE_SONG_IN_LIST,
})
